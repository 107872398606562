//import Dashboard from '@assets/img/dashboard-icon.svg';
//import Exposure from '@assets/img/exposure-icon.svg';
//import CashManag from '@assets/img/cash-manag.svg';
//import Rebalancing from '@assets/img/rebalancing.svg';
//import Performance from '@assets/img/performance.svg';
//import Reports from '@assets/img/reports.svg';
//import OprationManag from '@assets/img/opr-manag.svg';
//import Setting from '@assets/img/setting-icon.svg';
//import TradeManag from '@assets/img/order-management.svg';

export const SidebarConfig = [
  {
    displayName: 'Dashboard',
    path: '/dashboard',
    iconImg: 'dashboard',
    exact: true,
  },
  {
    displayName: 'Exposure',
    path: '/exposure',
    iconImg: 'chart',
  },
  {
    displayName: 'Cash Management',
    path: '/cash-management',
    iconImg: 'dollar',
  },
  {
    displayName: 'Trade Management',
    path: '/trade-management',
    iconImg: 'exchange',
  },
  {
    displayName: 'What-If Rebalancing',
    path: '/what-if-rebal',
    iconImg: 'refresh',
  },
  /*{
    displayName: 'Rebalancing',
    path: '/rebalancing',
    iconImg: Rebalancing,
  },
  {
    displayName: 'Performance',
    path: '/performance',
    iconImg: Performance,
  },
  {
    displayName: 'Reports',
    path: '/reports',
    iconImg: Reports,
  },*/
  {
    displayName: 'Operations Management',
    path: '/opsmanagement',
    iconImg: 'settings',
  },
  {
    displayName: 'Document Management',
    path: '/doc-management/funds',
    iconImg: 'folder-open',
  },
  {
    displayName: 'Administration',
    path: '/administration/funds',
    iconImg: 'cog',
    className: 'administration-list',
  },
];

import codes from 'country-calling-code';

interface IGetCountryDataFromCallingCode {
  value: string;
}
/** Utility function to get country data i.e such as country name, code(ISO2,ISO3) from calling code(i.e '91' for India , '1' for 'USA'
 and 'Canada')  */
const getCountryDataFromCallingCode = ({ value }: IGetCountryDataFromCallingCode): string => {
  let resultCountryISO3Code = '-';
  if (!value || Number.isNaN(value)) return '-';

  // console.log('Result Countries: ' + codes.map((code) => console.log(code.country + '--->' + code.countryCodes)));
  const resultCountries = codes.filter((code) => {
    return code.countryCodes.indexOf(value) > -1;
  });

  // console.log('Result Countries Test: ' + resultCountries.map((_) => _.country + ' ---> ' + _.countryCodes));
  console.log('Result Countries Length: ' + resultCountries.length);

  if (resultCountries.length > 0) {
    // Special case: Check for calling code "1" and make it default to "USA" as per current use case as both 'USA' and 'Canada' have same calling code as "1"
    if (value === '1') {
      resultCountryISO3Code = resultCountries.filter((country) => country.isoCode3.indexOf(CountryCode.USA) > -1)[0]
        .isoCode3;
    } else {
      resultCountryISO3Code = resultCountries.filter((country) => country.isoCode3)[0].isoCode3;
    }
  }

  return resultCountryISO3Code;
};

export enum CountryCode {
  USA = 'USA',
  CAN = 'CAN',
}

export default getCountryDataFromCallingCode;

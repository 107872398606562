import { FC, ReactElement } from 'react';
import { Icon, Tag } from '@blueprintjs/core';

interface ITradeStatusBadgeProps {
  type: string;
  text: string;
}

const getBadgeColor = (type: string) => {
  let color = '#6c757d';
  switch (type.toLowerCase()) {
    case 'pending approval':
      color = '#f5b540';
      break;
    case 'approved':
      color = '#03d618';
      break;
    case 'rejected':
      color = '#f23737';
      break;
    case 'pending trade':
      color = '#ff6b00';
      break;
    case 'traded':
      color = '#4b85cd';
      break;
    default:
      color = '#6c757d';
  }
  return color;
};

const TradeStatusBadge: FC<ITradeStatusBadgeProps> = ({ type, text }): ReactElement => {
  const badgeColor = getBadgeColor(type);
  return (
    <Tag
      minimal={true}
      round={true}
      icon={<Icon icon="full-circle" color={badgeColor} size={8}></Icon>}
      style={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: badgeColor,
        background: 'transparent',
        fontSize: 14,
      }}
    >
      <span>{text}</span>
    </Tag>
  );
};

export default TradeStatusBadge;

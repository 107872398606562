import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetDocMgmtFileUploadUrlResponse } from '@shared/exchange/getDocMgmtFileUploadUrl';

interface IGetDocMgmtFileUploadUrlRequestData {
  externalInd: boolean;
  folderId: number;
  fileName: string;
  contentType: string;
}

export default function useGetDocMgmtFileUploadUrl(): UseMutationResult<
  IGetDocMgmtFileUploadUrlResponse,
  IErrorResponse,
  IGetDocMgmtFileUploadUrlRequestData,
  IGetDocMgmtFileUploadUrlRequestData
> {
  const axios = useAxios();
  return useMutation<
    IGetDocMgmtFileUploadUrlResponse,
    IErrorResponse,
    IGetDocMgmtFileUploadUrlRequestData,
    IGetDocMgmtFileUploadUrlRequestData
  >(async (data: IGetDocMgmtFileUploadUrlRequestData) => {
    const searchParams = new URLSearchParams();
    searchParams.append('externalInd', String(data.externalInd));
    searchParams.append('folderId', String(data.folderId));
    searchParams.append('fileName', String(data.fileName));
    searchParams.append('contentType', String(data.contentType));
    return axios
      ?.get(`get-doc-mgmt-file-upload-url?${searchParams.toString()}`)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      });
  });
}

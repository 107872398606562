import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import { FC } from 'react';

interface ITradeOrderCreationInfoPanelProps {
  totalCash: number;
  totalBuyAmount: number;
  totalSellAmount: number;
}

const TradeOrderCreationInfoPanel: FC<ITradeOrderCreationInfoPanelProps> = ({
  totalCash,
  totalBuyAmount,
  totalSellAmount,
}) => {
  return (
    <div className="totalCashData">
      <div className="cashSum-wrap">
        <div className="cashSum">
          <label>Initial Cash:</label>
          <span>{formatAmountRoundTwo({ value: totalCash })} </span>
        </div>

        <div className="cashSum">
          <label>Total Buy(s):</label>
          <span>{formatAmountRoundTwo({ value: totalBuyAmount })} </span>
        </div>

        <div className="cashSum">
          <label>Total Sell(s):</label>
          <span>{formatAmountRoundTwo({ value: totalSellAmount })} </span>
        </div>
        <div className="cashSum">
          <label>Ending Cash:</label>
          {/* Ending Cash computation had issues during summation if any item is NaN*/}
          {/* For Eg. totalCash was NaN. So adding with existing definite values produced the result as 0.00 eventhough it was a definite value */}
          <span>
            {formatAmountRoundTwo({ value: (+totalCash || 0.0) + (+totalSellAmount || 0.0) - (totalBuyAmount || 0.0) })}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TradeOrderCreationInfoPanel;

import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface IUserPreferenceStore {
  zoom: number;
  setZoom: (zoom: number) => void;
}

const useUserPreferenceStore = create<IUserPreferenceStore>(
  persist<IUserPreferenceStore>(
    (set: SetState<IUserPreferenceStore>) => ({
      zoom: 1,
      setZoom: (zoom: number) => {
        set({ zoom });
      },
    }),
    {
      name: 'user-preference-cache',
      blacklist: [],
    },
  ),
);

export default useUserPreferenceStore;

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import dayjs from 'dayjs';
import { IGetOpsFeedsAndIntegrationsResponse } from '@shared/exchange/getOpsFeedsAndIntegrations';

export const GET_OPS_FEEDS_AND_INTEGRATION = 'get-ops-feeds-and-integrations';
export default function useGetOpsFeedsAndIntegrations(
  startDate: Date,
  endDate: Date,
): UseQueryResult<IGetOpsFeedsAndIntegrationsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetOpsFeedsAndIntegrationsResponse, IErrorResponse>(
    [GET_OPS_FEEDS_AND_INTEGRATION, { startDate, endDate }],

    async () => {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      return axios
        ?.get(`get-ops-feeds-and-integrations?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

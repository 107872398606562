import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import { Column, Row, useFilters, useGlobalFilter, useTable } from 'react-table';
import { ReactElement } from 'react';
import TableLoader from '@components/TableLoader';
import useUsers from '@common/hooks/useUsers';
import IUserOrgRole from '@shared/interfaces/IUserOrgRole';
import { ROLES_CONFIG } from '@shared/constants/Roles';
import GlobalLoader from '@components/GlobalLoader';
//import DeleteIcon from '../../../assets/img/delete-icon.svg';
import useAdministrationStore from '@stores/useAdministrationStore';
import IRole from '@shared/interfaces/IRole';
import useUserMetadata from '@common/hooks/useGetUserMetadata';
import useUpdateUser from '@common/hooks/useUpdateUser';
import useAuthStore from '@stores/useAuthStore';
import { Button, Intent, MenuItem, NonIdealState } from '@blueprintjs/core';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import CustomDropdown from '@components/CustomDropdown/CustomDropdown';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';
import dayjs from 'dayjs';
import UserAvatar from '@components/UserAvatar';
import IUser from '@shared/interfaces/IUser';

function UsersTable({
  columns,
  data,
  isWriteEnabled,
  currentUser,
}: {
  columns: Column<IUserOrgRole>[];
  data: IUserOrgRole[];
  isWriteEnabled: boolean;
  currentUser: IUser | undefined;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );
  const setUserIdFromRemoveFromOrg = useAdministrationStore((state) => state.setUserIdFromRemoveFromOrg);

  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div></div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>

      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
              <th style={{ textAlign: 'right' }}>Action</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}

                <td>
                  {isWriteEnabled && currentUser?.id !== row.original.user.id && (
                    <Flex flexDirection={'row-reverse'}>
                      <Button
                        small={true}
                        intent={Intent.DANGER}
                        outlined={true}
                        minimal={true}
                        onClick={() => setUserIdFromRemoveFromOrg(row.original.user.id)}
                        icon={'trash'}
                      ></Button>
                    </Flex>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No users found.</h5>} />}
    </>
  );
}

const RoleSelectionDropdown: FC<{ role: IRole; roles: IRole[]; onSelectionChange: (id: number) => void }> = ({
  role,
  onSelectionChange,
  roles,
}) => {
  console.log('Role**', role);
  return (
    <div className="btn-drop">
      <CustomDropdown
        text={ROLES_CONFIG.find((r) => r.role_name === role.roleName)?.display_name || role.roleName}
        disabled={false}
        menuItems={roles.map((role, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onSelectionChange(role.id);
              }}
              text={ROLES_CONFIG.find((r) => r.role_name === role.roleName)?.display_name || role.roleName}
            />
          );
        })}
      ></CustomDropdown>
    </div>
  );
};

const getColumnConfig = (
  roles: IRole[],
  onRoleChange: (userId: number, roleId: number) => void,
  isWriteEnabled: boolean,
  currentUser: IUser | undefined,
): Column<IUserOrgRole>[] => {
  return [
    {
      Header: '#',
      accessor: 'id',
      Cell: ({ row }) => {
        return <>{row.index + 1}</>;
      },
    },
    {
      Header: 'Username',
      accessor: (row) => row.user.userEmailId,
      Cell: ({ value, row }: { value: string; row: Row<IUserOrgRole> }) => (
        <Flex>
          <Box>
            <UserAvatar user={row.original.user}></UserAvatar>
          </Box>
          <Box ml={2}>
            <div>{row.original.user.firstName + ' ' + row.original.user.lastName}</div>
            <div>
              <small>{value}</small>
            </div>
          </Box>
        </Flex>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      Cell: ({ row }) => {
        return (
          <>
            {isWriteEnabled && currentUser?.id !== row.original.user.id ? (
              roles && (
                <RoleSelectionDropdown
                  role={row.original.role}
                  roles={roles}
                  onSelectionChange={(roleId: number) => onRoleChange(row.original.user.id, roleId)}
                ></RoleSelectionDropdown>
              )
            ) : (
              <>
                {ROLES_CONFIG.find((r) => r.role_name === row.original.role.roleName)?.display_name ||
                  row.original.role.roleName}
              </>
            )}
          </>
        );
      },
    },
    {
      Header: 'Created Dt',
      accessor: 'createdDt',
      Cell: ({ value }) => <>{dayjs(value).format('MM/DD/YYYY')}</>,
    },
  ];
};

const UsersPage: FC = (): ReactElement => {
  const { data: userMetaData, isLoading: isUserMetaDataLoading } = useUserMetadata();
  const { data: usersData, isLoading: isGetUsersLoading, isError, error, isFetching: isFetchingUsers } = useUsers();
  const setUserToUpdate = useAdministrationStore((state) => state.setUserDataToUpdate);
  const onRoleChange = (userId: number, roleId: number) => {
    setUserToUpdate({ userId, roleId });
  };
  const { error: updateUserError, isError: isUpdateUserError } = useUpdateUser({ remove: false });
  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const isWriteEnabled = canCurrentUserWriteACL('ADMIN_USERS');

  const { currentUser } = useAuthStore(({ currentUser }) => {
    return {
      currentUser,
    };
  });

  return (
    <Flex m={2}>
      <Box width={[10 / 12]}>
        <CustomCard
          heading="Users"
          rightToolbar={
            <Box>
              {isWriteEnabled && (
                <Link className="bp3-button bp3-intent-primary bp3-icon-new-person" to="/administration/users/create">
                  Invite User
                </Link>
              )}
            </Box>
          }
          body={
            <>
              {isFetchingUsers && <GlobalLoader></GlobalLoader>}
              {(isGetUsersLoading || isUserMetaDataLoading) && <TableLoader></TableLoader>}
              {isError && error && (
                <div className="col-lg-12  col-sm-12">
                  <div className="alert alert-danger text-center" role="alert">
                    {error.errorMessage}
                  </div>
                </div>
              )}
              {isUpdateUserError && updateUserError && (
                <div className="col-lg-12  col-sm-12">
                  <div className="alert alert-danger text-center" role="alert">
                    {updateUserError.errorMessage}
                  </div>
                </div>
              )}
              {usersData && usersData.userOrgRoles && userMetaData && (
                <UsersTable
                  columns={getColumnConfig(userMetaData.roles, onRoleChange, isWriteEnabled, currentUser?.user)}
                  data={usersData?.userOrgRoles}
                  isWriteEnabled={isWriteEnabled}
                  currentUser={currentUser?.user}
                />
              )}
            </>
          }
        ></CustomCard>
      </Box>
    </Flex>
  );
};

export default UsersPage;

interface IFormatAssetValueArg {
  value: number;
}
const formatAmountRoundTwo = ({ value }: IFormatAssetValueArg): string => {
  if (value == null || Number.isNaN(value)) return '0.00';
  const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  return nf.format(value);
};

export default formatAmountRoundTwo;

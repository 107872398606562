import CustomCard from '@components/CustomCard';
import { ReactElement, useEffect, useRef } from 'react';

const MarketDataWidget = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
    script.async = true;
    script.innerHTML = data; /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
    ref?.current?.appendChild(script);
  }, [ref]);

  return (
    <CustomCard
      simple
      heading={'Market Data'}
      body={
        <div className="tradingview-widget-container" ref={ref}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/markets/" rel="noopener noreferrer" target="_blank">
              <span className="blue-text">Financial Markets</span>
            </a>{' '}
            by TradingView
          </div>
        </div>
      }
    ></CustomCard>
  );
};

export default MarketDataWidget;

const data = `  {
  "width": "100%",
  "height": "660",
  "symbolsGroups": [
    {
      "name": "Equity Indices",
      "originalName": "Indices",
      "symbols": [
        {
          "name": "FOREXCOM:SPXUSD",
          "displayName": "S&P 500 Index"
        },
        {
          "name": "FOREXCOM:NSXUSD",
          "displayName": "Nasdaq 100 Index"
        },
        {
          "name": "FOREXCOM:DJI",
          "displayName": "Dow Jones 30 (DJIA)"
        },
        {
          "name": "FOREXCOM:US2000",
          "displayName": "R2000 Small Cap Index"
        },
        {
          "name": "FOREXCOM:UKXGBP",
          "displayName": "FTSE 100 Index"
        },
        {
          "name": "FOREXCOM:GRXEUR",
          "displayName": "DAX 40 Index"
        },
        {
          "name": "FOREXCOM:FRXEUR",
          "displayName": "CAC 40 Index"
        },
        {
          "name": "FOREXCOM:EU50",
          "displayName": "Eurostoxx 50 Index"
        },
        {
          "name": "FOREXCOM:AUXAUD",
          "displayName": "S&P/ASX 200 Index"
        },
        {
          "name": "FOREXCOM:JPXJPY",
          "displayName": "Nikkei 225 Index"
        },
        {
          "name": "FOREXCOM:HKXHKD",
          "displayName": "Hang Seng Index"
        },
        {
          "name": "FOREXCOM:SIMSCI",
          "displayName": "MSCI Singapore Index"
        },
        {
          "name": "FOREXCOM:CHN50",
          "displayName": "China A50 Index"
        }
      ]
    },
    {
      "name": "Bonds",
      "originalName": "Bonds",
      "symbols": [
        {
          "name": "CME:GE1!",
          "displayName": "Eurodollar"
        },
        {
          "name": "CBOT:ZB1!",
          "displayName": "T-Bond"
        },
        {
          "name": "CBOT:UB1!",
          "displayName": "Ultra T-Bond"
        },
        {
          "name": "EUREX:FGBL1!",
          "displayName": "Euro Bund"
        },
        {
          "name": "EUREX:FBTP1!",
          "displayName": "Euro BTP"
        },
        {
          "name": "EUREX:FGBM1!",
          "displayName": "Euro BOBL"
        }
      ]
    },
    {
      "name": "Forex",
      "originalName": "Forex",
      "symbols": [
        {
          "name": "FX:EURUSD"
        },
        {
          "name": "FX:GBPUSD"
        },
        {
          "name": "FX:USDJPY"
        },
        {
          "name": "FX:USDCHF"
        },
        {
          "name": "FX:AUDUSD"
        },
        {
          "name": "FX:USDCAD"
        }
      ]
    },
    {
      "name": "Commodities",
      "symbols": [
        {
          "name": "FOREXCOM:XAUUSD",
          "displayName": "Gold"
        },
        {
          "name": "FOREXCOM:XAGUSD",
          "displayName": "Silver"
        },
        {
          "name": "FOREXCOM:WTIUSD",
          "displayName": "WTI Crude Oil"
        },
        {
          "name": "FOREXCOM:SOYUSD",
          "displayName": "Soy Bean Oil"
        },
        {
          "name": "FOREXCOM:COTUSD",
          "displayName": "Cotton"
        },
        {
          "name": "FOREXCOM:SUGUSD",
          "displayName": "Sugar"
        }
      ]
    },
    {
      "name": "Crypto",
      "symbols": [
        {
          "name": "COINBASE:BTCUSD",
          "displayName": "BITCOIN"
        },
        {
          "name": "COINBASE:ETHUSD",
          "displayName": "ETHEREUM"
        },
        {
          "name": "COINBASE:SOLUSD",
          "displayName": "SOLANO"
        },
        {
          "name": "BITSTAMP:XRPUSD",
          "displayName": "XRP"
        },
        {
          "name": "KRAKEN:ADAUSD",
          "displayName": "CARDANO"
        },
        {
          "name": "COINBASE:LINKUSD",
          "displayName": "CHAINLINK"
        }
      ]
    }
  ],
  "showSymbolLogo": true,
  "colorTheme": "light",
  "isTransparent": false,
  "locale": "en"
}
`;

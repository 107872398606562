import { Callout, Intent } from '@blueprintjs/core';
import { ReactElement } from 'react';
import { Box } from 'reflexbox';

const NoAccessPage = (): ReactElement => {
  return (
    <Box p={2}>
      <Callout intent={Intent.DANGER}>
        <p>You do not have access to this page.</p>
      </Callout>
    </Box>
  );
};

export default NoAccessPage;

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetShowYourWorkResponse } from '@shared/exchange/getShowYourWork';

interface IGetShowYourWorkRequestData {
  fund_id: number;
  time_dim_key: Date;
  account_identifier_key: string;
  market_value: string;
  net_asset_value: string;
}

export default function useGetShowYourWork(
  data: IGetShowYourWorkRequestData,
  enabled: boolean,
): UseQueryResult<IGetShowYourWorkResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetShowYourWorkResponse, IErrorResponse>(
    ['get-show-your-work', { data }],
    async () => {
      const searchParams = new URLSearchParams();
      searchParams.append('fund_id', String(data.fund_id));
      searchParams.append('time_dim_key', String(data.time_dim_key));
      searchParams.append('account_identifier_key', String(data.account_identifier_key));
      searchParams.append('market_value', String(data.market_value));
      searchParams.append('net_asset_value', String(data.net_asset_value));
      return axios
        ?.get(`get-show-your-work?${searchParams.toString()}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, enabled: enabled },
  );
}

import { Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import IDashboardFundSummaryTableColumnConfig from '@shared/interfaces/IDashboardFundSummaryTableColumnConfig';
import IFundSummary from '@shared/interfaces/IFundSummary';
import { Column } from 'react-table';
import formatter from './MyFundsTableColumnForamatterLib';

const MyFundsTableBaseConfig: Column<IFundSummary>[] = [
  {
    Header: 'Fund Name',
    accessor: 'fund_name',
    sticky: 'left',
    Cell: formatter.formatFundName,
  },
  {
    Header: 'Total Assets',
    accessor: 'total_assets',
    Cell: ({ value }) => (
      <Tooltip2 content={String(+Number(value))} position={Position.BOTTOM}>
        {formatter.formatAssetValue({ value: Number(value) })}
      </Tooltip2>
    ),
  },
  {
    Header: 'Daily changes',
    accessor: 'dailyChange',
    Cell: formatter.formatDailyChange,
  },
];

const getMyFundsTableConfig = (
  headerConfig:
    | { columnMetaData: IDashboardFundSummaryTableColumnConfig[]; default?: boolean; fundIds: number[] }[]
    | undefined,
): Column<IFundSummary>[] => {
  if (!headerConfig) return [...MyFundsTableBaseConfig];

  if (headerConfig.length > 1) {
    return MyFundsTableBaseConfig.concat({
      Header: '',
      accessor: 'fund_id',
      Cell: ({ value, row }) => {
        let fundsHeader = headerConfig.find((_) => _.fundIds?.includes(value));
        if (!fundsHeader) fundsHeader = headerConfig.find((_) => _.default);
        return (
          <div className="ms-3">
            {fundsHeader?.columnMetaData.map((col: IDashboardFundSummaryTableColumnConfig) => (
              <div
                key={col.accessor}
                style={{ display: 'inline-block', textAlign: 'left', minWidth: '15%' }}
                className="border-right ms-3"
              >
                <small className="text-secondary">{col.Header}</small>
                <div>{formatter.formatAssetValue({ value: Number(row.original[col.accessor]) })}</div>
              </div>
            ))}
          </div>
        );
      },
    });
  } else if (headerConfig[0]) {
    return MyFundsTableBaseConfig.concat(
      headerConfig[0].columnMetaData.map((colConfig: IDashboardFundSummaryTableColumnConfig) => {
        const Cell = formatter.formatAssetValue;
        const headerName = colConfig.Header;
        if (headerName.indexOf('/') >= 0) {
          const headerNameParts = headerName.split('\n');
          return {
            Header: headerNameParts.map((namePart, i) => <div key={namePart + i}>{namePart}</div>),
            accessor: colConfig.accessor,
            Cell: Cell,
          };
        }

        return {
          Header: colConfig.Header,
          accessor: colConfig.accessor,
          Cell: Cell,
        };
      }),
    );
  } else {
    return MyFundsTableBaseConfig;
  }
};

export default getMyFundsTableConfig;

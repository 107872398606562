import { useAxios } from '@common/providers/AxiosProvider';
import { IUpdateExternalTransferAccountRequest } from '@shared/exchange/updateExternalTransferAccount';
import IUpdateExternalTransferAccountResponse from '@shared/exchange/updateExternalTransferAccount/IUpdateExternalTransferAccountResponse';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useUpdateExternalTransferAccount(): UseMutationResult<
  IUpdateExternalTransferAccountResponse,
  IErrorResponse,
  IUpdateExternalTransferAccountRequest,
  IUpdateExternalTransferAccountResponse
> {
  const axios = useAxios();
  return useMutation<
    IUpdateExternalTransferAccountResponse,
    IErrorResponse,
    IUpdateExternalTransferAccountRequest,
    IUpdateExternalTransferAccountResponse
  >(async (data: IUpdateExternalTransferAccountRequest) =>
    axios
      ?.put('update-external-transfer-account', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

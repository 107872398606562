import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetExternalTransferAccountsResponse } from '@shared/exchange/getExternalTransferAccounts';

export const GET_EXT_ACCOUNTS_KEY = 'admin-external-accounts';
export default function useExternalTransferAccounts(): UseQueryResult<
  IGetExternalTransferAccountsResponse,
  IErrorResponse
> {
  const axios = useAxios();
  return useQuery<IGetExternalTransferAccountsResponse, IErrorResponse>(
    [GET_EXT_ACCOUNTS_KEY],
    async () =>
      axios
        ?.get(`get-external-transfer-accounts`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false },
  );
}

import IGetDocSignedUploadUrlResponse from '@shared/exchange/getDocSignedUploadUrl/IGetDocSignedUploadUrlResponse';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IGetDocFactSignedUploadURLRequest {
  tradeItemId: number;
  isSigned: boolean;
}

export default function useGetDocFactSignedUploadUrl(): UseMutationResult<
  IGetDocSignedUploadUrlResponse,
  IErrorResponse,
  IGetDocFactSignedUploadURLRequest,
  IGetDocFactSignedUploadURLRequest
> {
  const axios = useAxios();
  return useMutation<
    IGetDocSignedUploadUrlResponse,
    IErrorResponse,
    IGetDocFactSignedUploadURLRequest,
    IGetDocFactSignedUploadURLRequest
  >(async (data: IGetDocFactSignedUploadURLRequest) =>
    axios
      ?.post('get-doc-fact-signed-upload-url', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

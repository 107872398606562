import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetTradeItemsAuditResponse } from '@shared/exchange/getTradeItemsAudit';

export const GET_TRADE_ITEMS_KEY = 'get-trade-items';
export default function useGetTradeItemsAudit(
  tradeItemId: number,
): UseQueryResult<IGetTradeItemsAuditResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetTradeItemsAuditResponse, IErrorResponse>(
    [GET_TRADE_ITEMS_KEY, { tradeItemId }],
    async () => {
      if (!tradeItemId) return [];
      const searchParams = new URLSearchParams();
      searchParams.append('tradeItemId', String(tradeItemId));
      return axios
        ?.get(`get-trade-items-audit?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetTradesAuditResponse } from '@shared/exchange/getTradesAudit';

export const GET_TRADES_KEY = 'get-trades';
export default function useGetTradesAudit(tradeId: number): UseQueryResult<IGetTradesAuditResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetTradesAuditResponse, IErrorResponse>(
    [GET_TRADES_KEY, { tradeId }],
    async () => {
      if (!tradeId) return [];
      const searchParams = new URLSearchParams();
      searchParams.append('tradeId', String(tradeId));
      return axios
        ?.get(`get-trades-audit?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

import { Button, Icon, Position, Tag } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import TradeStatusBadge from '@components/TradeStatusBadge/TradeStatusBadge';
import ICashTransaction from '@shared/interfaces/ICashTransaction';
import ICashTransactionStatus from '@shared/interfaces/ICashTransactionStatus';
import dayjs from 'dayjs';
import { Column, Row } from 'react-table';
import { Box } from 'reflexbox';
const getCashTransactionsTableConfig = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentTransactionStatus,
  transcationStatuses,
}: {
  currentTransactionStatus: ICashTransactionStatus;
  transcationStatuses: ICashTransactionStatus[];
}): Column<ICashTransaction>[] => {
  return [
    {
      Header: () => null,
      id: 'expander',
      Cell: ({ row }: { row: Row }) => (
        <Button
          intent="primary"
          minimal={true}
          outlined={true}
          small={true}
          {...row.getToggleRowExpandedProps()}
          icon={row.isExpanded ? <Icon icon="chevron-up"></Icon> : <Icon icon="chevron-down"></Icon>}
        ></Button>
      ),
    },

    {
      Header: () => 'ID',
      accessor: 'id',
    },
    {
      Header: 'Transaction Dt',
      accessor: 'transactionDt',
      Cell: ({ value }) => dayjs(value).format('MM/DD/YYYY'),
    },
    {
      Header: 'From Account',
      accessor: (row) => row.fromAccount?.accountName,
      Cell: ({ value, row }: { value: string; row: Row<ICashTransaction> }) => (
        <>
          <span> {value || ''}</span>{' '}
          {row.original.fromAccountIsExternalInd && (
            <Popover2 content={<h1>Popover!</h1>} position={Position.RIGHT}>
              <Tooltip2 content="External Account" position={Position.RIGHT} openOnTargetFocus={false}>
                <Tag>E</Tag>
              </Tooltip2>
            </Popover2>
          )}
        </>
      ),
    },

    {
      Header: 'To Account',
      accessor: (row) => row.toAccount?.accountName,
      Cell: ({ value, row }: { value: string; row: Row<ICashTransaction> }) => (
        <>
          <span> {value || ''}</span>{' '}
          {row.original.toAccountIsExternalInd && (
            <Popover2 content={<h1>Popover!</h1>} position={Position.RIGHT}>
              <Tooltip2 content="External Account" position={Position.RIGHT} openOnTargetFocus={false}>
                <Tag>E</Tag>
              </Tooltip2>
            </Popover2>
          )}
        </>
      ),
    },

    {
      Header: () => <Box textAlign="right">Amount</Box>,
      accessor: 'transactionAmt',
      Cell: ({ value }) => <Box textAlign="right">{formatAmountRoundTwo({ value: Number(value) })}</Box>,
    },
    {
      Header: 'Settlement Dt',
      accessor: 'settlementDt',
      Cell: ({ value }) => value && dayjs(value).format('MM/DD/YYYY'),
    },

    {
      Header: () => 'Type',
      accessor: (row) => row.transactionType.transactionTypeName,
      id: 'transactionType',
      Cell: ({ value }: { value: string }) => value,
    },
    {
      Header: 'Status',
      id: 'transactionStatus',
      accessor: (row) =>
        transcationStatuses.find((status) => status.id == row.transactionStatus.id)?.transactionStatusName,
      Cell: ({ value }: { value: string }) => {
        return <TradeStatusBadge type={value} text={value}></TradeStatusBadge>;
      },
    },
    {
      Header: () => null,
      accessor: (row) => row.createdBy.id,
      id: 'createdBy',
      Cell: () => null,
    },
  ];
};

export default getCashTransactionsTableConfig;

import { FC, ReactElement, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
//import Logo from '@assets/img/logo.svg';
import { SidebarConfig } from './SidebarConfig';
import { Colors, IconName, Navbar } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Box, Flex } from '@rebass/grid/emotion';
import { Icon } from '@blueprintjs/core';
import OsyteIcon from '@assets/img/OsyteIcon.png';
import routes from '../../routes';
import { canWriteAny, canReadAny } from '../../auth/acl';
import useCurrentUser from '@common/hooks/useCurrentUser';

const Sidebar: FC = (): ReactElement => {
  const toggleMenuWidth = useCallback(() => {
    const asideOpen = document.querySelector('.aside-open');
    if (asideOpen !== null) {
      const dropdownItem = document.querySelector('.dash-aside');
      dropdownItem?.addEventListener('mouseenter', function () {
        asideOpen?.classList.add('aside-hover');
      });
      dropdownItem?.addEventListener('mouseleave', function () {
        asideOpen?.classList.remove('aside-hover');
      });
    }
  }, []);

  const { isLoading: isCurrentUserLoading, data: currentUser } = useCurrentUser();

  useEffect(() => {
    toggleMenuWidth();
  }, []);

  const shouldShowMenu = (path: string) => {
    if (isCurrentUserLoading) return false;
    const route = routes.find((route) => route.path === path);
    console.log('ROUTE', route);
    if (!route || !route.acls) {
      return false;
    }
    if (canWriteAny(route.acls, currentUser)) return true;
    else if (canReadAny(route.acls, currentUser)) return true;
    return false;
  };

  return (
    <aside style={{ background: '#10161a', height: '100vh', position: 'fixed', left: 0, zIndex: 101 }}>
      <Navbar style={{ background: '#10161a', padding: 0 }}>
        <img src={OsyteIcon} width={'48'} style={{ paddingLeft: 6, paddingTop: 4 }}></img>
      </Navbar>
      <Flex flexDirection="column" className="bp3-dark">
        {SidebarConfig.map((item, index) => {
          return (
            <>
              {shouldShowMenu(item.path) && (
                <Tooltip2 key={index} content={item.displayName} intent="primary">
                  <NavLink
                    to={item.path}
                    activeStyle={{ backgroundColor: '#4b85cd' }}
                    className="bp3-button bp3-minimal bp3-large"
                    exact={item.exact}
                  >
                    {/*<Button icon={item.iconImg as IconName} minimal={true} large={true}></Button>*/}
                    <Box p="0.6rem" color={'inherit'}>
                      <Icon icon={item.iconImg as IconName} size={20} color={Colors.LIGHT_GRAY3} />
                    </Box>
                  </NavLink>
                </Tooltip2>
              )}
            </>
          );
        })}
      </Flex>
      {/*
      <aside className="dash-aside">
        <div className="aside-header">
          <Link to="/" className="logo">
            <img src={Logo} alt="logo" />
          </Link>
          <div className="humburge-menu" onClick={toggleMenu}>
            <img src={HumburgeMenu} alt="Humburge Menu" />
          </div>
        </div>
        <ul className="aside-list">
          {SidebarConfig.map((item, index) => {
            return (
              <li key={index} className={item.className}>
                <NavLink to={item.path} activeClassName="active" exact={item.exact}>
                  <img src={item.iconImg} alt={item.displayName}></img>
                  <p>{item.displayName}</p>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </aside>
        */}
    </aside>
  );
};

export default Sidebar;

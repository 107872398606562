import { FC, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//import { GET_DOC_FACTS_KEY } from '@common/hooks/useDocFacts';
import { useQueryClient } from 'react-query';
import IGetDocSignedUploadUrlResponse from '@shared/exchange/getDocSignedUploadUrl/IGetDocSignedUploadUrlResponse';
import useGetDocFactSignedUploadUrl from '@common/hooks/useGetDocFactSignedUploadUrl';
import useDocFactUpload from '@common/hooks/useUpload';
import useCreateOrUpdateDocFact from '@common/hooks/useCreateOrUpdateDocFact';
import { GET_TRADE_ITEM_KEY } from '@common/hooks/useGetTradeItems';
import { GET_DOC_FACTS_KEY } from '@common/hooks/useDocFacts';
import { Button, Callout, Dialog, Intent } from '@blueprintjs/core';
import { Box } from 'reflexbox';

interface ILODUploadProps {
  tradeItemId: number;
  isSigned: boolean;
}

const formSchema = yup.object().shape({
  file: yup
    .mixed()
    .required('File is required.')
    .test('type', 'File is required and Only pdf files are supported', (value: FileList) => {
      return value.length > 0 && value[0].type === 'application/pdf';
    })
    .test('fileSize', 'The file size should not be more than 1 MB', (value: FileList) => {
      return value.length > 0 && value[0].size <= 2000000;
    }),
});

const getUploadData = (file: File, s3Data: IGetDocSignedUploadUrlResponse): FormData => {
  const postData = new FormData();
  for (const key of Object.keys(s3Data.signedData.fields)) {
    postData.append(key, s3Data.signedData.fields[key]);
  }
  postData.append('file', file);
  return postData;
};

const LODUpload: FC<ILODUploadProps> = ({ tradeItemId, isSigned }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  const {
    mutateAsync: getDocFactSignedUploadUrl,
    isLoading: isGetDocFactSignedUploadUrlLoading,
    error: getDocFactSignedUploadUrlError,
  } = useGetDocFactSignedUploadUrl();

  const {
    mutateAsync: uploadDocFact,
    isLoading: isDocFactUploadLoading,
    error: docFactUploadError,
  } = useDocFactUpload();

  const {
    mutateAsync: createOrUpdateDocFact,
    isLoading: isDocFactCreateUpdateLoading,
    error: docFactCreateUpdateError,
  } = useCreateOrUpdateDocFact();

  const queryClient = useQueryClient();

  const onSubmit = async (data: { file: FileList }) => {
    try {
      const response: IGetDocSignedUploadUrlResponse = await getDocFactSignedUploadUrl({
        tradeItemId: tradeItemId,
        isSigned: isSigned,
      });

      await uploadDocFact({
        uploadUrl: response.signedData.url,
        doc: getUploadData(data.file[0], response),
      });

      await createOrUpdateDocFact({
        tradeItemId: tradeItemId,
        s3TemplateUrl: response.url,
        s3BucketName: response.bucketName,
        isSigned,
      });
      setModalOpen(false);
      queryClient.invalidateQueries(GET_TRADE_ITEM_KEY);
      queryClient.invalidateQueries(GET_DOC_FACTS_KEY);
    } catch (error) {}
  };

  const isSubmitting = isGetDocFactSignedUploadUrlLoading || isDocFactUploadLoading || isDocFactCreateUpdateLoading;
  const isError = docFactCreateUpdateError || getDocFactSignedUploadUrlError || docFactUploadError;
  return (
    <>
      <Button icon={'upload'} onClick={() => setModalOpen(true)}></Button>
      <Dialog
        isOpen={modalOpen}
        //onAfterOpen={afterOpenModal}
        title={'Upload LOD'}
        icon={'upload'}
        onClose={() => setModalOpen(false)}
        isCloseButtonShown={!isSubmitting}
      >
        {tradeItemId && (
          <div className="memoPopup memoPopup_tradeHistory">
            <Box p={2}>
              {isError && (
                <Callout intent={Intent.DANGER}>
                  <div>{getDocFactSignedUploadUrlError}</div>
                  <div>{docFactCreateUpdateError}</div>
                  <div>{docFactUploadError}</div>
                </Callout>
              )}
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <input type="file" className="bp3-input bp3-fill" {...register('file')}></input>
                {errors?.file?.message && <Callout intent={Intent.DANGER}>{errors?.file?.message}</Callout>}

                <Box mt={2} justifyContent="flex-end" display="flex">
                  <Button
                    type="submit"
                    icon={'upload'}
                    intent={Intent.PRIMARY}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Upload
                  </Button>
                </Box>
              </form>
            </Box>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default LODUpload;

import { ICreateCashTransactionEntryRequest } from '@shared/exchange/createCashTransactionEntry';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface ICreateCashEntryResponse {
  id: number;
}

export default function useCreateCashTransactionEntry(): UseMutationResult<
  ICreateCashEntryResponse,
  IErrorResponse,
  ICreateCashTransactionEntryRequest,
  ICreateCashEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateCashEntryResponse,
    IErrorResponse,
    ICreateCashTransactionEntryRequest,
    ICreateCashEntryResponse
  >(async (cashEntry: ICreateCashTransactionEntryRequest) =>
    axios
      ?.post('create-cash-transaction-entry', cashEntry)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

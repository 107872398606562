import { Icon, Intent, Position, Spinner, SpinnerSize } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import useGetShowYourWork from '@common/hooks/useGetShowYourWork';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import formatAllocationPercentageWithBoundsCheck from '@common/utils/formatAllocationPercentageWithBoundsCheck';
import formatAssetValue from '@common/utils/formatAssetValue';
import IExposure from '@shared/interfaces/IExposure';
import dayjs from 'dayjs';
import { FC, useCallback, useRef, useState } from 'react';
import { ReactElement } from 'react';
import { Column, Row } from 'react-table';
import { Flex, Box } from 'reflexbox';

interface IShowYourWorkProps {
  row: Row<IExposure>;
}

const ShowYourWorkPanel: FC<IShowYourWorkProps> = ({ row }) => {
  const [show, setShow] = useState<boolean>(false);
  const target = useRef(null);
  const {
    fund_id,
    time_dim_key,
    account_identifier_key,
    market_value_amt: market_value,
    net_asset_value,
  } = row.original;
  const data = { fund_id, time_dim_key, account_identifier_key, market_value, net_asset_value };
  const { data: showYourWorkData, isLoading } = useGetShowYourWork(data, show);

  const getHeaders = () => {
    if (!showYourWorkData?.data) return [];
    return Object.keys(showYourWorkData?.data[0] || {});
  };

  const formatValue = useCallback((_key: string, value: string | number) => {
    if (!_key || !value) return value;
    if (typeof value === 'string') return value;
    const key = _key.toLowerCase();
    if (key.startsWith('market value') || key.startsWith('net asset value')) {
      return '$' + formatAssetValue({ value: Number(value) });
    } /*else if (key.startsWith('proxy return') || key.startsWith('total returns')) {
    //const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 6, minimumFractionDigits: 6 });
    //return nf.format(Number(value));
    }*/
    return value;
  }, []);

  return (
    <>
      <Box ml={1} display={'inline'}></Box>
      <Popover2
        enforceFocus={false}
        isOpen={show}
        position={Position.RIGHT}
        content={
          <div className="bp3-dialog-container">
            <div className="bp3-dialog" style={{ margin: 0, width: 'auto' }}>
              <div className="bp3-dialog-header">
                <h5 className="bp3-heading">Calculations</h5>
                <button
                  onClick={() => setShow(false)}
                  aria-label="Close"
                  className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
                ></button>
              </div>
              <div className="bp3-dialog-body">
                {isLoading && <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />}
                <div>
                  {getHeaders().map((header) => {
                    return (
                      <div key={header} className="">
                        <span style={{ display: 'inline-block', width: '20rem', fontWeight: 500 }}>{header}</span>
                        {showYourWorkData?.data.map((row, i) => (
                          <span key={header + i} style={{ display: 'inline-block', width: '12rem' }}>
                            {formatValue(header, row[header])}
                          </span>
                        ))}
                        <hr className="p-0 m-1 text-secondary"></hr>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      >
        <button className="bp3-button bp3-minimal bp3-small" ref={target} onClick={() => setShow(!show)}>
          <Icon icon="info-sign" size={12} />
        </button>
      </Popover2>
    </>
  );
};

const ExposureTableBaseConfig: Column<IExposure>[] = [
  {
    id: 'name',
    accessor: 'name',
    Header: '',
    Cell: ({ row, value }: { row: Row<IExposure>; value: string }) => {
      const renderSource = (
        <span>
          {row.original.data_source_code && (
            <Box display="inline-block" fontStyle="italic" fontSize={10}>
              {row.original.data_source_code}
            </Box>
          )}
          {row.original.time_dim_key !== row.original.data_source_last_valuation_dt &&
            !!row.original.data_source_last_valuation_dt && (
              <span className="text-secondary">
                {' '}
                |{' '}
                <Box display="inline-block" fontStyle="italic" fontSize={10}>
                  {dayjs(row.original.data_source_last_valuation_dt).format('MM-DD-YYYY')}
                </Box>
              </span>
            )}
          {row.original.proxy_used_ind == 1 && <ShowYourWorkPanel row={row}></ShowYourWorkPanel>}
        </span>
      );

      let renderValue: ReactElement = <></>;
      if (row.depth === 0) {
        if (row.original.policy_id === null || row.original.policy_id === undefined)
          renderValue = (
            <small className="text-danger">
              <i className="bi bi-exclamation-triangle-fill"></i>
              {'  '}Not present in Fund Policy{' '}
            </small>
          );
      }
      if (row.original.isPolicyWithoutAccounts && Number(row.original.target_allocation_range_lower_bound) > 0)
        renderValue = (
          <small className="text-danger">
            <i className="bi bi-exclamation-triangle-fill"></i>
            {'  '}No Accounts present for this Policy{' '}
          </small>
        );
      renderValue = (
        <Flex flexDirection="column" textAlign="left">
          {value}
          <Box paddingLeft={2}>
            {renderSource}
            {renderValue}
          </Box>
        </Flex>
      );

      if (row.isExpanded) {
        return (
          <>
            <span
              {...row.getToggleRowExpandedProps({
                className: `expandArrow active`,
              })}
            ></span>

            {renderValue}
          </>
        );
      }

      return (
        <>
          <span
            {...row.getToggleRowExpandedProps({
              className: `expandArrow`,
              style: {},
            })}
          ></span>

          {renderValue}
        </>
      );
    },
  },
  /*{
    Header: '',
    accessor: 'data_source_code',
    Cell: ({ value }) => <div>{value}</div>,
  },*/
  {
    Header: 'Net Assets',
    accessor: 'asset_class_nav',
    Cell: ({ value }) => (
      <Tooltip2 position={Position.BOTTOM} content={<>{+Number(value)}</>}>
        {formatAssetValue({ value: Number(value) })}
      </Tooltip2>
    ),
  },
  {
    Header: 'Current Allocation',
    accessor: 'current_allocation_pct',
    Cell: formatAllocationPercentage,
  },
  {
    Header: 'Lower Bound',
    accessor: 'target_allocation_range_lower_bound',
    Cell: ({ row, value }) =>
      formatAllocationPercentageWithBoundsCheck(
        row.original.target_allocation_range_lower_bound,
        row.original.target_allocation_range_upper_bound,
        row.original.target_allocation,
        value,
      ),
  },
  {
    Header: 'Target Allocation',
    accessor: 'target_allocation',
    Cell: ({ row, value }) =>
      formatAllocationPercentageWithBoundsCheck(
        row.original.target_allocation_range_lower_bound,
        row.original.target_allocation_range_upper_bound,
        row.original.target_allocation,
        value,
      ),
  },
  {
    Header: 'Upper Bound',
    accessor: 'target_allocation_range_upper_bound',
    Cell: ({ row, value }) =>
      formatAllocationPercentageWithBoundsCheck(
        row.original.target_allocation_range_lower_bound,
        row.original.target_allocation_range_upper_bound,
        row.original.target_allocation,
        value,
      ),
  },
  {
    Header: 'Asset Class Benchmark',
    accessor: 'benchmark_name',
  },
];

const getExposureTableConfig = (): Column<IExposure>[] => {
  return ExposureTableBaseConfig;
};

export default getExposureTableConfig;

import useExposure from '@common/hooks/useExposure';
import TableLoader from '@components/TableLoader';
import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import ExposureTable from './components/ExposureTable';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import getExposureTableConfig from './components/ExposureTable/getExposureTableConfig';
import dayjs from 'dayjs';
import useExposureStore from '@stores/useExposureStore';
import AssetAllocationChart from './components/AssetAllocationChart';
import PieChartLoader from '@components/PieChartLoader';
import useFundsSummary from '@common/hooks/useFundsSummary';
import PerformanceChart from './components/PerformanceChart';
import 'react-datepicker/dist/react-datepicker.css';
import ChartLoader from '@components/ChartLoader';
import useFundSummaryHistory from '@common/hooks/useFundSummaryHistory';
import ExposureDateFilter from './components/ExposureDateFilter';
import PageToolbar from '@components/PageToolbar';
import ExposureDatePicker from './components/ExposureDatePicker';
import { Box } from 'reflexbox';
import { Container, Row, Col } from 'react-grid-system';
import CustomCard from '@components/CustomCard';
import { Callout, Intent } from '@blueprintjs/core';

const ExposurePage: FC = (): ReactElement => {
  const { selectedFundId, setSelectedFundId, setAllFunds, allFunds, filterDate, setFilterDate, switchFund } =
    useExposureStore();
  console.log(selectedFundId);
  const { data: fundsSummaryData, isLoading: isFundsSummaryLoading, error } = useFundsSummary();
  const { data, isLoading: isExpsoureLoading, isSuccess: isExposureSuccess } = useExposure(selectedFundId, filterDate);
  const { data: fundSummaryHistoryData, isLoading: isFundSummaryHistoryLoading } =
    useFundSummaryHistory(selectedFundId);
  const exposureTabeColumns = useMemo(() => getExposureTableConfig(), []);

  const _setFilterDate = useCallback(
    (_date: Date | undefined) => {
      if (_date === undefined) return setFilterDate(_date);
      const date = new Date(_date);
      const dateString = dayjs(date).format('YYYY-MM-DD');
      setFilterDate(dateString);
    },
    [setFilterDate],
  );

  useEffect(() => {
    if (!data || !data.allFundsofCurrentOrg) return;
    setAllFunds(data?.allFundsofCurrentOrg);
    setSelectedFundId(data?.fundId);
  }, [data]);

  const summaryOfSelectedFund = useMemo(() => {
    if (!fundsSummaryData) return null;
    return fundsSummaryData?.fundsSummary.find((_) => _.fund_id === selectedFundId);
  }, [fundsSummaryData, selectedFundId]);

  return (
    <>
      {error && (
        <Box p={2}>
          <Callout intent={Intent.DANGER}>{error.errorMessage}</Callout>
        </Box>
      )}
      {!error && (
        <div>
          <PageToolbar
            leftSegment={
              <>
                <Box mr={2}>
                  {selectedFundId && allFunds && (
                    <FundSelectionDropdown
                      switchFund={switchFund}
                      selectedFundId={selectedFundId}
                      allFunds={allFunds}
                      disabled={isExpsoureLoading}
                    ></FundSelectionDropdown>
                  )}
                </Box>
                <Box mr={2}>
                  <ExposureDateFilter setFilterDate={_setFilterDate}></ExposureDateFilter>
                </Box>

                {(data?.date || filterDate) && (
                  <Box mr={2}>
                    <ExposureDatePicker
                      isLoading={isExpsoureLoading}
                      date={data?.date}
                      filterDate={filterDate}
                      setFilterDate={_setFilterDate}
                    ></ExposureDatePicker>
                  </Box>
                )}
              </>
            }
          ></PageToolbar>

          <Container fluid={true}>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Box mt={3}>
                  {isExpsoureLoading && <PieChartLoader></PieChartLoader>}
                  {isExposureSuccess && data?.exposureData && (
                    <AssetAllocationChart allocations={data?.exposureData.allocations}></AssetAllocationChart>
                  )}
                </Box>
              </Col>

              <Col sm={12} md={12} lg={6}>
                {(isFundsSummaryLoading || isFundSummaryHistoryLoading) && summaryOfSelectedFund && (
                  <Box mt={3}>
                    <ChartLoader></ChartLoader>
                  </Box>
                )}
                {!summaryOfSelectedFund && (
                  <Box mt={3}>
                    <CustomCard
                      heading={'Performance'}
                      body={
                        <Callout intent="primary" icon="info-sign">
                          No data found for the selected fund and date.
                        </Callout>
                      }
                    />
                  </Box>
                )}
                {summaryOfSelectedFund && !isFundSummaryHistoryLoading && (
                  <Box mt={3}>
                    <PerformanceChart
                      fundSummaryHistory={fundSummaryHistoryData?.fundSummaryHistory}
                      fundSummary={summaryOfSelectedFund}
                    ></PerformanceChart>
                  </Box>
                )}
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Box mt={3} mb={3}>
                  {isExpsoureLoading && <TableLoader></TableLoader>}
                  {isExposureSuccess && data && <ExposureTable columns={exposureTabeColumns} data={data} />}
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ExposurePage;

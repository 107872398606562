import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetFileDownloadUrlResponse } from '@shared/exchange/getFileDownloadUrl';

interface IGetETLFileDownloadUrlRequestData {
  etlFileId: number;
  custFeedId: number;
}

export default function useGetETLFileDownloadUrl(): UseMutationResult<
  IGetFileDownloadUrlResponse,
  IErrorResponse,
  IGetETLFileDownloadUrlRequestData,
  IGetETLFileDownloadUrlRequestData
> {
  const axios = useAxios();
  return useMutation<
    IGetFileDownloadUrlResponse,
    IErrorResponse,
    IGetETLFileDownloadUrlRequestData,
    IGetETLFileDownloadUrlRequestData
  >(async (data: IGetETLFileDownloadUrlRequestData) => {
    const searchParams = new URLSearchParams();
    searchParams.append('etlFileId', String(data.etlFileId));
    searchParams.append('custFeedId', String(data.custFeedId));
    return axios
      ?.get(`get-etl-file-download-url?${searchParams.toString()}`)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      });
  });
}

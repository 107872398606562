import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetDocMgmtFileDownloadUrlResponse } from '@shared/exchange/getDocMgmtFileDownloadUrl';

interface IGetDocMgmtFileDownloadUrlRequestData {
  docFileId: number;
}

export default function useGetDocMgmtFileDownloadUrl(): UseMutationResult<
  IGetDocMgmtFileDownloadUrlResponse,
  IErrorResponse,
  IGetDocMgmtFileDownloadUrlRequestData,
  IGetDocMgmtFileDownloadUrlRequestData
> {
  const axios = useAxios();
  return useMutation<
    IGetDocMgmtFileDownloadUrlResponse,
    IErrorResponse,
    IGetDocMgmtFileDownloadUrlRequestData,
    IGetDocMgmtFileDownloadUrlRequestData
  >(async (data: IGetDocMgmtFileDownloadUrlRequestData) => {
    const searchParams = new URLSearchParams();
    searchParams.append('docFileId', String(data.docFileId));
    return axios
      ?.get(`get-doc-mgmt-file-download-url?${searchParams.toString()}`)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      });
  });
}

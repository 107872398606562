import IUserOrgRole from '@shared/interfaces/IUserOrgRole';
import { ACLKeys, canRead, canWrite } from '../auth/acl';
import create, { GetState, SetState, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import { IGetCurrentUserResponse } from '@shared/exchange/getCurrentUser';

interface IAuthStore {
  currentUserOrgRole: IUserOrgRole | undefined;
  setCurrentUserOrgRole: (currentUserOrgRole: IUserOrgRole) => void;
  resetCurrentUserOrgRole: () => void;
  canCurrentUserWriteACL: (aclKey: ACLKeys) => boolean;
  canCurrentUserReadACL: (aclKey: ACLKeys) => boolean;
  currentUser: IGetCurrentUserResponse | undefined;
  setCurrentUser: (currentUser: IGetCurrentUserResponse) => void;
}
const useAuthStore = create<IAuthStore>(
  persist<IAuthStore>(
    (set: SetState<IAuthStore>, get: GetState<IAuthStore>, api: StoreApi<IAuthStore>) => ({
      currentUserOrgRole: undefined,
      setCurrentUserOrgRole: (currentUserOrgRole: IUserOrgRole): void => {
        set({ currentUserOrgRole: currentUserOrgRole });
      },
      resetCurrentUserOrgRole: (): void => {
        set({ currentUserOrgRole: undefined });
      },
      canCurrentUserWriteACL: (aclKey): boolean => {
        return canWrite({ operationType: aclKey, operation: 'WRITE' }, api.getState()?.currentUser);
      },
      canCurrentUserReadACL: (aclKey): boolean => {
        return canRead({ operationType: aclKey, operation: 'READ' }, api.getState()?.currentUser);
      },
      currentUser: undefined,
      setCurrentUser: (currentUser: IGetCurrentUserResponse) => {
        set({ currentUser: currentUser });
      },
    }),
    { name: 'auth-cache', blacklist: ['currentUser'] },
  ),
);

export default useAuthStore;

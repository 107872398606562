import { Button, Intent } from '@blueprintjs/core';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import TradeStatusBadge from '@components/TradeStatusBadge/TradeStatusBadge';
import ITrade from '@shared/interfaces/ITrade';
import dayjs from 'dayjs';
import { Column, Row } from 'react-table';

const getTradeProcessingColumns = (): Column<ITrade>[] => {
  return [
    {
      Header: 'Trade Batch ID',
      accessor: 'id',
      Cell: ({ value, row }) => {
        return (
          <span {...row.getToggleRowExpandedProps()}>
            <Button small intent={Intent.PRIMARY} minimal={true} icon={'chevron-down'}>
              {value}
            </Button>
          </span>
        );
      },
    },
    {
      Header: 'Fund',
      accessor: 'fund',
      Cell: ({ value }) => value.fundName,
    },
    {
      Header: 'Buy/Sell',
      accessor: 'buyOrSell',
    },
    {
      Header: () => <div className="text-end">Total Buy</div>,
      accessor: 'totalBuyAmt',
      Cell: ({ value }) => <div className="text-end">{formatAmountRoundTwo({ value: Number(value) })}</div>,
    },
    {
      Header: () => <div className="text-end">Total Sell</div>,
      accessor: 'totalSellAmt',
      Cell: ({ value }) => <div className="text-end">{formatAmountRoundTwo({ value: Number(value) })}</div>,
    },
    {
      Header: 'Created Dt',
      accessor: 'createdDt',
      Cell: ({ value }) => <>{dayjs(value).format('MM/DD/YYYY')}</>,
    },
    {
      Header: 'Status',
      id: 'tradeStatus',
      accessor: (row) => row.tradeStatus?.tradeStatusName,
      Cell: ({ row }: { row: Row<ITrade> }) => (
        <TradeStatusBadge
          type={row.original.tradeStatus.tradeStatusName}
          text={row.original.tradeStatus.tradeStatusName}
        ></TradeStatusBadge>
      ),
    },
  ];
};

export default getTradeProcessingColumns;

import ExcelJS from 'exceljs';

const autoWidth = (worksheet: ExcelJS.Worksheet, minimalWidth = 10): void => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0);
      });
    }
    column.width = maxColumnLength + 2;
  });
};

export default autoWidth;

import AdministrationPageMenu from '@components/AdministrationPageMenu';
import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
import React from 'react';
import { FC, ReactElement, ReactNode } from 'react';
import { Box, Flex } from 'reflexbox';
import AdministrationErrors from './AdministrationErrors';

interface IAdministrationLayoutProps {
  children?: ReactNode;
  pageTitle?: string;
}
const AdministrationLayout: FC = ({ children }: IAdministrationLayoutProps): ReactElement => {
  return (
    <Flex>
      <Box>
        <Sidebar />
      </Box>
      <Box flex="1 1 auto" style={{ marginLeft: '3.5rem', marginTop: '3.2rem' }}>
        <Header pageTitle={'Administration'} />
        <Flex>
          <Box width={[2 / 12]} padding={2}>
            <AdministrationPageMenu></AdministrationPageMenu>
          </Box>
          <Box width={[5 / 6]}>
            <AdministrationErrors></AdministrationErrors>
            <main>{children} </main>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AdministrationLayout;

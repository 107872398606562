import useAdministrationStore from '@stores/useAdministrationStore';
import { ReactElement } from 'react';

const AdministrationErrors = (): ReactElement => {
  const { errors, setErrors } = useAdministrationStore(({ errors, setErrors }) => {
    return {
      errors,
      setErrors,
    };
  });
  return (
    <>
      {errors && errors.length > 0 && (
        <div className="alert alert-danger fade show adminErrorsContainer alert-dismissible" role="alert">
          {errors.map((error: string) => (
            <>{error}</>
          ))}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setErrors([])}
          ></button>
        </div>
      )}
    </>
  );
};

export default AdministrationErrors;

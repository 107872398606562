import { Card, Elevation } from '@blueprintjs/core';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import './AdministrationPageMenu.scss';

const AdministrationPageMenu = (): ReactElement => {
  return (
    <div className="AdministrationPageMenu">
      <Card elevation={Elevation.TWO} style={{ padding: 0 }}>
        <ul className="bp3-menu bp3-large" style={{ padding: 0 }}>
          {/*<li>
          <NavLink to="/administration/organization">
            <h3>Organization</h3>
          </NavLink>
        </li
        >
        <li>
          <NavLink to="/administration/users">
            <h3>Users</h3>
          </NavLink>
        </li>
        */}

          <li>
            <NavLink activeClassName="bp3-active" to="/administration/users" className="bp3-menu-item">
              Users
            </NavLink>
          </li>
          <li className="bp3-menu-divider" style={{ margin: 0 }}></li>

          <li>
            <NavLink activeClassName="bp3-active" to="/administration/funds" className="bp3-menu-item">
              Funds
            </NavLink>
          </li>
          <li className="bp3-menu-divider" style={{ margin: 0 }}></li>

          <li>
            <NavLink activeClassName="bp3-active" to="/administration/accounts" className="bp3-menu-item">
              Investment Accounts
            </NavLink>
          </li>
          <li className="bp3-menu-divider" style={{ margin: 0 }}></li>

          <li>
            <NavLink activeClassName="bp3-active" to="/administration/doc-templates" className="bp3-menu-item">
              Letter of Directives
            </NavLink>
          </li>
          <li className="bp3-menu-divider" style={{ margin: 0 }}></li>
          <li>
            <NavLink activeClassName="bp3-active" to="/administration/external-accounts" className="bp3-menu-item">
              External Transfer/Suspense Accounts
            </NavLink>
          </li>
          {/*
        <li>
          <NavLink to="/administration/investment">
            <h3>Investment Account</h3>
          </NavLink>
        </li>
        <li>
          <NavLink to="/administration/assetpolicy">
            <h3>Asset Class Policy</h3>
          </NavLink>
        </li>
        <li>
          <NavLink to="/administration/custodialfeeds">
            <h3>Custodial Feeds</h3>
          </NavLink>
        </li>
        <li>
          <NavLink to="/administration/referencedata">
            <h3>Reference Data</h3>
          </NavLink>
        </li>
        */}
        </ul>
      </Card>
    </div>
  );
};

export default AdministrationPageMenu;

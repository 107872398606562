import useGetUserOrgFunds, { ADMIN_GET_USERS_ORG_FUNDS_KEY } from '@common/hooks/useGetUserOrgFunds';
import styled from '@emotion/styled';
import IFundDetail from '@shared/interfaces/IFundDetail';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import {
  Alert,
  Alignment,
  Button,
  Callout,
  Card,
  Classes,
  Collapse,
  Dialog,
  FormGroup,
  Intent,
  MenuItem,
  Navbar,
  NonIdealState,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import UserAvatar from '@components/UserAvatar';
import { Column, useFilters, useGlobalFilter, useTable, Row } from 'react-table';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import useUsers from '@common/hooks/useUsers';
import IUserOrgRole from '@shared/interfaces/IUserOrgRole';
import { ROLES_CONFIG } from '@shared/constants/Roles';
import useCreateUserOrgFund from '@common/hooks/useCreateUserOrgFund';
import { AppToaster } from '@components/Toasters';
import { useQueryClient } from 'react-query';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import GlobalTableFilter from '@components/GlobalTableFilter';
import { Select } from '@blueprintjs/select';
import useDeleteUserOrgFund from '@common/hooks/useDeleteUserOrgFund';
import useAuthStore from '@stores/useAuthStore';

interface IFundsSettingsProps {
  fund: IFundDetail | undefined;
}

const UsersTable = ({ columns, data }: { columns: Column<IUserOrgRole>[]; data: IUserOrgRole[] }) => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );
  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div></div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && (
        <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No User has got access to this fund.</h5>} />
      )}
    </>
  );
};

const AddUserToFundDialog = ({
  isOpen,
  fund,
  possibleUsers,
  close,
}: {
  isOpen: boolean;
  fund: IFundDetail | undefined;
  possibleUsers: IUserOrgRole[] | [];
  close: () => void;
}): ReactElement => {
  const { mutateAsync: createUserOrgFund, isLoading: isCreateUserOrgFundLoading, error } = useCreateUserOrgFund();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [isHelperOpen, setIsHelperOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      await createUserOrgFund({ userId: Number(selectedUserId), fundId: Number(fund?.id) });
      await queryClient.invalidateQueries(ADMIN_GET_USERS_ORG_FUNDS_KEY);
      close();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (error) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: error.errorMessage,
        icon: 'warning-sign',
      });
    }
  }, [error]);

  const selectedUser = possibleUsers.find(({ user }) => user.id === selectedUserId)?.user;
  const UsersSelect = Select.ofType<IUserOrgRole>();
  return (
    <Dialog
      icon={'new-person'}
      title={'Grant User Access to this fund'}
      canOutsideClickClose={false}
      isOpen={isOpen}
      //onAfterOpen={afterOpenModal}
      onClose={() => close()}
      isCloseButtonShown={!isCreateUserOrgFundLoading}
    >
      <Box px={3} py={3}>
        <FormGroup
          label={
            <Box>
              Select the user to add to
              <Box>
                <strong>{fund?.fundName}</strong>
                <Button icon="info-sign" minimal onClick={() => setIsHelperOpen(true)}></Button>
              </Box>
            </Box>
          }
        >
          <Collapse isOpen={isHelperOpen}>
            <Callout intent={Intent.PRIMARY} icon={'info-sign'}>
              {
                "if you can't find the user in the list, invite the user in the Users tab first or check if the user is disabled"
              }
            </Callout>
          </Collapse>
          <Box pt={3} alignContent={'center'} justifyContent={'center'} display={'flex'}>
            <UsersSelect
              popoverProps={{}}
              filterable={false}
              items={possibleUsers}
              itemRenderer={(item) => (
                <MenuItem
                  key={item.user.id}
                  onClick={() => setSelectedUserId(item.user.id)}
                  text={
                    <Flex>
                      <Box>
                        <UserAvatar user={item.user}></UserAvatar>
                      </Box>
                      <Box ml={2}>
                        <div>{item.user.firstName + ' ' + item.user.lastName}</div>
                        <div>
                          <small>{item.user.userEmailId}</small>
                        </div>
                      </Box>
                    </Flex>
                  }
                />
              )}
              onItemSelect={(item) => setSelectedUserId(item.user.id)}
            >
              <Button
                fill={true}
                large={true}
                disabled={isCreateUserOrgFundLoading}
                text={
                  selectedUser ? (
                    <Flex>
                      <Box>
                        <UserAvatar user={selectedUser}></UserAvatar>
                      </Box>
                      <Box ml={2}>
                        <div>{selectedUser.firstName + ' ' + selectedUser.lastName}</div>
                        <div>
                          <small>{selectedUser.userEmailId}</small>
                        </div>
                      </Box>
                    </Flex>
                  ) : (
                    'Please select'
                  )
                }
                rightIcon="chevron-down"
              />
            </UsersSelect>
          </Box>
        </FormGroup>
      </Box>

      <Flex className={Classes.DIALOG_FOOTER} flexDirection="row-reverse" px={3}>
        <Button
          disabled={!selectedUserId}
          loading={isCreateUserOrgFundLoading}
          intent={Intent.PRIMARY}
          onClick={handleSubmit}
        >
          Add User
        </Button>
      </Flex>
    </Dialog>
  );
};

const UserAccess: FC<IFundsSettingsProps> = ({ fund }): ReactElement => {
  //const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  //const isWriteEnabled = canCurrentUserWriteACL('ADMIN_FUNDS');
  const { currentUser } = useAuthStore(({ currentUser }) => {
    return {
      currentUser,
    };
  });
  const {
    data: userOrgFundsData,
    isLoading: isGetuserOrgFundsDataLoading,
    //isError: isErrorGetuserOrgFundsData,
    error,
    isFetching: isGetuserOrgFundsDataFetching,
    refetch: refetchUserOrgFunds,
  } = useGetUserOrgFunds(fund?.id);
  const { data: allUsersOfOrgsData, isLoading: allUsersLoading } = useUsers();
  const [openAddUserToFundDialog, setOpenAddUserToFundDialog] = useState<boolean>(false);
  const [roleOrgFundToDelete, setRoleOrgFundToDelete] = useState<IUserOrgRole | undefined>();
  const { mutateAsync: deleteUserOrgFund, isLoading: isLoadingDeleteUserOrgFund } = useDeleteUserOrgFund();

  useEffect(() => {
    if (error) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: error.errorMessage,
        icon: 'warning-sign',
      });
    }
  }, [error]);

  const handleDeleteUserOrgRole = async () => {
    console.log('roleOrgFundToDelete', roleOrgFundToDelete);
    try {
      if (roleOrgFundToDelete && fund) {
        await deleteUserOrgFund({ userId: roleOrgFundToDelete?.user.id, fundId: fund?.id });
      }
      setRoleOrgFundToDelete(undefined);
      refetchUserOrgFunds();
    } catch (error) {
      console.log(error);
      setRoleOrgFundToDelete(undefined);
    }
  };

  const columns = useMemo(
    (): Column<IUserOrgRole>[] => [
      {
        Header: 'Username',
        accessor: (row) => row.user.userEmailId,
        Cell: ({ value, row }: { value: string; row: Row<IUserOrgRole> }) => (
          <>
            <Flex>
              <Box>
                <UserAvatar user={row.original.user}></UserAvatar>
              </Box>
              <Box ml={2}>
                <div>{row.original.user.firstName + ' ' + row.original.user.lastName}</div>
                <div>
                  <small>{value}</small>
                </div>
              </Box>
            </Flex>
          </>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => {
          return <>{ROLES_CONFIG.find((r) => r.role_name === value.roleName)?.display_name || ''}</>;
        },
      },

      {
        Header: '',
        accessor: 'id',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Cell: ({ row }) => {
          return (
            <Flex flexDirection={'row-reverse'}>
              {currentUser?.user.id !== row.original.user.id && (
                <Button minimal outlined intent={Intent.DANGER} onClick={() => setRoleOrgFundToDelete(row.original)}>
                  Revoke Access
                </Button>
              )}
            </Flex>
          );
        },
      },
    ],
    [],
  );

  const usersRoles = allUsersOfOrgsData?.userOrgRoles?.filter((uor) =>
    userOrgFundsData?.userOrgFunds.find((uof) => uof.user.id === uor.user.id),
  );

  const possibleUsersToAdd = allUsersOfOrgsData?.userOrgRoles?.filter(
    (uor) => !userOrgFundsData?.userOrgFunds.find((uof) => uof.user.id === uor.user.id),
  );

  const isLoading = allUsersLoading || isGetuserOrgFundsDataLoading;
  return (
    <div>
      <Box p={2}>
        <Card style={{ padding: 0 }}>
          <Navbar className="card-tabs-navbar">
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading> Users With Fund Access </Navbar.Heading>
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
              <Button intent={Intent.PRIMARY} onClick={() => setOpenAddUserToFundDialog(true)}>
                Grant User Access to this fund
              </Button>
            </Navbar.Group>
          </Navbar>
          {isGetuserOrgFundsDataFetching && <GlobalLoader></GlobalLoader>}
          {isLoading ? (
            <TableLoader></TableLoader>
          ) : (
            usersRoles && <UsersTable columns={columns} data={usersRoles}></UsersTable>
          )}
        </Card>
      </Box>

      <AddUserToFundDialog
        isOpen={openAddUserToFundDialog}
        fund={fund}
        possibleUsers={possibleUsersToAdd || []}
        close={() => {
          setOpenAddUserToFundDialog(false);
        }}
      ></AddUserToFundDialog>

      <Alert
        confirmButtonText="Yes, revoke the access."
        cancelButtonText={'Cancel'}
        isOpen={!!roleOrgFundToDelete}
        loading={isLoadingDeleteUserOrgFund}
        //onClose={() => setRoleOrgFundToDelete(undefined)}
        onCancel={() => setRoleOrgFundToDelete(undefined)}
        icon={'blocked-person'}
        intent={Intent.DANGER}
        onConfirm={handleDeleteUserOrgRole}
      >
        <p>Are you sure you want to revoke the access of {roleOrgFundToDelete?.user.userEmailId} ?</p>
      </Alert>
    </div>
  );
};

const FundsSettings: FC<IFundsSettingsProps> = ({ fund }): ReactElement => {
  const [activeTabId, setActiveTabId] = useState<string>('access');
  return (
    <Styles>
      <Box>
        <Navbar className={'tabs-navbar'}>
          <Navbar.Group align={Alignment.LEFT}>
            <Tabs
              renderActiveTabPanelOnly={true}
              onChange={(id) => setActiveTabId(id as string)}
              selectedTabId={activeTabId}
            >
              <Tab id="access" title="User Access" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <Box p={2}>{activeTabId === 'access' && <UserAccess fund={fund} />}</Box>
      </Box>
    </Styles>
  );
};

export default FundsSettings;

const Styles = styled.div``;

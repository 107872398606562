import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import logo from '../../assets/img/Osyte-Logo-02.svg';

const FullScreenLoaderWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #1e2640;
`;

const BrandImage = styled.img`
  width: 30vh;
  margin-bottom: 30px;
`;

const FullScreenLoader: FC = (): ReactElement => (
  <FullScreenLoaderWrapper>
    <BrandImage src={logo} />
    <BeatLoader size={20} color="#fff" />
  </FullScreenLoaderWrapper>
);

export default FullScreenLoader;

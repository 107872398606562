import formatAllocationPercentage from './formatAllocationPercentage';

const isValidTargetAndAllocationBounds = (
  target_allocation_range_lower_bound: string,
  target_allocation_range_upper_bound: string,
  target_allocation: string,
) => {
  if (
    target_allocation_range_lower_bound === target_allocation_range_upper_bound &&
    target_allocation_range_upper_bound === target_allocation
  ) {
    if (Number(target_allocation) === 0) return false;
    else if (!target_allocation) return false;
  }
  return true;
};

const formatAllocationPercentageWithBoundsCheck = (
  target_allocation_range_lower_bound: string,
  target_allocation_range_upper_bound: string,
  target_allocation: string,
  value: string,
): string => {
  if (
    !isValidTargetAndAllocationBounds(
      target_allocation_range_lower_bound,
      target_allocation_range_upper_bound,
      target_allocation,
    )
  )
    return '';
  return formatAllocationPercentage({ value: Number(value) });
};

export default formatAllocationPercentageWithBoundsCheck;

import ITrade from '@shared/interfaces/ITrade';
import { ReactElement, useMemo, useState } from 'react';
import { Column } from 'react-table';
import getTradeProcessingColumns from './getTradeProcessingColumns';
import TradeProcessingTable from './TradeProcessingTable';
//import Search from '../../../assets/img/search-icon.svg';
//import Filter from '../../../assets/img/filter-white-icon.svg';
//import Export from '../../../assets/img/export-right-icon.svg';

import useGetTrades from '@common/hooks/useGetTrades';
import TableLoader from '@components/TableLoader';
import GlobalLoader from '@components/GlobalLoader';
import { Alignment, Navbar } from '@blueprintjs/core';
import TradeStatusSelectionDropdown from '../components/TradeStatusSelectionDropdown';

interface ITradeProessingPaneProps {
  selectedFundId: number | undefined;
}

const TradeProcessingPane = ({ selectedFundId }: ITradeProessingPaneProps): ReactElement => {
  const tradeHistoryTableColumns = useMemo<Column<ITrade>[]>(getTradeProcessingColumns, []);
  const [selectedTradeStatusName, setSelectedTradeStatusName] = useState<string | undefined>();
  const {
    data: tradesData,
    isLoading: isTradesDataLoading,
    isFetching,
  } = useGetTrades(selectedFundId, undefined, undefined, 'PROCESSING');

  const handleChangeSelectedTradeStatus = (tradeStatusName: string | undefined) => {
    console.log('tradeStatusId', tradeStatusName);
    setSelectedTradeStatusName(tradeStatusName);
  };

  return (
    <>
      {isFetching && <GlobalLoader></GlobalLoader>}
      <div>
        <div>
          {isTradesDataLoading || !selectedFundId ? (
            <TableLoader></TableLoader>
          ) : (
            <div>
              {tradesData && (
                <>
                  <div>
                    <Navbar>
                      <Navbar.Group align={Alignment.LEFT}>
                        {tradesData && (
                          <TradeStatusSelectionDropdown
                            selectedTradeStatusName={selectedTradeStatusName}
                            switchTradeStatus={handleChangeSelectedTradeStatus}
                            tradeStatuses={tradesData.tradeStatuses}
                            disabled={isTradesDataLoading}
                          ></TradeStatusSelectionDropdown>
                        )}
                      </Navbar.Group>
                    </Navbar>
                    <TradeProcessingTable
                      columns={tradeHistoryTableColumns}
                      selectedTradeStatusName={selectedTradeStatusName}
                      data={tradesData.trades}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TradeProcessingPane;

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetJobLogsResponse } from '@shared/exchange/getJobLogs';
import dayjs from 'dayjs';

export const GET_OPS_JOB_LOGS = 'get-ops-job-logs';
export default function useGetOpsJobLogs(
  startDate: Date,
  endDate: Date,
): UseQueryResult<IGetJobLogsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetJobLogsResponse, IErrorResponse>(
    [GET_OPS_JOB_LOGS, { startDate, endDate }],

    async () => {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      return axios
        ?.get(`get-job-logs?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

import { ButtonGroup, Button } from '@blueprintjs/core';
import { FC } from 'react';
import { Box } from 'reflexbox';

export interface IPaginationProps {
  pageCount: number;
  pageOptions: number[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  nextPage: () => void;
  //setPageSize: (pageSize: number) => void;
  pageIndex: number;
}

const Pagination: FC<IPaginationProps> = ({
  pageIndex,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageOptions,
}) => {
  return (
    <nav>
      {pageOptions.length > 0 && (
        <Box display="inline" paddingRight="2">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Box>
      )}

      <ButtonGroup>
        <Button
          disabled={!canPreviousPage}
          onClick={() => {
            gotoPage(0);
          }}
          icon="double-chevron-left"
        ></Button>

        <Button
          disabled={!canPreviousPage}
          onClick={() => {
            previousPage();
          }}
          icon="chevron-left"
        >
          {'Previous'}
        </Button>

        <Button
          disabled={!canNextPage}
          onClick={() => {
            nextPage();
          }}
          rightIcon="chevron-right"
        >
          {'Next'}
        </Button>

        <Button
          disabled={!canNextPage}
          onClick={() => {
            gotoPage(pageCount - 1);
          }}
          rightIcon="double-chevron-right"
        ></Button>
      </ButtonGroup>
    </nav>
  );
};

export default Pagination;

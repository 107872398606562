import IGetDocSignedUploadUrlResponse from '@shared/exchange/getDocSignedUploadUrl/IGetDocSignedUploadUrlResponse';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IGetDocTemplateSignedUploadURLRequest {
  docName: string;
  fundId: number;
  accountId: number;
}

export default function useGetDocSignedUploadUrl(): UseMutationResult<
  IGetDocSignedUploadUrlResponse,
  IErrorResponse,
  IGetDocTemplateSignedUploadURLRequest,
  IGetDocTemplateSignedUploadURLRequest
> {
  const axios = useAxios();
  return useMutation<
    IGetDocSignedUploadUrlResponse,
    IErrorResponse,
    IGetDocTemplateSignedUploadURLRequest,
    IGetDocTemplateSignedUploadURLRequest
  >(async (data: IGetDocTemplateSignedUploadURLRequest) =>
    axios
      ?.post('get-doc-template-signed-upload-url', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

import { Card } from '@blueprintjs/core';
import { ReactElement } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const ChartLoader = (props: IContentLoaderProps): ReactElement => (
  <Card style={{ padding: 0, paddingBottom: 2, overflow: 'hidden' }}>
    <ContentLoader
      viewBox="0 0 100% 300"
      height={350}
      width={'100%'}
      {...props}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="48" />
      <rect x="30%" y="160" rx="0" ry="0" width="5%" height="80" />
      <rect x="37%" y="145" rx="0" ry="0" width="5%" height="95" />
      <rect x="44%" y="126" rx="0" ry="0" width="5%" height="114" />
      <rect x="51%" y="80" rx="0" ry="0" width="5%" height="160" />
      <rect x="58%" y="142" rx="0" ry="0" width="5%" height="98" />
    </ContentLoader>
  </Card>
);
export default ChartLoader;

import { createContext, ReactElement, useContext, useMemo } from 'react';
import Axios, { AxiosInstance } from 'axios';

export const AxiosExternalContext = createContext<AxiosInstance | undefined>(undefined);

export default function AxiosExternalProvider({ children }: React.PropsWithChildren<unknown>): ReactElement {
  const axios = useMemo(() => {
    const axios = Axios.create({});
    return axios;
  }, []);

  return <AxiosExternalContext.Provider value={axios}>{children}</AxiosExternalContext.Provider>;
}

export function useExternalAxios(): AxiosInstance | undefined {
  return useContext(AxiosExternalContext);
}

import useFunds from '@common/hooks/useFunds';
import FormLoader from '@components/FormLoader';
import useAdministrationStore from '@stores/useAdministrationStore';
import { FC, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import { FormGroup, HTMLSelect, InputGroup, Intent, Button, TextArea, Colors } from '@blueprintjs/core';
import customRegisterFormField from '@common/utils/customRegisterFormField';
import useCreateExternalTransferAccount from '@common/hooks/useCreateExternalTransferAccount';
import useGetAccountTypeRefs from '@common/hooks/useGetAccountTypeRefs';
import { GET_EXT_ACCOUNTS_KEY } from '@common/hooks/useExternalTransferAccounts';
import useUpdateExternalTransferAccount from '@common/hooks/useUpdateExternalTransferAccount';
import IExternalTransferAccount from '@shared/interfaces/IExternalTransferAccount';
import IAccountType from '@shared/interfaces/IAccountType';

export interface IExternalAccountRecordStateType {
  from: {
    externalAccount: IExternalTransferAccount | undefined;
  };
}

const formSchema = yup.object().shape({
  id: yup.number(),
  accountName: yup.string().required('Account name is required.'),
  fundId: yup.number().required('No Fund Found.'),
  accountTypeId: yup.number().required('No Account Type Found'),
  accountNo: yup.string().nullable(),
  accountSwiftCode: yup.string().nullable(),
  accountHoldingInstitutionName: yup.string().nullable(),
  accountAddress: yup.string().nullable(),
  accountContactEmailId: yup.string().nullable().email('Invalid Email Address Format'),
});

const ExternalTransferAccountsCreationOrUpdationPage: FC = (): ReactElement => {
  const {
    mutateAsync: createExternalAccount,
    isLoading: isCreateExtAccountLoading,
    error: createExtAccountError,
  } = useCreateExternalTransferAccount();
  const {
    mutateAsync: updateExternalAccount,
    isLoading: isUpdateExtAccountLoading,
    error: updateExtAccountError,
  } = useUpdateExternalTransferAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(formSchema) });
  const { data: fundsData, isLoading: isGetFundsLoading } = useFunds();
  const { data: accountTypeRefsData, isLoading: isGetAccountTypeRefsLoading } = useGetAccountTypeRefs();
  const { selectedFundId, setSelectedFundId } = useAdministrationStore(({ selectedFundId, setSelectedFundId }) => {
    return { selectedFundId, setSelectedFundId };
  });

  console.log('Account Type: ' + accountTypeRefsData?.accountTypeRefs.length);

  let filteredAccountTypeRefs: IAccountType[] = [];
  if (accountTypeRefsData && accountTypeRefsData.accountTypeRefs) {
    console.log('Inside Account Ref');
    filteredAccountTypeRefs = accountTypeRefsData.accountTypeRefs.filter(
      (accountType) => accountType.accountTypeName === 'Suspense Account',
    );
  }

  console.log('Filter Account Type Length: ' + filteredAccountTypeRefs?.length);

  useEffect(() => {
    if (!selectedFundId && fundsData) {
      setSelectedFundId(fundsData?.funds[0].id);
    }
  }, [fundsData]);

  const history = useHistory();
  const queryClient = useQueryClient();
  const location = useLocation();

  const { from } = location.state as IExternalAccountRecordStateType;

  useEffect(() => {
    if (from?.externalAccount) {
      console.log('Edit record: ' + from?.externalAccount.id);
      console.log('Fund Name: ' + from?.externalAccount.fund.fundName);
      console.log('Account Type Name: ' + from?.externalAccount.accountType.accountTypeName);
      setValue('id', from?.externalAccount.id);
      setValue('fundId', from?.externalAccount.fund.id, { shouldDirty: true, shouldValidate: true });
      setValue('accountName', from?.externalAccount.accountName, { shouldDirty: true, shouldValidate: true });
      setValue('accountTypeId', from?.externalAccount.accountType.id, { shouldDirty: false, shouldValidate: false });
      setValue('accountAddress', from?.externalAccount.accountAddress, { shouldDirty: true, shouldValidate: true });
      setValue('accountNo', from?.externalAccount.accountNo, { shouldDirty: true, shouldValidate: true });
      setValue('accountSwiftCode', from?.externalAccount.accountSwiftCode, { shouldDirty: true, shouldValidate: true });
      setValue('accountHoldingInstitutionName', from?.externalAccount.accountHoldingInstitutionName, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('accountContactEmailId', from?.externalAccount.accountContactEmailId, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('accountTypeId', filteredAccountTypeRefs[0]?.id);
    }
  }, [from?.externalAccount]);

  const onSubmit = async (data: {
    id: number;
    fundId: number;
    accountName: string;
    accountTypeId: number;
    accountAddress: string;
    accountNo: string;
    accountSwiftCode: string;
    accountHoldingInstitutionName: string;
    accountContactEmailId: string;
  }) => {
    console.log(`Data: ${JSON.stringify(data)}`);
    try {
      if (!from?.externalAccount) {
        await createExternalAccount({
          accountName: data.accountName,
          fundId: data.fundId,
          accountTypeId: data.accountTypeId,
          accountAddress: data.accountAddress,
          accountNo: data.accountNo,
          accountSwiftCode: data.accountSwiftCode,
          accountHoldingInstitutionName: data.accountHoldingInstitutionName,
          accountContactEmailId: data.accountContactEmailId,
        });
      } else {
        await updateExternalAccount({
          id: data.id,
          accountName: data.accountName,
          fundId: data.fundId,
          accountTypeId: data.accountTypeId,
          accountAddress: data.accountAddress,
          accountNo: data.accountNo,
          accountSwiftCode: data.accountSwiftCode,
          accountHoldingInstitutionName: data.accountHoldingInstitutionName,
          accountContactEmailId: data.accountContactEmailId,
        });
      }
      queryClient.invalidateQueries(GET_EXT_ACCOUNTS_KEY);
      history.push('/administration/external-accounts');
    } catch (error) {}
  };

  const isMetaDataLoading = isGetFundsLoading || isGetAccountTypeRefsLoading;
  const isSubmitting = isCreateExtAccountLoading || isUpdateExtAccountLoading;

  return (
    <Flex m={2}>
      <Box width={[6 / 12]}>
        <CustomCard
          heading={
            from?.externalAccount ? 'Edit External Transfer/Suspense Account' : 'Add External Transfer/Suspense Account'
          }
          body={
            <Box m={3}>
              {isMetaDataLoading ? (
                <FormLoader></FormLoader>
              ) : (
                <>
                  {(createExtAccountError || updateExtAccountError) && (
                    <div className="col-lg-6  col-sm-12 me-2">
                      <div className="alert alert-danger text-center" role="alert">
                        <>{createExtAccountError?.errorMessage}</>
                        <>{updateExtAccountError?.errorMessage}</>
                      </div>
                    </div>
                  )}
                  <form
                    className="formInputs col-12"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    key={from?.externalAccount ? from?.externalAccount.id.toString() : undefined}
                  >
                    <FormGroup
                      helperText={errors?.accountName?.message}
                      label="Enter Account Name"
                      labelFor="accountName"
                      labelInfo="(required)"
                      intent={errors?.accountName ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="accountName"
                        placeholder="Enter the account name"
                        intent={errors?.accountName ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'accountName')}
                      ></InputGroup>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.fundId?.message}
                      label="Fund"
                      labelFor="fundId"
                      labelInfo="(required)"
                      intent={errors?.fundId ? Intent.DANGER : Intent.NONE}
                    >
                      <HTMLSelect fill={true} id="fundId" {...customRegisterFormField(register, 'fundId')} large={true}>
                        {fundsData?.funds.map((fund) => (
                          <option key={fund.id} value={fund.id}>
                            {fund.fundName}
                          </option>
                        ))}
                      </HTMLSelect>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountTypeId?.message}
                      label="Account Type"
                      labelFor="accountTypeId"
                      labelInfo="(required)"
                      intent={errors?.accountTypeId ? Intent.DANGER : Intent.NONE}
                    >
                      <HTMLSelect
                        id="accountTypeId"
                        large={true}
                        fill={true}
                        {...customRegisterFormField(register, 'accountTypeId')}
                        style={{ backgroundColor: Colors.LIGHT_GRAY4 }}
                      >
                        {from?.externalAccount
                          ? accountTypeRefsData?.accountTypeRefs.map(
                              (accountType) =>
                                accountType.accountTypeName === 'Suspense Account' &&
                                from?.externalAccount &&
                                from.externalAccount.accountType.id === accountType.id && (
                                  <option key={accountType.id} value={accountType.id}>
                                    {accountType.accountTypeName}
                                  </option>
                                ),
                              //  : (
                              //   <option
                              //     key={accountType.id}
                              //     value={accountType.id}
                              //     disabled
                              //     style={{ backgroundColor: Colors.LIGHT_GRAY5 }}
                              //   >
                              //     {accountType.accountTypeName}
                              //   </option>
                              // ),
                            )
                          : filteredAccountTypeRefs.map((accountType) => (
                              <option key={accountType.id} value={accountType.id}>
                                {accountType.accountTypeName}
                              </option>
                            ))}
                        {/* {accountTypeRefsData?.accountTypeRefs.map((accountType) =>
                          accountType.accountTypeName === 'Suspense Account' ? (
                            <option key={accountType.id} value={accountType.id}>
                              {accountType.accountTypeName}
                            </option>
                          ) : (
                            <option
                              key={accountType.id}
                              value={accountType.id}
                              disabled
                              style={{ backgroundColor: Colors.LIGHT_GRAY5 }}
                            >
                              {accountType.accountTypeName}
                            </option>
                          ),
                        )} */}
                      </HTMLSelect>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountNo?.message}
                      label="Enter Account Number"
                      labelFor="accountNo"
                      labelInfo=""
                      intent={errors?.accountNo ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="accountNo"
                        placeholder="Enter the account number"
                        intent={errors?.accountNo ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'accountNo')}
                      ></InputGroup>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountSwiftCode?.message}
                      label="Enter Swift Code"
                      labelFor="accountSwiftCode"
                      labelInfo=""
                      intent={errors?.accountSwiftCode ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="accountSwiftCode"
                        placeholder="Enter swift code"
                        intent={errors?.accountSwiftCode ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'accountSwiftCode')}
                      ></InputGroup>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountHoldingInstitutionName?.message}
                      label="Enter Institution Name"
                      labelFor="accountHoldingInstitutionName"
                      labelInfo=""
                      intent={errors?.accountHoldingInstitutionName ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="accountHoldingInstitutionName"
                        placeholder="Enter Institution name"
                        intent={errors?.accountHoldingInstitutionName ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'accountHoldingInstitutionName')}
                      ></InputGroup>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountAddress?.message}
                      label="Enter Account Address"
                      labelFor="accountAddress"
                      labelInfo=""
                      intent={errors?.accountAddress ? Intent.DANGER : Intent.NONE}
                    >
                      <TextArea
                        id="accountAddress"
                        placeholder="Enter account address"
                        intent={errors?.accountAddress ? Intent.DANGER : Intent.NONE}
                        large={true}
                        style={{ width: '300px', height: '150px' }}
                        {...customRegisterFormField(register, 'accountAddress')}
                      ></TextArea>
                    </FormGroup>
                    <FormGroup
                      helperText={errors?.accountContactEmailId?.message}
                      label="Enter Contact Email Id"
                      labelFor="accountContactEmailId"
                      labelInfo=""
                      intent={errors?.accountContactEmailId ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="accountContactEmailId"
                        placeholder="Enter contact email id"
                        intent={errors?.accountContactEmailId ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'accountContactEmailId')}
                      ></InputGroup>
                    </FormGroup>
                    <Box mt={3}>
                      <Button loading={isSubmitting} type="submit" intent={Intent.PRIMARY} disabled={isSubmitting}>
                        Submit
                      </Button>
                      <Button minimal onClick={() => history.push('/administration/external-accounts')}>
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </>
              )}
            </Box>
          }
        ></CustomCard>
      </Box>
    </Flex>
  );
};

export default ExternalTransferAccountsCreationOrUpdationPage;

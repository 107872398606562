import { useAxios } from '@common/providers/AxiosProvider';
import {
  IPublishDocMgmtFolderEntryRequest,
  IPublishDocMgmtFolderEntryResponse,
} from '@shared/exchange/publishDocMgmtFolderEntry';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function usePublishDocMgmtFolderEntry(): UseMutationResult<
  IPublishDocMgmtFolderEntryResponse,
  IErrorResponse,
  IPublishDocMgmtFolderEntryRequest,
  IPublishDocMgmtFolderEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    IPublishDocMgmtFolderEntryResponse,
    IErrorResponse,
    IPublishDocMgmtFolderEntryRequest,
    IPublishDocMgmtFolderEntryResponse
  >(async (data: IPublishDocMgmtFolderEntryRequest) =>
    axios
      ?.patch('publish-doc-mgmt-folder-entry', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

import { Card } from '@blueprintjs/core';
import { ReactElement } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const TableLoader = (props: IContentLoaderProps): ReactElement => (
  <Card style={{ padding: 0, paddingBottom: 2, overflow: 'hidden' }}>
    <ContentLoader
      width={'100%'}
      height={400}
      viewBox="0 0 100% 400"
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      style={{ marginTop: '-45px' }}
      {...props}
    >
      <rect x="0" y="45" rx="3" ry="3" width="100%" height="17" />
      <circle cx="95%" cy="123" r="11" />
      <circle cx="97%" cy="123" r="11" />
      <rect x="1%" y="115" rx="3" ry="3" width="30%" height="15" />
      <rect x="35%" y="115" rx="3" ry="3" width="20%" height="15" />
      <rect x="59%" y="115" rx="3" ry="3" width="20%" height="15" />
      <rect x="83%" y="115" rx="3" ry="3" width="10%" height="15" />
      <rect x="0%" y="155" rx="3" ry="3" width="100%" height="2" />

      <circle cx="95%" cy="184" r="11" />
      <circle cx="97%" cy="184" r="11" />
      <rect x="1%" y="176" rx="3" ry="3" width="30%" height="15" />
      <rect x="35%" y="176" rx="3" ry="3" width="20%" height="15" />
      <rect x="59%" y="176" rx="3" ry="3" width="20%" height="15" />
      <rect x="83%" y="176" rx="3" ry="3" width="10%" height="15" />
      <rect x="0%" y="216" rx="3" ry="3" width="100%" height="2" />

      <circle cx="95%" cy="242" r="11" />
      <circle cx="97%" cy="242" r="11" />
      <rect x="1%" y="234" rx="3" ry="3" width="30%" height="15" />
      <rect x="35%" y="234" rx="3" ry="3" width="20%" height="15" />
      <rect x="59%" y="234" rx="3" ry="3" width="20%" height="15" />
      <rect x="83%" y="234" rx="3" ry="3" width="10%" height="15" />
      <rect x="0%" y="274" rx="3" ry="3" width="100%" height="2" />

      <circle cx="95%" cy="303" r="11" />
      <circle cx="97%" cy="303" r="11" />
      <rect x="1%" y="295" rx="3" ry="3" width="30%" height="15" />
      <rect x="35%" y="295" rx="3" ry="3" width="20%" height="15" />
      <rect x="59%" y="295" rx="3" ry="3" width="20%" height="15" />
      <rect x="83%" y="295" rx="3" ry="3" width="10%" height="15" />
      <rect x="0%" y="335" rx="3" ry="3" width="100%" height="2" />

      <circle cx="95%" cy="363" r="11" />
      <circle cx="97%" cy="363" r="11" />
      <rect x="1%" y="355" rx="3" ry="3" width="30%" height="15" />
      <rect x="35%" y="355" rx="3" ry="3" width="20%" height="15" />
      <rect x="59%" y="355" rx="3" ry="3" width="20%" height="15" />
      <rect x="83%" y="355" rx="3" ry="3" width="10%" height="15" />
      <rect x="0%" y="395" rx="3" ry="3" width="100%" height="2" />

      <rect x="0" y="80" rx="3" ry="3" width="100%" height="17" />

      <rect x="0%" y="57" rx="3" ry="3" width="2%" height="33" />
      <rect x="29%" y="54" rx="3" ry="3" width="7%" height="33" />
      <rect x="53%" y="54" rx="3" ry="3" width="7%" height="33" />
      <rect x="77%" y="54" rx="3" ry="3" width="7%" height="33" />
      <rect x="92%" y="54" rx="3" ry="3" width="8%" height="33" />
    </ContentLoader>
  </Card>
);

export default TableLoader;

import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FC, ReactElement } from 'react';
import FullScreenLoader from '../components/FullScreenLoader';
import useCurrentUser from '@common/hooks/useCurrentUser';
import { canRead, canWrite } from './acl';
import NoAccessPage from '../pages/NoAccessPage';
import { FunctionComponent } from 'react-bootstrap/node_modules/@types/react';
import ACLLoadingPage from '../pages/ACLLoadingPage/ACLLoadingPage';
import IFunctionACL from './IFuntionACL';

interface IProtectRouteProps {
  component: FC;
  path: string;
  exact?: boolean;
  acls: IFunctionACL[];
  Layout: FunctionComponent<{ pageTitle?: string }>;
}

//TODO Reusse this function
const withLayout =
  (Layout: FC<{ pageTitle?: string }>, Page: FC, pageTitle: string): FC =>
  (): ReactElement =>
    (
      <Layout pageTitle={pageTitle}>
        <Page></Page>
      </Layout>
    );

const withACL = (
  Component: FC,
  acls: IFunctionACL[],
  Layout: FunctionComponent<{ pageTitle?: string }>,
  path: string,
) => {
  const { isLoading: isCurrentUserLoading, data: currentUser } = useCurrentUser();
  if (isCurrentUserLoading || !currentUser) return FullScreenLoader;

  if (currentUser) {
    if (path === '/' || path === '') return withLayout(Layout, ACLLoadingPage, '');
    const isValid = acls.some((acl) => {
      return (
        (acl.operation == 'READ' && canRead(acl, currentUser)) ||
        (acl.operation == 'WRITE' && canWrite(acl, currentUser))
      );
    });
    return isValid ? Component : withLayout(Layout, NoAccessPage, '');
  }
  return FullScreenLoader;
};

const ProtectedRoute: FC<IProtectRouteProps> = ({ component, path, exact, acls, Layout }): ReactElement => (
  <Route
    component={withAuthenticationRequired(withACL(component, acls, Layout, path), {
      onRedirecting: () => <FullScreenLoader />,
    })}
    path={path}
    exact={exact}
  />
);

export default ProtectedRoute;

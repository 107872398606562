import { Slider, Divider, Intent } from '@blueprintjs/core';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import { Position } from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';

const Sliders = ({ resetRebalResults }: { resetRebalResults: () => void }): ReactElement => {
  const { transactionsDegree, setTransactionsDegree, targetConstraints, setTargetConstraints } = useWhatIfRebalStore(
    ({ transactionsDegree, setTransactionsDegree, targetConstraints, setTargetConstraints }) => {
      return { transactionsDegree, setTransactionsDegree, targetConstraints, setTargetConstraints };
    },
  );
  return (
    <Flex height={'100%'} width={'100%'}>
      <Box width={[1 / 2]}>
        <Box p={2} pb={1}>
          <h6 className="bp3-heading">
            <strong>Relative Weight: Number of Transactions</strong>
            <Popover2
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              interactionKind="hover-target"
              content={
                <>
                  The Number of Transactions slider affects the relative number of assets in the rebalancing solution. A
                  value toward the Less end results in fewer assets in the proposed solution and value toward the More
                  end results in more assets in the proposed solution.
                </>
              }
              position={Position.BOTTOM}
            >
              <Box pl={2}>
                <a href="#">Info</a>
              </Box>
            </Popover2>
          </h6>
        </Box>
        <Divider style={{ margin: 0, padding: 0 }} />
        <Box mt={3}>
          <Flex paddingLeft={'2rem'} paddingRight={'2rem'} justifyContent="center">
            <Slider
              showTrackFill={false}
              min={0}
              max={10}
              stepSize={0.25}
              labelStepSize={1}
              onChange={(value) => {
                resetRebalResults();
                setTransactionsDegree(value);
              }}
              value={transactionsDegree}
              vertical={false}
              labelRenderer={(value) => {
                if (value === 0)
                  return (
                    <Box textAlign="center">
                      <Box mb={1}>0</Box>
                      <Box>
                        <small>Less</small>
                      </Box>
                    </Box>
                  );
                else if (value === 10)
                  return (
                    <Box textAlign="center">
                      <Box mb={1}>10</Box>
                      <Box>
                        <small>More</small>
                      </Box>
                    </Box>
                  );
                return value?.toString();
              }}
            />
          </Flex>
        </Box>
      </Box>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Box width={[1 / 2]}>
        <Box p={2} pb={1}>
          <h6 className="bp3-heading">
            <strong>Relative Weight: Target Constraints</strong>
            <Popover2
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              interactionKind="hover-target"
              content={
                <>
                  The Target Constraints slider affects the relative weights of the Asset Targets and the Asset Class
                  Targets. A value toward the Asset Targets end gives more weight to the Asset Targets and results in a
                  proposed solution that is closer to the Asset Targets and a value toward the Asset Classes end gives
                  more weight to the Asset Class Targets and results in a proposed solution that is closer to the Asset
                  Class Targets.
                </>
              }
              position={Position.BOTTOM}
            >
              <Box pl={2}>
                <a href="#">Info</a>
              </Box>
            </Popover2>
          </h6>
        </Box>
        <Divider style={{ margin: 0, padding: 0 }} />
        <Box mt={3}>
          <Flex paddingLeft={'2rem'} paddingRight={'2rem'} justifyContent="center">
            <Slider
              intent={Intent.PRIMARY}
              showTrackFill={false}
              min={0}
              max={10}
              stepSize={0.25}
              labelStepSize={1}
              onChange={(value) => {
                resetRebalResults();
                setTargetConstraints(value);
              }}
              value={targetConstraints}
              vertical={false}
              labelRenderer={(value) => {
                if (value === 0)
                  return (
                    <Box textAlign="center">
                      <Box mb={1}>0</Box>
                      <Box>
                        <small>Asset</small>
                      </Box>
                      <Box>
                        <small>Targets</small>
                      </Box>
                    </Box>
                  );
                else if (value === 10)
                  return (
                    <Box textAlign="center">
                      <Box mb={1}>10</Box>
                      <Box>
                        <small>Asset Class</small>
                      </Box>
                    </Box>
                  );
                return value?.toString();
              }}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Sliders;

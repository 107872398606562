import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import dayjs from 'dayjs';
import { IGetMarketDataResponse } from '@shared/exchange/getMarketData';

export const GET_ETL_MARKET_DATA = 'get-etl-market-data';
export default function useGetETLMarketData(
  startDate: Date,
  endDate: Date,
): UseQueryResult<IGetMarketDataResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetMarketDataResponse, IErrorResponse>(
    [GET_ETL_MARKET_DATA, { startDate, endDate }],
    async () => {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      return axios
        ?.get(`get-market-data?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

import { IUpdateWhatIfRebalSettingsRequest } from '@shared/exchange/updateWhatIfRebalSettings';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useUpdateWhatIfRebalSettings(): UseMutationResult<
  IUpdateWhatIfRebalSettingsRequest,
  IErrorResponse,
  IUpdateWhatIfRebalSettingsRequest,
  IUpdateWhatIfRebalSettingsRequest
> {
  const axios = useAxios();
  return useMutation<
    IUpdateWhatIfRebalSettingsRequest,
    IErrorResponse,
    IUpdateWhatIfRebalSettingsRequest,
    IUpdateWhatIfRebalSettingsRequest
  >(async (whatIfRebalSettingsRequestData: IUpdateWhatIfRebalSettingsRequest) => {
    const routePath = 'update-what-if-rebal-settings';
    return axios
      ?.put(routePath, whatIfRebalSettingsRequestData)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      });
  });
}

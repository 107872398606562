import { Spinner } from '@blueprintjs/core';
import useAuthStore from '@stores/useAuthStore';
import { ReactElement } from 'react';
import { useHistory } from 'react-router';
import routes from '../../routes';

function ACLLoadingPage(): ReactElement {
  const { canCurrentUserReadACL } = useAuthStore();
  const history = useHistory();
  let firstAllowedRoute = '/dashboard';
  for (const route of routes) {
    if (route.path !== '/' && route.acls.some((acl) => canCurrentUserReadACL(acl.operationType))) {
      firstAllowedRoute = route.path;
      break;
    }
  }
  history.push(firstAllowedRoute);
  return <Spinner></Spinner>;
}

export default ACLLoadingPage;

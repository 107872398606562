import { ReactElement } from 'react';

const CenteredMetric = ({ centerX, centerY }: { centerX: number; centerY: number }): ReactElement => {
  return (
    <>
      <text
        x={centerX}
        y={centerY - 12}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '16px',
        }}
      >
        Current
      </text>

      <text
        x={centerX}
        y={centerY + 12}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '16px',
        }}
      >
        Allocation
      </text>
    </>
  );
};

export default CenteredMetric;

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import { IGetFundsSummaryResponse } from '@shared/exchange/getFundsSummary';
import IErrorResponse from '@shared/interfaces/IErrorReponse';

export default function useFundsSummary(): UseQueryResult<IGetFundsSummaryResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetFundsSummaryResponse, IErrorResponse>(
    ['fundsSummary'],
    async () =>
      axios
        ?.get('get-funds-summary')
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false },
  );
}

import { Alignment, Card, Elevation, Navbar, Tab, TabId, Tabs } from '@blueprintjs/core';
import useFunds from '@common/hooks/useFunds';

import useGetFileCreationMetadata from '@common/hooks/useGetFileCreationMetadata';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import PageToolbar from '@components/PageToolbar';
import useAuthStore from '@stores/useAuthStore';

import useDocumentManagementStore from '@stores/useDocumentManagementStore';
import { ReactElement, useEffect, useState } from 'react';
import { Box } from 'reflexbox';
import DocumentManagementContainer from './components/DocumentManagementContainer';

const DocumentManagement = (): ReactElement => {
  const { selectedFundId, setSelectedFundId, switchFund, selectedDocTypeId, setSelectedDocTypeId, setSelectedFolder } =
    useDocumentManagementStore();

  const { data: fundData } = useFunds();
  const { data: docTypeData } = useGetFileCreationMetadata();

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (!docTypeData || !docTypeData?.docMgmtMetadata || docTypeData?.docMgmtMetadata.length === 0) return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
    if (!selectedDocTypeId) setSelectedDocTypeId(docTypeData?.docMgmtMetadata[0].id);
  }, [fundData, docTypeData]);

  const canCurrentUserReadACL = useAuthStore((state) => state.canCurrentUserReadACL);
  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);

  const hasUserFundExternalReadAccess = canCurrentUserReadACL('DOCUMENT_MANAGEMENT_FUND_CLIENT_SERVICE');
  const hasUserFundInternalReadAccess = canCurrentUserReadACL('DOCUMENT_MANAGEMENT_FUND_INTERNAL');
  const hasUserFundExternalWriteAccess = canCurrentUserWriteACL('DOCUMENT_MANAGEMENT_FUND_CLIENT_SERVICE');
  const hasUserFundInternalWriteAccess = canCurrentUserWriteACL('DOCUMENT_MANAGEMENT_FUND_INTERNAL');
  const [activeTab, setActiveTab] = useState<string>(hasUserFundInternalReadAccess ? 'INTERNAL' : 'EXTERNAL');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTabChange = (tabId: TabId, prevTabId: TabId) => {
    setActiveTab(tabId as string);
    setSelectedFolder(undefined);
  };

  return (
    <Box>
      <PageToolbar
        leftSegment={
          <>
            <Box marginRight={2}>
              {selectedFundId && fundData?.funds && (
                <FundSelectionDropdown
                  switchFund={switchFund}
                  selectedFundId={selectedFundId}
                  allFunds={fundData?.funds}
                  disabled={false}
                ></FundSelectionDropdown>
              )}
            </Box>
          </>
        }
      ></PageToolbar>
      <Box mx={2} mt={4}>
        <Card elevation={Elevation.TWO} style={{ padding: 0 }}>
          <Navbar className="card-tabs-navbar">
            <Navbar.Group align={Alignment.LEFT}>
              <Tabs selectedTabId={activeTab} onChange={handleTabChange} renderActiveTabPanelOnly={true}>
                {hasUserFundInternalReadAccess && <Tab id="INTERNAL" title="Document Management & Sharing" />}
                {hasUserFundExternalReadAccess && <Tab id="EXTERNAL" title="Client Service Portal & Sharing" />}
              </Tabs>
            </Navbar.Group>
          </Navbar>
          <Box>
            {activeTab === 'INTERNAL' && (
              <DocumentManagementContainer
                hasWriteAccess={hasUserFundInternalWriteAccess}
                type="INTERNAL"
              ></DocumentManagementContainer>
            )}
          </Box>
          <Box>
            {activeTab === 'EXTERNAL' && (
              <DocumentManagementContainer
                hasWriteAccess={hasUserFundExternalWriteAccess}
                type="EXTERNAL"
              ></DocumentManagementContainer>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default DocumentManagement;

import { Alert, Intent } from '@blueprintjs/core';
import useUpdateUser from '@common/hooks/useUpdateUser';
import useUsers, { ADMIN_GET_USERS_KEY } from '@common/hooks/useUsers';
import { IUpdateUserRequest } from '@shared/exchange/updateUser';
import useAdministrationStore from '@stores/useAdministrationStore';
import { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';

const ConfirmRemoveUserFromOrganization = (): ReactElement => {
  const { setUserIdFromRemoveFromOrg, userIdFromRemoveFromOrg } = useAdministrationStore(
    ({ userIdFromRemoveFromOrg, setUserIdFromRemoveFromOrg }) => {
      return {
        setUserIdFromRemoveFromOrg,
        userIdFromRemoveFromOrg,
      };
    },
  );
  const { refetch: refetchUsers } = useUsers();
  const queryClient = useQueryClient();

  const { mutateAsync: removeUserFromOrg, isLoading } = useUpdateUser({ remove: true });

  const handleRemoveUser = async () => {
    if (userIdFromRemoveFromOrg === undefined) return;
    const updateData: IUpdateUserRequest = {
      userId: userIdFromRemoveFromOrg,
    };
    try {
      await queryClient.cancelQueries(ADMIN_GET_USERS_KEY);
      await removeUserFromOrg(updateData);
      setUserIdFromRemoveFromOrg(undefined);
      refetchUsers();
    } catch (e) {
      console.log('Error', e);
      setUserIdFromRemoveFromOrg(undefined);
    }
  };

  const handleCancelRemoveUser = useCallback(() => {
    setUserIdFromRemoveFromOrg(undefined);
  }, []);

  console.log('Cancel User', userIdFromRemoveFromOrg);
  return (
    <>
      <Alert
        isOpen={!!userIdFromRemoveFromOrg}
        onConfirm={() => handleRemoveUser()}
        onCancel={handleCancelRemoveUser}
        // onClose={handleCancelRemoveUser}
        icon="disable"
        intent={Intent.DANGER}
        cancelButtonText={!isLoading ? 'Cancel' : undefined}
        confirmButtonText={'Yes, remove the user.'}
        loading={isLoading}
      >
        <>
          <div>
            {!isLoading && <p>{`Are you sure you want to remove this user?`}</p>}
            {isLoading && (
              <>
                <p>{`Removing the user...`}</p>
              </>
            )}
          </div>
        </>
      </Alert>
      {/*
      {userIdFromRemoveFromOrg && (
        <SweetAlert
          title={undefined}
          showCancel={!isLoading}
          onConfirm={handleRemoveUser}
          onCancel={handleCancelRemoveUser}
          showConfirm={!isLoading}
          btnSize="sm"
          customClass="modal-box  px-0 pt-0"
          closeOnClickOutside={false}
        ></SweetAlert>
      )}
      */}
    </>
  );
};

export default ConfirmRemoveUserFromOrganization;

import { MenuItem } from '@blueprintjs/core';
import CustomDropdown from '@components/CustomDropdown/CustomDropdown';
import { FC, memo } from 'react';
import getDateEvents from './getDateEvents';

interface IDateEventPickerProps {
  setFilterDate: (date: Date | undefined) => void;
}
const ExposureDateFilter: FC<IDateEventPickerProps> = ({ setFilterDate }) => {
  return (
    <CustomDropdown
      menuItems={getDateEvents().map((event) => (
        <MenuItem key={event.event} onClick={() => setFilterDate(event.date)} text={event.displayName}></MenuItem>
      ))}
      disabled={false}
      text={'Filters'}
    ></CustomDropdown>
  );
};

export default memo(ExposureDateFilter);
/*
<div className="btn-drop ms-4">
  <div className="dropdown " style={{ zIndex: 2000 }}>
    <button className="btn dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      Filters
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      {getDateEvents().map((event) => (
        <li key={event.event}>
          <a className="dropdown-item" href="#" onClick={() => setFilterDate(event.date)}>
            {event.displayName}
          </a>
        </li>
      ))}
    </ul>
  </div>
</div>;
*/

import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateTradeOrderRequest, ICreateTradeOrderResponse } from '@shared/exchange/createTradeOrder';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useCreateTradeOrder(): UseMutationResult<
  ICreateTradeOrderResponse,
  IErrorResponse,
  ICreateTradeOrderRequest,
  ICreateTradeOrderResponse
> {
  const axios = useAxios();
  return useMutation<ICreateTradeOrderResponse, IErrorResponse, ICreateTradeOrderRequest, ICreateTradeOrderResponse>(
    async (data: ICreateTradeOrderRequest) =>
      axios
        ?.post('create-trade-order', data)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
  );
}

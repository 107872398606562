import { Colors, Tag } from '@blueprintjs/core';
import { FC, ReactNode } from 'react';
import { Flex } from 'reflexbox';
import styled from '@emotion/styled';

interface ISelectWrapperProps {
  label?: string;
  children: ReactNode;
  large?: boolean;
}

const SelectWrapper: FC<ISelectWrapperProps> = ({ label, children, large }) => (
  <Styles>
    <Flex>
      {label && (
        <Tag className="label" large={large}>
          {label}
        </Tag>
      )}
      {children}
    </Flex>
  </Styles>
);

const Styles = styled.div`
  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;
    border-color: ${Colors.GRAY5};
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;
    border-color: ${Colors.GRAY5};
  }
  .label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${Colors.LIGHT_GRAY4};
    border-color: ${Colors.GRAY5};
    border-width: 1px;
    border-style: solid;
    color: ${Colors.BLACK};
    font-size: 14px;
  }
`;

export default SelectWrapper;

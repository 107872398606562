import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateDocRequest } from '@shared/exchange/creaDoc';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

interface ICreateDocResponse {
  id: number;
}

export default function useCreateDoc(): UseMutationResult<
  ICreateDocResponse,
  IErrorResponse,
  ICreateDocRequest,
  ICreateDocResponse
> {
  const axios = useAxios();
  return useMutation<ICreateDocResponse, IErrorResponse, ICreateDocRequest, ICreateDocResponse>(
    async (data: ICreateDocRequest) =>
      axios
        ?.post('create-doc', data)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
  );
}

interface IRoundAllocationPercentageArg {
  value: number;
}
const roundtAllocationPercentage = ({ value }: IRoundAllocationPercentageArg): number => {
  if (!value || Number.isNaN(value)) return 0;
  const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  return Number(+nf.format(value));
};

export default roundtAllocationPercentage;

import { IUpdateUserRequest, IUpdateUserResponse } from '@shared/exchange/updateUser';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useUpdateUser({
  remove,
}: {
  remove: boolean;
}): UseMutationResult<IUpdateUserResponse, IErrorResponse, IUpdateUserRequest, IUpdateUserResponse> {
  const axios = useAxios();
  return useMutation<IUpdateUserResponse, IErrorResponse, IUpdateUserRequest, IUpdateUserResponse>(
    async (req: IUpdateUserRequest) => {
      let routePath = 'update-user';
      if (remove) {
        routePath = routePath + '?remove=true';
      }
      return axios?.post(routePath, req).then((res) => res.data.data);
    },
  );
}

import { Button } from '@blueprintjs/core';
import useGetDocMgmtDirectories from '@common/hooks/useGetDocMgmDirectories';
import useDocumentManagementStore from '@stores/useDocumentManagementStore';
import { ReactElement } from 'react';
import { Box } from 'reflexbox';
import FolderNode from './FolderNode';

interface IFolderTreeProps {
  type: 'INTERNAL' | 'EXTERNAL';
  hasWriteAccess: boolean;
}

function FolderTree({ type, hasWriteAccess }: IFolderTreeProps): ReactElement {
  const { selectedFundId, levelCode, setSelectedFolder } = useDocumentManagementStore(
    ({ selectedFundId, levelCode, setSelectedFolder }) => {
      return { selectedFundId, levelCode, setSelectedFolder };
    },
  );

  const {
    data: externalDirectoryData,
    //isLoading,
    //error,
  } = useGetDocMgmtDirectories(type, levelCode, undefined, selectedFundId);
  return (
    <Box>
      <Button minimal icon={'chevron-down'} onClick={() => setSelectedFolder(undefined)} rightIcon={'home'} />
      <Box pl={3}>
        {externalDirectoryData?.directories.map((folderData) => (
          <FolderNode
            hasWriteAccess={hasWriteAccess}
            type={type}
            key={folderData.id}
            depth={0}
            folderData={folderData}
          ></FolderNode>
        ))}
      </Box>
    </Box>
  );
}

export default FolderTree;

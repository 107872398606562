import { useAxios } from '@common/providers/AxiosProvider';
import IGetDocSignedDownloadUrlRequest from '@shared/exchange/getDocSignedDownloadUrl/IGetDocSignedDownloadUrlRequest';
import IGetDocSignedDownloadUrlResponse from '@shared/exchange/getDocSignedDownloadUrl/IGetDocSignedDownloadUrlResponse';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

export default function useGetDocTemplateDownloadUrl(): UseMutationResult<
  IGetDocSignedDownloadUrlResponse,
  AxiosError,
  IGetDocSignedDownloadUrlRequest,
  IGetDocSignedDownloadUrlResponse
> {
  const axios = useAxios();
  return useMutation<
    IGetDocSignedDownloadUrlResponse,
    AxiosError,
    IGetDocSignedDownloadUrlRequest,
    IGetDocSignedDownloadUrlResponse
  >(async (data: IGetDocSignedDownloadUrlRequest) =>
    axios?.post('get-doc-signed-download-url', data).then((res) => res.data),
  );
}

import React, { ReactElement } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const FormLoader = (props: IContentLoaderProps): ReactElement => {
  return (
    <ContentLoader viewBox="0 0 1300 400" height={400} width={1300} {...props}>
      <rect x="10" y="17" rx="10" ry="10" width="420" height="33" />
      <rect x="10" y="71" rx="10" ry="10" width="420" height="33" />
      <rect x="10" y="125" rx="10" ry="10" width="420" height="33" />
      <rect x="10" y="216" rx="10" ry="10" width="420" height="33" />

      <rect x="10" y="311" rx="8" ry="8" width="130" height="38" />
      <rect x="150" y="311" rx="8" ry="8" width="130" height="38" />
    </ContentLoader>
  );
};

export default FormLoader;

import { NonIdealState } from '@blueprintjs/core';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import CustomCard from '@components/CustomCard';
import IOrgInvestmentContact from '@shared/interfaces/IOrgInvestmentContact';
import { useMemo, FC, ReactElement, useCallback } from 'react';
import { Cell, Column, useExpanded, useTable } from 'react-table';
import { Box } from 'reflexbox';
import getOrgInvestmentContactsForAccountTableConfig from './getOrgInvestmentContactsForAccountTableConfig';
import OrgInvestmentContactRowPanel from './OrgInvestmentContactsForAccountRowPanel';

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: { width?: string; wordWrap: 'break-word'; whiteSpace: 'unset' } = {
    wordWrap: 'break-word',
    whiteSpace: 'unset',
  };
  switch (columnId) {
    case 'id':
      style.width = '5%';
      break;
    case 'contactName':
      style.width = '10%';
      break;
    case 'contactTypeCd':
      style.width = '10%';
      break;
    case 'contactDeliveryTypeCd':
      style.width = '10%';
      break;
    case 'emailId':
      style.width = '12%';
      break;
    case 'businessPhone':
      style.width = '10%';
      break;
    case 'company':
      style.width = '10%';
      break;
    case 'activeInd':
      style.width = '7%';
      break;
    case 'createdBy':
      style.width = '13%';
      break;
    case 'createdDt':
      style.width = '10%';
      break;
  }
  return { style };
};

const getCellProps = (cell: Cell<IOrgInvestmentContact>) => {
  const styles = getStyleOfColumns(cell.column.id);
  return { ...styles };
};

const getHeaderProps = (column: Column<IOrgInvestmentContact>) => {
  const styles = getStyleOfColumns(column.id);
  return { ...styles };
};

function InvestmentContactsTable({
  columns,
  data,
}: {
  columns: Column<IOrgInvestmentContact>[];
  data: IOrgInvestmentContact[];
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } =
    useTable<IOrgInvestmentContact>(
      {
        columns,
        data,
        filterTypes,
      },
      useExpanded,
    );

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <div>
        <OrgInvestmentContactRowPanel investmentContact={row.original}></OrgInvestmentContactRowPanel>
      </div>
    ),
    [],
  );

  return (
    <>
      <table
        className="bp3-html-table  bp3-html-table-bordered"
        {...getTableProps()}
        style={{ tableLayout: 'fixed', width: '100%' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps(getCellProps(cell))} key={row.id + '_' + j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
                {console.log('Is expanded: ' + row.isExpanded)}
              </>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <NonIdealState
          icon={'issue'}
          title={<h5 className="bp3-heading"> No Investment Contacts found for the given account.</h5>}
        />
      )}
    </>
  );
}

export interface IGetOrgInvestmentContactsForAccount {
  contacts: IOrgInvestmentContact[];
}
const OrgInvestmentContactsForAccountPage: FC<IGetOrgInvestmentContactsForAccount> = (props: {
  contacts: IOrgInvestmentContact[];
}): ReactElement => {
  const columns = useMemo(() => getOrgInvestmentContactsForAccountTableConfig(), []);
  return (
    <Box m={2} width={[8 / 8]}>
      <CustomCard
        heading={'Investment Contacts'}
        body={
          <Box>
            <InvestmentContactsTable columns={columns} data={props?.contacts} />
          </Box>
        }
      ></CustomCard>
    </Box>
  );
};

export default OrgInvestmentContactsForAccountPage;

import { useExternalAxios } from '@common/providers/AxiosExternalProvider';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

interface ISendResetPasswordRequest {
  client_id: string;
  email: string;
  connection: string;
}

interface ISendResetPasswordResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export default function useSendResetPasswordLink(): UseMutationResult<
  ISendResetPasswordResponse,
  AxiosError,
  ISendResetPasswordRequest,
  ISendResetPasswordResponse
> {
  const axios = useExternalAxios();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const restetPasswordURL = `https://${domain}/dbconnections/change_password`;
  return useMutation<ISendResetPasswordResponse, AxiosError, ISendResetPasswordRequest, ISendResetPasswordResponse>(
    async (data: ISendResetPasswordRequest) => axios?.post(restetPasswordURL, data).then((res) => res.data.data),
  );
}

import { Button, Icon, Switch } from '@blueprintjs/core';
import UserAvatar from '@components/UserAvatar';
import IOrgInvestmentContact from '@shared/interfaces/IOrgInvestmentContact';
import dayjs from 'dayjs';
import { Column, Row } from 'react-table';
import { Box, Flex } from 'reflexbox';
const getOrgInvestmentContactsForAccountTableConfig = (): Column<IOrgInvestmentContact>[] => {
  return [
    {
      Header: () => null,
      id: 'expander',
      Cell: ({ row }: { row: Row }) => (
        <Button
          intent="primary"
          minimal={true}
          outlined={true}
          small={true}
          {...row.getToggleRowExpandedProps()}
          icon={row.isExpanded ? <Icon icon="chevron-up"></Icon> : <Icon icon="chevron-down"></Icon>}
        ></Button>
      ),
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'contactName',
      Cell: ({ row }) => row.original.contactName,
    },
    {
      Header: 'Contact Type',
      accessor: 'contactTypeCd',
      Cell: ({ row }) => row.original.contactTypeCd,
    },
    {
      Header: 'Delivery Type',
      accessor: 'contactDeliveryTypeCd',
      Cell: ({ row }) => row.original.contactDeliveryTypeCd,
    },

    {
      Header: 'Email',
      accessor: 'emailId',
      Cell: ({ row }) => row.original.emailId,
    },
    {
      Header: 'Business Phone',
      accessor: 'businessPhone',
      Cell: ({ row }) => row.original.businessPhone,
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ row }) => row.original.company,
    },
    {
      Header: 'Status',
      accessor: 'activeInd',
      Cell: ({ value }) => (
        <Switch
          defaultChecked={value}
          readOnly={true}
          checked={value}
          disabled={true}
          style={{ marginBottom: 0 }}
        ></Switch>
      ),
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      Cell: ({ row }) => {
        return (
          <Flex alignItems={'center'}>
            <Box>
              <UserAvatar user={row.original.createdBy}></UserAvatar>
            </Box>
            <Box ml={2}>
              <div>{row.original.createdBy.firstName + ' ' + row.original.createdBy.lastName}</div>
            </Box>
          </Flex>
        );
      },
    },
    {
      Header: 'Created Date',
      accessor: 'createdDt',
      Cell: ({ row }) => dayjs(row.original.createdDt).format('MM/DD/YYYY'),
    },
  ];
};

export default getOrgInvestmentContactsForAccountTableConfig;

import { Alignment, Card, Navbar } from '@blueprintjs/core';
import { Box } from '@rebass/grid/emotion';
import { FC, ReactElement } from 'react';

interface ICustomCardProps {
  simple?: boolean;
  heading: string | ReactElement;
  rightToolbar?: ReactElement;
  body: ReactElement;
  innerToolbar?: ReactElement | undefined | null;
}
const CustomCard: FC<ICustomCardProps> = ({ simple, heading, rightToolbar, body, innerToolbar }): ReactElement => {
  return (
    <Card style={{ padding: 0, overflow: 'hidden' }} elevation={simple ? 0 : 2}>
      <Navbar
        style={{
          paddingLeft: 0,
          zIndex: 0,
          boxShadow: 'none',
        }}
      >
        <Navbar.Group align={Alignment.LEFT}>
          <span
            style={{
              display: 'inline-block',
              marginRight: '0.5rem',
              minWidth: '6px',
              height: '70%',
              backgroundImage: 'linear-gradient(to top, #4b85cd 0%, #4b85cd 100%)',
              borderTopRightRadius: 1000,
              borderBottomRightRadius: 1000,
            }}
          ></span>
          <Navbar.Heading>{heading}</Navbar.Heading>
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>{rightToolbar}</Navbar.Group>
      </Navbar>
      {innerToolbar && (
        <Navbar>
          <Navbar.Group align={Alignment.LEFT}>{innerToolbar}</Navbar.Group>
        </Navbar>
      )}
      <Box>{body}</Box>
    </Card>
  );
};

export default CustomCard;

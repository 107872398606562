import { FC } from 'react';
import dayjs from 'dayjs';
import { DateInput } from '@blueprintjs/datetime';
import { Box, Flex } from 'reflexbox';
import { Colors, Tag } from '@blueprintjs/core';
import styled from '@emotion/styled';

interface ICustomDatePickerDatePickerProps {
  isLoading: boolean;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  startDate: Date | undefined;
  endDate: Date | undefined;
  label?: string;
}

const CustomDateRangePicker: FC<ICustomDatePickerDatePickerProps> = ({
  isLoading,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  label,
}) => {
  return (
    <Styles>
      <Flex>
        <Tag className="label">{label ? label : 'Date Range'}</Tag>
        <Box width={'6rem'}>
          <DateInput
            className="fromDateInput"
            formatDate={(date) => dayjs(date).format('MM/DD/YYYY')}
            parseDate={(str) => new Date(str)}
            placeholder={'MM/DD/YYYY'}
            disabled={isLoading}
            value={dayjs(startDate).toDate()}
            onChange={(date: Date, isUserChange: boolean) => {
              if (isUserChange) {
                const d = dayjs(date).toDate();
                setStartDate(d);
              }
            }}
            maxDate={dayjs().add(1, 'year').toDate()}
          ></DateInput>
        </Box>
        <Box width={'6rem'}>
          <DateInput
            className="toDateInput"
            formatDate={(date) => dayjs(date).format('MM/DD/YYYY')}
            parseDate={(str) => new Date(str)}
            placeholder={'MM/DD/YYYY'}
            disabled={isLoading}
            value={dayjs(endDate).toDate()}
            onChange={(date: Date, isUserChange: boolean) => {
              if (isUserChange) {
                const d = dayjs(date).toDate();
                setEndDate(d);
              }
            }}
            maxDate={dayjs().add(1, 'year').toDate()}
          ></DateInput>
        </Box>

        {/*
          <DatePicker
            disabled={isLoading}
            selected={dayjs(startDate).toDate()}
            onChange={(date: Date) => {
              const d = dayjs(date).toDate();
              console.log(d);
              setStartDate(d);
            }}
            customInput={<DateFilterComponent />}
          />
          */}
      </Flex>
    </Styles>
  );
};

const Styles = styled.div`
  .label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${Colors.LIGHT_GRAY4};
    border-color: ${Colors.GRAY5};
    border-width: 1px;
    border-style: solid;
    color: ${Colors.BLACK};
    font-size: 14px;
  }
  .fromDateInput .bp3-input {
    border-radius: 0 !important;
    border-left: none !important;
  }
  .toDateInput .bp3-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
  }
  .fromDateInput .bp3-input:focus,
  .toDateInput .bp3-input:focus {
    z-index: 1000;
  }
`;
export default CustomDateRangePicker;

import IFund from '@shared/interfaces/IFund';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface IExposureStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;

  allFunds: IFund[] | undefined;
  setAllFunds: (allFunds: IFund[]) => void;

  filterDate: string | undefined;
  setFilterDate: (date: string | undefined) => void;

  switchFund: (fundId: number) => void;
  resetExposureStore: () => void;
}

const useExposureStore = create<IExposureStore>(
  persist<IExposureStore>(
    (set: SetState<IExposureStore>) => ({
      selectedFundId: undefined,
      allFunds: undefined,
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },
      setAllFunds: (allFunds: IFund[]) => {
        set({ allFunds: allFunds });
      },
      resetExposureStore: (): void => {
        set({ selectedFundId: undefined, allFunds: undefined, filterDate: undefined });
      },
      filterDate: undefined,
      setFilterDate: (date: string | undefined) => {
        set({ filterDate: date });
      },
      switchFund: (fundId: number): void => {
        console.log('FUNDIS', fundId);
        set({ selectedFundId: fundId, filterDate: undefined });
      },
    }),
    { name: 'exposure-cache', blacklist: ['filterDate'] },
  ),
);

export default useExposureStore;

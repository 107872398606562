import { Alignment, Card, Elevation, Navbar, Tab, TabId, Tabs } from '@blueprintjs/core';
import { ReactElement, useState } from 'react';
import { Box } from 'reflexbox';
import OpsDashboard from './components/OpsDashboard/OpsDashboard';
import OpsFeedsAndIntegrations from './components/OpsFeedsAndIntegrations';
import OpsJobsLogs from './components/OpsJobLogs';
import OpsMarketData from './components/OpsMarketData';

const OpsManagement = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<string>('OpsDashboard');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTabChange = (tabId: TabId, prevTabId: TabId) => {
    setActiveTab(tabId as string);
  };
  return (
    <Box mx={2} mt={2}>
      <Card elevation={Elevation.TWO} style={{ padding: 0 }}>
        <Navbar className="card-tabs-navbar">
          <Navbar.Group align={Alignment.LEFT}>
            <Tabs selectedTabId={activeTab} onChange={handleTabChange} renderActiveTabPanelOnly={true}>
              <Tab id="OpsDashboard" title="Dashboard" />
              <Tab id="OpsFeedsAndIntegrations" title="Feeds and Integrations" />
              <Tab id="OpsJobLogs" title="Job Logs" />
              <Tab id="OpsMarketData" title="Market Data" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <Box>{activeTab === 'OpsDashboard' && <OpsDashboard></OpsDashboard>}</Box>
        <Box>{activeTab === 'OpsFeedsAndIntegrations' && <OpsFeedsAndIntegrations></OpsFeedsAndIntegrations>}</Box>
        <Box>{activeTab === 'OpsMarketData' && <OpsMarketData></OpsMarketData>}</Box>
        <Box>{activeTab === 'OpsJobLogs' && <OpsJobsLogs></OpsJobsLogs>}</Box>
      </Card>
    </Box>
  );
};

export default OpsManagement;

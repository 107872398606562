import { IGetExposureResponse } from '@shared/exchange/getExposure';
import IExposure from '@shared/interfaces/IExposure';
import { FC, memo, ReactElement, useEffect, useState } from 'react';
import { Cell, Column, Row, useExpanded, useTable } from 'react-table';
import 'simplebar/dist/simplebar.min.css';
import {
  Alignment,
  Button,
  Callout,
  Intent,
  Position,
  Switch,
  ButtonGroup,
  Colors,
  Tag,
  Navbar,
} from '@blueprintjs/core';
import { Box, Flex } from 'reflexbox';
import CustomCard from '@components/CustomCard';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { Tooltip2 } from '@blueprintjs/popover2';
import formatAssetValue from '@common/utils/formatAssetValue';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import roundtAllocationPercentage from '@common/utils/roudAllocationPercentage';

interface IExposureDataFormPanelProps {
  columns: Column<IExposure>[];
  data: IGetExposureResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rebalResults: any;
  resetRebalResults: () => void;
}

const getRowProps = (row: Row<IExposure>) => {
  let className = '';

  if (row.depth === 2) {
    className += ` list-unstyled sub-child last-child ${row.isExpanded ? '' : ''}`;
  } else if (row.depth === 1 && !row.original.isAccount) {
    className += ` list-unstyled child whatifrebal-tr ${row.isExpanded ? 'active' : ''}`;
  } else if (row.depth === 1 && row.original.isAccount) {
    className += ` list-unstyled child last-child`;
  } else if (row.depth === 0) {
    className += ` list-unstyled whatifrebal-tr ${row.isExpanded ? 'active' : ''}`;
  }

  const { current_allocation_pct, target_allocation_range_lower_bound, target_allocation_range_upper_bound } =
    row.original;

  if (target_allocation_range_lower_bound === null && target_allocation_range_upper_bound === null) {
    className += ' bg-white';
  } else if (
    current_allocation_pct < Number(target_allocation_range_lower_bound) ||
    current_allocation_pct > Number(target_allocation_range_upper_bound)
  ) {
    className += ' bg-red-light';
  } else {
    className += ' bg-white';
  }
  return { className };
};

const getClassNameOfColumns = (columnId: string | undefined) => {
  if (!columnId) return {};
  let className = '';
  if (['benchmark_name', 'name', 'data_source_code'].includes(columnId)) {
    className += ' text-start';
  }
  return { className };
};

const getCellProps = (cell: Cell<IExposure>) => {
  const borderRight = ['current_allocation_pct', 'target_allocation_range_upper_bound'].includes(cell.column.id)
    ? '1px solid #e3e3e3'
    : '';
  const borderLeft = ['target_allocation_range_lower_bound'].includes(cell.column.id) ? '1px solid #e3e3e3' : '';
  const style = {
    width: cell.column.width,
    borderRight,
    borderLeft,
    background: !cell.row.original.isAccount ? Colors.LIGHT_GRAY5 : 'transparent',
    fontWeight: !cell.row.original.isAccount ? 600 : 400,
  };
  const classNames = getClassNameOfColumns(cell.column.id);
  return { style, ...classNames };
};

const getHeaderProps = (column: Column<IExposure>) => {
  const style = { width: column.width, border: '1px solid #e3e3e3' };
  const classNames = getClassNameOfColumns(column.id);
  return { style, ...classNames };
};

const ExposureDataFormPanel: FC<IExposureDataFormPanelProps> = ({
  columns,
  data,
  rebalResults,
  resetRebalResults,
}): ReactElement => {
  const [allExpanded, setAllExpanded] = useState<boolean>(true);
  const { getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded, isAllRowsExpanded } =
    useTable<IExposure>(
      {
        columns,
        data: data?.exposureData?.allocations || [],
        expandSubRows: false,
      },
      useExpanded,
    );

  useEffect(() => {
    if (allExpanded && !isAllRowsExpanded) toggleAllRowsExpanded();
    else if (!allExpanded && isAllRowsExpanded) toggleAllRowsExpanded();
  }, [allExpanded]);

  const { lockedAccountsMap, setAccountLocked, setAllAccountsLocked } = useWhatIfRebalStore(
    ({ lockedAccountsMap, setAccountLocked, setAllAccountsLocked }) => {
      return { lockedAccountsMap, setAccountLocked, setAllAccountsLocked };
    },
  );

  const handleLockUnlock = (accountId: number | undefined, lock: boolean) => {
    if (!accountId) return;
    resetRebalResults();
    setAccountLocked(accountId, lock);
    resetTotalInfo();
  };

  const handleLockOrUnlockAll = (lock: boolean) => {
    resetRebalResults();
    setAllAccountsLocked(lock);
    resetTotalInfo();
  };

  const [totalProposedPurchase, setTotalProposedPurchase] = useState<number | undefined>(undefined);
  const [totalProposedPurchasePct, setTotalProposedPurchasePct] = useState<number | undefined>(undefined);
  const [totalProposedSell, setTotalProposedSell] = useState<number | undefined>(undefined);
  const [totalProposedSellPct, setTotalProposedSellPct] = useState<number | undefined>(undefined);
  const [totalProposedAllocationPct, setTotalProposedAllocationPct] = useState<number | undefined>(undefined);
  const [totalProposedAllocationAmount, setTotalProposedAllocationAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!rebalResults) {
      resetTotalInfo();
      return;
    }
    if (!rebalResults['rebal_df']) {
      resetTotalInfo();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const totalInfoRecord = rebalResults['rebal_df'].find((row: any) => row['asset_name'] === 'Total');
    setTotalProposedPurchase(totalInfoRecord['buy']);
    setTotalProposedPurchasePct(totalInfoRecord['p'] * 100);
    setTotalProposedSell(totalInfoRecord['sell']);
    setTotalProposedSellPct(totalInfoRecord['s'] * 100);
    setTotalProposedAllocationPct(totalInfoRecord['w_new']);
    setTotalProposedAllocationAmount(totalInfoRecord['alloc_amt_new']);
  }, [rebalResults]);

  const resetTotalInfo = () => {
    setTotalProposedPurchase(undefined);
    setTotalProposedPurchasePct(undefined);
    setTotalProposedSell(undefined);
    setTotalProposedSellPct(undefined);
    setTotalProposedAllocationPct(undefined);
    setTotalProposedAllocationAmount(undefined);
  };

  const getRebalResultColValues = (row: Row<IExposure>) => {
    console.log(row);
    const emptyCol = row.original.isAccount ? (
      <li style={{ width: '8%', borderLeft: '1px solid #e3e3e3' }}></li>
    ) : (
      <li
        style={{ width: '42%', borderLeft: '1px solid #e3e3e3', background: Colors.LIGHT_GRAY5, fontWeight: 600 }}
      ></li>
    );
    if (!rebalResults) return emptyCol;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    if (!row.original.isAccount) {
      const styles = {
        backgroundColor: Colors.LIGHT_GRAY5,
        fontWeight: 600,
      };
      if (!rebalResults['asset_class']) return emptyCol;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const assetClassResultRow = rebalResults['asset_class'].find((assetClassRecord: any) => {
        return assetClassRecord['asset_class_id'] === row.original.fund_asset_class_id;
      });

      console.log(row.original.name, assetClassResultRow);

      if (!assetClassResultRow) {
        let totalBuyAC = 0,
          totalSellAC = 0,
          totalSellACPct = 0,
          totalBuyACPct = 0,
          totalAllocNew = 0,
          totalAllocAmountNew = 0;
        for (const subRow of row.original.subRows) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const subAssetClassRebalResRow = rebalResults['asset_class'].find((assetClassRecord: any) => {
            console.log(subRow['fund_asset_class_id'], assetClassRecord['asset_class_id']);
            return subRow['fund_asset_class_id'] === assetClassRecord['asset_class_id'];
          });

          if (!subAssetClassRebalResRow) continue;
          totalBuyAC += Number(subAssetClassRebalResRow['buy_ac']);
          totalSellAC += Number(subAssetClassRebalResRow['sell_ac']);
          totalSellACPct += Number(subAssetClassRebalResRow['s_ac']);
          totalBuyACPct += Number(subAssetClassRebalResRow['p_ac']);
          totalAllocNew += Number(subAssetClassRebalResRow['w_ac_new']);
          totalAllocAmountNew += Number(subAssetClassRebalResRow['alloc_ac_amt_new']);
        }
        return (
          <>
            <li style={{ width: '7%', borderLeft: '1px solid #e3e3e3', ...styles }}>
              {totalBuyAC != 0 && (
                <Box textAlign="right">
                  <Tooltip2 position={Position.BOTTOM} content={<>{totalBuyAC}</>}>
                    {formatAssetValue({ value: totalBuyAC })}
                  </Tooltip2>
                </Box>
              )}
            </li>

            <li style={{ width: '7%', ...styles }}>
              {totalBuyACPct != 0 && (
                <Box textAlign="right">
                  <Tooltip2 position={Position.BOTTOM} content={<>{totalBuyACPct * 100}</>}>
                    {formatAllocationPercentage({ value: totalBuyACPct * 100 })}
                  </Tooltip2>
                </Box>
              )}
            </li>

            <li style={{ width: '7%', ...styles }}>
              {totalSellAC != 0 && (
                <Box textAlign="right">
                  <Tooltip2 position={Position.BOTTOM} content={<>{totalSellAC}</>}>
                    {formatAssetValue({ value: totalSellAC })}
                  </Tooltip2>
                </Box>
              )}
            </li>

            <li style={{ width: '7%', ...styles }}>
              {totalSellACPct != 0 && (
                <Box textAlign="right">
                  <Tooltip2 position={Position.BOTTOM} content={<>{totalSellACPct * 100}</>}>
                    {formatAllocationPercentage({ value: totalSellACPct * 100 })}
                  </Tooltip2>
                </Box>
              )}
            </li>
            <li style={{ width: '7%', ...styles }}>
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{totalAllocAmountNew}</>}>
                  {formatAssetValue({ value: totalAllocAmountNew })}
                </Tooltip2>
              </Box>
            </li>
            <li style={{ width: '7%', ...styles }}>
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{totalAllocNew}</>}>
                  {formatAllocationPercentage({ value: totalAllocNew })}
                </Tooltip2>
              </Box>
            </li>
          </>
        );
      }
      return (
        <>
          <li style={{ width: '7%', borderLeft: '1px solid #e3e3e3', ...styles }}>
            {assetClassResultRow['buy_ac'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['buy_ac'])}</>}>
                  {formatAssetValue({ value: Number(assetClassResultRow['buy_ac']) })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {assetClassResultRow['p_ac'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['p_ac']) * 100}</>}>
                  {formatAllocationPercentage({ value: Number(assetClassResultRow['p_ac']) * 100 })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {assetClassResultRow['sell_ac'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['sell_ac'])}</>}>
                  {formatAssetValue({ value: Number(assetClassResultRow['sell_ac']) })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {assetClassResultRow['s_ac'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['s_ac']) * 100}</>}>
                  {formatAllocationPercentage({ value: Number(assetClassResultRow['s_ac']) * 100 })}
                </Tooltip2>
              </Box>
            )}
          </li>
          <li style={{ width: '7%', ...styles }}>
            <Box textAlign="right">
              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['alloc_ac_amt_new'])}</>}>
                {formatAssetValue({ value: Number(assetClassResultRow['alloc_ac_amt_new']) })}
              </Tooltip2>
            </Box>
          </li>
          <li style={{ width: '7%', ...styles }}>
            <Box textAlign="right">
              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(assetClassResultRow['w_ac_new'])}</>}>
                {formatAllocationPercentage({ value: Number(assetClassResultRow['w_ac_new']) })}
              </Tooltip2>
            </Box>
          </li>
        </>
      );
    } else if (row.original.isAccount) {
      const styles = {
        backgroundColor: 'white',
      };
      if (!rebalResults['rebal_df']) return emptyCol;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rebalResultOfRow = rebalResults['rebal_df'].find((rebalRecord: any) => {
        return rebalRecord['asset_id'] === row.original.account_id;
      });
      if (!rebalResultOfRow) return emptyCol;
      return (
        <>
          <li style={{ width: '7%', borderLeft: '1px solid #e3e3e3', ...styles }}>
            {rebalResultOfRow['buy'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['buy'])}</>}>
                  {formatAssetValue({ value: Number(rebalResultOfRow['buy']) })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {rebalResultOfRow['p'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['p']) * 100}</>}>
                  {formatAllocationPercentage({ value: Number(rebalResultOfRow['p']) * 100 })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {rebalResultOfRow['sell'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['sell'])}</>}>
                  {formatAssetValue({ value: Number(rebalResultOfRow['sell']) })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            {rebalResultOfRow['s'] != 0 && (
              <Box textAlign="right">
                <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['s']) * 100}</>}>
                  {formatAllocationPercentage({ value: Number(rebalResultOfRow['s']) * 100 })}
                </Tooltip2>
              </Box>
            )}
          </li>

          <li style={{ width: '7%', ...styles }}>
            <Box textAlign="right">
              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['alloc_amt_new'])}</>}>
                {formatAssetValue({ value: Number(rebalResultOfRow['alloc_amt_new']) })}
              </Tooltip2>
            </Box>
          </li>

          <li style={{ width: '7%', ...styles }}>
            <Box textAlign="right">
              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(rebalResultOfRow['w_new'])}</>}>
                {formatAllocationPercentage({ value: Number(rebalResultOfRow['w_new']) })}
              </Tooltip2>
            </Box>
          </li>
        </>
      );
    }
  };

  const renderRowsRecursively = (rows: Row<IExposure>[]) => {
    if (!rows) return;
    return rows.map((row) => {
      prepareRow(row);
      return (
        <ul {...row.getRowProps(getRowProps(row))} key={row.id}>
          {row.cells.map((cell) => {
            return (
              <li {...cell.getCellProps(getCellProps(cell))} key={cell.row.id + ' ' + cell.column.id}>
                {cell.render('Cell')}
                {cell.column.id === 'name' && row.original.isAccount && (
                  <Box pl={1}>
                    {lockedAccountsMap && lockedAccountsMap[row.original.account_id || ''] && (
                      <Button
                        onClick={() => handleLockUnlock(row.original.account_id, false)}
                        minimal
                        icon="lock"
                        small
                        outlined
                        intent="danger"
                      ></Button>
                    )}
                    {lockedAccountsMap && !lockedAccountsMap[row.original.account_id || ''] && (
                      <Button
                        onClick={() => handleLockUnlock(row.original.account_id, true)}
                        minimal
                        icon="unlock"
                        small
                        outlined
                        intent="primary"
                      ></Button>
                    )}
                  </Box>
                )}
              </li>
            );
          })}
          {getRebalResultColValues(row)}
          {row.subRows && row.isExpanded && renderRowsRecursively(row.subRows)}
        </ul>
      );
    });
  };

  return (
    <>
      <Box paddingBottom={30}>
        <CustomCard
          heading={
            <ButtonGroup>
              <Button onClick={() => handleLockOrUnlockAll(true)} icon="lock" intent={Intent.DANGER} minimal outlined>
                Lock All
              </Button>
              <Button
                onClick={() => handleLockOrUnlockAll(false)}
                icon="unlock"
                intent={Intent.PRIMARY}
                minimal
                outlined
              >
                Unlock All
              </Button>
            </ButtonGroup>
          }
          rightToolbar={
            <Flex paddingX={3} paddingY={3} alignItems="center">
              <Switch
                large={true}
                checked={isAllRowsExpanded}
                label=" Expand/Collapse all"
                onChange={(e) => setAllExpanded(e.currentTarget.checked)}
                alignIndicator={Alignment.RIGHT}
                style={{ marginBottom: 0 }}
              />
            </Flex>
          }
          body={
            <div className="exposure-table">
              <div>
                {headerGroups.map((headerGroup, i) => (
                  <ul
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                    className="list-unstyled whatifrebal-table-head"
                    style={{
                      marginTop: 0,
                    }}
                  >
                    {headerGroup.headers.map((column) => (
                      <li {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                        <Flex flexDirection="column" height="100%" justifyContent="space-between">
                          <Box>{column.render('Header')}</Box>
                          {i == 1 && (
                            <Box pt={2} color={Colors.BLUE2}>
                              {column.id === 'asset_class_nav' && (
                                <Tooltip2
                                  position={Position.BOTTOM}
                                  content={<>{+Number(data.exposureData.totalAssetClassNav)}</>}
                                >
                                  {formatAssetValue({ value: Number(data.exposureData.totalAssetClassNav) })}
                                </Tooltip2>
                              )}

                              {column.id === 'current_allocation_pct' && (
                                <>
                                  {roundtAllocationPercentage({
                                    value: data.exposureData.totalCurrentAllocation,
                                  })}{' '}
                                  %
                                </>
                              )}

                              {column.id === 'target_allocation' && (
                                <>
                                  {roundtAllocationPercentage({
                                    value: data.exposureData.totalTargetAllocation,
                                  })}{' '}
                                  %
                                </>
                              )}
                            </Box>
                          )}
                        </Flex>
                      </li>
                    ))}
                    {i == 0 && (
                      <li style={{ width: '42%', border: '1px solid #e3e3e3' }}>
                        <Box textAlign="center">Proposed</Box>
                      </li>
                    )}
                    {i == 1 && (
                      <>
                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>Purchase</Box>
                            <Box color={Colors.BLUE3}>
                              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(totalProposedPurchase)}</>}>
                                {totalProposedPurchase && <>{formatAssetValue({ value: totalProposedPurchase })}</>}
                              </Tooltip2>
                            </Box>
                          </Flex>
                        </li>

                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>%Purchase</Box>
                            <Box color={Colors.BLUE3}>
                              {totalProposedPurchasePct && (
                                <>{formatAllocationPercentage({ value: totalProposedPurchasePct })}</>
                              )}
                            </Box>
                          </Flex>
                        </li>

                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>Sell</Box>
                            <Box color={Colors.BLUE3}>
                              <Tooltip2 position={Position.BOTTOM} content={<>{+Number(totalProposedSell)}</>}>
                                {totalProposedSell && <>{formatAssetValue({ value: totalProposedSell })}</>}
                              </Tooltip2>
                            </Box>
                          </Flex>
                        </li>
                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>%Sell</Box>
                            <Box color={Colors.BLUE3}>
                              {totalProposedSellPct && (
                                <>{formatAllocationPercentage({ value: totalProposedSellPct })}</>
                              )}
                            </Box>
                          </Flex>
                        </li>

                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>Proposed Amount</Box>
                            <Box color={Colors.BLUE3}>
                              {totalProposedAllocationAmount && (
                                <>{formatAssetValue({ value: totalProposedAllocationAmount })} </>
                              )}
                            </Box>
                          </Flex>
                        </li>

                        <li style={{ width: '7%', border: '1px solid #e3e3e3' }}>
                          <Flex flexDirection="column" height="100%" justifyContent="space-between">
                            <Box>Proposed Allocation</Box>
                            <Box color={Colors.BLUE3}>
                              {totalProposedAllocationPct && (
                                <>{roundtAllocationPercentage({ value: totalProposedAllocationPct })} % </>
                              )}
                            </Box>
                          </Flex>
                        </li>
                      </>
                    )}
                  </ul>
                ))}
              </div>

              <div {...getTableBodyProps()} className="whatifrebal-table-body">
                <>{renderRowsRecursively(rows)}</>
                {rows && rows.length == 0 && (
                  <Callout intent="danger">No data found for the selected fund and date.</Callout>
                )}
              </div>
            </div>
          }
        ></CustomCard>
      </Box>

      {rebalResults && rebalResults['slider_df'] && rebalResults['slider_df'][0] && (
        <Box>
          <Navbar
            style={{
              height: 30,
              bottom: 50,
              position: 'fixed',
              right: '0rem',
              paddingLeft: '4.3rem',
              zIndex: 20,
              backgroundColor: Colors.LIGHT_GRAY5,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" pt={1}>
              <Flex fontStyle="normal">
                <Box>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Number of Purchases:{' '}
                    <Box display="inline" fontWeight="bold">
                      {rebalResults['slider_df'][0]['num_p']}{' '}
                    </Box>
                  </Tag>
                </Box>

                <Box ml={2}>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Number of Sales:{' '}
                    <Box display="inline" fontWeight="bold">
                      {rebalResults['slider_df'][0]['num_s']}{' '}
                    </Box>
                  </Tag>
                </Box>

                <Box ml={2}>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Total Initial Asset Deviation:{' '}
                    <Box display="inline" fontWeight="bold">
                      {formatAllocationPercentage({ value: rebalResults['slider_df'][0]['d_0'] * 100 })}{' '}
                    </Box>
                  </Tag>
                </Box>

                <Box ml={2}>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Total Proposed Asset Deviation:{' '}
                    <Box display="inline" fontWeight="bold">
                      {formatAllocationPercentage({ value: rebalResults['slider_df'][0]['sum_d'] * 100 })}{' '}
                    </Box>
                  </Tag>
                </Box>

                <Box ml={2}>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Total Initial Asset Class Deviation:{' '}
                    <Box display="inline" fontWeight="bold">
                      {formatAllocationPercentage({ value: rebalResults['slider_df'][0]['sum_d_ac_init'] * 100 })}{' '}
                    </Box>
                  </Tag>
                </Box>

                <Box ml={2}>
                  <Tag round style={{ background: Colors.LIGHT_GRAY2, color: Colors.DARK_GRAY5, fontSize: 14 }}>
                    Total Proposed Asset Class Deviation:{' '}
                    <Box display="inline" fontWeight="bold">
                      {formatAllocationPercentage({ value: rebalResults['slider_df'][0]['sum_d_ac'] * 100 })}{' '}
                    </Box>
                  </Tag>
                </Box>
              </Flex>
            </Flex>
          </Navbar>
        </Box>
      )}
    </>
  );
};

export default memo(ExposureDataFormPanel);

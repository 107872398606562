import { Alignment, Button, Divider, Navbar } from '@blueprintjs/core';
//import useAuthStore from '@stores/useAuthStore';
import { FC, ReactElement, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import DocumentsMainPanel from '../DocumentsMainPanel';
import FolderTree from '../FolderTree';
import SearchFilesDialogPanel from '../SearchFilesDialogPanel';

interface IDocumentManagementContainerProps {
  type: 'INTERNAL' | 'EXTERNAL';
  hasWriteAccess: boolean;
}
const DocumentManagementContainer: FC<IDocumentManagementContainerProps> = ({ type, hasWriteAccess }): ReactElement => {
  const [searchFilesModalOpen, setSearchFilesModalOpen] = useState<boolean>(false);

  return (
    <>
      <Box>
        <Navbar>
          <Navbar.Group align={Alignment.LEFT}>
            <Button intent="primary" minimal outlined icon="search" onClick={() => setSearchFilesModalOpen(true)}>
              Search All Files
            </Button>
          </Navbar.Group>
        </Navbar>
        <Flex>
          <Box width={[1 / 5]} p={1}>
            <FolderTree hasWriteAccess={hasWriteAccess} type={type}></FolderTree>
          </Box>
          <Divider style={{ margin: 0 }}></Divider>
          <Box width={[4 / 5]}>
            <DocumentsMainPanel type={type} hasWriteAccess={hasWriteAccess}></DocumentsMainPanel>
          </Box>
        </Flex>
      </Box>
      <SearchFilesDialogPanel
        modalOpen={searchFilesModalOpen}
        setModalOpen={setSearchFilesModalOpen}
        type={type}
        hasWriteAccess={hasWriteAccess}
      ></SearchFilesDialogPanel>
    </>
  );
};

export default DocumentManagementContainer;

import dayjs from 'dayjs';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
interface IDateStore {
  timezone: string;
}

const useDateStore = create<IDateStore>(
  persist<IDateStore>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (set: SetState<IDateStore>) => ({
      timezone: dayjs.tz.guess(),
    }),
    {
      name: 'date-store-cache',
      blacklist: ['timezone'],
    },
  ),
);

export default useDateStore;

import { Colors, HTMLTable } from '@blueprintjs/core';
import UserAvatar from '@components/UserAvatar';
import ICashTransaction from '@shared/interfaces/ICashTransaction';
import dayjs from 'dayjs';
import { FC, ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import styled from '@emotion/styled';

interface ICashTransactionRowPanel {
  cashTransaction: ICashTransaction;
}
const CashTransactionRowPanel: FC<ICashTransactionRowPanel> = ({ cashTransaction }): ReactElement => {
  return (
    <Styles>
      <HTMLTable className="table" style={{ borderWidth: 1, borderStyle: 'solid', borderColor: Colors.LIGHT_GRAY1 }}>
        <tbody>
          <tr>
            <td className="keyName">Created By</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  <UserAvatar user={cashTransaction.createdBy}></UserAvatar>
                </Box>
                <Box display="inline-block" paddingLeft="0.5rem">
                  <div>{cashTransaction.createdBy.firstName + ' ' + cashTransaction.createdBy.lastName}</div>
                  <small>{cashTransaction.createdBy.userEmailId}</small>
                </Box>
              </Flex>
            </td>
          </tr>

          <tr>
            <td className="keyName">Created Date</td>
            <td>{dayjs(cashTransaction.createdDt).format('MM/DD/YYYY')}</td>
          </tr>

          {cashTransaction.canceledBy && (
            <>
              <tr>
                <td className="keyName">Canceled By</td>
                <td>
                  <Flex>
                    <Box display="inline-flex" alignItems="center">
                      <UserAvatar user={cashTransaction.canceledBy}></UserAvatar>
                    </Box>
                    <Box display="inline-block" paddingLeft="0.5rem">
                      <div>{cashTransaction.canceledBy.firstName + ' ' + cashTransaction.canceledBy.lastName}</div>
                      <small>{cashTransaction.canceledBy.userEmailId}</small>
                    </Box>
                  </Flex>
                </td>
              </tr>
              <tr>
                <td className="keyName">Canceled Date</td>
                <td>
                  <p>{dayjs(cashTransaction.canceledDt).format('MM/DD/YYYY')}</p>
                </td>
              </tr>
              <tr>
                <td className="keyName">Cancel Reason</td>
                <td>{cashTransaction.canceledReasonText || ''}</td>
              </tr>
            </>
          )}
        </tbody>
      </HTMLTable>
    </Styles>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY4} !important;
  }
`;
export default CashTransactionRowPanel;

import { createContext, ReactElement, useContext, useMemo } from 'react';
import Axios, { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import useAuthStore from '@stores/useAuthStore';

export const AxiosContext = createContext<AxiosInstance | undefined>(undefined);

export default function AxiosProvider({ children }: React.PropsWithChildren<unknown>): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  const { currentUserOrgRole } = useAuthStore();
  const history = useHistory();
  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
    });
    axios.interceptors.request.use(async (config) => {
      let token: string;
      if (currentUserOrgRole?.org?.id) {
        token = await getAccessTokenSilently({ orgId: currentUserOrgRole.org.id });
      } else {
        token = await getAccessTokenSilently();
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        history.push('/');
      }
      return config;
    });

    return axios;
  }, [currentUserOrgRole]);

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
}

export function useAxios(): AxiosInstance | undefined {
  return useContext(AxiosContext);
}

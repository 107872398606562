import { Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import formatAllocationPercentageWithBoundsCheck from '@common/utils/formatAllocationPercentageWithBoundsCheck';
import formatAssetValue from '@common/utils/formatAssetValue';
import IExposure from '@shared/interfaces/IExposure';
import { ReactElement } from 'react';
import { Column, Row } from 'react-table';
import { Flex, Box } from 'reflexbox';

const ExposureDataFormConfig: Column<IExposure>[] = [
  {
    Header: '',
    width: '18%',
    id: 'name-header-group',
    columns: [
      {
        id: 'name',
        accessor: 'name',
        Header: '',
        width: '18%',
        Cell: ({ row, value }: { row: Row<IExposure>; value: string }) => {
          let renderValue: ReactElement = <></>;
          if (row.depth === 0) {
            if (row.original.policy_id === null || row.original.policy_id === undefined)
              renderValue = (
                <small className="text-danger">
                  <i className="bi bi-exclamation-triangle-fill"></i>
                  {'  '}Not present in Fund Policy{' '}
                </small>
              );
          }
          if (row.original.isPolicyWithoutAccounts && Number(row.original.target_allocation_range_lower_bound) > 0)
            renderValue = (
              <small className="text-danger">
                <i className="bi bi-exclamation-triangle-fill"></i>
                {'  '}No Accounts present for this Policy{' '}
              </small>
            );
          renderValue = (
            <Flex flexDirection="column" textAlign="left" width="100%">
              <Box>
                <Flex justifyContent="space-between" width="100%">
                  <Box>{value}</Box>
                </Flex>
              </Box>
              <Box paddingLeft={2}>{renderValue}</Box>
            </Flex>
          );

          if (row.isExpanded) {
            return (
              <>
                <span
                  {...row.getToggleRowExpandedProps({
                    className: `expandArrow active`,
                  })}
                ></span>

                {renderValue}
              </>
            );
          }

          return (
            <>
              <span
                {...row.getToggleRowExpandedProps({
                  className: `expandArrow`,
                  style: {},
                })}
              ></span>

              {renderValue}
            </>
          );
        },
      },
    ],
  },
  {
    id: 'current',
    width: '18%',
    Header: () => <Box textAlign="center">Current</Box>,
    columns: [
      {
        Header: 'Balance',
        accessor: 'asset_class_nav',
        width: '10%',
        Cell: ({ value }) => (
          <Tooltip2 position={Position.BOTTOM} content={<>{+Number(value)}</>}>
            {formatAssetValue({ value: Number(value) })}
          </Tooltip2>
        ),
      },
      {
        Header: 'Current Allocation',
        accessor: 'current_allocation_pct',
        width: '8%',
        Cell: formatAllocationPercentage,
      },
    ],
  },
  {
    id: 'target',
    width: '22%',
    Header: () => <Box textAlign="center">Target</Box>,
    columns: [
      {
        Header: 'Lower Bound',
        accessor: 'target_allocation_range_lower_bound',
        width: '7%',
        Cell: ({ row, value }) =>
          formatAllocationPercentageWithBoundsCheck(
            row.original.target_allocation_range_lower_bound,
            row.original.target_allocation_range_upper_bound,
            row.original.target_allocation,
            value,
          ),
      },
      {
        Header: 'Target Allocation',
        accessor: 'target_allocation',
        width: '8%',
        Cell: ({ row, value }) =>
          formatAllocationPercentageWithBoundsCheck(
            row.original.target_allocation_range_lower_bound,
            row.original.target_allocation_range_upper_bound,
            row.original.target_allocation,
            value,
          ),
      },
      {
        Header: 'Upper Bound',
        accessor: 'target_allocation_range_upper_bound',
        width: '7%',
        Cell: ({ row, value }) =>
          formatAllocationPercentageWithBoundsCheck(
            row.original.target_allocation_range_lower_bound,
            row.original.target_allocation_range_upper_bound,
            row.original.target_allocation,
            value,
          ),
      },
    ],
  },
];

const getExposureDataFormConfig = (): Column<IExposure>[] => {
  return ExposureDataFormConfig;
};

export default getExposureDataFormConfig;

import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import { Column, useFilters, useGlobalFilter, useTable } from 'react-table';
import IFundDetail from '@shared/interfaces/IFundDetail';
import { ReactElement } from 'react';
import useFunds from '@common/hooks/useFunds';
import TableLoader from '@components/TableLoader';
import useAuthStore from '@stores/useAuthStore';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import GlobalTableFilter from '@components/GlobalTableFilter';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import { ButtonGroup, Callout, Icon, Intent, NonIdealState } from '@blueprintjs/core';
import styled from '@emotion/styled';

import FundsSettings from './FundsSettings';

function FundsTable({
  columns,
  data,
  isWriteEnabled,
  setFundsSelectedForSettings,
}: {
  columns: Column<IFundDetail>[];
  data: IFundDetail[];
  isWriteEnabled: boolean;
  setFundsSelectedForSettings: (fund: IFundDetail) => void;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );

  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div></div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>

      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
              <th style={{ textAlign: 'right' }}>Manage Fund Access</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
                {isWriteEnabled && (
                  <td>
                    <Flex flexDirection={'row-reverse'}>
                      <ButtonGroup>
                        <button
                          onClick={() => setFundsSelectedForSettings(row.original)}
                          className="bp3-button  bp3-minimal bp3-outlined bp3-intent-primary bp3-icon-cog"
                        ></button>
                      </ButtonGroup>
                    </Flex>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No Funds found.</h5>} />}
    </>
  );
}

const AdministrationFundsPage: FC = (): ReactElement => {
  const { data: fundsData, isLoading: isGetFundsLoading, isError, error } = useFunds(true);
  const [fundsSeletedForSettings, setFundsSelectedForSettings] = useState<IFundDetail | undefined>();
  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const isWriteEnabled = canCurrentUserWriteACL('ADMIN_FUNDS');

  const columns = useMemo(
    (): Column<IFundDetail>[] => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: 'Fund Name',
        accessor: 'fundName',
      },
      {
        Header: 'Fund Type',
        accessor: 'fundType',
        Cell: ({ row }) => row.original.fundType.fundTypeName,
      },
      {
        Header: 'Inception Date',
        accessor: 'inceptionDt',
        Cell: ({ value }) => (value ? value : ''),
      },
    ],
    [],
  );

  return (
    <Styles>
      <Flex m={2}>
        <Box width={[12 / 12, 12 / 12, 10 / 12]}>
          <CustomCard
            heading={
              fundsSeletedForSettings ? (
                <>
                  <Link to="funds">Funds</Link> <Icon icon={'chevron-right'} style={{ verticalAlign: 'middle' }}></Icon>{' '}
                  {fundsSeletedForSettings.fundName}
                </>
              ) : (
                'Funds'
              )
            }
            body={
              <>
                {isGetFundsLoading && <TableLoader></TableLoader>}
                {isError && <Callout intent={Intent.DANGER}>{error?.errorMessage}</Callout>}
                {fundsData && fundsData.funds && !fundsSeletedForSettings && (
                  <FundsTable
                    columns={columns}
                    data={fundsData?.funds}
                    isWriteEnabled={isWriteEnabled}
                    setFundsSelectedForSettings={setFundsSelectedForSettings}
                  />
                )}
                {fundsSeletedForSettings && <FundsSettings fund={fundsSeletedForSettings}></FundsSettings>}
              </>
            }
          ></CustomCard>
        </Box>
      </Flex>
    </Styles>
  );
};

export default AdministrationFundsPage;

const Styles = styled.div`
  .user-avatar-container {
    z-index: 0;
    cursor: pointer;
    //position: absolute;
  }
  .user-avatar-container:hover {
    z-index: 1000 !important;
    //font-size: 15px;
  }
`;

import IGetCashEntryMetadataResponse from '@shared/exchange/getCashEntryMetadata/IGetCashEntryMetadataResponse';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useCashEntryCreationFormMetaData(
  fundId: number | undefined,
): UseQueryResult<IGetCashEntryMetadataResponse, Error> {
  const axios = useAxios();
  return useQuery<IGetCashEntryMetadataResponse, Error>(
    ['cash-entry-metadata', { fundId }],
    async () => axios?.get(`get-cash-entry-metadata?fundId=${fundId}`).then((res) => res.data.data),
    { staleTime: 30 * 60 * 1000, enabled: !!fundId },
  );
}

import { FC, ReactElement, memo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { ROLES_CONFIG } from '@shared/constants/Roles';
import IUserOrgRole from '@shared/interfaces/IUserOrgRole';
import useExposureStore from '@stores/useExposureStore';
import useAuthStore from '@stores/useAuthStore';
import useCashManagementStore from '@stores/useCashManagementStore';
import useCurrentUser from '@common/hooks/useCurrentUser';
import useAdministrationStore from '@stores/useAdministrationStore';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import { Button, Menu, MenuItem, Spinner, Tag } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Colors } from '@blueprintjs/core';
import { Box } from '@rebass/grid/emotion';

const getRoleDisplayName = (roleName: string) =>
  ROLES_CONFIG.find((roleConfig) => roleConfig.role_name === roleName)?.display_name || '';

interface IHeaderOrgChangeFormProps {
  orgRoles?: IUserOrgRole[];
  currentOrgRole?: IUserOrgRole;
}
const HeaderOrgChangeForm: FC<IHeaderOrgChangeFormProps> = ({ orgRoles, currentOrgRole }): ReactElement => {
  const setCurrentUserOrgRole = useAuthStore((state) => state.setCurrentUserOrgRole);
  const { getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();
  const { isLoading: isCurrentUserLoading } = useCurrentUser();
  const queryClient = useQueryClient();
  const resetExposureStore = useExposureStore((state) => state.resetExposureStore);
  const resetCashManagementStore = useCashManagementStore((state) => state.reset);
  const resetAdministrationStore = useAdministrationStore((state) => state.resetAdministrationStore);
  const resetTradeOrderManagementStore = useTradeOrderManagementStore((state) => state.resetTradeOrderManagementStore);

  const handleOrgChange = async (orgRole: IUserOrgRole) => {
    if (currentOrgRole?.org.id === orgRole.org.id) return;
    try {
      setCurrentUserOrgRole(orgRole);
      await getAccessTokenSilently({ ignoreCache: true, orgId: orgRole.org.id });
      queryClient.clear();
      resetExposureStore();
      resetCashManagementStore();
      resetAdministrationStore();
      resetTradeOrderManagementStore();
    } catch (e) {
      console.log(e);
    }
  };

  const isCurrentOrg = useCallback((orgRole: IUserOrgRole): boolean => {
    return currentOrgRole?.org.id === orgRole.org.id;
  }, []);

  const isLoading = isCurrentUserLoading || isAuth0Loading;
  return (
    <>
      {orgRoles && (
        <>
          <Popover2
            content={
              <Menu style={{ zIndex: 1000 }}>
                {orgRoles.map((orgRole) => (
                  <MenuItem
                    key={orgRole.org.id}
                    icon={<i className="bi bi-bank2"></i>}
                    active={isCurrentOrg(orgRole)}
                    onClick={() => handleOrgChange(orgRole)}
                    text={<Box>{orgRole.org.orgName} </Box>}
                    labelElement={
                      <Tag style={isCurrentOrg(orgRole) ? { background: Colors.LIGHT_GRAY4 } : {}} minimal={true}>
                        {getRoleDisplayName(orgRole.role.roleName)}
                      </Tag>
                    }
                  ></MenuItem>
                ))}
              </Menu>
            }
            placement={'bottom-end'}
          >
            <Button
              disabled={isLoading}
              rightIcon="chevron-down"
              icon={
                isLoading ? (
                  <span>
                    <Spinner intent="primary" size={14} tagName="span" />
                  </span>
                ) : (
                  <></>
                )
              }
              text={
                <>
                  <i className="bi bi-bank2 pe-2"></i> {currentOrgRole?.org.orgName}{' '}
                  <Tag minimal={true}>{getRoleDisplayName(currentOrgRole?.role.roleName || '')}</Tag>
                </>
              }
            />
          </Popover2>
        </>
      )}
    </>
  );
};

export default memo(HeaderOrgChangeForm, (preProps, nextProps) => {
  if (preProps.orgRoles && !nextProps.orgRoles) return true;
  return false;
});

import { Icon, Button, Intent, Colors, Dialog, ProgressBar, NonIdealState } from '@blueprintjs/core';
import useFunds from '@common/hooks/useFunds';
import useGetDocMgmtFileDownloadUrl from '@common/hooks/useGetDocMgmtFileDownloadUrl';
import useSearchDocMgmtFacts from '@common/hooks/useSearchDocMgmtFacts';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import { AppToaster } from '@components/Toasters';
import IDocMgmtFact from '@shared/interfaces/IDocMgmtFact';
import useDocumentManagementStore from '@stores/useDocumentManagementStore';
import FileSaver from 'file-saver';
import { ReactElement, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Box } from 'reflexbox';

const SearchFilesDialogPanel = (props: {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  hasWriteAccess: boolean;
  type: 'INTERNAL' | 'EXTERNAL';
}): ReactElement => {
  //const location = useLocation();
  //const pathParts = location.pathname.split('/');
  //if (pathParts[1] === 'funds') levelCode = 'F';
  //else if (pathParts[1] === 'org') levelCode = 'O';
  //else if (pathParts[1] === 'user') levelCode = 'U';

  const { modalOpen, setModalOpen } = props;
  const { selectedFundId, setSelectedFundId, levelCode } = useDocumentManagementStore();

  const { data: fundData } = useFunds();
  const [fileNameToSearch, setFileNameToSearch] = useState<string>('');
  const {
    data: searchFileData,
    isLoading: isFileSearchLoading,
    isFetching: isFileSearchFetching,
  } = useSearchDocMgmtFacts(selectedFundId, fileNameToSearch, levelCode, props.type);

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (!searchFileData || !searchFileData?.files || searchFileData?.files.length === 0) return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
  }, [fundData, searchFileData]);

  const onSearchFileNameChange = (value: string) => {
    if (value && value.trim() != '') {
      setFileNameToSearch(value);
    } else {
      setFileNameToSearch('');
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setFileNameToSearch('');
  };

  const { mutateAsync: getDownloadURL } = useGetDocMgmtFileDownloadUrl();
  const handleDownload = async (file: IDocMgmtFact) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });

    try {
      const res = await getDownloadURL({ docFileId: file.id });
      fetch(res.url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, file.fileName);
          AppToaster.clear();
        })
        .catch((e) => {
          console.log(e);
          AppToaster.clear();
        });
    } catch (e) {
      AppToaster.clear();
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Something went wrong. Please try again.',
        icon: 'warning-sign',
      });
    }
  };

  return (
    <>
      <Dialog
        canOutsideClickClose={false}
        style={{ width: 1000 }}
        title="Files"
        icon="document"
        isOpen={modalOpen}
        onClose={() => {
          handleClose();
        }}
      >
        <Box p={2}>
          {/* <Icon icon={'search'} size={22}></Icon> */}
          <div className="bp3-input-group bp3-large">
            <span className="bp3-icon bp3-icon-search bp3-large"></span>
            <DebounceInput
              className="bp3-input bp3-input-search bp3-large bp3-fill"
              placeholder="Search File Name"
              // type="search"
              element="input"
              minLength={3}
              debounceTimeout={100}
              onChange={(event) => onSearchFileNameChange(event.target.value)}
            />
          </div>
        </Box>
        <Box>
          {isFileSearchFetching && <GlobalLoader></GlobalLoader>}
          {isFileSearchLoading && <TableLoader></TableLoader>}
          {searchFileData && (
            <>
              <table className="bp3-html-table bp3-html-table-bordered" width="100%">
                <thead>
                  <tr style={{ backgroundColor: Colors.WHITE }}>
                    <th>File Name</th>
                    <th>File Path</th>
                  </tr>
                </thead>
                <tbody>
                  {searchFileData?.files.map((file) => (
                    <tr key={file.id}>
                      <td>
                        <Button minimal rightIcon={'download'} onClick={() => handleDownload(file)}>
                          <Icon icon={'document'} size={22}></Icon>
                          <Box pl={2} display="inline">
                            {file.fileName}
                          </Box>
                        </Button>
                        <>
                          {props.hasWriteAccess && (
                            <>{file.visibleInd && <Icon icon="eye-on" intent={Intent.SUCCESS}></Icon>}</>
                          )}
                        </>
                      </td>
                      <td>
                        <Box pl={2} display="inline">
                          <Icon icon={'home'} size={22}></Icon>
                          <Box pl={2} display="inline">
                            {file.fileS3FullUrl.replace(
                              file.s3BaseUrl.concat('/').concat(levelCode).concat('/').concat(String(selectedFundId)),
                              '',
                            ) || '/'}
                          </Box>
                        </Box>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={50}>
                      {(!searchFileData || searchFileData?.files.length === 0) &&
                        searchFileData?.files.length === 0 && (
                          <NonIdealState
                            icon={'issue'}
                            title={<h5 className="bp3-heading"> No files present for the search criteria.</h5>}
                          />
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default SearchFilesDialogPanel;

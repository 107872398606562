import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetDocMgmtExternalDirectoriesResponse } from '@shared/exchange/getDocMgmtExternalDirectories';
import { IGetDocMgmtInternalDirectoriesResponse } from '@shared/exchange/getDocMgmtInternalDirectories';

export const GET_DOC_MGMT_EXT_DIRECTORIES = 'get-doc-mgmt-directories';

export default function useGetDocMgmDirectories(
  type: 'EXTERNAL' | 'INTERNAL',
  levelCode: string | undefined,
  folderId: number | undefined,
  fundId?: number | undefined,
  active?: boolean,
): UseQueryResult<IGetDocMgmtExternalDirectoriesResponse | IGetDocMgmtInternalDirectoriesResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetDocMgmtExternalDirectoriesResponse | IGetDocMgmtInternalDirectoriesResponse, IErrorResponse>(
    [GET_DOC_MGMT_EXT_DIRECTORIES, { fundId, levelCode, folderId, type }],
    async () => {
      const searchParams = new URLSearchParams();
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      if (levelCode) {
        searchParams.append('levelCode', levelCode);
      }
      if (folderId) {
        searchParams.append('folderId', String(folderId));
      }
      if (type === 'EXTERNAL') {
        return axios
          ?.get(`get-doc-mgmt-external-directories?${searchParams.toString()}`)
          .then((res) => res.data.data)
          .catch((e) => {
            throw e.response.data;
          });
      } else if (type === 'INTERNAL') {
        return axios
          ?.get(`get-doc-mgmt-internal-directories?${searchParams.toString()}`)
          .then((res) => res.data.data)
          .catch((e) => {
            throw e.response.data;
          });
      } else {
        return;
      }
    },
    { staleTime: 0, retry: false, enabled: active, refetchOnWindowFocus: false },
  );
}

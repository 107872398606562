import { ResponsiveLine } from '@nivo/line';
import CHART_COLORS from '@common/constants/chartColors';
import convertToInternationalCurrencySystem from '@common/utils/convertToInternationalCurrencySystem';
import { ReactElement } from 'react';

export interface IPeformanceLineChartProps {
  id: string;
  data: {
    x: string;
    y: number;
  }[];
}

const PerformanceLineChart = ({ data }: { data: IPeformanceLineChartProps[] }): ReactElement => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 30, right: 30, bottom: 30, left: 80 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Date',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Net Asset Value',
        legendOffset: -70,
        legendPosition: 'middle',
        format: (value) => convertToInternationalCurrencySystem(value),
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridY={false}
      colors={() => CHART_COLORS[3]}
      enableArea={true}
      enableSlices={'x'}
      yFormat=" >-,.2f" //" >-$.2f"
    />
  );
};

export default PerformanceLineChart;

import useAccounts from '@common/hooks/useAccounts';
import useCreateDoc from '@common/hooks/useCreateDoc';
import useDocTemplateUpload from '@common/hooks/useUpload';
import useFunds from '@common/hooks/useFunds';
import useGetDocSignedUploadUrl from '@common/hooks/useGetDocTemplateSignedUploadUrl';
import FormLoader from '@components/FormLoader';
import IGetDocSignedUploadUrlResponse from '@shared/exchange/getDocSignedUploadUrl/IGetDocSignedUploadUrlResponse';
import IAccount from '@shared/interfaces/IAccount';
import useAdministrationStore from '@stores/useAdministrationStore';
import { FC, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { GET_DOCS_KEY } from '@common/hooks/useDocs';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import { FormGroup, HTMLSelect, InputGroup, Intent, Button } from '@blueprintjs/core';
import customRegisterFormField from '@common/utils/customRegisterFormField';

const formSchema = yup.object().shape({
  docName: yup.string().required('Document name is required.'),
  fundId: yup.number().required('No Fund Found.'),
  accountId: yup.number().required(),
  file: yup
    .mixed()
    .required('File is required.')
    .test('type', 'File is required and Only pdf files are supported', (value: FileList) => {
      return value.length > 0 && value[0].type === 'application/pdf';
    })
    .test('fileSize', 'The file size should not be more than 2 MB', (value: FileList) => {
      return value.length > 0 && value[0].size <= 2000000;
    }),
});

const getUploadData = (file: File, s3Data: IGetDocSignedUploadUrlResponse): FormData => {
  const postData = new FormData();
  for (const key of Object.keys(s3Data.signedData.fields)) {
    postData.append(key, s3Data.signedData.fields[key]);
  }
  postData.append('file', file);
  return postData;
};

const DocTemplateCreationPage: FC = (): ReactElement => {
  const {
    mutateAsync: uploadDocTemplate,
    isLoading: isDocTemplateUploadLoading,
    error: docTemplateUploadError,
  } = useDocTemplateUpload();
  const {
    mutateAsync: getDocSignedUploadUrl,
    isLoading: isGetDocSignedUploadUrlLoading,
    error: getDocSignedUploadUrlError,
  } = useGetDocSignedUploadUrl();
  const { mutateAsync: createDoc, isLoading: isCreateDocLoading, error: createDocError } = useCreateDoc();
  const {
    register,
    handleSubmit,
    formState: { errors },
    //setValue,
    watch,
  } = useForm({ resolver: yupResolver(formSchema) });
  const { data: fundsData, isLoading: isGetFundsLoading } = useFunds();
  const { data: accountsData, isLoading: isGetAccountsLoading } = useAccounts();
  const { selectedFundId, setSelectedFundId } = useAdministrationStore(({ selectedFundId, setSelectedFundId }) => {
    return { selectedFundId, setSelectedFundId };
  });

  useEffect(() => {
    if (!selectedFundId && fundsData) {
      setSelectedFundId(fundsData?.funds[0].id);
    }
  }, [fundsData]);

  const getAccountsOfSelectedFund = () => {
    if (!accountsData) return [];
    const defaultAccount = { id: -1, accountName: 'Default' };
    return [
      defaultAccount,
      ...accountsData.accounts.filter(
        (account: IAccount) => account.fund.id === selectedFundId && account.lodRequiredInd,
      ),
    ];
  };
  const history = useHistory();
  const queryClient = useQueryClient();

  const onSubmit = async (data: {
    fundId: number;
    accountId: number;
    docName: string;
    file: FileList;
    isSignatureRequired: boolean;
  }) => {
    try {
      const response: IGetDocSignedUploadUrlResponse = await getDocSignedUploadUrl({
        fundId: data.fundId,
        accountId: data.accountId,
        docName: data.docName,
      });

      await uploadDocTemplate({
        uploadUrl: response.signedData.url,
        doc: getUploadData(data.file[0], response),
      });
      await createDoc({
        fundId: data.fundId,
        accountId: data.accountId,
        isSignatureRequired: data.isSignatureRequired,
        s3TemplateUrl: response.url,
        s3BucketName: response.bucketName,
      });
      queryClient.invalidateQueries(GET_DOCS_KEY);
      history.push('/administration/doc-templates');
    } catch (error) {}
  };

  const isMetaDataLoading = isGetFundsLoading || isGetAccountsLoading;
  const isSubmitting = isDocTemplateUploadLoading || isGetDocSignedUploadUrlLoading || isCreateDocLoading;

  const file = watch('file');
  console.log(file);
  return (
    <Flex m={2}>
      <Box width={[6 / 12]}>
        <CustomCard
          heading={'Add Letter of Directive'}
          body={
            <Box m={3}>
              {isMetaDataLoading ? (
                <FormLoader></FormLoader>
              ) : (
                <>
                  {(docTemplateUploadError || getDocSignedUploadUrlError || createDocError) && (
                    <div className="col-lg-6  col-sm-12 me-2">
                      <div className="alert alert-danger text-center" role="alert">
                        <>{docTemplateUploadError?.errorMessage}</>
                        <>{getDocSignedUploadUrlError?.errorMessage}</>
                        <>{createDocError?.errorMessage}</>
                      </div>
                    </div>
                  )}
                  <form className="formInputs col-12" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <FormGroup
                      helperText={errors?.docName?.message}
                      label="Enter Document Name"
                      labelFor="docName"
                      labelInfo="(required)"
                      intent={errors?.docName ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        id="docName"
                        placeholder="Enter the document name"
                        intent={errors?.docName ? Intent.DANGER : Intent.NONE}
                        large={true}
                        {...customRegisterFormField(register, 'docName')}
                      ></InputGroup>
                    </FormGroup>

                    {/*<div className="row mb-2">
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-floating">
                      <input
                        className={`form-control ${errors?.docName ? 'is-invalid' : ''} `}
                        type="text"
                        placeholder="Enter the document name"
                        id="docName"
                        {...register('docName')}
                      ></input>
                      <label htmlFor="docName" className="pb-3">
                        Enter Document Name
                      </label>
                      <div className="invalid-feedback">{errors?.docName?.message}</div>
                    </div>
                  </div>
              </div>*/}

                    <FormGroup
                      helperText={errors?.fundId?.message}
                      label="Fund"
                      labelFor="fund"
                      labelInfo="(required)"
                      intent={errors?.fundId ? Intent.DANGER : Intent.NONE}
                    >
                      <HTMLSelect
                        fill={true}
                        id="fund"
                        {...customRegisterFormField(register, 'fundId')}
                        onChange={(e) => setSelectedFundId(Number(e.currentTarget.value))}
                        large={true}
                      >
                        {fundsData?.funds.map((fund) => (
                          <option key={fund.id} value={fund.id}>
                            {fund.fundName}
                          </option>
                        ))}
                      </HTMLSelect>
                    </FormGroup>

                    {/*
                <div className="row mb-2">
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-floating">
                      <select
                        className={`form-select ${errors?.fundId ? 'is-invalid' : ''} `}
                        id="fund"
                        {...register('fundId')}
                        onChange={(e) => setSelectedFundId(Number(e.target.value))}
                      >
                        {fundsData?.funds.map((fund) => (
                          <option key={fund.id} value={fund.id}>
                            {fund.fundName}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="fund">Fund</label>
                      <div className="invalid-feedback">{errors?.fundId?.message}</div>
                    </div>
                  </div>
                        </div>*/}

                    <FormGroup
                      helperText={errors?.accountId?.message}
                      label="Account"
                      labelFor="account"
                      labelInfo="(required)"
                      intent={errors?.accountId ? Intent.DANGER : Intent.NONE}
                    >
                      <HTMLSelect
                        fill={true}
                        id="account"
                        onChange={(e) => setSelectedFundId(Number(e.target.value))}
                        {...customRegisterFormField(register, 'accountId')}
                        large={true}
                      >
                        {getAccountsOfSelectedFund().map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.accountName}
                          </option>
                        ))}
                      </HTMLSelect>
                    </FormGroup>

                    {/*
                <div className="row mb-2">
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-floating">
                      <select
                        className={`form-select ${errors?.accountId ? 'is-invalid' : ''} `}
                        id="account"
                        {...register('accountId')}
                      >
                        {getAccountsOfSelectedFund().map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.accountName}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="account">Account</label>
                      <div className="invalid-feedback">{errors?.accountId?.message}</div>
                    </div>
                  </div>
                        </div>*/}

                    <FormGroup
                      helperText={errors?.file?.message}
                      label="Upload file"
                      labelFor="file"
                      labelInfo="(required)"
                      intent={errors?.file ? Intent.DANGER : Intent.NONE}
                    >
                      <label
                        className={`bp3-file-input bp3-fill bp3-large ${
                          file && file?.length > 0 ? 'bp3-file-input-has-selection' : ''
                        } `}
                      >
                        <input
                          className={`bp3-large ${errors?.file ? 'is-invalid' : ''} `}
                          {...register('file')}
                          type="file"
                          name="file"
                          accept=".pdf,"
                        />
                        <span className="bp3-file-upload-input">
                          {!file || file?.length === 0 ? 'Choose file ' : file[0].name}
                        </span>
                      </label>
                    </FormGroup>

                    {/*
                <div className="row mb-2">
                  <div className="col-lg-6 col-sm-12">
                    <div className="">
                      <input
                        className={`form-control ${errors?.file ? 'is-invalid' : ''} `}
                        {...register('file')}
                        type="file"
                        name="file"
                      />
                      <div className="invalid-feedback">{errors?.file?.message}</div>
                    </div>
                  </div>
                      </div>*/}

                    <div>
                      <label className="bp3-control bp3-switch bp3-large">
                        <input
                          className="bp3-large"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={true}
                          {...register('isSignatureRequired', {})}
                          accept=".pdf,"
                        />
                        <span className="bp3-control-indicator"></span>
                        Signature Required
                      </label>

                      {/*
                  <label className="bp3-control bp3-checkbox">
                    <input
                      type="checkbox"
                      id="flexCheckChecked"
                      defaultChecked={true}
                      {...register('isSignatureRequired', {})}
                      accept=".pdf,"
                    />
                    <span className="bp3-control-indicator"></span>
                    Signature Required
                  </label>
                */}
                    </div>

                    <Box mt={3}>
                      <Button loading={isSubmitting} type="submit" intent={Intent.PRIMARY} disabled={isSubmitting}>
                        Submit
                      </Button>
                      <Button minimal onClick={() => history.push('/administration/doc-templates')}>
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </>
              )}
            </Box>
          }
        ></CustomCard>
      </Box>
    </Flex>
  );
};

export default DocTemplateCreationPage;

import { ResponsivePie } from '@nivo/pie';
import CenteredMetric from './CenteredMetric';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import { ReactElement } from 'react';

export interface IAssetAllocationPieChartDataRecord {
  id: string;
  label: string;
  value: number;
  color: string;
}

const AssetAllocationPieChart = ({ data }: { data: IAssetAllocationPieChartDataRecord[] }): ReactElement => (
  <ResponsivePie
    data={data}
    margin={{ top: 10, bottom: 10, left: 30, right: 10 }}
    innerRadius={0.55}
    padAngle={0.7}
    cornerRadius={0}
    activeOuterRadiusOffset={8}
    colors={(r) => r.data.color}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#fff"
    enableArcLinkLabels={false}
    arcLabel={(record) => formatAllocationPercentage({ value: record.value })}
    theme={{}}
    layers={['arcs', 'arcLabels', 'arcLinkLabels', CenteredMetric]}
  />
);

export default AssetAllocationPieChart;

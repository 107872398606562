import IDocFact from '@shared/interfaces/IDocFact';
import ITradeItem from '@shared/interfaces/ITradeItem';
import { Row } from 'react-table';

const getFileNameFromDocFact = (docFact: IDocFact, row: Row<ITradeItem>): string => {
  let downloadFileName = docFact.s3_lod_file_url?.split('/').pop() || '';
  const { cashTransaction } = row.original;
  if (downloadFileName === '')
    downloadFileName =
      cashTransaction.fromAccount.accountName +
      '_' +
      cashTransaction.toAccount.accountName +
      '_' +
      row.original.tradeDt +
      '.pdf';
  return downloadFileName;
};

export default getFileNameFromDocFact;

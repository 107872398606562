import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { ISearchDocMgmtFactsResponse } from '@shared/exchange/searchDocMgmtFacts';

export const SEARCH_DOC_MGMT_FACTS = 'admin-search-users';
export default function useSearchDocMgmtFacts(
  fundId: number | undefined,
  fileName: string | undefined,
  levelCode: string,
  type: string,
): UseQueryResult<ISearchDocMgmtFactsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<ISearchDocMgmtFactsResponse, IErrorResponse>(
    [SEARCH_DOC_MGMT_FACTS, { fundId, type, fileName }],
    async () => {
      if (!fileName) return;
      const searchParams = new URLSearchParams();
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      if (fileName) {
        searchParams.append('fileName', fileName);
      }
      searchParams.append('levelCode', levelCode);
      searchParams.append('type', type);
      searchParams.append('isExternalInd', String(type === 'EXTERNAL'));
      return axios
        ?.get(`search-doc-mgmt-facts?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
  );
}

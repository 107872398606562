import IGetDocFactsResponse from '@shared/exchange/getDocFacts/IGetDocFactsResponse';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export const GET_DOC_FACTS_KEY = 'get-doc-facts';
export default function useDocFacts(tradeId: number | undefined): UseQueryResult<IGetDocFactsResponse, Error> {
  const axios = useAxios();
  return useQuery<IGetDocFactsResponse, Error>(
    [GET_DOC_FACTS_KEY, { tradeId }],
    async () => {
      if (!tradeId) return null;
      return axios?.post('get-doc-facts', { tradeId: tradeId }).then((res) => res.data.data);
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

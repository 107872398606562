import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import IExposure from '@shared/interfaces/IExposure';
import { ReactElement, useMemo } from 'react';
import CHART_COLORS from '@common/constants/chartColors';
import roundtAllocationPercentage from '@common/utils/roudAllocationPercentage';
import AssetAllocationPieChart, { IAssetAllocationPieChartDataRecord } from './AssetAllocationPieChart';
import { Box, Flex } from '@rebass/grid/emotion';
import { Callout, Divider, Icon, IconSize } from '@blueprintjs/core';
import CustomCard from '@components/CustomCard';

const AssetAllocationChart = ({ allocations }: { allocations: IExposure[] }): ReactElement => {
  const chartData: IAssetAllocationPieChartDataRecord[] | undefined = useMemo(() => {
    if (!allocations) return [];
    return allocations.map(({ name, current_allocation_pct }, i) => {
      return {
        id: name,
        label: name,
        value: roundtAllocationPercentage({ value: current_allocation_pct }),
        color: CHART_COLORS[i % allocations.length],
      };
    });
  }, [allocations]);
  return (
    <CustomCard
      rightToolbar={<></>}
      heading="Asset Allocation"
      body={
        chartData && chartData.length == 0 ? (
          <Callout intent="danger">No data found for the selected fund and date.</Callout>
        ) : (
          <Flex height={250} padding={2}>
            <Box width={2 / 5}>
              <AssetAllocationPieChart data={chartData}></AssetAllocationPieChart>
            </Box>
            <Box width={3 / 5} alignSelf="center" px={4}>
              <div>
                {chartData.map(({ id, color, value, label }) => (
                  <>
                    <Flex key={id}>
                      <Box width={3 / 5}>
                        <Icon icon="symbol-square" size={IconSize.LARGE} style={{ color: color }}></Icon>
                        <span className="bp3-text-large" style={{ color: color }}>
                          {label}
                        </span>
                      </Box>
                      <Box width={2 / 5} style={{ textAlign: 'right' }}>
                        {formatAllocationPercentage({ value })}
                      </Box>
                    </Flex>
                    <Divider />
                  </>
                ))}
              </div>
            </Box>
          </Flex>
        )
      }
    ></CustomCard>
  );
};

export default AssetAllocationChart;

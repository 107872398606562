import { FormGroup, Divider, Switch, Colors, Spinner } from '@blueprintjs/core';
import { FC, ReactElement, useEffect, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import { DateInput } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import useGetWhatIfRebalScheduledCashflows from '@common/hooks/useGetWhatIfRebalScheduledCashflows';
import formatAssetValue from '@common/utils/formatAssetValue';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';

const getDateShortcuts = (): { label: string; date: Date }[] => {
  const dateEvents: { label: string; date: Date }[] = [];
  dateEvents.push({
    label: 'Current Week End',
    date: dayjs().endOf('week').toDate(),
  });

  dateEvents.push({
    label: 'Current Month End',
    date: dayjs().endOf('month').toDate(),
  });

  dateEvents.push({
    label: 'Next Month End',
    date: dayjs().endOf('month').add(1, 'month').toDate(),
  });

  const currentQuarter = dayjs().quarter();
  if (currentQuarter <= 1) {
    dateEvents.push({
      label: 'Quater 1 End',
      date: dayjs().set('month', 2).set('date', 31).toDate(),
    });
  }
  if (currentQuarter < 2) {
    dateEvents.push({
      label: 'Quarter 2 End',
      date: dayjs().set('month', 5).set('date', 30).toDate(),
    });
  }
  if (currentQuarter < 3) {
    dateEvents.push({
      label: 'Quarter 3 End',
      date: dayjs().set('month', 8).set('date', 30).toDate(),
    });
  }

  dateEvents.push({
    label: 'Year End',
    date: dayjs().endOf('year').toDate(),
  });

  return dateEvents;
};

interface IScheduledCashflowInputPanelProps {
  onChange: () => void;
  selectedFundId: number;
}
const ScheduledCashflowInputPanel: FC<IScheduledCashflowInputPanelProps> = ({
  onChange,
  selectedFundId,
}): ReactElement => {
  const [selectedDate, setSelectedDate] = useState<Date>(dayjs().toDate());

  const {
    setScheduledCashflowRedemption,
    setScheduledCashflowContribution,
    scheduledCashflowRedemption,
    scheduledCashflowContribution,
    isScheduledCashFlowEnabled,
    setScheduledCashFlowEnabled,
  } = useWhatIfRebalStore();

  const { isLoading: isGetScheduledCashflowsLoading, data: scheduledCashflowsData } =
    useGetWhatIfRebalScheduledCashflows(selectedFundId, selectedDate, isScheduledCashFlowEnabled);

  useEffect(() => {
    if (!scheduledCashflowsData) {
      setScheduledCashflowContribution('0');
      setScheduledCashflowRedemption('0');
      return;
    }
    setScheduledCashflowContribution(scheduledCashflowsData?.scheduledCashFlows.contribution_amt || '0');
    setScheduledCashflowRedemption(scheduledCashflowsData?.scheduledCashFlows.benefit_amt || '0');
  }, [scheduledCashflowsData]);

  return (
    <Box>
      <Box>
        <Flex justifyContent={'space-between'}>
          <Flex p={2} pb={1}>
            <h6 className="bp3-heading">
              <strong>Scheduled Cashflow</strong>
            </h6>
            <Box pl={1}>
              <Switch
                style={{ marginBottom: 0 }}
                checked={isScheduledCashFlowEnabled}
                onChange={(props) => {
                  setScheduledCashFlowEnabled(props.currentTarget.checked);
                  onChange();
                }}
              ></Switch>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Box
        px={3}
        pt={1}
        backgroundColor={!isScheduledCashFlowEnabled ? Colors.LIGHT_GRAY4 : Colors.WHITE}
        color={!isScheduledCashFlowEnabled ? Colors.GRAY4 : Colors.BLACK}
        height="100%"
        style={{ borderBottomLeftRadius: '0.6rem' }}
      >
        <Flex>
          <FormGroup label="Date">
            <DateInput
              disabled={!isScheduledCashFlowEnabled}
              formatDate={(date) => dayjs(date).format('MM/DD/YYYY')}
              parseDate={(str) => new Date(str)}
              placeholder={'MM/DD/YYYY'}
              value={selectedDate}
              maxDate={dayjs().add(1, 'year').toDate()}
              shortcuts={getDateShortcuts()}
              onChange={(date) => {
                setSelectedDate(date);
                onChange();
              }}
              fill={true}
              popoverProps={{
                placement: 'bottom-start',
              }}
            ></DateInput>
          </FormGroup>
        </Flex>
        <Flex>
          <Flex pr={1}>
            <Box pr={1} display="inline">
              <label>Redemption : </label>
            </Box>
            {isGetScheduledCashflowsLoading ? (
              <Spinner size={18}></Spinner>
            ) : (
              <strong>{formatAssetValue({ value: Number(scheduledCashflowRedemption) })}</strong>
            )}
          </Flex>
          <Flex pl={3}>
            <Box pr={1} display="inline">
              <label>Contribution : </label>
            </Box>
            {isGetScheduledCashflowsLoading ? (
              <Spinner size={18}></Spinner>
            ) : (
              <strong>
                {formatAssetValue({
                  value: Number(scheduledCashflowContribution),
                })}
              </strong>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ScheduledCashflowInputPanel;

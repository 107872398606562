import IDocMgmtDocTypeRef from '@shared/interfaces/IDocMgmtDocTypeRef';
import IDocMgmtFolder from '@shared/interfaces/IDocMgmtFolder';
import IFund from '@shared/interfaces/IFund';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface IDocumentManagementStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;

  allFunds: IFund[] | undefined;
  setAllFunds: (allFunds: IFund[]) => void;

  switchFund: (fundId: number) => void;
  resetDocumentManagementStore: () => void;

  selectedDocTypeId: number | undefined;
  setSelectedDocTypeId: (docTypeId: number) => void;

  allDocMgmtDocRefs: IDocMgmtDocTypeRef[] | undefined;
  setAllDocMgmtDocRefs: (allDocMgmtDocRefs: IDocMgmtDocTypeRef[]) => void;

  switchDocType: (docTypeId: number) => void;
  resetDocTypeStore: () => void;

  selectedFolder: IDocMgmtFolder | undefined;
  setSelectedFolder: (folder: IDocMgmtFolder | undefined) => void;

  levelCode: string;
}

const useDocumentManagementStore = create<IDocumentManagementStore>(
  persist<IDocumentManagementStore>(
    (set: SetState<IDocumentManagementStore>) => ({
      selectedFundId: undefined,
      allFunds: undefined,
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId, selectedFolder: undefined });
      },
      setAllFunds: (allFunds: IFund[]) => {
        set({ allFunds: allFunds });
      },
      resetDocumentManagementStore: (): void => {
        set({ selectedFundId: undefined, allFunds: undefined });
      },
      switchFund: (fundId: number): void => {
        set({ selectedFundId: fundId, selectedFolder: undefined });
      },
      selectedDocTypeId: undefined,
      allDocMgmtDocRefs: undefined,
      setSelectedDocTypeId: (docTypeId: number): void => {
        set({ selectedDocTypeId: docTypeId });
      },
      setAllDocMgmtDocRefs: (allDocMgmtDocRefs: IDocMgmtDocTypeRef[]) => {
        set({ allDocMgmtDocRefs: allDocMgmtDocRefs });
      },
      resetDocTypeStore: (): void => {
        set({ selectedDocTypeId: undefined, allDocMgmtDocRefs: undefined });
      },
      switchDocType: (docTypeId: number): void => {
        set({ selectedDocTypeId: docTypeId });
      },
      selectedFolder: undefined,
      setSelectedFolder: (folder: IDocMgmtFolder | undefined): void => {
        set({ selectedFolder: folder });
      },
      levelCode: 'F',
    }),
    {
      name: 'doc-management-cache',
      blacklist: [
        'allFunds',
        'selectedFundId',
        'allDocMgmtDocRefs',
        'selectedDocTypeId',
        'selectedFolder',
        'levelCode',
      ],
    },
  ),
);

export default useDocumentManagementStore;

import useGetTradeItems from '@common/hooks/useGetTradeItems';
import TableLoader from '@components/TableLoader';
import { useCallback, useMemo, useState } from 'react';
import { FC, ReactElement } from 'react';
import getTradeItemsHistoryColumns from './getTradeItemsHistoryColumns';
import TradeItemsHistoryTable from './TradeItemsHistoryTable';
import Modal from 'react-modal';
import FileSaver from 'file-saver';
import useGetDocFactSignedDownloadUrl from '@common/hooks/useGetDocFactSignedDownloadUrl';
import useDocFacts from '@common/hooks/useDocFacts';
import CustomCard from '@components/CustomCard';
import { Button, Intent, ProgressBar } from '@blueprintjs/core';
import { Box } from 'reflexbox';
import { AppToaster } from '@components/Toasters';

interface ITradeHistoryTradeOrdersSubPane {
  tradeId: number | undefined;
  toggleClose: (id: string[], value?: boolean | undefined) => void;
  id: string;
}

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    padding: '0',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    marginRight: '-50%',
    borderRadius: '0.5rem',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
  },
};

const TradeItemsHistorySubPane: FC<ITradeHistoryTradeOrdersSubPane> = ({ tradeId, toggleClose, id }): ReactElement => {
  // Use the state and functions returned from useTable to build your UI

  const { data: tradeItemsData, isLoading: isTradesDataLoading } = useGetTradeItems(tradeId);

  const [currentMemoText, setCurrentMemoText] = useState<string | undefined>(undefined);
  const { data: docFactsData, isLoading: isDocFactsLoading, isFetching: isDocFactsFetching } = useDocFacts(tradeId);

  const getDocFact = useCallback(
    (tradeItemId: number) => {
      console.log('docFactsData', docFactsData);
      if (!docFactsData?.doc_facts) return null;
      return docFactsData?.doc_facts.find((docFact) => docFact.trade_item_id === tradeItemId);
    },
    [docFactsData],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lodUploadTradeItemId, setLodUploadTradeItemId] = useState<number | undefined>(undefined);

  const { mutateAsync: getDownloadUrl } = useGetDocFactSignedDownloadUrl();

  const handleDownloadUrl = useCallback(async (id: number, name: string, isSigned: boolean) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });
    const res = await getDownloadUrl({ docFactId: id, signed: isSigned });

    fetch(res.url)
      .then((res) => res.blob())
      .then((blob) => {
        FileSaver.saveAs(blob, `${name}.pdf`);
        AppToaster.clear();
      })
      .catch((e) => {
        console.log(e);
        AppToaster.clear();
      });
  }, []);

  const columns = useMemo(
    () =>
      getTradeItemsHistoryColumns({
        setMemoText: setCurrentMemoText,
        getDocFact,
        handleLODDownload: handleDownloadUrl,
        isLODLoading: isDocFactsLoading || isDocFactsFetching,
      }),
    [currentMemoText, getDocFact, isDocFactsLoading, isDocFactsFetching],
  );
  return (
    <>
      <CustomCard
        heading="Trade Orders"
        rightToolbar={<Button small minimal icon="cross" onClick={() => toggleClose([id], false)} />}
        body={
          <Box>
            {isTradesDataLoading || isDocFactsLoading ? (
              <TableLoader></TableLoader>
            ) : (
              <>
                {tradeItemsData && (
                  <TradeItemsHistoryTable data={tradeItemsData.tradeItems} columns={columns}></TradeItemsHistoryTable>
                )}
              </>
            )}
          </Box>
        }
      />

      <Modal
        isOpen={!!currentMemoText}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => setCurrentMemoText(undefined)}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div className="memoPopup memoPopup_tradeHistory">
          <div className="memoPopup_Title">
            <h4>Memo</h4>
            <button className="btn btn-link" onClick={() => setCurrentMemoText(undefined)}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="memoPopup_plot">
            <p id="memoPopup_para">{currentMemoText}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TradeItemsHistorySubPane;

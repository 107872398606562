import { IDeleteExternalTransferAccountRequest } from '@shared/exchange/deleteExternalTransferAccount';
import IDeleteExternalTransferAccountResponse from '@shared/exchange/deleteExternalTransferAccount/IDeleteExternalTransferAccountResponse';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useDeactivateExternalTransferAccount(): UseMutationResult<
  IDeleteExternalTransferAccountResponse,
  AxiosError,
  IDeleteExternalTransferAccountRequest,
  IDeleteExternalTransferAccountResponse
> {
  const axios = useAxios();
  return useMutation<
    IDeleteExternalTransferAccountResponse,
    AxiosError,
    IDeleteExternalTransferAccountRequest,
    IDeleteExternalTransferAccountResponse
  >(async (externalAccountDeleteEntry: IDeleteExternalTransferAccountRequest) => {
    const routePath = 'delete-external-transfer-account';
    return axios?.put(routePath, externalAccountDeleteEntry).then((res) => res.data.data);
  });
}

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetUserOrgFundsResponse } from '@shared/exchange/getUserOrgFunds';

export const ADMIN_GET_USERS_ORG_FUNDS_KEY = 'admin-get-user-org-funds';
export default function useGetUserOrgFunds(
  fundId: number | undefined = undefined,
): UseQueryResult<IGetUserOrgFundsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetUserOrgFundsResponse, IErrorResponse>(
    [ADMIN_GET_USERS_ORG_FUNDS_KEY, { fundId }],
    async () => {
      if (!fundId) return null;
      return axios
        ?.get('get-user-org-funds?fundId=' + fundId)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, refetchOnWindowFocus: false },
  );
}

import { Divider } from '@blueprintjs/core';
import { Box } from '@rebass/grid/emotion';
import { FC, ReactElement } from 'react';
import { Flex } from 'reflexbox';
import EconomicCalendarWidget from './components/EconomicCalendarWidget';
import ForexCrossRates from './components/ForexCrossRates';
import MartketOverviewWidget from './components/MarketOverviewWidget';
import MarketsMostActiveGainersLosers from './components/MarketsMostActiveGainersLosers';
import MarketDataWidget from './components/MartketDataWidget';
import MyFundsTable from './components/MyFundsTable';

const DashboardPage: FC = (): ReactElement => {
  return (
    <Box p={2}>
      <Box mb={3}>
        <MyFundsTable></MyFundsTable>
      </Box>
      <Box px={'40%'} mb={3}>
        <Divider />
      </Box>
      <Flex mt={2}>
        <Box mr={1} width={['30%']}>
          <MartketOverviewWidget></MartketOverviewWidget>
        </Box>
        <Box ml={1} width={['70%']}>
          <MarketDataWidget></MarketDataWidget>
        </Box>
      </Flex>
      <Flex mt={2}>
        <Box mr={1} width={['30%']}>
          <EconomicCalendarWidget></EconomicCalendarWidget>
        </Box>
        <Box ml={1} mr={1} width={['45%']}>
          <ForexCrossRates></ForexCrossRates>
        </Box>
        <Box ml={1} width={['25%']}>
          <MarketsMostActiveGainersLosers></MarketsMostActiveGainersLosers>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardPage;

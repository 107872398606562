import { useAxios } from '@common/providers/AxiosProvider';
import {
  IPublishDocMgmtFactEntryRequest,
  IPublishDocMgmtFactEntryResponse,
} from '@shared/exchange/publishDocMgmtFactEntry';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function usePublishDocMgmtFactEntry(): UseMutationResult<
  IPublishDocMgmtFactEntryResponse,
  IErrorResponse,
  IPublishDocMgmtFactEntryRequest,
  IPublishDocMgmtFactEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    IPublishDocMgmtFactEntryResponse,
    IErrorResponse,
    IPublishDocMgmtFactEntryRequest,
    IPublishDocMgmtFactEntryResponse
  >(async (data: IPublishDocMgmtFactEntryRequest) =>
    axios
      ?.patch('publish-doc-mgmt-fact-entry', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

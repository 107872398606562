import { FC, ReactElement, memo, useMemo, useCallback } from 'react';
import IGetFundsSummaryResponse from '@shared/exchange/getFundsSummary/IGetFundsSummaryResponse';
import useFundsSummary from '../../../../common/hooks/useFundsSummary';
import { Column, useTable, useGlobalFilter, useFilters, Cell } from 'react-table';
import IFundSummary from '@shared/interfaces/IFundSummary';
import TableLoader from '../../../../components/TableLoader';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';
import styled from '@emotion/styled';
import { useSticky } from 'react-table-sticky';
import getMyFundsTableConfig from './getMyFundsTableConfig';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import useExposureStore from '@stores/useExposureStore';
import { useHistory } from 'react-router';

import { Alignment, Callout, Card, Colors, Intent, Navbar } from '@blueprintjs/core';
import { Box, Flex } from '@rebass/grid/emotion';

interface IMyFundaTableProps {
  columns: Column<IFundSummary>[];
  data: IGetFundsSummaryResponse;
  onRowClicked: (fundId: number) => void;
}

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: Record<string, string | number> = { textAlign: 'right' };
  switch (columnId) {
    case 'fund_id':
      style.textAlign = 'left';
      break;
    case 'fund_name':
      style.textAlign = 'left';
      break;
  }
  return { style };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getClassNameOfColumns = (columnId: string | undefined) => {
  return {};
};

const getCellProps = (cell: Cell<IFundSummary>) => {
  const styles = getStyleOfColumns(cell.column.id);
  const classNames = getClassNameOfColumns(cell.column.id);
  return { ...styles, ...classNames };
};

const getHeaderProps = (column: Column<IFundSummary>) => {
  const styles = getStyleOfColumns(column.id);
  const classNames = getClassNameOfColumns(column.id);
  return { ...styles, ...classNames };
};

const MyFundsTable: FC<IMyFundaTableProps> = ({ columns, data, onRowClicked }) => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } =
    useTable<IFundSummary>(
      {
        columns: columns,
        data: data.fundsSummary,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSticky,
    );

  const isEmptyData = data?.fundsSummary?.length === 0;
  return (
    <Card elevation={3} style={{ padding: 0, paddingBottom: 2, overflow: 'hidden' }}>
      <Navbar
        style={{
          paddingLeft: 0,
        }}
      >
        <Navbar.Group align={Alignment.LEFT}>
          <span
            style={{
              display: 'inline-block',
              marginRight: '0.5rem',
              minWidth: '6px',
              height: '70%',
              backgroundImage: 'linear-gradient(to top, #4481eb 0%, #4481eb 100%)',
              borderTopRightRadius: 1000,
              borderBottomRightRadius: 1000,
            }}
          ></span>
          <Navbar.Heading>My Funds</Navbar.Heading>
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>
          {!isEmptyData && (
            <label htmlFor="search-table">
              <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
            </label>
          )}
        </Navbar.Group>
      </Navbar>

      {isEmptyData && (
        <Flex justifyContent="space-around" py={4}>
          <Box width={'60%'}>
            <Callout intent={Intent.DANGER}> We could not find data for the summary of funds.</Callout>
          </Box>
        </Flex>
      )}
      {!isEmptyData && (
        <SimpleBar className="table-wrap">
          <Styles>
            <table
              {...getTableProps()}
              className="bp3-html-table  bp3-interactive align-middle sticky"
              style={{ width: '100%' }}
            >
              <thead className="table-header">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id} className="tr">
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(getHeaderProps(column))} key={column.id} className="th">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row.id}
                      className="tr"
                      onClick={() => onRowClicked(row.original.fund_id)}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps(getCellProps(cell))} key={`${cell.column.id} ${cell.row.id}`}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Styles>
        </SimpleBar>
      )}
    </Card>
  );
};

const MyFundsTableContainer: FC = (): ReactElement => {
  const { data, isLoading, error } = useFundsSummary();
  const setSelectedFundId = useExposureStore((state) => state.setSelectedFundId);
  const history = useHistory();

  const onRowClicked = useCallback((fundId: number) => {
    setSelectedFundId(fundId);
    history.push('/exposure');
  }, []);

  const columns = useMemo<Column<IFundSummary>[]>(() => getMyFundsTableConfig(data?.headerConfig), [data]);

  return (
    <>
      {isLoading && !error && <TableLoader></TableLoader>}
      {error && <Callout intent={Intent.DANGER}>{error.errorMessage}</Callout>}
      {data && data?.fundsSummary && (
        <MyFundsTable data={data} columns={columns} onRowClicked={onRowClicked}></MyFundsTable>
      )}
    </>
  );
};

const Styles = styled.div`
  .table {
    &.sticky {
      overflow: scroll;
      .table-header,
      .table-footer {
        position: sticky;
        z-index: 1;
        width: 100%;
        font-weight: normal;
        font-size: 15px;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // background-color: #fff;
      }

      [data-sticky-last-left-td] {
        &.th {
          background-color: transparent;
        }
      }
    }
    &.sticky tr:hover td {
      // background-color: ${Colors.LIGHT_GRAY4};
    }
  }

  table tbody tr th {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  table tbody tr td {
    vertical-align: middle;
  }

  table tbody tr:hover {
    // background-color: ${Colors.LIGHT_GRAY4} !important;
  }
  table tbody tr {
    cursor: pointer;
  }
`;

export default memo(MyFundsTableContainer);

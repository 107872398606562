import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetOpsDashboardResponse } from '@shared/exchange/getOpsDashboard';

export const GET_OPS_DASHBOARD = 'get-ops-dashboard';
export default function useGetOpsDashboard(
  intervalDays: number,
): UseQueryResult<IGetOpsDashboardResponse, IErrorResponse> {
  const axios = useAxios();
  console.log(intervalDays);
  return useQuery<IGetOpsDashboardResponse, IErrorResponse>(
    [GET_OPS_DASHBOARD, { intervalDays }],
    async () => {
      return axios
        ?.get(`get-ops-dashboard?intervalDays=${intervalDays}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, keepPreviousData: true },
  );
}

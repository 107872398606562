import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import { IGetFundsResponse } from '@shared/exchange/getFunds';
import IErrorResponse from '@shared/interfaces/IErrorReponse';

export default function useFunds(includeInactive = false): UseQueryResult<IGetFundsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetFundsResponse, IErrorResponse>(
    ['admin-funds', { includeInactive }],
    async () => {
      const routePath = 'get-funds';
      const searchParams = new URLSearchParams();
      if (includeInactive) {
        searchParams.append('includeInactive', 'true');
      } else {
        searchParams.append('includeInactive', 'false');
      }
      return axios
        ?.get(`${routePath}?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

import dayjs from 'dayjs';

interface IInvestmentNotificationMins {
  value: number;
}
const getInvestmentNotificationTimeValue = ({ value }: IInvestmentNotificationMins): string => {
  if (!value || Number.isNaN(value)) return '';
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  const timeValue = dayjs().set('hour', hours).set('minutes', minutes);
  const formattedTimeValue = timeValue.format('h:mm A');
  console.log('Formatted Time Value: ' + formattedTimeValue);
  return formattedTimeValue;
};

export default getInvestmentNotificationTimeValue;

import { Button, ButtonGroup, Callout, Card, Colors, Intent, NonIdealState } from '@blueprintjs/core';
import useGetOpsDashboard from '@common/hooks/useGetOpsDashboard';
import CustomCard from '@components/CustomCard';
import styled from '@emotion/styled';
import { ReactElement, useMemo, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import { ROLES_CONFIG } from '@shared/constants/Roles';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';

const OpsDashboard = (): ReactElement => {
  const [intervalDays, setIntervalDays] = useState<number>(7);
  const {
    data: opsDashboardData,
    isLoading: opsDashboardDataLoading,
    isFetching: opDashboardDataFetching,
  } = useGetOpsDashboard(intervalDays);
  const tradeStatusMap = useMemo(() => {
    return [
      {
        name: 'Pending Approval',
        displayName: 'Pending Approval',
        abbr: 'PA',
      },
      {
        name: 'Pending Trade',
        displayName: 'Pending Trade',
        abbr: 'PT',
      },
      {
        name: 'traded_yet_to_reconcile',
        displayName: 'Pending Reconciliation',
        abbr: 'PR',
      },
    ];
  }, []);

  const userTypesMap = useMemo(() => {
    return [
      {
        name: 'total_users',
        displayName: 'Total Users',
      },
    ]
      .concat(ROLES_CONFIG.map((rc) => ({ name: rc.role_name, displayName: rc.display_name })))
      .concat([
        {
          name: 'other_users',
          displayName: 'Other Users',
        },
      ]);
  }, []);

  return (
    <Styles>
      <Box p={2}>
        {opsDashboardDataLoading && <TableLoader></TableLoader>}
        {opsDashboardData && (
          <>
            <Flex>
              <Box m={2} width={[5 / 5]}>
                <CustomCard
                  heading={'Investments'}
                  body={
                    <>
                      {opDashboardDataFetching && <GlobalLoader></GlobalLoader>}
                      <Flex>
                        <Box mt={1} p={3} width={[1 / 3]}>
                          <Card
                            style={{
                              borderLeftWidth: '8px',
                              borderLeftStyle: 'solid',
                              borderLeftColor: Colors.COBALT5,
                            }}
                          >
                            <Box px={4} py={2}>
                              <Flex justifyContent={'space-between'}>
                                <h3 style={{ color: Colors.COBALT5 }} className="bp3-heading metricTitle">
                                  Funds
                                </h3>
                                <Box ml={4}>
                                  <h1
                                    className="bp3-heading"
                                    style={{ color: Colors.COBALT5, fontWeight: 'bold', fontSize: 50, margin: 0 }}
                                  >
                                    {opsDashboardData.investmentView.total_active_funds}
                                  </h1>
                                </Box>
                              </Flex>
                            </Box>
                          </Card>
                        </Box>
                        <Box mt={1} p={3} width={[1 / 3]}>
                          <Card
                            style={{ borderLeftWidth: '8px', borderLeftStyle: 'solid', borderLeftColor: Colors.GREEN5 }}
                          >
                            <Box px={4} py={2}>
                              <Flex justifyContent={'space-between'}>
                                <h3 style={{ color: Colors.GREEN5 }} className="bp3-heading metricTitle">
                                  Total Accounts
                                </h3>
                                <Box ml={4}>
                                  <h1
                                    style={{ color: Colors.GREEN5, fontWeight: 'bold', fontSize: 50, margin: 0 }}
                                    className="bp3-heading"
                                  >
                                    {opsDashboardData.investmentView.total_active_accounts}
                                  </h1>
                                </Box>
                              </Flex>
                            </Box>
                          </Card>
                        </Box>
                        <Box mt={1} p={3} width={[1 / 3]}>
                          <Card
                            style={{ borderLeftWidth: '8px', borderLeftStyle: 'solid', borderLeftColor: Colors.GOLD5 }}
                          >
                            <Box px={4} py={2}>
                              <Flex justifyContent={'space-between'}>
                                <p style={{ color: Colors.GOLD5 }} className="bp3-heading metricTitle">
                                  Private Accounts
                                </p>
                                <Box ml={4}>
                                  <h1
                                    style={{ color: Colors.GOLD5, fontWeight: 'bold', fontSize: 50, margin: 0 }}
                                    className="bp3-heading"
                                  >
                                    {opsDashboardData.investmentView.total_private_accounts}
                                  </h1>
                                </Box>
                              </Flex>
                            </Box>
                          </Card>
                        </Box>
                      </Flex>
                    </>
                  }
                ></CustomCard>
              </Box>
            </Flex>

            <Flex>
              <Box m={2} width={[1.8 / 5]}>
                <CustomCard
                  heading={'Trades'}
                  body={
                    <Flex p={2}>
                      <Box>
                        <table>
                          <thead>
                            <th></th>
                            {tradeStatusMap.map((tradeKey) => {
                              return (
                                <th key={tradeKey.abbr}>
                                  <small>{tradeKey.abbr}</small>
                                </th>
                              );
                            })}
                          </thead>
                          <tbody>
                            {opsDashboardData.tradeStatusView.map((tradeStatus) => {
                              return (
                                <tr key={tradeStatus.fund_name}>
                                  <td>
                                    <Box mr={2}>{tradeStatus.fund_name}</Box>
                                  </td>
                                  {tradeStatusMap.map((tradeKey) => {
                                    return (
                                      <td key={tradeKey.name}>
                                        <Callout intent={Intent.PRIMARY} icon={null}>
                                          {<h4 className="bp3-heading">{tradeStatus[tradeKey.name]}</h4>}
                                        </Callout>
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {opsDashboardData.tradeStatusView?.length === 0 && (
                          <Box mt={2}>
                            <NonIdealState
                              icon={'issue'}
                              title={<h5 className="bp3-heading"> No Cash Transactions Found.</h5>}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box ml={2} mt={3}>
                        <Card>
                          <table>
                            <tbody>
                              <tr>
                                <th>PA</th>
                                <td>Pending Approval</td>
                              </tr>
                              <tr>
                                <th>PT</th>
                                <td>Pending Trade</td>
                              </tr>
                              <tr>
                                <th>PR</th>
                                <td>Pending Reconciliation</td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                      </Box>
                    </Flex>
                  }
                ></CustomCard>
              </Box>

              <Box m={2} width={[3.2 / 5]}>
                <CustomCard
                  heading={'Cash Management'}
                  rightToolbar={
                    <Box>
                      <ButtonGroup>
                        {[7, 15, 30].map((interval) => (
                          <Button
                            key={interval}
                            onClick={() => setIntervalDays(interval)}
                            active={intervalDays === interval}
                          >
                            Next {interval} days
                          </Button>
                        ))}
                      </ButtonGroup>
                    </Box>
                  }
                  body={
                    <Flex p={2} flexDirection="column">
                      <table>
                        <thead>
                          <th></th>
                          {opsDashboardData?.cashTransactionTypes.map((cashTransactionRef) => {
                            return (
                              <th key={cashTransactionRef.id}>
                                <Box px={2}>{cashTransactionRef.transactionTypeName}</Box>
                              </th>
                            );
                          })}
                        </thead>
                        <tbody>
                          {opsDashboardData.cashManagementView.map((cashRecord) => {
                            return (
                              <tr key={cashRecord['id']}>
                                <td>
                                  <Box mr={2}>{cashRecord['fund_name']}</Box>
                                </td>
                                {opsDashboardData?.cashTransactionTypes.map((cashTransactionRef) => {
                                  return (
                                    <td key={cashTransactionRef.id}>
                                      <Callout intent={Intent.PRIMARY} icon={null}>
                                        <Flex flexDirection={'row-reverse'}>
                                          <h4 className="bp3-heading">
                                            {formatAmountRoundTwo({
                                              value: Number(cashRecord[cashTransactionRef.transactionTypeCode]),
                                            })}
                                          </h4>
                                        </Flex>
                                      </Callout>
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {opsDashboardData?.cashManagementView.length === 0 && (
                        <Box mt={2}>
                          <NonIdealState
                            icon={'issue'}
                            title={<h5 className="bp3-heading"> No Cash Transactions Found.</h5>}
                          />
                        </Box>
                      )}
                    </Flex>
                  }
                ></CustomCard>
              </Box>
            </Flex>

            <Box m={2}>
              <CustomCard
                heading={'Users'}
                body={
                  <Flex>
                    {opsDashboardData.userView.map((userRow) => {
                      return (
                        <>
                          {userTypesMap.map(
                            (userType) =>
                              userRow[userType.name] && (
                                <Box key={userType.displayName} mt={1} p={2} width={[1 / 5]}>
                                  <Card
                                    style={{
                                      borderLeftWidth: '8px',
                                      borderLeftStyle: 'solid',
                                      borderLeftColor: Colors.GRAY2,
                                    }}
                                  >
                                    <Box px={2} py={2}>
                                      <Flex justifyContent={'space-between'}>
                                        <p style={{ color: Colors.GRAY2 }} className="bp3-heading metricTitle-sm">
                                          {userType.displayName}
                                        </p>
                                        <Box>
                                          <h1
                                            style={{ color: Colors.GRAY2, fontWeight: 'bold', fontSize: 35, margin: 0 }}
                                            className="bp3-heading"
                                          >
                                            {userRow[userType.name]}
                                          </h1>
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </Card>
                                </Box>
                              ),
                          )}
                        </>
                      );
                    })}
                  </Flex>
                }
              ></CustomCard>
            </Box>
          </>
        )}
      </Box>
    </Styles>
  );
};

export default OpsDashboard;

const Styles = styled.div`
  .bp3-card {
    padding: 0.8rem;
  }
  .metricTitle {
    line-height: 2;
    font-weight: bold;
    font-size: 20px !important;
    margin: 0;
  }
  .metricTitle-sm {
    line-height: 3;
    font-weight: bold;
    font-size: 15px !important;
    margin: 0;
  }
`;

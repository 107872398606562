import TradeActionEnum from '@shared/constants/TradeAction';
import { FC, ReactElement, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
//import AddBlue from '@assets/img/add-blue.svg';
//import DeleteIcon from '@assets/img/delete-icon.svg';
import styled from '@emotion/styled';
import IAccount from '@shared/interfaces/IAccount';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import truncateTableString from '@common/utils/truncateTableString';
import TradeOrderCreationInfoPanel from './TradeOrderCreationInfoPanel';
import { ICreateTradeOrderRequest } from '@shared/exchange/createTradeOrder';
import useCreateTradeOrder from '@common/hooks/useCreateTradeOrder';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import { useQueryClient } from 'react-query';
import { GET_TRADES_KEY } from '@common/hooks/useGetTrades';
import ueGetSettlementDate from '@common/hooks/useGetSettlementDateOfTrade';
import {
  Alignment,
  Button,
  HTMLSelect,
  Intent,
  Navbar,
  //InputGroup,
  Callout,
  NonIdealState,
  Alert,
} from '@blueprintjs/core';
import customRegisterFormField from '@common/utils/customRegisterFormField';
import { Box, Flex } from 'reflexbox';
import { AppToaster } from '@components/Toasters';
import CurrencyInput from 'react-currency-input-field';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';

interface ITradeOrderCreationFormProps {
  accounts: IAccount[];
  cashAccounts: IAccount[];
  totalCash: number | undefined;
  selectedFundId: number;
}

type TradeItemInput = {
  amount: number;
  memo: string;
  tradeDate: Date;
  buyOrSell: TradeActionEnum;
  cashAccount: number;
  account: number;
};

type FormValues = {
  tradeItems: TradeItemInput[];
};

const TradeOrderCreationForm: FC<ITradeOrderCreationFormProps> = ({
  accounts,
  cashAccounts,
  totalCash,
  selectedFundId,
}): ReactElement => {
  const [indexes, setIndexes] = useState<number[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [netAssetAssetValues, setNetAssetAssetValues] = useState<Array<string | undefined>>([]);
  const [settlementDates, setSettlementDates] = useState<Array<Date | undefined>>([]);
  const [totalBuyAmount, setTotalBuyAmount] = useState<number>(0);
  const [totalSellAmount, setTotalSellAmount] = useState<number>(0);
  const [isProposeTradeModalOpen, setIsProposeTradeModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { register, handleSubmit, watch, setValue, getValues } = useForm({});
  const {
    mutateAsync: createTradeOrder,
    error: errorCreateTradeOrder,
    isLoading: isLoadingCreateTradeOrder,
  } = useCreateTradeOrder();
  const setActiveTab = useTradeOrderManagementStore((state) => state.setActiveTab);

  const [tradeItemCreationInProgress, setTradeItemCreationInProgress] = useState<boolean>(false);
  const { tradeOrdersFromRebalResults, setTradeOrdersFromRebalResults } = useTradeOrderManagementStore(
    ({ tradeOrdersFromRebalResults, setTradeOrdersFromRebalResults }) => {
      return { tradeOrdersFromRebalResults, setTradeOrdersFromRebalResults };
    },
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!accounts) return;
    const call = async () => {
      if (!tradeOrdersFromRebalResults) return;
      let accountsCount = 0;
      const updatedNetAssetvalues: Array<string | undefined> = [];
      const accountIdTradeDateMap: Array<{ accountId: number; tradeDate: string }> = [];
      const accountIdaccountIdentifierNumMap: Record<number, number> = {};

      //const updatedSettlementDates = [];
      setTradeItemCreationInProgress(true);
      tradeOrdersFromRebalResults.forEach((tradeOrderFromRebalResultRecord) => {
        const accountId = tradeOrderFromRebalResultRecord['accountId'];
        if (!!accountId) {
          const selectedAccount = accounts.find((account) => account.id === accountId);
          if (!selectedAccount || selectedAccount?.cashAccountInd || selectedAccount?.primaryCashAccountInd) return;
          const accountIdentifierNum = accountsCount;
          const fieldName = `tradeItems.${accountIdentifierNum}`;
          setValue(`${fieldName}.account`, accountId, {});
          updatedNetAssetvalues[accountIdentifierNum] = selectedAccount?.netAssetValue;

          const possibleTradeDate = getPossibleValidTradeDate(selectedAccount?.investNotificationPeriod);
          accountIdTradeDateMap.push({
            accountId: accountId,
            tradeDate: dayjs(possibleTradeDate).format('YYYY-MM-DD'),
          });
          setValue(`${fieldName}.tradeDate`, possibleTradeDate, {});

          if (!!tradeOrderFromRebalResultRecord?.buy) {
            setValue(`${fieldName}.amount`, Math.round(tradeOrderFromRebalResultRecord?.buy), {});
            setValue(`${fieldName}.buyOrSell`, TradeActionEnum.buy, {});
          } else if (!!tradeOrderFromRebalResultRecord?.sell) {
            setValue(`${fieldName}.amount`, Math.round(tradeOrderFromRebalResultRecord?.sell), {});
            setValue(`${fieldName}.buyOrSell`, TradeActionEnum.sell, {});
          }
          accountIdaccountIdentifierNumMap[accountId] = accountIdentifierNum;
          accountsCount++;
        }
      });

      const res = await getSettlementDate(accountIdTradeDateMap);
      const updatedSettlementDates = [];
      for (const settlementDateRecord of res.settlementDaysData) {
        const accountIdentifierNum = accountIdaccountIdentifierNumMap[settlementDateRecord.accountId];
        updatedSettlementDates[accountIdentifierNum] = settlementDateRecord.settlementDate;

        setInvalidTradeDateRows((invalidTradeDateRows) => {
          return { ...invalidTradeDateRows, [accountIdentifierNum]: settlementDateRecord.isValidTradeDate == false };
        });
        setInvalidAccountNotificationPeriodRows((invalidAccountNotificationPeriodRows) => {
          return {
            ...invalidAccountNotificationPeriodRows,
            [accountIdentifierNum]: settlementDateRecord.isValidAccountNotificationPeriod == false,
          };
        });
      }
      setNetAssetAssetValues(updatedNetAssetvalues);
      setIndexes(Array.from({ length: accountsCount }, (e, i) => i));
      setCounter(accountsCount);
      setSettlementDates(updatedSettlementDates);
      setTradeItemCreationInProgress(false);
    };
    call();
  }, [tradeOrdersFromRebalResults, accounts]);

  const validateErrors = (data: TradeItemInput[]) => {
    for (const tradeItem of data) {
      if (!tradeItem.amount || tradeItem.amount === 0) {
        throw 'Amount values should not be empty or 0';
      }
    }
  };

  const getData = (formData: FormValues) => {
    const tradeItems = formData.tradeItems;
    const data = [];
    for (const index of indexes) {
      data.push(tradeItems[index]);
    }
    return data;
  };

  const createRequestData = (data: TradeItemInput[]) => {
    const initialTotalCashAmount = totalCash ? totalCash : 0;
    const requestData: ICreateTradeOrderRequest = {
      fundId: selectedFundId,
      totalBuyAmt: totalBuyAmount,
      totalSellAmt: totalSellAmount,
      tradeMemoText: '',
      initialTotalCashAmount: initialTotalCashAmount,
      endingTotalCashAmount: Number(initialTotalCashAmount) + totalSellAmount - totalBuyAmount,
      tradeItems: data.map((tradeItem) => {
        return {
          amount: tradeItem.amount,
          accountId: tradeItem.account,
          buy: tradeItem.buyOrSell == TradeActionEnum.buy,
          sell: tradeItem.buyOrSell == TradeActionEnum.sell,
          tradeDate: dayjs(tradeItem.tradeDate).format('YYYY-MM-DD'),
          tradeItemMemoText: tradeItem.memo,
          settleDate: dayjs(tradeItem.tradeDate).format('YYYY-MM-DD'),
          //settleDate: dayjs(findSettlementDate(tradeItem.tradeDate, tradeItem.account)).format('YYYY-MM-DD'),
          cashAccountId: tradeItem.cashAccount,
        };
      }),
    };
    return requestData;
  };

  const onSubmit = async (formData: FormValues) => {
    AppToaster.clear();
    setError(null);
    try {
      const data = getData(formData);
      validateErrors(data);
      const requestData: ICreateTradeOrderRequest = createRequestData(data);
      try {
        await createTradeOrder(requestData);
        clearTradeItems();
        setActiveTab('TradeOrderProcessing');
        queryClient.invalidateQueries(GET_TRADES_KEY);
      } catch (e) {}
      setIsProposeTradeModalOpen(false);
    } catch (e: unknown) {
      setError(e as string);
      setIsProposeTradeModalOpen(false);
    }
  };

  const addTradeItem = async () => {
    setTradeItemCreationInProgress(true);
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    const updatedNetAssetvalues = [...netAssetAssetValues];
    //Set initial value for netAssetValues
    const accountId = accounts[0].id;
    updatedNetAssetvalues[counter] = accounts[0]?.netAssetValue;
    setNetAssetAssetValues(updatedNetAssetvalues);
    //Set initial value for settlementDates
    const updatedSettlementDates = [...settlementDates];
    const possibleTradeDate = getPossibleValidTradeDate(accounts[0].investNotificationPeriod);
    //Update form with date for trade iem
    const fieldName = `tradeItems.${counter}`;
    setValue(`${fieldName}.tradeDate`, possibleTradeDate, {});
    //Find and update settlementdate for this tradeitem
    updatedSettlementDates[counter] = await findSettlementDate(possibleTradeDate, accountId, counter);
    setSettlementDates(updatedSettlementDates);
    //Update Counter
    setCounter((prevCounter) => prevCounter + 1);
    setTradeItemCreationInProgress(false);
  };

  const removeTradeItem = (index: number) => () => {
    setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
  };

  const clearTradeItems = () => {
    setIndexes([]);
    setCounter(0);
    setError(null);
    setTradeOrdersFromRebalResults(undefined);
  };

  const calculateBuysAndSells = (tradeItems: { amount: number; buyOrSell: TradeActionEnum }[]) => {
    let totalBuyAmount = 0;
    let totalSellAmount = 0;

    for (const idx of indexes) {
      const tradeItem = tradeItems[idx];
      const amount = tradeItem.amount;
      const buyOrSell = tradeItem.buyOrSell;
      if (buyOrSell === TradeActionEnum.buy) totalBuyAmount += Number(amount);
      if (buyOrSell === TradeActionEnum.sell) totalSellAmount += Number(amount);

      setTotalBuyAmount(totalBuyAmount);
      setTotalSellAmount(totalSellAmount);
    }
  };

  useEffect(() => {
    const tradeItems = getValues()?.tradeItems;
    if (!tradeItems) return;
    calculateBuysAndSells(tradeItems);
  }, [indexes]);

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (!name) return;
      const nameParts = name?.split('.');
      const index = Number(nameParts[1]);
      const field = nameParts[2];
      if (!field) return;

      if (field === 'account') {
        const updatedNetAssetvalues = [...netAssetAssetValues];
        const accountId = value.tradeItems[index].account;
        updatedNetAssetvalues[index] = accounts?.find(
          (account) => Number(account.id) == Number(accountId),
        )?.netAssetValue;
        setNetAssetAssetValues(updatedNetAssetvalues);
        const selectedAccount = accounts.find((account) => account.id === accountId);
        const updatedSettlementDates = [...settlementDates];
        const tradeDate = getPossibleValidTradeDate(selectedAccount?.investNotificationPeriod);
        updatedSettlementDates[index] = await findSettlementDate(tradeDate, accountId, index);
        setSettlementDates(updatedSettlementDates);
      }
      if (field === 'tradeDate') {
        const updatedSettlementDates = [...settlementDates];
        const tradeDate = value.tradeItems[index].tradeDate;
        const accountId = value.tradeItems[index].account;
        updatedSettlementDates[index] = await findSettlementDate(tradeDate, accountId, index);
        setSettlementDates(updatedSettlementDates);
      }
      if (field === 'amount' || field === 'buyOrSell') {
        calculateBuysAndSells(value.tradeItems);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, netAssetAssetValues, settlementDates, totalBuyAmount, totalSellAmount, indexes]);

  const [loadingRows, setLoadingRows] = useState<Record<number, boolean>>({});
  const [invalidTradeDateRows, setInvalidTradeDateRows] = useState<Record<number, boolean>>({});
  const [invalidAccountNotificationPeriodRows, setInvalidAccountNotificationPeriodRows] = useState<
    Record<number, boolean>
  >({});

  const { mutateAsync: getSettlementDate, isLoading: isSettlementDateLoading } = ueGetSettlementDate();

  const findSettlementDate = useCallback(
    async (date: Date, accountId: number, index: number) => {
      setLoadingRows({ ...loadingRows, [index]: true });
      setInvalidTradeDateRows({ ...invalidTradeDateRows, [index]: false });
      setInvalidAccountNotificationPeriodRows({
        ...invalidAccountNotificationPeriodRows,
        [index]: false,
      });

      const res = await getSettlementDate([{ accountId: accountId, tradeDate: dayjs(date).format('YYYY-MM-DD') }]);
      const settlementDateRes = res.settlementDaysData[0];

      setLoadingRows({ ...loadingRows, [index]: false });
      setInvalidTradeDateRows({ ...invalidTradeDateRows, [index]: settlementDateRes.isValidTradeDate == false });
      setInvalidAccountNotificationPeriodRows({
        ...invalidAccountNotificationPeriodRows,
        [index]: settlementDateRes.isValidAccountNotificationPeriod == false,
      });
      return dayjs(settlementDateRes.settlementDate).toDate();
      //let settlementInDays = 0;
      //const account = getAccount(accountId);
      //if (account?.settlementInDays) settlementInDays = Number(account.settlementInDays);
      //return dayjs(date).add(settlementInDays, 'days').toDate();
    },
    [accounts, loadingRows, invalidTradeDateRows, invalidAccountNotificationPeriodRows],
  );

  useEffect(() => {
    if (error) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: error,
        icon: 'warning-sign',
      });
    }
  }, [error]);

  useEffect(() => {
    if (errorCreateTradeOrder) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: errorCreateTradeOrder?.errorMessage,
        icon: 'warning-sign',
      });
    }
  }, [errorCreateTradeOrder]);

  return (
    <div>
      <Navbar>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button
            loading={tradeItemCreationInProgress}
            icon={'plus'}
            minimal
            outlined
            intent={Intent.PRIMARY}
            onClick={() => addTradeItem()}
          >
            Add Trade
          </Button>
        </Navbar.Group>
      </Navbar>
      <form onSubmit={(e) => e.preventDefault()}>
        <Styles>
          <table className="bp3-html-table bp3-html-table-bordered">
            <thead>
              <tr>
                <th className="account">Account</th>
                <th className="text-end">Current NAV</th>
                <th>Amount</th>
                <th>Buy/Sell</th>
                <th>Cash Account</th>
                <th>Trade Date</th>
                <th>Settlement</th>
                <th>Memo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {indexes.map((index) => {
                const fieldName = `tradeItems.${index}`;
                const memo = watch(`${fieldName}.memo`);
                const accountId = watch(`${fieldName}.account`);
                const account = accounts.find((account) => account.id == accountId);
                const rowLoading = loadingRows[index];
                const invalidRow = invalidTradeDateRows[index] || invalidAccountNotificationPeriodRows[index];
                return (
                  <>
                    <tr
                      key={fieldName}
                      className={`${rowLoading && 'loading'} ${invalidRow && 'bottomBorderLess alert-warning'}`}
                    >
                      <td className="">
                        <HTMLSelect
                          {...customRegisterFormField(register, `${fieldName}.account`, { valueAsNumber: true })}
                        >
                          {accounts.map((account) => {
                            return (
                              <option key={account.id} value={account.id}>
                                {account.accountName}
                              </option>
                            );
                          })}
                        </HTMLSelect>
                      </td>

                      <td className="text-end align-middle">
                        {formatAmountRoundTwo({ value: Number(netAssetAssetValues[index]) })}
                      </td>

                      <td className="">
                        <CurrencyInput
                          readOnly={rowLoading}
                          min={0}
                          defaultValue={0}
                          className={'bp3-input bp3-fill'}
                          onValueChange={(value) => setValue(`${fieldName}.amount`, value)}
                          value={watch(`${fieldName}.amount`)}
                          {...customRegisterFormField(register, `${fieldName}.amount`)}
                        />
                        {/*

                        <InputGroup
                          readOnly={rowLoading}
                          type="number"
                          defaultValue={0}
                          min={0}
                          className="amountInput"
                          {...customRegisterFormField(register, `${fieldName}.amount`, { valueAsNumber: true })}
                        ></InputGroup>
                        */}
                      </td>

                      <td className="buyOrSell">
                        <HTMLSelect
                          {...customRegisterFormField(register, `${fieldName}.buyOrSell`)}
                          className="buyOrSellSelect"
                        >
                          <option key={TradeActionEnum.buy} value={TradeActionEnum.buy} defaultChecked>
                            Buy
                          </option>
                          <option key={TradeActionEnum.sell} value={TradeActionEnum.sell}>
                            Sell
                          </option>
                        </HTMLSelect>
                      </td>

                      <td className="cashAccount">
                        <HTMLSelect
                          {...customRegisterFormField(register, `${fieldName}.cashAccount`, { valueAsNumber: true })}
                          className="cashAccount"
                        >
                          {cashAccounts.map((cashAccount) => {
                            return (
                              <option key={cashAccount.id} value={cashAccount.id}>
                                {cashAccount.accountName}
                              </option>
                            );
                          })}
                        </HTMLSelect>
                      </td>

                      <td>
                        <DatePicker
                          className={'bp3-input'}
                          popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                          }}
                          minDate={new Date()}
                          selected={
                            watch(`${fieldName}.tradeDate`) ||
                            getPossibleValidTradeDate(account?.investNotificationPeriod)
                          }
                          onChange={(date: Date) => {
                            setValue(`${fieldName}.tradeDate`, date, { shouldDirty: true });
                          }}
                          filterDate={filterDate}
                        />
                      </td>

                      <td className="align-middle">
                        {rowLoading ? (
                          <div className="spinner-border text-primary spinner-border-sm"></div>
                        ) : (
                          dayjs(settlementDates[index]).format('MM/DD/YYYY')
                        )}
                      </td>

                      <td>
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          rootClose={true}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header as="h3">Enter Memo</Popover.Header>
                              <Popover.Body>
                                <textarea className="form-control" {...register(`${fieldName}.memo`)}></textarea>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <span>
                            <span>{truncateTableString(memo)}</span>
                            <Button intent={Intent.PRIMARY} minimal rightIcon={'edit'}></Button>
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <Button
                          intent={Intent.DANGER}
                          outlined
                          minimal
                          icon="trash"
                          onClick={removeTradeItem(index)}
                        ></Button>
                      </td>
                    </tr>
                    {invalidRow && (
                      <tr>
                        <td
                          className="noTopBorder"
                          colSpan={100}
                          style={{ boxShadow: 'none', paddingTop: '0px !important' }}
                        >
                          {invalidTradeDateRows[index] && (
                            <Callout intent={Intent.WARNING}>
                              <strong>Warning:</strong> Market is closed on the selected trade date.
                            </Callout>
                          )}
                          {invalidAccountNotificationPeriodRows[index] && (
                            <Callout intent={Intent.WARNING}>
                              <strong>Warning:</strong> Trade date does not meet Notification Period requirement.
                            </Callout>
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>

          {counter === 0 && !isSettlementDateLoading && (
            <Box p={2}>
              <NonIdealState icon={'info-sign'}>No trades have been created. Please add trade.</NonIdealState>
            </Box>
          )}

          <TradeOrderCreationInfoPanel
            totalCash={Number(totalCash)}
            totalBuyAmount={totalBuyAmount}
            totalSellAmount={totalSellAmount}
          ></TradeOrderCreationInfoPanel>

          <Flex flexDirection="row-reverse">
            <Box m={3}>
              <Button intent={Intent.NONE} minimal={true} onClick={clearTradeItems}>
                Clear All
              </Button>
              <Button
                intent={Intent.PRIMARY}
                //type="submit"
                onClick={() => {
                  setIsProposeTradeModalOpen(true);
                }}
                disabled={counter === 0}
              >
                Propose All
              </Button>
            </Box>
          </Flex>
        </Styles>
      </form>
      <Alert
        loading={isLoadingCreateTradeOrder}
        intent={Intent.PRIMARY}
        icon={'add'}
        isOpen={!!isProposeTradeModalOpen}
        onCancel={() => {
          setIsProposeTradeModalOpen(false);
        }}
        confirmButtonText={'Yes, Propose all trades'}
        cancelButtonText={'Cancel'}
        canOutsideClickCancel={true}
        onConfirm={handleSubmit(onSubmit)}
      >
        <p>Do you want to propose all the trade entries ?</p>
      </Alert>
    </div>
  );
};

export default TradeOrderCreationForm;

const getPossibleValidTradeDate = (investNotificationPeriod: number | undefined): Date => {
  if (investNotificationPeriod == undefined) return new Date();
  let possibleTradeDate = dayjs().add(investNotificationPeriod + 1, 'day');
  const day = possibleTradeDate.format('ddd').toLowerCase();
  console.log('Day', day);
  if (day === 'sun') possibleTradeDate = possibleTradeDate.add(1, 'day');
  else if (day === 'sat') possibleTradeDate = possibleTradeDate.add(2, 'day');
  return possibleTradeDate.toDate();
};

function filterDate(current: Date) {
  // Can not select Sundays and predefined days
  return dayjs(current).day() !== 0 && dayjs(current).day() !== 6;
}

const Styles = styled.div`
  table {
    width: 100%;
    tr {
      td,
      th {
        text-align: left;
      }
      .amountInput {
        width: 10rem !important;
      }
      .buyOrSellSelect {
        width: 4rem !important;
      }
    }
  }
`;

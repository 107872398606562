import { Colors, Dialog, Icon, NonIdealState, Position, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import useFunds from '@common/hooks/useFunds';
import useGetCashTransactionsAudit from '@common/hooks/useGetCashTransactionAudit';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';

import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import UserAvatar from '@components/UserAvatar';
import styled from '@emotion/styled';
import ICashTransaction from '@shared/interfaces/ICashTransaction';
import useCashManagementStore from '@stores/useCashManagementStore';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { FC, ReactElement } from 'react-bootstrap/node_modules/@types/react';
import { Box, Flex } from 'reflexbox';

interface ICashTransactionAuditProps {
  cashRecord: ICashTransaction;
  isOpen: boolean;
  close: () => void;
}
const CashTransactionAudit: FC<ICashTransactionAuditProps> = (props): ReactElement => {
  const {
    data: cashTransactionAuditData,
    isLoading: isViewCashAuditLoading,
    isFetching: isViewCashAuditFetching,
  } = useGetCashTransactionsAudit(props.cashRecord?.id);
  console.log(cashTransactionAuditData);

  const { isOpen, close, cashRecord } = props;
  const {
    id,
    fromAccount,
    toAccount,
    transactionAmt,
    transactionType,
    fromAccountIsExternalInd,
    toAccountIsExternalInd,
    tradeItemOfCashTransaction,
  } = cashRecord;
  console.log('Cash Transaction Id: ' + id);
  console.log('Trade Item for Cash Transaction: ' + ('tradeItemOfCashTransaction' in cashRecord ? 'Yes' : 'No'));

  const { selectedFundId, setSelectedFundId } = useCashManagementStore();
  const { data: fundData } = useFunds();

  const selectedFundData = fundData?.funds.filter((_) => _.id === selectedFundId);
  const fundName = selectedFundData && selectedFundData[0].fundName;

  // Get Badge Color For Cash Transaction Status
  const getBadgeColor = (type: string) => {
    let color = '#6c757d';
    switch (type.toLowerCase()) {
      case 'pending approval':
        color = '#f5b540';
        break;
      case 'approved':
        color = '#03d618';
        break;
      case 'rejected':
        color = '#f23737';
        break;
      case 'pending trade':
        color = '#ff6b00';
        break;
      case 'traded':
        color = '#4b85cd';
        break;
      default:
        color = '#6c757d';
    }
    return color;
  };

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (
      !cashTransactionAuditData ||
      !cashTransactionAuditData?.cashTransactionsAudit ||
      cashTransactionAuditData?.cashTransactionsAudit.length === 0
    )
      return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
  }, [fundData, cashTransactionAuditData]);

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Dialog
        canOutsideClickClose={false}
        title={`Cash Transaction Audit`}
        icon="history"
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
      >
        <Box pl={2}>
          {isViewCashAuditFetching && <GlobalLoader></GlobalLoader>}
          {isViewCashAuditLoading && <TableLoader></TableLoader>}

          <div>
            {!!cashTransactionAuditData && (
              <Styles>
                <Box style={{ background: Colors.WHITE }}>
                  <table width="100%" className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered">
                    <tbody>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Fund</Box>
                        </td>
                        <td>
                          <Box pl={2}>{fundName}</Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Cash Transaction ID</Box>
                        </td>
                        <td>
                          <Box p={2}>{id}</Box>
                        </td>
                      </tr>
                      <tr>
                        {cashRecord &&
                          cashRecord.transactionType.transactionTypeName === 'Trade' &&
                          tradeItemOfCashTransaction && (
                            <>
                              <td className="keyName">
                                <Box p={2}>Buy/Sell</Box>
                              </td>
                              <td>
                                <Box p={2}>
                                  {tradeItemOfCashTransaction?.buyInd && <span>Buy</span>}
                                  {tradeItemOfCashTransaction?.sellInd && <span>Sell</span>}
                                </Box>
                              </td>
                            </>
                          )}
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>From Account</Box>
                        </td>
                        <td>
                          <Box p={2}>
                            {fromAccount?.accountName}{' '}
                            <span>
                              {fromAccountIsExternalInd && (
                                <Tooltip2
                                  content="External Account"
                                  position={Position.RIGHT}
                                  openOnTargetFocus={false}
                                >
                                  <Tag>E</Tag>
                                </Tooltip2>
                              )}
                            </span>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>To Account</Box>
                        </td>
                        <td>
                          <Box p={2}>
                            {toAccount?.accountName}{' '}
                            <span>
                              {toAccountIsExternalInd && (
                                <Tooltip2
                                  content="External Account"
                                  position={Position.RIGHT}
                                  openOnTargetFocus={false}
                                >
                                  <Tag>E</Tag>
                                </Tooltip2>
                              )}
                            </span>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Amount</Box>
                        </td>
                        <td>
                          <Box p={2}>{formatAmountRoundTwo({ value: Number(transactionAmt) })}</Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Type</Box>
                        </td>
                        <td>
                          <Box p={2}>{transactionType.transactionTypeName}</Box>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
                <div className="timeline">
                  {cashTransactionAuditData?.cashTransactionsAudit?.map((anAudit, i) => (
                    <div key={anAudit.id} className={`container right ${i == 0 ? 'active' : ''}`}>
                      <div className="content">
                        <table className="table" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>Status</td>
                              <td>
                                <Tag
                                  minimal={true}
                                  round={true}
                                  icon={
                                    <Icon
                                      icon="full-circle"
                                      color={getBadgeColor(anAudit?.transactionStatus?.transactionStatusName)}
                                      size={8}
                                    ></Icon>
                                  }
                                  style={{
                                    borderWidth: 2,
                                    borderStyle: 'solid',
                                    borderColor: getBadgeColor(anAudit?.transactionStatus?.transactionStatusName),
                                    background: 'transparent',
                                    fontSize: 14,
                                  }}
                                >
                                  <span>{anAudit.transactionStatus.transactionStatusName}</span>
                                </Tag>
                              </td>
                            </tr>
                            <tr>
                              <td>Updated By</td>
                              <td>
                                <Flex alignItems={'center'}>
                                  <Box>
                                    <UserAvatar user={anAudit.actionUserId}></UserAvatar>
                                  </Box>
                                  <Box ml={2}>
                                    <div>{anAudit.actionUserId.firstName + ' ' + anAudit.actionUserId.lastName}</div>
                                  </Box>
                                </Flex>
                              </td>
                            </tr>
                            <tr>
                              <td> Updated Date</td>
                              <td>
                                <span>{dayjs(anAudit.createdDt).format('MM/DD/YYYY hh:mm:ss A')}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </Styles>
            )}
          </div>
          {(!cashTransactionAuditData || cashTransactionAuditData?.cashTransactionsAudit.length === 0) &&
            cashTransactionAuditData?.cashTransactionsAudit.length === 0 && (
              <NonIdealState
                icon={'issue'}
                title={<h5 className="bp3-heading">No Audit present for the given cash transaction id</h5>}
              />
            )}
        </Box>
      </Dialog>
    </>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY5} !important;
  }
  .table tbody tr td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

export default CashTransactionAudit;

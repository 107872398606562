import { useExternalAxios } from '@common/providers/AxiosExternalProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

interface IUploadResponse {
  id: number;
}

interface IUploadRequest {
  doc: FormData;
  uploadUrl: string;
  method?: 'PUT' | 'POST';
}

export default function useUpload(): UseMutationResult<
  IUploadResponse,
  IErrorResponse,
  IUploadRequest,
  IUploadResponse
> {
  const axios = useExternalAxios();
  return useMutation<IUploadResponse, IErrorResponse, IUploadRequest, IUploadResponse>(
    async ({ doc, uploadUrl, method }: IUploadRequest) => {
      let callApi = axios?.post;
      if (method === 'PUT') callApi = axios?.put;
      if (!callApi) return;
      return callApi(uploadUrl, doc)
        .then((res) => res.data.data)
        .catch((e) => {
          console.log(e);
          throw e.response.data;
        });
    },
  );
}

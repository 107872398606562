import { FC, forwardRef, HTMLProps } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { Button } from '@blueprintjs/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ButtonProps = HTMLProps<any>;

interface IExposureDatePickerProps {
  isLoading: boolean;
  filterDate: string | undefined;
  setFilterDate: (date: Date) => void;
  date: Date | undefined;
}

const ExposureDatePicker: FC<IExposureDatePickerProps> = ({ isLoading, filterDate, setFilterDate, date }) => {
  const DateFilterComponent = forwardRef<Button, ButtonProps>(({ value, onClick }, ref) => (
    <Button
      icon={'calendar'}
      large={false}
      minimal={true}
      outlined={true}
      text={value}
      rightIcon="chevron-down"
      ref={ref}
      onClick={onClick}
    />
  ));

  return (
    <DatePicker
      disabled={isLoading}
      selected={dayjs(date || filterDate).toDate()}
      onChange={(date: Date) => {
        setFilterDate(date);
      }}
      customInput={<DateFilterComponent />}
    />
  );
};

export default ExposureDatePicker;

/*
  <div className="fund-drop ms-4">
      <p>
        <i className="bi bi-calendar2 fs-6"></i>
      </p>
      <div className="dropdown" style={{ zIndex: 999 }}>
        <DatePicker
          disabled={isLoading}
          selected={dayjs(date || filterDate).toDate()}
          onChange={(date: Date) => {
            setFilterDate(date);
          }}
          customInput={<DateFilterComponent />}
        />
      </div>
    </div>

    */

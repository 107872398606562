import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import IGetWhatIfRebalSettingsResponse from '@shared/exchange/getWhatIfRebalSettings/IGetWhatIfRebalSettingsResponse';

export default function useGetWhatIfRebalSettings(
  fundId: number | undefined,
): UseQueryResult<IGetWhatIfRebalSettingsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetWhatIfRebalSettingsResponse, IErrorResponse>(
    ['get-what-if-rebal-settings', { fundId }],
    async () => {
      const searchParams = new URLSearchParams();
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      return axios
        ?.get(`get-what-if-rebal-settings?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, enabled: !!fundId, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}

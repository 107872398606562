import { IUpdateCashTransactionEntryRequest } from '@shared/exchange/updateCashTransactionEntry';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface ICreateCashEntryResponse {
  id: number;
}

export default function useUpdateCashTransaction(): UseMutationResult<
  ICreateCashEntryResponse,
  AxiosError,
  IUpdateCashTransactionEntryRequest,
  ICreateCashEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateCashEntryResponse,
    AxiosError,
    IUpdateCashTransactionEntryRequest,
    ICreateCashEntryResponse
  >(async (cashUpdateEntry: IUpdateCashTransactionEntryRequest) => {
    const routePath = 'update-cash-transaction-entry';
    return axios?.post(routePath, cashUpdateEntry).then((res) => res.data.data);
  });
}

import IGetCreateTradeMetadataResponse from '@shared/exchange/getCreateTradeMetadata/IGetCreateTradeMetadataResponse';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useCreateTradeMetadata(
  fundId: number | undefined,
): UseQueryResult<IGetCreateTradeMetadataResponse, Error> {
  const axios = useAxios();
  return useQuery<IGetCreateTradeMetadataResponse, Error>(
    ['create-trade-metadata', { fundId }],
    async () => {
      if (!fundId) return null;
      return axios?.get(`get-create-trade-metadata?fundId=${fundId}`).then((res) => res.data.data);
    },
    { staleTime: 30 * 60 * 1000 },
  );
}

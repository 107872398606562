import { matchSorter } from 'match-sorter';
import { Row } from 'react-table';

function fuzzyTextFilterTableFn(rows: Row[], id: string | number, filterValue: string): Row[] {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterTableFn.autoRemove = (val: string) => !val;

export default fuzzyTextFilterTableFn;

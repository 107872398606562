import { Alert, Intent } from '@blueprintjs/core';
import useDeactivateExternalTransferAccount from '@common/hooks/useDeactivateExternalTransferAccount';
import useExternalTransferAccounts, { GET_EXT_ACCOUNTS_KEY } from '@common/hooks/useExternalTransferAccounts';
import { IDeleteExternalTransferAccountRequest } from '@shared/exchange/deleteExternalTransferAccount';
import { IGetExternalTransferAccountsResponse } from '@shared/exchange/getExternalTransferAccounts';
import IExternalTransferAccount from '@shared/interfaces/IExternalTransferAccount';
import useAdministrationStore from '@stores/useAdministrationStore';
import { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';

const ConfirmDeactivateExternalAccountModal = (): ReactElement => {
  const { externalAccountToDeactivate, setExternalAccountToDeactivate } = useAdministrationStore(
    ({ externalAccountToDeactivate, setExternalAccountToDeactivate }) => {
      return {
        externalAccountToDeactivate,
        setExternalAccountToDeactivate,
      };
    },
  );

  const { refetch: refetchExternalAccounts } = useExternalTransferAccounts();
  const queryClient = useQueryClient();

  const { mutateAsync: deactivateExternalAccount, isLoading } = useDeactivateExternalTransferAccount();

  const handleDeactivateExternalAccount = useCallback(async () => {
    if (externalAccountToDeactivate === undefined) return;
    const deleteData: IDeleteExternalTransferAccountRequest = {
      id: externalAccountToDeactivate.id,
      isDelete: externalAccountToDeactivate.activeInd ? true : false,
    };
    try {
      queryClient.cancelQueries(GET_EXT_ACCOUNTS_KEY);
      const response = await deactivateExternalAccount(deleteData);
      setExternalAccountToDeactivate(undefined);

      /*** Optimistically Update the record ***/
      const previousExtAccountsData: IExternalTransferAccount[] =
        queryClient.getQueryData<IGetExternalTransferAccountsResponse>(GET_EXT_ACCOUNTS_KEY)
          ?.externalTransferAccounts || [];
      const updatedExtAccountsData = previousExtAccountsData.map((extAccount: IExternalTransferAccount) => {
        if (extAccount.id !== response.deletedExternalTransferAccount?.id) return extAccount;
        else {
          const extAccountUpdated = { ...extAccount, active_ind: externalAccountToDeactivate.activeInd ? 0 : 1 };
          return extAccountUpdated;
        }
      });
      queryClient.setQueryData<IGetExternalTransferAccountsResponse>(GET_EXT_ACCOUNTS_KEY, {
        externalTransferAccounts: updatedExtAccountsData,
      });
      /***********/

      //Refetch to sync with backend
      refetchExternalAccounts();
    } catch (e) {
      setExternalAccountToDeactivate(undefined);
    }
  }, [externalAccountToDeactivate]);

  const handleCancelDeactivateExternalAccount = useCallback(async () => {
    setExternalAccountToDeactivate(undefined);
  }, []);

  const action = externalAccountToDeactivate?.activeInd ? 'Deactivate' : 'Activate';
  return (
    <>
      <Alert
        isOpen={!!externalAccountToDeactivate}
        onConfirm={handleDeactivateExternalAccount}
        onCancel={handleCancelDeactivateExternalAccount}
        icon={action == 'Deactivate' ? 'disable' : 'updated'}
        intent={action == 'Deactivate' ? Intent.DANGER : Intent.PRIMARY}
        cancelButtonText={!isLoading ? 'Cancel' : undefined}
        confirmButtonText={`Yes, ${action.toLowerCase()}.`}
        loading={isLoading}
      >
        <div>
          {!isLoading && <>{`Are you sure you want to ${action.toLowerCase()} this account?`}</>}
          {isLoading && (
            <>
              <p>{`${action.slice(0, -1)}ing this account...`}</p>
            </>
          )}
        </div>
      </Alert>
    </>
  );
};

export default ConfirmDeactivateExternalAccountModal;

import getInitialsFromName from '@common/utils/getInitialsFromName';
import styled from '@emotion/styled';
import IUser from '@shared/interfaces/IUser';
import { FC, ReactElement } from 'react';

const Styles = styled.span`
  display: inline-block;
  line-height: 1.5rem;
  height: 2rem;
  width: 2rem;
  color: #4b85cd;
  background-color: rgba(75, 133, 205, 0.15);
  text-align: center;
  vertical-align: middle;
  padding: 0.3rem;
  border-radius: 1000px;
`;
const UserAvatar: FC<{ user: IUser }> = ({ user }): ReactElement => {
  return <Styles>{getInitialsFromName(user.firstName + ' ' + user.lastName)}</Styles>;
};

export default UserAvatar;

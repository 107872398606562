import { useAxios } from '@common/providers/AxiosProvider';
import { IDeleteUserOrgFundRequest } from '@shared/exchange/deleteUserOrgFund';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import IUserOrgFund from '@shared/interfaces/IUserOrgFund';
import { useMutation, UseMutationResult } from 'react-query';

interface IDeleteUserOrgFundReponse {
  data: IUserOrgFund;
}

export default function useDeleteUserOrgFund(): UseMutationResult<
  IDeleteUserOrgFundReponse,
  IErrorResponse,
  IDeleteUserOrgFundRequest,
  IDeleteUserOrgFundReponse
> {
  const axios = useAxios();
  return useMutation<IDeleteUserOrgFundReponse, IErrorResponse, IDeleteUserOrgFundRequest, IDeleteUserOrgFundReponse>(
    async (data: IDeleteUserOrgFundRequest) =>
      axios
        ?.put('delete-user-org-fund', data)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
  );
}

import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateUserOrgFundRequest } from '@shared/exchange/createUserOrgFund';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import IUserOrgFund from '@shared/interfaces/IUserOrgFund';
import { useMutation, UseMutationResult } from 'react-query';

interface ICreateUserOrgFundResponse {
  data: IUserOrgFund;
}
export default function useCreateUserOrgFund(): UseMutationResult<
  ICreateUserOrgFundResponse,
  IErrorResponse,
  ICreateUserOrgFundRequest,
  ICreateUserOrgFundResponse
> {
  const axios = useAxios();
  return useMutation<ICreateUserOrgFundResponse, IErrorResponse, ICreateUserOrgFundRequest, ICreateUserOrgFundResponse>(
    async (data: ICreateUserOrgFundRequest) =>
      axios
        ?.post('create-user-org-fund', data)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
  );
}

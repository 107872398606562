import { Button, Intent, Spinner, Icon } from '@blueprintjs/core';
import useGetDocMgmtDirectories from '@common/hooks/useGetDocMgmDirectories';
import IDocMgmtFolder from '@shared/interfaces/IDocMgmtFolder';
import useDocumentManagementStore from '@stores/useDocumentManagementStore';
import { ReactElement, useState } from 'react';
import { Box, Flex } from 'reflexbox';

interface IFolderNodeProps {
  folderData: IDocMgmtFolder;
  depth: number;
  type: 'INTERNAL' | 'EXTERNAL';
  hasWriteAccess: boolean;
}
function FolderNode({ folderData, depth, type, hasWriteAccess }: IFolderNodeProps): ReactElement {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const setSelectedFolder = useDocumentManagementStore((state) => state.setSelectedFolder);
  const { selectedFundId, levelCode, selectedFolder } = useDocumentManagementStore(
    ({ selectedFundId, levelCode, selectedFolder }) => {
      return { selectedFundId, levelCode, selectedFolder };
    },
  );
  const {
    data: directoryData,
    isLoading: isDirectoryDataLoading,
    //error,
  } = useGetDocMgmtDirectories(type, levelCode, folderData.id, selectedFundId, isExpanded);

  const handleClick = () => {
    setSelectedFolder(folderData);
  };

  const handleExpandClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    setExpanded((expanded) => !expanded);
  };

  return (
    <>
      <Flex>
        <span style={{ paddingLeft: depth + 'rem' }}></span>

        <Flex flex={1}>
          <Button
            icon={isExpanded ? 'chevron-down' : 'chevron-right'}
            intent={Intent.PRIMARY}
            minimal
            onClick={handleExpandClick}
            style={{
              visibility: !directoryData || directoryData.directories.length !== 0 ? 'visible' : 'hidden',
            }}
          ></Button>

          <Box flex={1}>
            <Button
              alignText={'left'}
              intent={selectedFolder?.id == folderData.id ? Intent.PRIMARY : Intent.NONE}
              active={selectedFolder?.id == folderData.id ? true : false}
              fill
              icon="folder-close"
              minimal
              onClick={handleClick}
              text={<span>{folderData.folderName}</span>}
              rightIcon={
                <>
                  {hasWriteAccess && (
                    <>{folderData.visibleInd && <Icon icon="eye-on" intent={Intent.SUCCESS}></Icon>}</>
                  )}
                  {isExpanded && <>{isDirectoryDataLoading && <Spinner intent={Intent.PRIMARY} size={20}></Spinner>}</>}
                </>
              }
            ></Button>
          </Box>
        </Flex>
      </Flex>
      {isExpanded && (
        <>
          {directoryData && (
            <div>
              {directoryData?.directories.map((childFolderData, i) => (
                <div key={i}>
                  <FolderNode
                    hasWriteAccess={hasWriteAccess}
                    type={type}
                    depth={depth + 1}
                    folderData={childFolderData}
                  ></FolderNode>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {/*
      <Menu style={{ background: 'transparent' }}>
        <MenuItem
          style={{ padding: 2 }}
          icon={
            <>
              <span style={{ paddingLeft: depth + 'rem' }}></span>
              <Button
                icon={isExpanded ? 'chevron-down' : 'chevron-right'}
                intent={Intent.PRIMARY}
                small
                minimal
                onClick={handleExpandClick}
              ></Button>
              <Icon icon={'folder-close'} />
            </>
          }
          onClick={handleClick}
          text={<span>{folderData.folderName} </span>}
          labelElement={<>{isExpanded && <span>{isDirectoryDataLoading && <Spinner size={22}></Spinner>}</span>}</>}
        ></MenuItem>
        {isExpanded && (
          <>
            {directoryData && (
              <div>
                {directoryData?.listExternalDirectories.map((childFolderData, i) => (
                  <div key={i}>
                    <FolderNode depth={depth + 1} folderData={childFolderData}></FolderNode>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </Menu>
      */}
    </>
  );
}

export default FolderNode;

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import dayjs from 'dayjs';
import { IGetWhatIfRebalScheduledCashFlowsResponse } from '@shared/exchange/getWhatIfRebalScheduledCashFlows';

export default function useGetWhatIfRebalScheduledCashflows(
  fundId: number | undefined,
  transactionDt: Date,
  isScheduledCashFlowEnabled: boolean,
): UseQueryResult<IGetWhatIfRebalScheduledCashFlowsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetWhatIfRebalScheduledCashFlowsResponse, IErrorResponse>(
    ['get-what-if-rebal-scheduled-cashflows', { fundId, transactionDt, isScheduledCashFlowEnabled }],
    async () => {
      if (!isScheduledCashFlowEnabled) return null;
      const searchParams = new URLSearchParams();
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      if (transactionDt) {
        searchParams.append('transactionDt', dayjs(transactionDt).format('YYYY-MM-DD'));
      }
      return axios
        ?.get(`get-what-if-rebal-scheduled-cashflows?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, enabled: !!fundId },
  );
}

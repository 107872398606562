import formatAssetValue from '@common/utils/formatAssetValue';
import IFundSummary from '@shared/interfaces/IFundSummary';
import { FC, ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IPeformanceLineChartProps } from './PerformanceLineChart';
import PerformanceLineChart from './PerformanceLineChart';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from '@rebass/grid/emotion';
import { Card, Intent, Position, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface IPerformanceChartProps {
  fundSummary?: IFundSummary;
  fundSummaryHistory?: IFundSummary[];
}
const PerformanceChart: FC<IPerformanceChartProps> = ({ fundSummary, fundSummaryHistory }): ReactElement => {
  const dailyChangeValue = Number(fundSummary?.dailyChange.dailyChangeValue);
  const dailyChangePct = Number(fundSummary?.dailyChange.dailyChangePct);
  const [chartData, setChartData] = useState<IPeformanceLineChartProps[]>();

  console.log(fundSummary, fundSummaryHistory);

  useEffect(() => {
    let data: { y: number; x: string }[] = [];
    if (!!fundSummaryHistory)
      data = fundSummaryHistory
        .filter(({ total_assets }) => Number(total_assets) > 0)
        .map(({ total_assets, time_dim_key }) => {
          return {
            y: Number(total_assets),
            x: dayjs(time_dim_key).format('MMM DD'),
          };
        })
        .reverse();

    setChartData([
      {
        data,
        id: 'Net Asset Value',
      },
    ]);
  }, [fundSummary, fundSummaryHistory]);

  return (
    <CustomCard
      rightToolbar={<></>}
      heading={`NAV Overview - As of ${dayjs(fundSummary?.time_dim_key).format('MM/DD/YYYY')}`}
      body={
        <Flex height={250} padding={2}>
          {chartData && chartData.length > 0 && (
            <Box width={3 / 4}>
              <PerformanceLineChart data={chartData}></PerformanceLineChart>
            </Box>
          )}

          <Box width={1 / 4} alignSelf="center">
            <Box mb={2}>
              <Card style={{ padding: '0.5rem' }}>
                <h6 className="bp3-heading">Total Assets</h6>
                <Tooltip2 position={Position.BOTTOM} content={<span>{+Number(fundSummary?.total_assets)}</span>}>
                  <div className="bp3-text-large">{formatAssetValue({ value: Number(fundSummary?.total_assets) })}</div>
                </Tooltip2>
              </Card>
            </Box>
            <Box mb={2}>
              <Card style={{ padding: '0.5rem' }}>
                <h6 className="bp3-heading">Daily Change</h6>
                <span className="bp3-text-large">
                  {formatAssetValue({ value: dailyChangeValue })}{' '}
                  <Tag intent={dailyChangePct < 0 ? Intent.DANGER : Intent.SUCCESS}>
                    {formatAllocationPercentage({ value: dailyChangePct })}
                  </Tag>
                </span>
              </Card>
            </Box>
            <Box mb={2}>
              <Card style={{ padding: '0.5rem' }}>
                <h6 className="bp3-heading">Total Cash</h6>
                <Tooltip2 position={Position.BOTTOM} content={<span>{+Number(fundSummary?.total_cash)}</span>}>
                  <div className="bp3-text-large">{formatAssetValue({ value: Number(fundSummary?.total_cash) })}</div>
                </Tooltip2>
              </Card>
            </Box>
          </Box>
        </Flex>
      }
    ></CustomCard>
  );
};

export default PerformanceChart;

import { IGetExposureResponse } from '@shared/exchange/getExposure';
import IExposure from '@shared/interfaces/IExposure';
import { FC, memo, ReactElement } from 'react';
import { Cell, Column, Row, useExpanded, useTable } from 'react-table';
import 'simplebar/dist/simplebar.min.css';
import formatAssetValue from '@common/utils/formatAssetValue';
import ExportExposureData from '../ExposureDataExport';
import roundtAllocationPercentage from '@common/utils/roudAllocationPercentage';
import { Alignment, Callout, Position, Switch } from '@blueprintjs/core';
import { Box } from '@rebass/grid/emotion';
import SimpleBar from 'simplebar-react';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Flex } from 'reflexbox';
import CustomCard from '@components/CustomCard';

interface IExposureTableProps {
  columns: Column<IExposure>[];
  data: IGetExposureResponse;
}

const getRowProps = (row: Row<IExposure>) => {
  let className = '';

  if (row.depth === 2) {
    className += ` list-unstyled sub-child last-child ${row.isExpanded ? '' : ''}`;
  } else if (row.depth === 1 && !row.original.isAccount) {
    className += ` list-unstyled child expo-tr ${row.isExpanded ? 'active' : ''}`;
  } else if (row.depth === 1 && row.original.isAccount) {
    className += ` list-unstyled child last-child`;
  } else if (row.depth === 0) {
    className += ` list-unstyled expo-tr ${row.isExpanded ? 'active' : ''}`;
  }

  const { current_allocation_pct, target_allocation_range_lower_bound, target_allocation_range_upper_bound } =
    row.original;

  if (target_allocation_range_lower_bound === null && target_allocation_range_upper_bound === null) {
    className += ' bg-white';
  } else if (
    current_allocation_pct < Number(target_allocation_range_lower_bound) ||
    current_allocation_pct > Number(target_allocation_range_upper_bound)
  ) {
    className += ' bg-red-light';
  } else {
    className += ' bg-white';
  }
  return { className };
};

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: { width?: string } = {};
  switch (columnId) {
    case 'name':
      style.width = '25%';
      break;
    case 'data_source_code':
      style.width = '15%';
      break;

    case 'asset_class_nav':
      style.width = '12%';
      break;

    case 'current_allocation_pct':
      style.width = '12%';
      break;

    case 'target_allocation_range_lower_bound':
      style.width = '10%';
      break;

    case 'target_allocation':
      style.width = '10%';
      break;

    case 'target_allocation_range_upper_bound':
      style.width = '10%';
      break;
    case 'benchmark_name':
      style.width = '21%';
      break;
  }
  return { style };
};

const getClassNameOfColumns = (columnId: string | undefined) => {
  if (!columnId) return {};
  let className = '';
  if (['benchmark_name', 'name', 'data_source_code'].includes(columnId)) {
    className += ' text-start';
  }
  return { className };
};

const getCellProps = (cell: Cell<IExposure>) => {
  const styles = getStyleOfColumns(cell.column.id);
  const classNames = getClassNameOfColumns(cell.column.id);
  return { ...styles, ...classNames };
};

const getHeaderProps = (column: Column<IExposure>) => {
  const styles = getStyleOfColumns(column.id);
  const classNames = getClassNameOfColumns(column.id);
  return { ...styles, ...classNames };
};

const ExposureTable: FC<IExposureTableProps> = ({ columns, data }): ReactElement => {
  const { getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded, isAllRowsExpanded } =
    useTable<IExposure>(
      {
        columns,
        data: data?.exposureData?.allocations || [],
        expandSubRows: false,
      },
      useExpanded,
    );

  const renderRowsRecursively = (rows: Row<IExposure>[]) => {
    if (!rows) return;
    return rows.map((row) => {
      prepareRow(row);
      return (
        <ul {...row.getRowProps(getRowProps(row))} key={row.id}>
          {row.cells.map((cell) => {
            return (
              <li {...cell.getCellProps(getCellProps(cell))} key={cell.row.id + ' ' + cell.column.id}>
                {cell.render('Cell')}
              </li>
            );
          })}
          {row.subRows && row.isExpanded && renderRowsRecursively(row.subRows)}
        </ul>
      );
    });
  };

  return (
    <CustomCard
      heading=""
      rightToolbar={
        <Flex paddingX={3} paddingY={3} alignItems="center" flexDirection="row-reverse">
          <Box ml={4}>
            <ExportExposureData data={data}></ExportExposureData>
          </Box>
          <Switch
            large={true}
            checked={isAllRowsExpanded}
            label=" Expand/Collapse all"
            onChange={() => toggleAllRowsExpanded()}
            alignIndicator={Alignment.RIGHT}
            style={{ marginBottom: 0 }}
          />
        </Flex>
      }
      body={
        <SimpleBar>
          <div className="exposure-table">
            <>
              {headerGroups.map((headerGroup) => (
                <ul
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.id}
                  className="list-unstyled expo-table-head"
                >
                  {headerGroup.headers.map((column) => (
                    <li {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                      {column.render('Header')}
                    </li>
                  ))}
                </ul>
              ))}
            </>
            <div {...getTableBodyProps()} className="expo-table-body">
              <ul className="expo-table-title list-unstyled">
                <li {...getStyleOfColumns('name')} {...getClassNameOfColumns('name')}>
                  Total Fund
                </li>
                {/*}  <li {...getStyleOfColumns('data_source_code')} {...getClassNameOfColumns('data_source_code')}></li> */}
                <li {...getStyleOfColumns('asset_class_nav')} {...getClassNameOfColumns('asset_class_nav')}>
                  <Tooltip2 position={Position.BOTTOM} content={<>{+Number(data.exposureData.totalAssetClassNav)}</>}>
                    {formatAssetValue({ value: Number(data.exposureData.totalAssetClassNav) })}
                  </Tooltip2>
                </li>
                <li
                  {...getStyleOfColumns('current_allocation_pct')}
                  {...getClassNameOfColumns('current_allocation_pct')}
                >
                  {roundtAllocationPercentage({ value: data.exposureData.totalCurrentAllocation })} %
                </li>
                <li
                  {...getStyleOfColumns('target_allocation_range_lower_bound')}
                  {...getClassNameOfColumns('target_allocation_range_lower_bound')}
                >
                  {' '}
                </li>
                <li {...getStyleOfColumns('target_allocation')} {...getClassNameOfColumns('target_allocation')}>
                  {roundtAllocationPercentage({ value: data.exposureData.totalTargetAllocation })} %
                </li>
                <li
                  {...getStyleOfColumns('target_allocation_range_upper_bound')}
                  {...getClassNameOfColumns('target_allocation_range_upper_bound')}
                ></li>
                <li {...getStyleOfColumns('benchmark_name')} {...getClassNameOfColumns('benchmark_name')}></li>
              </ul>
              <>{renderRowsRecursively(rows)}</>

              {rows && rows.length == 0 && (
                <Callout intent="danger">No data found for the selected fund and date.</Callout>
              )}
            </div>
          </div>
        </SimpleBar>
      }
    ></CustomCard>
  );
};

export default memo(ExposureTable);

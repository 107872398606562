import { Alignment, Button, Icon, Intent, Navbar, NonIdealState, ProgressBar } from '@blueprintjs/core';
import useGetETLFileDownloadUrl from '@common/hooks/useGetETLFileDownloadUrl';
import CustomDateRangePicker from '@components/CustomDateRangePicker';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import { AppToaster } from '@components/Toasters';
import IEtlFileControl from '@shared/interfaces/IEtlFileControl';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { ReactElement, useMemo } from 'react';
import { Column, Row, useTable } from 'react-table';
import { Box } from 'reflexbox';

function Table({ columns, data }: { columns: Column<IEtlFileControl>[]; data: IEtlFileControl[] }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              <th>#</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {/*column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>{column.isGrouped ? 'GG ' : ' '}</span>
                      ) : null*/}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.id}
                className={row.original.fileLoadedInd && !row.original.fileProcessedInd ? 'bg-red-light' : ''}
              >
                <td>{i + 1}</td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id + '_' + cell.row.id}>
                      {cell.isGrouped ? (
                        <>{cell.render('Cell')}</>
                      ) : cell.isAggregated ? (
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <Box mt={2}>
          <NonIdealState
            icon={'issue'}
            title={<h5 className="bp3-heading"> No Files found for this feed and selected date range.</h5>}
          />
        </Box>
      )}
    </>
  );
}

interface IFeedsPanelProps {
  startDate: Date;
  endDate: Date;
  data: IEtlFileControl[];
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  isLoading: boolean;
  isFetching: boolean;
  setSelectedFile: (file: IEtlFileControl) => void;
  selectedCustodialFeedId?: number;
}

function FilesPanel({
  data,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isLoading,
  isFetching,
  setSelectedFile,
  selectedCustodialFeedId,
}: IFeedsPanelProps): ReactElement {
  const { mutateAsync: getDownloadURL } = useGetETLFileDownloadUrl();
  const handleDownload = async (etlFileId: number, custFeedId: number, filename: string) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });

    try {
      const res = await getDownloadURL({ etlFileId, custFeedId });
      fetch(res.url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, filename);
          AppToaster.clear();
        })
        .catch((e) => {
          console.log(e);
          AppToaster.clear();
        });
    } catch (e) {
      AppToaster.clear();
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Something went wrong. Please try again.',
        icon: 'warning-sign',
      });
    }
  };

  const filesColumns: Column<IEtlFileControl>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row }) => (
          <>
            {' '}
            {row.original.fileLoadedInd && !row.original.fileProcessedInd && (
              <Icon icon="warning-sign" intent={Intent.DANGER}></Icon>
            )}
          </>
        ),
      },
      {
        Header: 'File',
        accessor: 'fileName',
        Cell: ({ row, value }) => (
          <Button
            minimal
            rightIcon="download"
            onClick={() => {
              value && selectedCustodialFeedId && handleDownload(row.original.id, selectedCustodialFeedId, value);
            }}
            disabled={!value}
          >
            {value || 'NA'}
          </Button>
        ),
      },
      {
        Header: 'Files Loaded?',
        accessor: 'fileLoadedInd',
        Cell: ({ value }) => <>{value ? 'Yes' : 'No'}</>,
      },
      {
        Header: 'Files Processed?',
        accessor: 'fileProcessedInd',
        Cell: ({ value }) => <>{value ? 'Yes' : 'No'}</>,
      },
      {
        Header: 'Records Count',
        accessor: 'totalRecordCount',
      },
      {
        Header: 'Received Date',
        accessor: 'fileReceivedDate',
        Cell: ({ value }) => dayjs(value).format('MM/DD/YYYY HH:mm:ss'),
      },
      {
        Header: 'Loaded Date',
        accessor: 'fileLoadedDate',
        Cell: ({ value }) => (value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : ''),
      },
      {
        Header: 'Processed Date',
        accessor: 'fileProcessedDate',
        Cell: ({ value }) => (value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : ''),
      },
      {
        Header: '',
        accessor: (row) => row.id,
        id: 'controls',
        Cell: ({ row }: { row: Row<IEtlFileControl> }) => (
          <Button
            intent={Intent.PRIMARY}
            rightIcon={'chevron-right'}
            minimal
            outlined
            onClick={() => setSelectedFile(row.original)}
          >
            View Logs
          </Button>
        ),
      },
    ],
    [],
  );

  const Filters = () => {
    return (
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <CustomDateRangePicker
            label={'Date Range'}
            isLoading={!data}
            startDate={startDate}
            endDate={endDate}
            setStartDate={(date) => setStartDate(date)}
            setEndDate={(date) => setEndDate(date)}
          ></CustomDateRangePicker>
        </Navbar.Group>
      </Navbar>
    );
  };
  return (
    <>
      {isFetching && <GlobalLoader></GlobalLoader>}
      {isLoading && <TableLoader></TableLoader>}
      {!isLoading && <Filters></Filters>}
      {!isLoading && data && <Table columns={filesColumns} data={data} />}
    </>
  );
}

export default FilesPanel;

import FormLoader from '@components/FormLoader';
import { FC, ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import useUserMetadata from '@common/hooks/useGetUserMetadata';
import { ROLES_CONFIG } from '@shared/constants/Roles';
import useCreateUser from '@common/hooks/useCreateUser';
import { useAsyncDebounce } from 'react-table';
import useSearchUser, { ADMIN_SEARCH_USER_KEY } from '@common/hooks/useSearchUser';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { ADMIN_GET_USERS_KEY } from '@common/hooks/useUsers';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import { Button, FormGroup, HTMLSelect, InputGroup, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { AppToaster } from '@components/Toasters';
import customRegisterFormField from '@common/utils/customRegisterFormField';

const formSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required.'),
  roleId: yup.number().required(),
  lastName: yup.string().required('Last Name is required.'),
  firstName: yup.string().required('First Name is required.'),
});

const getRoleDisplayName = (roleName: string) => {
  return ROLES_CONFIG.find((rc) => rc.role_name === roleName)?.display_name;
};

const UserCreationPage: FC = (): ReactElement => {
  const { data: userMetaData, isLoading: isUserMetadataLoading } = useUserMetadata();
  const { mutateAsync: createUser, isLoading: isCreateUserLoading, error, isError } = useCreateUser();
  const [userEmailToSearch, setUserEmailToSearch] = useState<string>('');
  const { data: searchData, isLoading: isSearchLoading } = useSearchUser(userEmailToSearch);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSearchEmailChange = useAsyncDebounce((value) => {
    if (value && value.trim() != '') {
      setUserEmailToSearch(value);
    }
  }, 200);

  useEffect(() => {
    if (searchData) {
      setValue('firstName', searchData.firstName, { shouldDirty: true, shouldValidate: true });
      setValue('lastName', searchData.lastName, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue('firstName', '');
      setValue('lastName', '');
    }
  }, [searchData]);
  console.log(errors);

  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    try {
      await createUser(data);
      resetQueries();
      history.push('/administration/users');
    } catch (e) {
      resetQueries();
    }
  };

  const resetQueries = () => {
    queryClient.removeQueries(ADMIN_SEARCH_USER_KEY);
    queryClient.invalidateQueries(ADMIN_GET_USERS_KEY);
  };

  const isLoading = useMemo(() => isUserMetadataLoading, [isUserMetadataLoading]);
  const isSearchValid = !!searchData || isSearchLoading;

  useEffect(() => {
    if (isError && error?.errorMessage)
      AppToaster.show({ icon: 'warning-sign', intent: Intent.DANGER, message: error?.errorMessage });
  }, [error, isError]);

  return (
    <Flex m={2}>
      <Box width={[6 / 12]}>
        <CustomCard
          heading="Invite User"
          body={
            <Box m={3}>
              {isLoading ? (
                <FormLoader></FormLoader>
              ) : (
                <>
                  {isError && !isCreateUserLoading && error && (
                    <div className="col-lg-6  col-sm-12 pe-4">
                      <div className="alert alert-danger text-center" role="alert">
                        {error.errorMessage}
                      </div>
                    </div>
                  )}
                  <form className="formInputs col-lg-7 col-sm-12" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <FormGroup
                      helperText={errors?.email?.message}
                      label="Email"
                      labelFor="email"
                      labelInfo="(required)"
                      intent={errors?.email ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        large={true}
                        {...customRegisterFormField(register, 'email')}
                        onChange={(e) => onSearchEmailChange(e.target.value)}
                        intent={errors?.email ? Intent.DANGER : Intent.NONE}
                        id="email"
                        placeholder="Enter the user's email"
                      />
                    </FormGroup>

                    <FormGroup
                      helperText={errors?.roleId?.message}
                      label="Role"
                      labelFor="roleId"
                      labelInfo="(required)"
                      intent={errors?.roleId ? Intent.DANGER : Intent.NONE}
                    >
                      <HTMLSelect id="role" large={true} fill={true} {...customRegisterFormField(register, 'roleId')}>
                        {userMetaData?.roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {getRoleDisplayName(role.roleName)}
                          </option>
                        ))}
                      </HTMLSelect>
                    </FormGroup>

                    <FormGroup
                      helperText={errors?.firstName?.message}
                      label="First Name"
                      labelFor="firstName"
                      labelInfo="(required)"
                      intent={errors?.firstName ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        {...customRegisterFormField(register, 'firstName')}
                        asyncControl={true}
                        large={true}
                        intent={errors?.firstName ? Intent.DANGER : Intent.NONE}
                        id="firstName"
                        placeholder="Enter the user's First Name"
                        readOnly={isSearchValid}
                        rightElement={
                          isSearchLoading ? (
                            <Spinner tagName="span" intent={Intent.PRIMARY} size={SpinnerSize.SMALL}>
                              Loading...
                            </Spinner>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </FormGroup>

                    <FormGroup
                      helperText={errors?.lastName?.message}
                      label="Last Name"
                      labelFor="lastName"
                      labelInfo="(required)"
                      intent={errors?.lastName ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        {...customRegisterFormField(register, 'lastName')}
                        asyncControl={true}
                        large={true}
                        intent={errors?.lastName ? Intent.DANGER : Intent.NONE}
                        id="lastName"
                        placeholder="Enter the user's Last Name"
                        readOnly={isSearchValid}
                        rightElement={
                          isSearchLoading ? (
                            <Spinner tagName="span" intent={Intent.PRIMARY} size={SpinnerSize.SMALL}>
                              Loading...
                            </Spinner>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </FormGroup>
                    {/*

                  <div className="row mb-2">
                    <div className="col-lg-10 col-sm-10">
                      <div className="form-floating">
                        <input
                          className={`form-control ${errors?.email ? 'is-invalid' : ''} `}
                          type="text"
                          placeholder="Enter the user's email"
                          id="email"
                          {...register('email')}
                          onChange={(e) => onSearchEmailChange(e.target.value)}
                        ></input>
                        <label htmlFor="email" className="pb-3">
                          {`Enter User's Email`}
                        </label>
                        <div className="invalid-feedback">{errors?.email?.message}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-lg-10 col-sm-10">
                      <div className="form-floating">
                        <select
                          className={`form-select ${errors?.roleId ? 'is-invalid' : ''} `}
                          id="role"
                          {...register('roleId')}
                        >
                          {userMetaData?.roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {getRoleDisplayName(role.roleName)}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="role">Role</label>
                        <div className="invalid-feedback">{errors?.roleId?.message}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-lg-10 col-sm-10">
                      <div className="form-floating">
                        <input
                          className={`form-control ${errors?.firstName ? 'is-invalid' : ''} `}
                          type="text"
                          placeholder="Enter the user's First Name"
                          id="firstName"
                          {...register('firstName')}
                          readOnly={isSearchValid}
                        ></input>
                        <label htmlFor="firstName" className="pb-3">
                          First Name
                        </label>
                        <div className="invalid-feedback">{errors?.firstName?.message}</div>
                      </div>
                    </div>
                    {isSearchLoading && (
                      <div className="col-lg-2 col-sm-2 pb-2 d-flex justify-content-left align-items-center">
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mb-2">
                    <div className="col-lg-10 col-sm-10">
                      <div className="form-floating">
                        <input
                          className={`form-control ${errors?.lastName ? 'is-invalid' : ''} `}
                          type="text"
                          placeholder="Enter the user's Last Name"
                          id="lastName"
                          {...register('lastName')}
                          readOnly={isSearchValid}
                        ></input>
                        <label htmlFor="lastName" className="pb-3">
                          Last Name
                        </label>
                        <div className="invalid-feedback">{errors?.lastName?.message}</div>
                      </div>
                    </div>
                    {isSearchLoading && (
                      <div className="col-lg-2 col-sm-2 pb-2 d-flex justify-content-left align-items-center">
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                    */}

                    <Flex pt={2}>
                      <Button
                        type="submit"
                        intent={Intent.PRIMARY}
                        disabled={isCreateUserLoading || isSearchLoading}
                        loading={isCreateUserLoading}
                      >
                        Submit
                      </Button>
                      <Button
                        intent={Intent.NONE}
                        minimal={true}
                        onClick={() => {
                          resetQueries();
                          history.push('/administration/users');
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </form>
                </>
              )}
            </Box>
          }
        ></CustomCard>
      </Box>
    </Flex>
  );
};

export default UserCreationPage;

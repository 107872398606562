import CustomCard from '@components/CustomCard';
import { ReactElement, useEffect, useRef } from 'react';

const EconomicCalendarWidget = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
    script.async = true;
    script.innerHTML = data; /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
    ref?.current?.appendChild(script);
  }, [ref]);

  return (
    <CustomCard
      simple
      heading={'Forex Cross Currency Rates'}
      body={
        <div className="tradingview-widget-container" ref={ref}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/markets/" rel="noopener noreferrer" target="_blank">
              <span className="blue-text">Exchange Rates</span>
            </a>{' '}
            by TradingView
          </div>
        </div>
      }
    ></CustomCard>
  );
};
export default EconomicCalendarWidget;

const data = `
{
  "width": "100%",
  "height": "600",
  "currencies": [
    "EUR",
    "USD",
    "JPY",
    "GBP",
    "CHF",
    "AUD",
    "CAD",
    "NZD",
    "CNY"
  ],
  "isTransparent": false,
  "colorTheme": "light",
  "locale": "en"
}
  `;

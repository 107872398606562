import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetAccountTypeRefsDataResponse } from '@shared/exchange/getAccountTypeRefsData';

export default function useGetAccountTypeRefs(): UseQueryResult<IGetAccountTypeRefsDataResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetAccountTypeRefsDataResponse, IErrorResponse>(
    ['admin-account-type-refs'],
    async () =>
      axios
        ?.get('get-account-type-refs')
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false },
  );
}

import { Divider, FormGroup } from '@blueprintjs/core';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { FC, ReactElement } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Box, Flex } from 'reflexbox';

interface IUserDefindCashflowInputPanelProps {
  onChange: () => void;
}
const UserDefindCashflowInputPanel: FC<IUserDefindCashflowInputPanelProps> = ({ onChange }): ReactElement => {
  const {
    userDefinedCashflowRedemption,
    setUserDefinedCashflowRedemption,
    userDefinedCashflowContribution,
    setUserDefinedCashflowContribution,
  } = useWhatIfRebalStore(
    ({
      userDefinedCashflowRedemption,
      setUserDefinedCashflowRedemption,
      userDefinedCashflowContribution,
      setUserDefinedCashflowContribution,
    }) => {
      return {
        userDefinedCashflowRedemption,
        setUserDefinedCashflowRedemption,
        userDefinedCashflowContribution,
        setUserDefinedCashflowContribution,
      };
    },
  );
  return (
    <Box>
      <Box p={2} pb={1}>
        <h6 className="bp3-heading">
          <strong>User Defined Cashflow</strong>
        </h6>
      </Box>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Box px={3} pt={1}>
        <Flex>
          <Box pr={1}>
            <FormGroup label="Redemption">
              <CurrencyInput
                min={0}
                allowNegativeValue={false}
                //defaultValue={0}
                className={'bp3-input  bp3-fill'}
                value={userDefinedCashflowRedemption}
                onValueChange={(value) => {
                  setUserDefinedCashflowRedemption(value);
                  onChange();
                }}
              />
            </FormGroup>
          </Box>
          <Box pl={1}>
            <FormGroup label="Contribution">
              <CurrencyInput
                min={0}
                //defaultValue={0}
                allowNegativeValue={false}
                className={'bp3-input bp3-fill'}
                value={userDefinedCashflowContribution}
                onValueChange={(value) => {
                  setUserDefinedCashflowContribution(value);
                  onChange();
                }}
              />
            </FormGroup>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default UserDefindCashflowInputPanel;

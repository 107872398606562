import { Button, Colors, Menu, Position, Tag } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { FC, ReactElement } from 'react';
import { Flex } from 'reflexbox';
import styled from '@emotion/styled';

interface ICustomDropwon {
  disabled: boolean;
  text: string;
  label?: string;
  menuItems: ReactElement[];
  large?: boolean;
}

const CustomDropdown: FC<ICustomDropwon> = ({ disabled, text, menuItems, label, large = false }) => {
  return (
    <Styles>
      <Flex>
        {label && (
          <Tag className="label" large={large}>
            {label}
          </Tag>
        )}
        <Popover2
          modifiers={{ arrow: { enabled: false }, preventOverflow: { enabled: true } }}
          transitionDuration={0}
          placement={Position.BOTTOM}
          content={
            <Menu large={large} style={{ zIndex: 10001 }}>
              {menuItems}
            </Menu>
          }
        >
          <Button
            large={large}
            disabled={disabled}
            text={text}
            rightIcon="chevron-down"
            minimal={true}
            outlined={true}
            className={label ? 'labeledButton' : ''}
          ></Button>
        </Popover2>
      </Flex>
    </Styles>
  );
};

const Styles = styled.span`
  .label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${Colors.LIGHT_GRAY4};
    border-color: ${Colors.GRAY5};
    border-width: 1px;
    border-style: solid;
    color: ${Colors.BLACK};
    font-size: 14px;
  }
  .labeledButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border-color: ${Colors.GRAY5};
  }
`;
export default CustomDropdown;

import IGetCashTransactionResponse from '@shared/exchange/getCashTransactions/IGetCashTransactionResponse';
import ICashTransaction from '@shared/interfaces/ICashTransaction';
import { useCallback, useEffect, useState } from 'react';
import { FC, useMemo } from 'react';
import { Cell, useTable, useExpanded, useFilters, useGlobalFilter, usePagination } from 'react-table';
import getCashTransactionsTableConfig from './getCashTransactionsTableConfig';
import useCashManagementStore from '@stores/useCashManagementStore';
import CashTransactionRowPanel from './CashTransactionRowPanel';
import Pagination from '@components/Pagination';
import useSettleCashTransaction from '@common/hooks/useSettleCashTransaction';
import { useQueryClient } from 'react-query';
import { GET_CASH_TRANSACTIONS_KEY } from '@common/hooks/useGetCashTransactions';
import { Alert, Button, ButtonGroup, Intent, NonIdealState, Position } from '@blueprintjs/core';
import { Flex } from 'reflexbox';
import styled from '@emotion/styled';
import useAuthStore from '@stores/useAuthStore';
import { Tooltip2 } from '@blueprintjs/popover2';
import CashTransactionAudit from '../CashTransactionAudit';

interface ICashTransactionTableProps {
  data: IGetCashTransactionResponse;
  selectedUserId: number | undefined;
  globalFilterValue: string | undefined;
  selectedTransactionTypeName: string | undefined;
  selectedTransactionStatusName: string | undefined;
}

const getClassNameOfColumns = (columnId: string | undefined) => {
  if (!columnId) return {};
  let className = 'px-3 py-1 pt-2  ';
  if (['transactionAmount'].includes(columnId)) {
    className += ' text-end';
  }
  return { className };
};

const getCellProps = (cell: Cell<ICashTransaction>) => {
  const classNames = getClassNameOfColumns(cell.column.id);
  return { ...classNames };
};

const CashTransactionsTable: FC<ICashTransactionTableProps> = ({
  data,
  selectedUserId,
  globalFilterValue,
  selectedTransactionTypeName,
  selectedTransactionStatusName,
}) => {
  const { setTransactionIdToCancel } = useCashManagementStore(({ setTransactionIdToCancel }) => {
    return { setTransactionIdToCancel };
  });
  const columns = useMemo(
    () =>
      getCashTransactionsTableConfig({
        transcationStatuses: data.transactionStatuses,
        currentTransactionStatus: data.transactionStatuses[0],
      }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    prepareRow,
    visibleColumns,
    setFilter,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    rows,
  } = useTable<ICashTransaction>(
    {
      columns,
      data: data.cashTransactions,
      initialState: {
        pageSize: 30,
      },
    },
    useFilters,
    useGlobalFilter,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    setFilter('createdBy', selectedUserId);
  }, [selectedUserId]);

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  useEffect(() => {
    setFilter('transactionType', selectedTransactionTypeName);
  }, [selectedTransactionTypeName]);

  useEffect(() => {
    setFilter('transactionStatus', selectedTransactionStatusName);
  }, [selectedTransactionStatusName]);

  const { mutateAsync: settleCashTransaction } = useSettleCashTransaction();
  const queryClient = useQueryClient();

  const [eventAction, setEventAction] =
    useState<{ event: 'NEXT' | 'WF_TERMINATE'; cashTransactionId: number } | undefined>();

  const [statusUpdating, setStatusUpdating] = useState<boolean>(false);

  const [selectedCashRecord, setSelectedCashRecord] = useState<ICashTransaction | undefined>();

  const callEvent = useCallback(async () => {
    if (!eventAction) return;
    setStatusUpdating(true);
    try {
      const { event, cashTransactionId } = eventAction;
      if (event === 'NEXT') await settleCashTransaction({ cashTransactionId });
      else if (event === 'WF_TERMINATE') await settleCashTransaction({ shouldCancel: true, cashTransactionId });
      setEventAction(undefined);
      setStatusUpdating(false);
      queryClient.resetQueries(GET_CASH_TRANSACTIONS_KEY);
    } catch (e) {
      setEventAction(undefined);
      setStatusUpdating(false);
    }
  }, [eventAction]);

  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <div>
        <CashTransactionRowPanel cashTransaction={row.original}></CashTransactionRowPanel>
      </div>
    ),
    [],
  );

  return (
    <Styles>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" style={{ width: '100%' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
              <th>Action</th>
              <th>Audit</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps(getCellProps(cell))} key={cell.row.id + '_' + cell.column.id}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  {canCurrentUserWriteACL('CASH_MANAGEMENT') && (
                    <td>
                      <div
                        style={{
                          visibility:
                            !row.original.rebalanceTranInd &&
                            row.original.transactionStatus.transactionStatusName.toLowerCase() !== 'canceled'
                              ? undefined
                              : 'hidden',
                        }}
                      >
                        <ButtonGroup>
                          {row.original.transactionType.transactionTypeName?.toLowerCase() === 'trade' ? (
                            <>
                              {row.original.events?.NEXT && (
                                <>
                                  <Button
                                    // small={false}
                                    icon="edit"
                                    intent="primary"
                                    onClick={() =>
                                      setEventAction({ event: 'NEXT', cashTransactionId: row.original.id })
                                    }
                                  >
                                    {row.original.events?.NEXT.name}
                                  </Button>
                                </>
                              )}
                              {row.original.events?.WF_TERMINATE && (
                                <>
                                  <Button
                                    // small={true}
                                    intent="danger"
                                    icon={'trash'}
                                    onClick={() =>
                                      setEventAction({ event: 'WF_TERMINATE', cashTransactionId: row.original.id })
                                    }
                                  >
                                    {row.original.events?.WF_TERMINATE.name}
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Button
                                intent="danger"
                                //small={true}
                                icon={'trash'}
                                onClick={() => setTransactionIdToCancel(row.original.id)}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        </ButtonGroup>
                      </div>
                    </td>
                  )}
                  <td>
                    <Tooltip2
                      interactionKind="hover"
                      content="See Cash Transaction Audit"
                      position={Position.LEFT}
                      openOnTargetFocus={true}
                    >
                      <Button
                        small
                        minimal
                        intent={Intent.PRIMARY}
                        onClick={() => {
                          console.log(`You clicked Cash Id: ${row.original.id} `);
                          setSelectedCashRecord(row.original);
                        }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </Button>
                    </Tooltip2>
                  </td>
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </>
            );
          })}
          {rows.length === 0 && (
            <tr>
              <td colSpan={1000}>
                <NonIdealState icon={'issue'} title={<h5 className="bp3-heading">No transactions found</h5>} />
              </td>
            </tr>
          )}
          {selectedCashRecord && (
            <CashTransactionAudit
              cashRecord={selectedCashRecord}
              isOpen={!!selectedCashRecord}
              close={() => setSelectedCashRecord(undefined)}
            />
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={100}>
              <Flex flexDirection="row-reverse">
                <Pagination
                  pageIndex={pageIndex}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageCount={pageCount}
                  pageOptions={pageOptions}
                ></Pagination>
              </Flex>
            </td>
          </tr>
        </tfoot>
      </table>

      <Alert
        icon={eventAction?.event === 'NEXT' ? 'tick-circle' : 'trash'}
        intent={eventAction?.event === 'NEXT' ? Intent.PRIMARY : Intent.DANGER}
        isOpen={!!eventAction}
        //onAfterOpen={afterOpenModal}
        confirmButtonText="Yes"
        onConfirm={() => eventAction && callEvent()}
        onCancel={() => setEventAction(undefined)}
        cancelButtonText={'Cancel'}
        loading={statusUpdating}
      >
        <div>
          {!statusUpdating ? <p>Are you sure you want to update the status?</p> : <p>Updating the status...</p>}
        </div>
      </Alert>
    </Styles>
  );
};

export default CashTransactionsTable;

const Styles = styled.div``;

import { FC, ReactElement, ReactNode } from 'react';
//import { useIsFetching } from 'react-query';
//import GlobalLoader from '@components/GlobalLoader';
import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
import { Flex, Box } from '@rebass/grid/emotion';

interface IMainLayoutProps {
  children?: ReactNode;
  pageTitle?: string;
}
const MainLayout: FC = ({ children, pageTitle }: IMainLayoutProps): ReactElement => {
  // const isFetching = useIsFetching();
  return (
    <>
      {/*isFetching > 0 && <GlobalLoader />*/}
      <Flex>
        <Box>
          <Sidebar />
        </Box>
        <Box flex="1 1 auto" style={{ marginLeft: '3.5rem', marginTop: '3.2rem' }}>
          <Header pageTitle={pageTitle} />
          <main>{children} </main>
        </Box>
      </Flex>
    </>
  );
};

export default MainLayout;

import IAccount from '@shared/interfaces/IAccount';
import IFund from '@shared/interfaces/IFund';
import IUser from '@shared/interfaces/IUser';
import dayjs from 'dayjs';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

export interface ICashManagementStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;

  allFunds: IFund[] | undefined;
  setAllFunds: (allFunds: IFund[]) => void;

  switchFund: (fundId: number) => void;

  fromAccounts: IAccount[] | undefined;
  setFromAccounts: (fromAccounts: IAccount[] | undefined) => void;
  toAccounts: IAccount[] | undefined;
  setToAccounts: (toAccounts: IAccount[] | undefined) => void;

  transactionIdToCancel: number | undefined;
  setTransactionIdToCancel: (id: number | undefined) => void;

  selectedUserId: number | undefined;
  setSelectedUserId: (userId: number | undefined) => void;

  allUsers: IUser[] | undefined;
  setAllUsers: (allUsers: IUser[]) => void;

  startDate: string | undefined;
  setStartDate: (startDate: string) => void;

  endDate: string | undefined;
  setEndDate: (endDate: string) => void;

  setStartAndEndDate: (starDate: string, endDate: string) => void;

  globalFilterValue: string | undefined;
  setGlobalFilterValue: (value: string | undefined) => void;

  selectedAccountName: string | undefined;
  setSelectedAccountName: (value: string | undefined) => void;

  selectedCashtransactionTypeName: string | undefined;
  setSelectedCashtransactionTypeName: (name: string | undefined) => void;

  selectedTransactionStatusName: string | undefined;
  setSelectedTransactionStatusName: (name: string | undefined) => void;

  switchUser: (userId: number | undefined) => void;

  superSearchType: 'TRADE' | 'TRADE_ITEM';
  setSupeSearchType: (type: 'TRADE' | 'TRADE_ITEM') => void;

  superSearchText: string;
  setSuperSearchText: (text: string) => void;
  reset: () => void;

  searchTradeId: string | undefined;
  searchTradeItemId: string | undefined;

  superSearch: (searchTradeId: string | undefined, searchTradeItemId: string | undefined) => void;
  clearSearch: () => void;

  disableAllFilters: boolean;
  setDisableAllFilters: (val: boolean) => void;
}

const useCashManagementStore = create<ICashManagementStore>(
  persist<ICashManagementStore>(
    (set: SetState<ICashManagementStore>) => ({
      selectedFundId: undefined,
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },
      allFunds: undefined,
      setAllFunds: (allFunds: IFund[]) => {
        set({ allFunds });
      },

      switchFund: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },
      fromAccounts: undefined,
      setFromAccounts: (fromAccounts: IAccount[] | undefined): void => {
        set({ fromAccounts });
      },
      toAccounts: undefined,
      setToAccounts: (toAccounts: IAccount[] | undefined): void => {
        set({ toAccounts });
      },
      transactionIdToCancel: undefined,
      setTransactionIdToCancel: (id: number | undefined) => set({ transactionIdToCancel: id }),

      selectedUserId: undefined,
      setSelectedUserId: (userId: number | undefined): void => {
        set({ selectedUserId: userId });
      },
      allUsers: undefined,
      setAllUsers: (allUsers: IUser[]) => {
        set({ allUsers });
      },

      switchUser: (userId: number | undefined): void => {
        set({ selectedUserId: userId });
      },

      startDate: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
      endDate: dayjs().add(30, 'days').format('MM/DD/YYYY'),

      setStartAndEndDate: (startDate: string, endDate: string): void => {
        set({ startDate: startDate, endDate: endDate });
      },

      setStartDate: (startDate: string): void => {
        set({ startDate: startDate });
      },

      setEndDate: (endDate: string): void => {
        console.log('END DATE CALLED');
        set({ endDate: endDate });
      },

      globalFilterValue: undefined,
      setGlobalFilterValue: (value: string | undefined) => {
        set({ globalFilterValue: value });
      },

      selectedAccountName: undefined,
      setSelectedAccountName: (value: string | undefined) => {
        set({ selectedAccountName: value });
      },

      selectedCashtransactionTypeName: undefined,
      setSelectedCashtransactionTypeName: (name: string | undefined) => {
        set({ selectedCashtransactionTypeName: name });
      },

      selectedTransactionStatusName: undefined,
      setSelectedTransactionStatusName: (name: string | undefined) => {
        set({ selectedTransactionStatusName: name });
      },

      superSearchType: 'TRADE',
      setSupeSearchType: (type: 'TRADE' | 'TRADE_ITEM') => {
        set({ superSearchType: type });
      },

      disableAllFilters: false,
      setDisableAllFilters: (val: boolean) => {
        set({ disableAllFilters: val });
      },

      superSearchText: '',
      setSuperSearchText: (text: string) => {
        let disableAllFilters = false;

        if (!text || text.length === 0) {
          disableAllFilters = false;
        } else {
          disableAllFilters = true;
        }
        set(() => {
          return { superSearchText: text, disableAllFilters };
        });
      },

      superSearch: (searchTradeId: string | undefined, searchTradeItemId: string | undefined) => {
        set({
          searchTradeId,
          searchTradeItemId,
          //disableAllFilters: true,
          //selectedAccountName: undefined,
          //selectedCashtransactionTypeName: undefined,
          //selectedFundId: undefined,
          //selectedTransactionStatusName: undefined,
          //selectedUserId: undefined,
          //startDate: undefined,
          //endDate: undefined,
        });
      },

      clearSearch: () => {
        set(() => {
          return {
            superSearchText: '',
            searchTradeId: undefined,
            searchTradeItemId: undefined,
            disableAllFilters: false,
          };
        });
      },

      reset: () => {
        set({
          selectedCashtransactionTypeName: undefined,
          selectedAccountName: undefined,
          globalFilterValue: undefined,
          startDate: undefined,
          endDate: undefined,
          allUsers: undefined,
          selectedUserId: undefined,
          transactionIdToCancel: undefined,
          toAccounts: undefined,
          fromAccounts: undefined,
          allFunds: undefined,
          selectedFundId: undefined,
          selectedTransactionStatusName: undefined,
        });
      },
      searchTradeId: undefined,
      searchTradeItemId: undefined,
    }),
    {
      name: 'cash-management-cache',
      blacklist: [
        'transactionIdToCancel',
        'startDate',
        'endDate',
        'globalFilterValue',
        'selectedAccountName',
        'selectedCashtransactionTypeName',
        'superSearchType',
        'searchTradeId',
        'searchTradeItemId',
        'superSearchText',
        'disableAllFilters',
      ],
    },
  ),
);

export default useCashManagementStore;

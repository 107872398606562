import { Alert, Button, Dialog, Intent } from '@blueprintjs/core';
import useGetCashTransactions from '@common/hooks/useGetCashTransactions';
import useUpdateCashTransaction from '@common/hooks/useUpdateCashTransaction';
import { IUpdateCashTransactionEntryRequest } from '@shared/exchange/updateCashTransactionEntry';
import useCashManagementStore from '@stores/useCashManagementStore';
import { ReactElement, useCallback, useState } from 'react';
import { Box } from 'reflexbox';

const ConfirmCancelCashTransactionModal = (): ReactElement => {
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [cancelReasonText, setCancelReasonText] = useState<string>('');
  const { mutateAsync: updateCashTransactionEntry, isLoading: isRequestInProgress } = useUpdateCashTransaction();
  const { setTransactionIdToCancel, transactionIdToCancel, selectedFundId, startDate, endDate } =
    useCashManagementStore(
      ({ setTransactionIdToCancel, transactionIdToCancel, selectedFundId, startDate, endDate }) => {
        return {
          setTransactionIdToCancel,
          transactionIdToCancel,
          selectedFundId,
          startDate,
          endDate,
        };
      },
    );
  const { refetch: refreshGetTransactions } = useGetCashTransactions(selectedFundId, startDate, endDate);

  const submitCancelTransaction = useCallback(
    async (transactionIdToCancel: number, cancelReasonText: string) => {
      if (transactionIdToCancel === undefined) return;
      const updateData: IUpdateCashTransactionEntryRequest = {
        id: transactionIdToCancel,
        cancel: true,
        cancelReasonText,
      };
      try {
        await updateCashTransactionEntry(updateData);
        setConfirmCancel(false);
        setTransactionIdToCancel(undefined);
        setCancelReasonText('');
        refreshGetTransactions();
      } catch (e) {
        setConfirmCancel(false);
        setTransactionIdToCancel(undefined);
        setCancelReasonText('');
      }
    },
    [transactionIdToCancel],
  );

  const handleCancelTransaction = useCallback(async () => {
    setConfirmCancel(true);
  }, [confirmCancel]);

  return (
    <>
      <Alert
        onConfirm={handleCancelTransaction}
        onCancel={() => setTransactionIdToCancel(undefined)}
        isOpen={!!transactionIdToCancel && !confirmCancel}
        confirmButtonText="Yes"
        cancelButtonText="Cancel"
        icon="trash"
        intent={Intent.DANGER}
      >
        <div className="modal-details"> Do you want to cancel the cash transaction?</div>
      </Alert>

      {transactionIdToCancel && confirmCancel && (
        <Dialog
          icon={'trash'}
          onClose={() => {
            setTransactionIdToCancel(undefined);
            setConfirmCancel(false);
          }}
          canOutsideClickClose={false}
          isOpen={!!confirmCancel}
          isCloseButtonShown={!isRequestInProgress}
          title={'Cancel Transaction'}
        >
          <div>
            {!isRequestInProgress && (
              <Box p={3}>
                <label>Please Enter the reason to cancel the transaction*</label>
                <Box my={2}>
                  <textarea
                    className="bp3-input bp3-fill"
                    maxLength={80}
                    onChange={(e) => setCancelReasonText(e.target.value)}
                  ></textarea>
                </Box>
              </Box>
            )}
            {isRequestInProgress && (
              <Box p={3}>
                <p>Canceling the transaction...</p>
              </Box>
            )}

            <Box px={3} display="flex" flexDirection="row-reverse">
              <Button
                loading={isRequestInProgress}
                intent={Intent.DANGER}
                text="Cancel Transaction"
                onClick={() => submitCancelTransaction(transactionIdToCancel, cancelReasonText)}
              ></Button>
              <Button
                disabled={isRequestInProgress}
                minimal={true}
                text="Discard"
                onClick={() => {
                  setTransactionIdToCancel(undefined);
                  setConfirmCancel(false);
                }}
              ></Button>
            </Box>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmCancelCashTransactionModal;

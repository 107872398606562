import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { FC, ReactNode } from 'react';
import useAuthStore from '@stores/useAuthStore';

interface IAuth0ProviderWithHistoryProps {
  children: ReactNode;
}

const Auth0ProviderWithHistory: FC<IAuth0ProviderWithHistoryProps> = ({ children }) => {
  const domain = String(process.env.REACT_APP_AUTH0_DOMAIN);
  const clientId = String(process.env.REACT_APP_AUTH0_CLIENT_ID);
  const audience = String(process.env.REACT_APP_AUTH0_AUDIENCE);
  const connection = String(process.env.REACT_APP_AUTH0_CONNECTION);
  const history = useHistory();
  const { currentUserOrgRole } = useAuthStore();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="memory"
      orgId={currentUserOrgRole?.org?.id}
      connection={connection}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

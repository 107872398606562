import { FC, useMemo } from 'react';
import FileSaver from 'file-saver';
import { useCallback } from 'react';
import ExcelJS from 'exceljs';
import { IGetExposureResponse } from '@shared/exchange/getExposure';
import IExposure from '@shared/interfaces/IExposure';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import autoWidth from '@common/utils/exportToExcelUtils/autoWidth';
import formatAllocationPercentageWithBoundsCheck from '@common/utils/formatAllocationPercentageWithBoundsCheck';
import { Button } from '@blueprintjs/core';

const setHierarchyData = (
  data: IExposure[] | undefined,
  formattedData: Array<Array<string | number>> = [],
  level: number,
  sheet: ExcelJS.Worksheet,
) => {
  if (!data) return;
  data.forEach((row) => {
    const formattedRow: Array<string | number> = [];
    formattedData.push(formattedRow);
    formattedRow.push(row.name);
    formattedRow.push(+Number(row.asset_class_nav) || 0);
    formattedRow.push(formatAllocationPercentage({ value: Number(row.current_allocation_pct) }));
    formattedRow.push(
      formatAllocationPercentageWithBoundsCheck(
        row.target_allocation_range_lower_bound,
        row.target_allocation_range_upper_bound,
        row.target_allocation,
        row.target_allocation_range_lower_bound,
      ),
    );
    formattedRow.push(
      formatAllocationPercentageWithBoundsCheck(
        row.target_allocation_range_lower_bound,
        row.target_allocation_range_upper_bound,
        row.target_allocation,
        row.target_allocation,
      ),
    );

    formattedRow.push(
      formatAllocationPercentageWithBoundsCheck(
        row.target_allocation_range_lower_bound,
        row.target_allocation_range_upper_bound,
        row.target_allocation,
        row.target_allocation_range_upper_bound,
      ),
    );
    formattedRow.push(row.benchmark_name || '');

    const sheetRow = sheet.addRow(formattedRow);
    if (level === 1) {
      sheetRow.eachCell(function (cell) {
        cell.fill = { type: 'pattern', fgColor: { argb: 'FFdcdcdc' }, pattern: 'solid' };
        cell.font = { italic: true };
      });
    } else if (level == 0) {
      sheetRow.eachCell(function (cell) {
        cell.fill = { type: 'pattern', fgColor: { argb: 'FF002b49' }, pattern: 'solid' };
        cell.font = { color: { argb: 'FFffffff' }, bold: true };
      });
    }

    if (row.subRows) {
      setHierarchyData(row.subRows, formattedData, level + 1, sheet);
    }
  });
};

interface IExposureDataExportProps {
  data: IGetExposureResponse;
}
const ExportExposureData: FC<IExposureDataExportProps> = ({ data }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const filterDate = data.date;

  const fundName = useMemo(() => {
    return data.allFundsofCurrentOrg?.find((fund) => fund.id === data.fundId)?.fundName;
  }, [data]);

  const getExcelWorkBook = useCallback(() => {
    const metaDataRows = [
      ['Fund', fundName],
      ['Date', filterDate],
    ];

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`${fundName + '_' + filterDate}`);
    sheet.addRows(metaDataRows);
    sheet.addRows(['']);

    const headerRow = [
      '',
      'Net Assets',
      'Current Allocation',
      'Lower Bound',
      'Target Allocation',
      'Upper Bound',
      'Asset Class Benchmark',
    ];
    sheet.addRow(headerRow).font = { bold: true };

    const formattedData: Array<Array<string | number>> = [];
    setHierarchyData(data.exposureData.allocations, formattedData, 0, sheet);
    const totalRow = [
      'Total Fund',
      +Number(data.exposureData.totalAssetClassNav),
      formatAllocationPercentage({ value: data.exposureData.totalTargetAllocation }),
      '',
      formatAllocationPercentage({ value: data.exposureData.totalTargetAllocation }),
      '',
      '',
    ];

    const sheetTotalRow = sheet.addRow(totalRow);
    sheetTotalRow.eachCell(function (cell) {
      cell.fill = { type: 'pattern', fgColor: { argb: 'FF002b49' }, pattern: 'solid' };
      cell.font = { color: { argb: 'FFffffff' }, bold: true };
    });

    const cellBoldStyle = { font: { bold: true } };
    sheet.getCell('A1').style = cellBoldStyle;
    sheet.getCell('A2').style = cellBoldStyle;

    sheet.getColumn(2).numFmt = '$#,##0.00';

    autoWidth(sheet, 2);

    return workbook;
  }, [data]);

  const exportToCSV = async () => {
    const workbook = getExcelWorkBook();
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, `${fundName + '_' + filterDate}` + fileExtension);
  };

  return (
    <Button intent={'primary'} onClick={exportToCSV} icon="export">
      Export
    </Button>
  );
};

export default ExportExposureData;

import MainLayout from './layouts/MainLayout';
import DashboardPage from './pages/DashboardPage';
import { IRoute } from './common/interfaces';
import ExposurePage from './pages/ExposurePage';
import CashManementRecordCreationPage from './pages/CashManagementEntryCreationPage';
import CashManagementPage from './pages/CashManagementPage/CashManagementPage';
import AdministrationFundsPage from './pages/Adminstration/AdministrationFundsPage';
import AdministrationLayout from './layouts/AdministrationLayout';
import AccountsPage from './pages/Adminstration/AccountsPage';
import DocTemplateCreationPage from './pages/Adminstration/DocTemplateCreationPage';
import DocsPage from './pages/Adminstration/DocsPage';
import UsersPage from './pages/Adminstration/UsersPage';
import UserCreationPage from './pages/Adminstration/UserCreationPage';
import TradeManagementPage from './pages/TradeManagementPage';
import OpsManagement from './pages/OpsManagement';
import DocumentManagement from './pages/DocumentManagement';
import ACLLoadingPage from './pages/ACLLoadingPage/ACLLoadingPage';
import WhatIfRebalPage from './pages/WhatIfRebalPage';
import ExternalTransferAccountsPage from './pages/Adminstration/ExternalTransferAccountsPage';
import ExternalTransferAccountsCreationOrUpdationPage from './pages/Adminstration/ExternalTransferAccountsCreationOrUpdationPage';

const routes: IRoute[] = [
  {
    path: '/dashboard',
    exact: true,
    Page: DashboardPage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Dashboard',
    acls: [
      {
        operationType: 'DASHBOARD',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/exposure',
    Page: ExposurePage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Exposure',
    acls: [
      {
        operationType: 'EXPOSURE',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/cash-management',
    Page: CashManagementPage,
    Layout: MainLayout,
    exact: true,
    isAuthenticationRequired: true,
    pageTitle: 'Cash Management',
    acls: [
      {
        operationType: 'CASH_MANAGEMENT',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/trade-management',
    Page: TradeManagementPage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Trade Order Management',
    acls: [
      {
        operationType: 'TRADE_MANAGEMENT_HISTORY',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/administration/funds',
    Page: AdministrationFundsPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Funds',
    acls: [
      {
        operationType: 'ADMIN_FUNDS',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/cash-management/create',
    Page: CashManementRecordCreationPage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Cash Management',
    exact: true,
    acls: [
      {
        operationType: 'CASH_MANAGEMENT',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/administration/accounts',
    Page: AccountsPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Document Templates',
    acls: [
      {
        operationType: 'ADMIN_ACCOUNTS',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/administration/doc-templates',
    Page: DocsPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Create Document Template',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_DOCS',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/administration/doc-templates/create',
    Page: DocTemplateCreationPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Accounts',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_DOCS',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/administration/users',
    Page: UsersPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Users',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_USERS',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/administration/users/create',
    Page: UserCreationPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Invite Users',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_USERS',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/administration/external-accounts',
    Page: ExternalTransferAccountsPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'External Transfer/Suspense Accounts',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_EXTERNAL_TRANSFER_ACCOUNTS',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/administration/external-accounts/create',
    Page: ExternalTransferAccountsCreationOrUpdationPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'External Transfer/Suspense Accounts',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_EXTERNAL_TRANSFER_ACCOUNTS',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/administration/external-accounts/edit',
    Page: ExternalTransferAccountsCreationOrUpdationPage,
    Layout: AdministrationLayout,
    isAuthenticationRequired: true,
    pageTitle: 'External Transfer/Suspense Accounts',
    exact: true,
    acls: [
      {
        operationType: 'ADMIN_EXTERNAL_TRANSFER_ACCOUNTS',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/opsmanagement',
    exact: true,
    Page: OpsManagement,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Operations Management',
    acls: [
      {
        operationType: 'OPS_MANAGEMENT',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/doc-management/funds',
    exact: true,
    Page: DocumentManagement,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'Document Management',
    acls: [
      {
        operationType: 'DOCUMENT_MANAGEMENT_FUND_CLIENT_SERVICE',
        operation: 'READ',
      },
      {
        operationType: 'DOCUMENT_MANAGEMENT_FUND_INTERNAL',
        operation: 'READ',
      },
    ],
  },
  {
    path: '/what-if-rebal',
    exact: true,
    Page: WhatIfRebalPage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: 'What-If Rebalancing',
    acls: [
      {
        operationType: 'WHAT_IF_REBALANCE',
        operation: 'WRITE',
      },
    ],
  },
  {
    path: '/',
    exact: true,
    Page: ACLLoadingPage,
    Layout: MainLayout,
    isAuthenticationRequired: true,
    pageTitle: '',
    acls: [
      {
        operationType: 'DASHBOARD',
        operation: 'READ',
      },
    ],
  },
];

export default routes;

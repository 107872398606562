import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IUpdateTradeStatusResponse {
  id: number;
}

interface IUpdateTradeStatusRequest {
  shouldCancel?: boolean;
  cashTransactionId: number;
}
export default function useSettleCashTransaction(): UseMutationResult<
  IUpdateTradeStatusResponse,
  AxiosError,
  IUpdateTradeStatusRequest,
  IUpdateTradeStatusResponse
> {
  const axios = useAxios();
  return useMutation<IUpdateTradeStatusResponse, AxiosError, IUpdateTradeStatusRequest, IUpdateTradeStatusResponse>(
    async ({ shouldCancel, cashTransactionId }: IUpdateTradeStatusRequest) => {
      const routePath = 'settle-cash-transaction';
      const searchParams = new URLSearchParams();
      if (shouldCancel) {
        searchParams.append('cancel', 'true');
      }
      return axios
        ?.put(`${routePath}?${searchParams.toString()}`, { cashTransactionId: cashTransactionId })
        .then((res) => res.data.data);
    },
  );
}

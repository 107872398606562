import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetUserMetadataResponse } from '@shared/exchange/getUserMetadata';

export default function useUserMetadata(): UseQueryResult<IGetUserMetadataResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetUserMetadataResponse, IErrorResponse>(
    ['admin-user-metadata'],
    async () =>
      axios
        ?.get('get-user-metadata')
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false },
  );
}

const CHART_COLORS = [
  '#114B94',
  '#4CB7EE',
  '#20DCC5',
  '#1F86FF',
  '#54E0FF',
  '#5055A6',
  '#C75A6C',
  '#28C195',
  '#8D253D',
  '#9C0039',
];
export default CHART_COLORS;

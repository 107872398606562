import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateUserRequest, ICreateUserResponse } from '@shared/exchange/createUser';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useCreateUser(): UseMutationResult<
  ICreateUserResponse,
  IErrorResponse,
  ICreateUserRequest,
  ICreateUserResponse
> {
  const axios = useAxios();
  return useMutation<ICreateUserResponse, IErrorResponse, ICreateUserRequest, ICreateUserResponse>(
    async (data: ICreateUserRequest) =>
      axios
        ?.post('create-user', data)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
  );
}

import IFund from '@shared/interfaces/IFund';
import dayjs from 'dayjs';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface ITradeOrderDataFromRebalResult {
  accountId: number;
  buy: number;
  sell: number;
}

interface ITradeOrderManagementStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;

  allFunds: IFund[] | undefined;
  setAllFunds: (allFunds: IFund[]) => void;

  switchFund: (fundId: number) => void;
  resetTradeOrderManagementStore: () => void;

  selectedFilterStartDate: string | undefined;
  setSelectedFilterStartDate: (startDate: Date | undefined) => void;

  selectedFilterEndDate: string | undefined;
  setSelectedFilterEndDate: (endDate: Date | undefined) => void;

  startDate: Date | undefined;
  endDate: Date | undefined;

  setFilterDateRange: (stardDate: Date | undefined, endDate: Date | undefined) => void;

  activeTab: string | null;
  setActiveTab: (tabName: string | null) => void;

  tradeOrdersFromRebalResults: ITradeOrderDataFromRebalResult[] | undefined;
  setTradeOrdersFromRebalResults: (tradeOrdersFromRebalResults: ITradeOrderDataFromRebalResult[] | undefined) => void;
}

const useTradeOrderManagementStore = create<ITradeOrderManagementStore>(
  persist<ITradeOrderManagementStore>(
    (set: SetState<ITradeOrderManagementStore>) => ({
      selectedFundId: undefined,
      allFunds: undefined,
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },
      setAllFunds: (allFunds: IFund[]) => {
        set({ allFunds: allFunds });
      },
      resetTradeOrderManagementStore: (): void => {
        set({ selectedFundId: undefined, allFunds: undefined });
      },
      switchFund: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },

      selectedFilterStartDate: undefined,
      setSelectedFilterStartDate: (startDate: Date | undefined) => {
        set({ startDate: startDate });
      },

      selectedFilterEndDate: undefined,
      setSelectedFilterEndDate: (endDate: Date | undefined) => {
        set({ endDate: endDate });
      },
      setFilterDateRange: (startDate: Date | undefined, endDate: Date | undefined) => {
        set({ startDate: startDate, endDate: endDate });
      },
      activeTab: 'TradeHistory',
      setActiveTab: (tabName: string | null) => {
        set({ activeTab: tabName });
      },
      startDate: dayjs().subtract(30, 'days').toDate(),
      endDate: dayjs().toDate(),

      tradeOrdersFromRebalResults: undefined,
      setTradeOrdersFromRebalResults: (tradeOrdersFromRebalResults: ITradeOrderDataFromRebalResult[] | undefined) => {
        set({ tradeOrdersFromRebalResults: tradeOrdersFromRebalResults });
      },
    }),
    {
      name: 'trade-order-management-cache',
      blacklist: ['allFunds', 'selectedFundId', 'startDate', 'endDate', 'tradeOrdersFromRebalResults'],
    },
  ),
);

export default useTradeOrderManagementStore;

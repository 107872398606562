import { FC, ReactElement } from 'react';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderUserSettings from './HeaderUserSettings';
import HeaderOrgChangeForm from './HeaderOrgChangeForm';
import useCurrentUser from '../../common/hooks/useCurrentUser';
import useAuthStore from '@stores/useAuthStore';
import { Alignment, Navbar } from '@blueprintjs/core';
import { Box, Flex } from '@rebass/grid/emotion';
import AppSettings from './AppSettings';

interface IHeaderProps {
  pageTitle?: string;
  pageSubTitle?: string;
}
const Header: FC<IHeaderProps> = ({ pageTitle }): ReactElement => {
  const { isAuthenticated } = useAuth0();
  const { data: currentUser } = useCurrentUser();
  const currentUserOrgRole = useAuthStore((state) => state.currentUserOrgRole);

  return (
    <>
      <Navbar fixedToTop={true} style={{ paddingLeft: '4rem', zIndex: 20 }}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <h4 className="bp3-heading" style={{ marginBottom: 0 }}>
              {pageTitle}
            </h4>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {isAuthenticated && (
            <>
              <Flex>
                <Box mr={2}>
                  <HeaderOrgChangeForm orgRoles={currentUser?.userOrgRoles} currentOrgRole={currentUserOrgRole} />
                </Box>
                <Box mr={2}>
                  <HeaderUserSettings user={currentUser?.user} />
                </Box>

                <Box mr={2}>
                  <AppSettings></AppSettings>
                </Box>
                <LogoutButton />
              </Flex>
            </>
          )}
        </Navbar.Group>
      </Navbar>
    </>
  );
};

export default Header;

import { ReactElement } from 'react';
import TradeHistoryPane from './TradeHistory/TradeHistoryPane';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import PageToolbar from '@components/PageToolbar';
import useFunds from '@common/hooks/useFunds';
import { useEffect } from 'react';
import TradeOrderCreationPane from './TradeOrderCreation/TradeOrderCreationPane';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import TableLoader from '@components/TableLoader';
import TradeProcessingPane from './TradeOrderProcessing/TradeProcessingPane';
import useAuthStore from '@stores/useAuthStore';
import { Alignment, Card, Elevation, Navbar, Tab, TabId, Tabs } from '@blueprintjs/core';
import { Box } from 'reflexbox';

const TradeManagement = (): ReactElement => {
  const { selectedFundId, setSelectedFundId, switchFund, activeTab, setActiveTab } = useTradeOrderManagementStore(
    ({ selectedFundId, setSelectedFundId, switchFund, activeTab, setActiveTab }) => {
      return { selectedFundId, setSelectedFundId, switchFund, activeTab, setActiveTab };
    },
  );
  const { data: fundData } = useFunds();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTabChange = (tabId: TabId, prevTabId: TabId) => {
    setActiveTab(tabId as string);
  };

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
  }, [fundData]);

  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);

  return (
    <>
      <PageToolbar
        leftSegment={
          <>
            {selectedFundId && fundData?.funds && (
              <FundSelectionDropdown
                switchFund={switchFund}
                selectedFundId={selectedFundId}
                allFunds={fundData?.funds}
                disabled={false}
              ></FundSelectionDropdown>
            )}
          </>
        }
      ></PageToolbar>
      <Box mx={2} mt={4}>
        {!selectedFundId || !activeTab ? (
          <div className="card">
            <div className="card-body">
              <TableLoader></TableLoader>
            </div>
          </div>
        ) : (
          <Card elevation={Elevation.TWO} style={{ padding: 0 }}>
            <Navbar className="card-tabs-navbar">
              <Navbar.Group align={Alignment.LEFT}>
                <Tabs selectedTabId={activeTab} onChange={handleTabChange} renderActiveTabPanelOnly={true}>
                  {canCurrentUserWriteACL('TRADE_MANAGEMENT_CREATE') && (
                    <Tab id="TradeOrderEntry" title="Trade Order Entry" />
                  )}
                  <Tab id="TradeOrderProcessing" title="Trade Order Processing" />
                  <Tab id="TradeHistory" title="Trade History" />
                </Tabs>
              </Navbar.Group>
            </Navbar>
            <Box>
              {activeTab === 'TradeOrderEntry' && fundData && (
                <TradeOrderCreationPane selectedFundId={selectedFundId} />
              )}
              {activeTab === 'TradeOrderProcessing' && fundData && (
                <TradeProcessingPane selectedFundId={selectedFundId} />
              )}
              {activeTab === 'TradeHistory' && fundData && <TradeHistoryPane selectedFundId={selectedFundId} />}
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
};

export default TradeManagement;

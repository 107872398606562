import IFund from '@shared/interfaces/IFund';
import create, { GetState, SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface IWhatIfRebalStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;
  switchFund: (fundId: number) => void;
  allFunds: IFund[] | undefined;
  setAllFunds: (allFunds: IFund[]) => void;

  lockedAccountsMap: Record<string | number, boolean>;
  setAccountLocked: (accountId: number, lock: boolean) => void;
  resetAllAccountLocked: () => void;
  setAllAccountsLocked: (lock: boolean) => void;

  userDefinedCashflowRedemption: number | undefined;
  setUserDefinedCashflowRedemption: (redemption: string | undefined) => void;
  userDefinedCashflowContribution: number | undefined;
  setUserDefinedCashflowContribution: (contribution: string | undefined) => void;

  scheduledCashflowRedemption: number | undefined;
  setScheduledCashflowRedemption: (redemption: string | undefined) => void;
  scheduledCashflowContribution: number | undefined;
  setScheduledCashflowContribution: (contribution: string | undefined) => void;

  transactionsDegree: number;
  setTransactionsDegree: (degree: number) => void;

  targetConstraints: number;
  setTargetConstraints: (constraints: number) => void;

  isScheduledCashFlowEnabled: boolean;
  setScheduledCashFlowEnabled: (val: boolean) => void;
}

const useWhatIfRebalStore = create<IWhatIfRebalStore>(
  persist<IWhatIfRebalStore>(
    (set: SetState<IWhatIfRebalStore>, get: GetState<IWhatIfRebalStore>) => ({
      selectedFundId: undefined,
      allFunds: undefined,
      setAllFunds: (allFunds: IFund[]) => {
        set({ allFunds: allFunds });
      },
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },
      switchFund: (fundId: number): void => {
        set({ selectedFundId: fundId, userDefinedCashflowRedemption: 0, userDefinedCashflowContribution: 0 });
      },
      lockedAccountsMap: {},
      setAccountLocked: (accountId: number, lock: boolean) => {
        set((previousValue) => {
          return { lockedAccountsMap: { ...previousValue.lockedAccountsMap, [accountId]: lock } };
        });
      },
      resetAllAccountLocked: () => {
        set({ lockedAccountsMap: {} });
      },
      userDefinedCashflowRedemption: 0,
      setUserDefinedCashflowRedemption: (redemption: string | undefined) => {
        const value = redemption ? Number(redemption) : undefined;
        set({ userDefinedCashflowRedemption: value });
      },
      userDefinedCashflowContribution: 0,
      setUserDefinedCashflowContribution: (contribution: string | undefined) => {
        const value = contribution ? Number(contribution) : undefined;
        set({ userDefinedCashflowContribution: value });
      },
      scheduledCashflowRedemption: 0,
      setScheduledCashflowRedemption: (redemption: string | undefined) => {
        const value = redemption ? Number(redemption) : undefined;
        set({ scheduledCashflowRedemption: value });
      },
      scheduledCashflowContribution: 0,
      setScheduledCashflowContribution: (redemption: string | undefined) => {
        const value = redemption ? Number(redemption) : undefined;
        set({ scheduledCashflowContribution: value });
      },
      setAllAccountsLocked: (lock: boolean) => {
        const lockedAccountsMap = get().lockedAccountsMap;
        const tempLockedAccountsMap: Record<string | number, boolean> = {};
        if (lockedAccountsMap) {
          for (const lockedAccountKey of Object.keys(lockedAccountsMap)) {
            tempLockedAccountsMap[lockedAccountKey] = !!lock;
          }
          set({ lockedAccountsMap: tempLockedAccountsMap });
        }
      },

      transactionsDegree: 5,
      setTransactionsDegree: (degree: number) => {
        set({ transactionsDegree: degree });
      },

      targetConstraints: 5,
      setTargetConstraints: (constraints: number) => {
        set({ targetConstraints: constraints });
      },

      isScheduledCashFlowEnabled: true,
      setScheduledCashFlowEnabled: (val: boolean) => {
        set({ isScheduledCashFlowEnabled: val });
      },
    }),
    {
      name: 'whatif-rebal-cache',
      blacklist: [
        'transactionsDegree',
        'targetConstraints',
        'lockedAccountsMap',
        'userDefinedCashflowRedemption',
        'userDefinedCashflowContribution',
        'scheduledCashflowRedemption',
        'scheduledCashflowContribution',
      ],
    },
  ),
);

export default useWhatIfRebalStore;

import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import ICustodialFeed from '@shared/interfaces/ICustodialFeed';
import { ReactElement, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { Box } from 'reflexbox';

function Table({ columns, data }: { columns: Column<ICustodialFeed>[]; data: ICustodialFeed[] }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              <th style={{ padding: 5, fontWeight: 'normal' }}>#</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id} style={{ padding: 5, fontWeight: 500 }}>
                  {/*column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>{column.isGrouped ? 'GG ' : ' '}</span>
                      ) : null*/}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                <td>{i + 1}</td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id + '_' + cell.row.id}>
                      {cell.isGrouped ? (
                        <>{cell.render('Cell')}</>
                      ) : cell.isAggregated ? (
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <Box mt={2}>
          <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No Feeds Found.</h5>} />
        </Box>
      )}
    </>
  );
}

function FeedsPanel({
  data,
  setSelectedCustodialFeed,
}: {
  data: ICustodialFeed[];
  setSelectedCustodialFeed: (feed: ICustodialFeed) => void;
}): ReactElement {
  const custodialFeedColumns: Column<ICustodialFeed>[] = useMemo(
    () => [
      {
        Header: 'Feed Name',
        accessor: 'feedName',
        Cell: ({ value }) => {
          return <Box width="15rem">{value}</Box>;
        },
      },
      {
        Header: 'Feed Delivery Type',
        accessor: 'feedDeliveryTypeCode',
      },
      {
        Header: 'Feed Type',
        accessor: 'feedTechTypeCode',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row }) => {
          return (
            <Button
              rightIcon="chevron-right"
              onClick={() => setSelectedCustodialFeed(row.original)}
              intent={Intent.PRIMARY}
              outlined
              minimal
            >
              View Files
            </Button>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <Table columns={custodialFeedColumns} data={data} />
    </>
  );
}

export default FeedsPanel;

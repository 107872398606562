import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetTradesResponse } from '@shared/exchange/getTrades';
import useDateStore from '@stores/useDateStore';

export const GET_TRADES_KEY = 'get-trades';
export default function useGetTrades(
  fundId: number | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined,
  reqType: 'HISTORY' | 'PROCESSING',
): UseQueryResult<IGetTradesResponse, IErrorResponse> {
  const axios = useAxios();
  const timezone = useDateStore((store) => store.timezone);

  return useQuery<IGetTradesResponse, IErrorResponse>(
    [GET_TRADES_KEY, { fundId, reqType, startDate, endDate }],
    async () => {
      if (!fundId) return [];
      const searchParams = new URLSearchParams();
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      if (startDate) {
        const startDateStr = startDate.toUTCString();
        searchParams.append('startDate', startDateStr);
      }
      if (endDate) {
        const endDateStr = endDate.toUTCString();
        searchParams.append('endDate', endDateStr);
      }
      searchParams.append('reqType', String(reqType));
      searchParams.append('tz', timezone);
      return axios
        ?.get(`get-trades?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false, refetchOnReconnect: true },
  );
}

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IGetCashTransactionResponse from '@shared/exchange/getCashTransactions/IGetCashTransactionResponse';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import useDateStore from '@stores/useDateStore';

export const GET_CASH_TRANSACTIONS_KEY = 'get-cash-transactions';

export default function useGetCashTransactions(
  fundId?: number | undefined,
  startDate?: string,
  endDate?: string,
  searchTradeId?: string | undefined,
  searchTradeItemId?: string | undefined,
): UseQueryResult<IGetCashTransactionResponse, IErrorResponse> {
  console.log(fundId, startDate, endDate, searchTradeId, searchTradeItemId);
  const axios = useAxios();
  const timezone = useDateStore((store) => store.timezone);
  const searchParams = new URLSearchParams();
  if (searchTradeId) {
    searchParams.append('tradeId', searchTradeId);
  } else if (searchTradeItemId) {
    searchParams.append('tradeItemId', searchTradeItemId);
  } else {
    if (fundId) {
      searchParams.append('fundId', String(fundId));
    }
    if (startDate) {
      searchParams.append('startDate', startDate);
    }
    if (endDate) {
      searchParams.append('endDate', endDate);
    }
    searchParams.append('tz', timezone);
  }
  const filterQuery = searchParams.toString();

  return useQuery<IGetCashTransactionResponse, IErrorResponse>(
    [GET_CASH_TRANSACTIONS_KEY, { filterQuery }],
    async () => {
      const routePath = 'get-cash-transactions';
      return axios
        ?.get(`${routePath}?${filterQuery}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    {
      staleTime: 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useWhatIfRebal(): UseMutationResult<any, IErrorResponse, any, any> {
  const axios = useAxios();
  return useMutation<any, IErrorResponse, any, any>(async (data: any) => {
    const routePath = 'proxy-data-science-service';
    const reqData = { payload: data, serviceName: 'WhatIfAllocation' };
    return axios?.post(routePath, reqData).then((res) => res.data.data);
  });
}

import { FC, ReactElement, useMemo } from 'react';
import TradeOrderCreationForm from './TradeOrderCreationForm';
import useCreateTradeMetadata from '@common/hooks/useGetCreateTradeMetadata';
import useFundsSummary from '@common/hooks/useFundsSummary';
import TableLoader from '@components/TableLoader';

interface ITradeOrderCreationPaneProps {
  selectedFundId: number | undefined;
}
const TradeOrderCreationPane: FC<ITradeOrderCreationPaneProps> = ({ selectedFundId }): ReactElement => {
  const { data: createTradeMetadata } = useCreateTradeMetadata(selectedFundId);
  const { data: fundsSummaryData } = useFundsSummary();

  const summaryOfSelectedFund = useMemo(() => {
    if (!fundsSummaryData) return null;
    return fundsSummaryData?.fundsSummary.find((_) => _.fund_id === selectedFundId);
  }, [fundsSummaryData, selectedFundId]);

  return (
    <>
      {(!selectedFundId || !createTradeMetadata) && <TableLoader></TableLoader>}

      {createTradeMetadata && selectedFundId && (
        <TradeOrderCreationForm
          accounts={createTradeMetadata.accounts}
          cashAccounts={createTradeMetadata.cashAccounts}
          totalCash={summaryOfSelectedFund?.total_cash}
          selectedFundId={selectedFundId}
        ></TradeOrderCreationForm>
      )}
    </>
  );
};

export default TradeOrderCreationPane;

import ITrade from '@shared/interfaces/ITrade';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import getTradeHistoryColumns from './getTradeHistoryColumns';
import TradeHistoryTable from './TradeHistoryTable';
//import Search from '../../../assets/img/search-icon.svg';
//import Filter from '../../../assets/img/filter-white-icon.svg';
//import Export from '../../../assets/img/export-right-icon.svg';

import useGetTrades from '@common/hooks/useGetTrades';
import TableLoader from '@components/TableLoader';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import CustomDatePicker from '@components/CustomDateRangePicker';
import GlobalLoader from '@components/GlobalLoader';
import dayjs from 'dayjs';
import { Alignment, Navbar } from '@blueprintjs/core';
import TradeStatusSelectionDropdown from '../components/TradeStatusSelectionDropdown';
import { Box } from 'reflexbox';

interface ITradeHistoryPaneProps {
  selectedFundId: number | undefined;
}

const TradeHistoryPane = ({ selectedFundId }: ITradeHistoryPaneProps): ReactElement => {
  const tradeHistoryTableColumns = useMemo<Column<ITrade>[]>(getTradeHistoryColumns, []);

  const { setSelectedFilterStartDate, setSelectedFilterEndDate, startDate, endDate } = useTradeOrderManagementStore(
    ({ startDate, endDate, setSelectedFilterStartDate, setSelectedFilterEndDate }) => {
      return {
        setSelectedFilterStartDate,
        setSelectedFilterEndDate,
        startDate,
        endDate,
      };
    },
  );

  const {
    data: tradesData,
    isLoading: isTradesDataLoading,
    isFetching,
  } = useGetTrades(selectedFundId, startDate, endDate, 'HISTORY');

  useEffect(() => {
    if (!startDate) setSelectedFilterStartDate(dayjs(tradesData?.fromDate).toDate());
    if (!endDate) setSelectedFilterEndDate(dayjs(tradesData?.toDate).toDate());
  }, [tradesData, startDate, endDate]);

  const [selectedTradeStatusName, setSelectedTradeStatusName] = useState<string | undefined>();
  const handleChangeSelectedTradeStatus = (tradeStatusName: string | undefined) => {
    console.log('tradeStatusId', tradeStatusName);
    setSelectedTradeStatusName(tradeStatusName);
  };
  return (
    <>
      <div className="TradeOrderProcessing tradeHistory fullChange">
        <div className="tableInfo">
          {isFetching && <GlobalLoader></GlobalLoader>}
          {isTradesDataLoading || !selectedFundId ? (
            <TableLoader></TableLoader>
          ) : (
            <div>
              {tradesData && (
                <>
                  <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                      <CustomDatePicker
                        isLoading={false}
                        setStartDate={(date) => setSelectedFilterStartDate(date)}
                        setEndDate={(date) => setSelectedFilterEndDate(date)}
                        startDate={startDate}
                        endDate={endDate}
                      ></CustomDatePicker>
                      <Box ml={2}>
                        <TradeStatusSelectionDropdown
                          selectedTradeStatusName={selectedTradeStatusName}
                          switchTradeStatus={handleChangeSelectedTradeStatus}
                          tradeStatuses={tradesData.tradeStatuses}
                          disabled={isTradesDataLoading}
                        ></TradeStatusSelectionDropdown>
                      </Box>
                    </Navbar.Group>
                  </Navbar>

                  <div>
                    <TradeHistoryTable
                      columns={tradeHistoryTableColumns}
                      selectedTradeStatusName={selectedTradeStatusName}
                      data={tradesData.trades}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TradeHistoryPane;

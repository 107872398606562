import { InputGroup } from '@blueprintjs/core';
import { FC, ReactElement, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

interface IGlobalTableFilterProps {
  globalFilter: string;
  setGlobalFilter: (value: string | undefined) => void;
}

const GlobalTableFilter: FC<IGlobalTableFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}: IGlobalTableFilterProps): ReactElement => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup
      asyncControl={true}
      leftIcon="search"
      type="search"
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder="Search"
    />
  );
};

export default GlobalTableFilter;

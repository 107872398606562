import { IGetDocSignedDownloadUrlResponse } from '@shared/exchange/getDocSignedDownloadUrl';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IGetDocFactSignedDownloadURLRequest {
  docFactId: number;
  signed: boolean;
}

export default function useGetDocFactSignedDownloadUrl(): UseMutationResult<
  IGetDocSignedDownloadUrlResponse,
  IErrorResponse,
  IGetDocFactSignedDownloadURLRequest,
  IGetDocFactSignedDownloadURLRequest
> {
  const axios = useAxios();
  return useMutation<
    IGetDocSignedDownloadUrlResponse,
    IErrorResponse,
    IGetDocFactSignedDownloadURLRequest,
    IGetDocFactSignedDownloadURLRequest
  >(async (data: IGetDocFactSignedDownloadURLRequest) =>
    axios
      ?.post('get-doc-fact-signed-download-url', data)
      .then((res) => res.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

import { useAxios } from '@common/providers/AxiosProvider';
import {
  ICreateDocMgmtFolderEntryRequest,
  ICreateDocMgmtFolderEntryResponse,
} from '@shared/exchange/createDocMgmtFolderEntry';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useCreateDocMgmtFolderEntry(): UseMutationResult<
  ICreateDocMgmtFolderEntryResponse,
  IErrorResponse,
  ICreateDocMgmtFolderEntryRequest,
  ICreateDocMgmtFolderEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateDocMgmtFolderEntryResponse,
    IErrorResponse,
    ICreateDocMgmtFolderEntryRequest,
    ICreateDocMgmtFolderEntryResponse
  >(async (data: ICreateDocMgmtFolderEntryRequest) =>
    axios
      ?.post('create-doc-mgmt-folder-entry', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

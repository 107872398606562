import { Alert, Intent } from '@blueprintjs/core';
import useDocs, { GET_DOCS_KEY } from '@common/hooks/useDocs';
import useUpdateDoc from '@common/hooks/useUpdateDoc';
import IGetDocsResponse from '@shared/exchange/getDocs/IGetDocsRequest';
import IUpdateDocRequest from '@shared/exchange/updateDoc/IUpdateDocRequest';
import IDoc from '@shared/interfaces/IDoc';
import useAdministrationStore from '@stores/useAdministrationStore';
import { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';

const ConfirmDeactiveDocModel = (): ReactElement => {
  const { docToUpdate, setDocToUpdate } = useAdministrationStore(({ docToUpdate, setDocToUpdate }) => {
    return {
      docToUpdate,
      setDocToUpdate,
    };
  });
  const { refetch: refetchDocs } = useDocs();
  const queryClient = useQueryClient();

  const { mutateAsync: updateDoc, isLoading } = useUpdateDoc();

  const handleDeactivateDoc = useCallback(async () => {
    if (docToUpdate === undefined) return;
    const updateData: IUpdateDocRequest = {
      id: docToUpdate.id,
      deactivate: docToUpdate.active_ind === 1 ? true : false,
    };
    try {
      queryClient.cancelQueries(GET_DOCS_KEY);
      const response = await updateDoc(updateData);
      setDocToUpdate(undefined);

      /*** Optimistically Update the record ***/
      const previousDocsData: IDoc[] = queryClient.getQueryData<IGetDocsResponse>(GET_DOCS_KEY)?.docs || [];
      const updatedDocsData = previousDocsData.map((doc: IDoc) => {
        if (doc.id !== response.id) return doc;
        else {
          const docUpdated = { ...doc, active_ind: docToUpdate.active_ind === 1 ? 0 : 1 };
          return docUpdated;
        }
      });
      queryClient.setQueryData<IGetDocsResponse>(GET_DOCS_KEY, { docs: updatedDocsData });
      /***********/

      //Refetch to sync with backend
      refetchDocs();
    } catch (e) {
      setDocToUpdate(undefined);
    }
  }, [docToUpdate]);

  const handleCancelDeactivateDoc = useCallback(async () => {
    setDocToUpdate(undefined);
  }, []);

  const action = docToUpdate?.active_ind === 1 ? 'Deactivate' : 'Activate';
  return (
    <>
      <Alert
        isOpen={!!docToUpdate}
        onConfirm={handleDeactivateDoc}
        onCancel={handleCancelDeactivateDoc}
        // onClose={handleCancelRemoveUser}
        icon={action == 'Deactivate' ? 'disable' : 'updated'}
        intent={action == 'Deactivate' ? Intent.DANGER : Intent.PRIMARY}
        cancelButtonText={!isLoading ? 'Cancel' : undefined}
        confirmButtonText={`Yes, ${action.toLowerCase()} the Document.`}
        loading={isLoading}
      >
        <div>
          {!isLoading && <>{`Are you sure you want to ${action.toLowerCase()} the document?`}</>}

          {isLoading && (
            <>
              <p>{`${action.slice(0, -1)}ing the document...`}</p>
            </>
          )}
        </div>
      </Alert>
    </>
  );
};

export default ConfirmDeactiveDocModel;

import useUpdateUser from '@common/hooks/useUpdateUser';
import useUsers, { ADMIN_GET_USERS_KEY } from '@common/hooks/useUsers';
import useAdministrationStore from '@stores/useAdministrationStore';
import { ReactElement, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useQueryClient } from 'react-query';

const ConfirmUserRoleChange = (): ReactElement => {
  const { setUserDataToUpdate, userDataToUpdate } = useAdministrationStore(
    ({ setUserDataToUpdate, userDataToUpdate }) => {
      return {
        setUserDataToUpdate,
        userDataToUpdate,
      };
    },
  );
  const { refetch: refetchUsers } = useUsers();
  const queryClient = useQueryClient();
  const { mutateAsync: updateUser, isLoading } = useUpdateUser({ remove: false });

  const handleUpdateUser = useCallback(async () => {
    if (userDataToUpdate === undefined) return;
    try {
      queryClient.cancelQueries(ADMIN_GET_USERS_KEY);
      await updateUser(userDataToUpdate);
      setUserDataToUpdate(undefined);
      refetchUsers();
    } catch (e) {
      setUserDataToUpdate(undefined);
    }
  }, [userDataToUpdate]);

  const handleCancelUpdateUser = useCallback(() => {
    setUserDataToUpdate(undefined);
  }, []);

  return (
    <>
      {userDataToUpdate && (
        <SweetAlert
          title={undefined}
          showCancel={!isLoading}
          onConfirm={handleUpdateUser}
          onCancel={handleCancelUpdateUser}
          showConfirm={!isLoading}
          btnSize="sm"
          customClass="modal-box  px-0 pt-0"
          closeOnClickOutside={false}
        >
          <>
            <div className="modal-header">Update user</div>
            <div className="modal-details">
              {!isLoading && <>{`Are you sure you want update the role of the user?`}</>}
              {isLoading && (
                <>
                  <p>{`Updating the user's role...`}</p>
                  <p className="mt-2">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </p>
                </>
              )}
            </div>
          </>
        </SweetAlert>
      )}
    </>
  );
};

export default ConfirmUserRoleChange;

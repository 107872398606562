import useGetOpsJobLogs from '@common/hooks/useGetJobLogs';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Column, Row, useFilters, useGlobalFilter, useTable } from 'react-table';
import IEtlJobRun from '@shared/interfaces/IEtlJobRun';
import {
  Alignment,
  Button,
  Classes,
  Divider,
  Drawer,
  Icon,
  Intent,
  MenuItem,
  Navbar,
  NonIdealState,
  ProgressBar,
} from '@blueprintjs/core';
import { Box, Flex } from 'reflexbox';
import CustomDateRangePicker from '@components/CustomDateRangePicker';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import CustomCard from '@components/CustomCard';
import IEtlJobRunLog from '@shared/interfaces/IEtlJobLog';
import useGetJobMetadata from '@common/hooks/useGetJobMetadata';
import CustomDropdown from '@components/CustomDropdown/CustomDropdown';
import IEtlJobControl from '@shared/interfaces/IEtlJobControl';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';
import { AppToaster } from '@components/Toasters';
import FileSaver from 'file-saver';
import useGetETLFileDownloadUrl from '@common/hooks/useGetETLFileDownloadUrl';

const logLevelDisplayTextMap: Record<string, string> = {
  '0': 'Verbose',
  '1': 'Debug',
  '2': 'Info',
  '3': 'Business Exception',
  '4': 'System Exception',
};

const jobRunStatusDisplayTextMap: Record<string, string> = {
  S: 'Success',
  F: 'Failure',
  R: 'Running',
};

const jobTypeDisplayTexMap: Record<string, string> = {
  EXTRACT: 'Extract',
  MERGE: 'Merge',
  LOADER: 'Load',
};

export function JobRunLogs({ data }: { data: IEtlJobRunLog[] }): ReactElement {
  return (
    <>
      <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-condensed" width="100%">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Description</th>
            <th>Log Level</th>
          </tr>
        </thead>
        <tbody>
          {data.map((r) => (
            <tr key={r.id} className={r.logLevel && r?.logLevel >= 3 ? 'bg-red-light' : ''}>
              <td>
                {r.logLevel !== undefined && r.logLevel >= 3 && (
                  <Icon intent={Intent.DANGER} size={14} icon={'warning-sign'}></Icon>
                )}
              </td>
              <td>{dayjs(r.logDt).format('MM/DD/YYYY HH:mm:ss')}</td>
              <td>
                <Box style={{ paddingLeft: (r.logIndentLevel || 0) * 2 + 'rem' }}>
                  {r.logIndentLevel != 0 && ' '}
                  {Array(r.logIndentLevel)
                    .fill(0)
                    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                    .map((_) => ' ')}{' '}
                  {r.logDesc}
                </Box>
              </td>
              <td>{r.logLevel != undefined && logLevelDisplayTextMap[String(r.logLevel)]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <Box mt={2}>
          <NonIdealState
            icon={'issue'}
            title={<h5 className="bp3-heading"> No Logs Found for the selected Job and Date range.</h5>}
          />
        </Box>
      )}
      <Divider style={{ margin: 0 }}></Divider>
    </>
  );
}

function Table({
  columns,
  data,
  setSelectedJobRunId,
  selectedJob,
  selectedJobTypeKey,
  globalFilterValue,
}: {
  columns: Column<IEtlJobRun>[];
  data: IEtlJobRun[];
  selectedJob: IEtlJobControl | undefined;
  setSelectedJobRunId: (id: number) => void;
  selectedJobTypeKey: string | undefined;
  globalFilterValue: string;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, setFilter, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        groupBy: ['proxy_ticker'],
      },
      expandSubRows: false,
    },
    useFilters,
    useGlobalFilter,
  );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  useEffect(() => {
    setFilter('jobId', selectedJob?.id);
  }, [selectedJob]);

  useEffect(() => {
    setFilter('jobType', selectedJobTypeKey);
  }, [selectedJobTypeKey]);

  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              <th>#</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {/*column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>{column.isGrouped ? 'GG ' : ' '}</span>
                  ) : null*/}

                  {column.render('Header')}
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                <td>{i + 1}</td>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // For educational purposes, let's color the
                      // cell depending on what type it is given
                      // from the useGroupBy hook
                      {...cell.getCellProps()}
                      key={cell.column.id + '_' + cell.row.id}
                    >
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>{cell.render('Cell')}</>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
                <td>
                  <Flex flexDirection="row-reverse">
                    <Button
                      onClick={() => setSelectedJobRunId(row.original.id)}
                      outlined
                      minimal
                      intent={Intent.PRIMARY}
                      rightIcon={'chevron-right'}
                    >
                      View Logs
                    </Button>
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <Box mt={2}>
          <NonIdealState
            icon={'issue'}
            title={<h5 className="bp3-heading"> No Jobs Found for selected dates and filters.</h5>}
          />
        </Box>
      )}
    </>
  );
}

const OpsJobsLogs = (): ReactElement => {
  const [startDate, setStarteDate] = useState<Date>(dayjs().subtract(7, 'days').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());
  const {
    data: jobsLogsData,
    isLoading: isJobsLogsDataLoading,
    isFetching: isJobsLogsDataFetching,
  } = useGetOpsJobLogs(startDate, endDate);
  const { data: jobsMetada, isLoading: isJobsMetadataLoading } = useGetJobMetadata();
  const [selectedJobRunId, setSelectedJobRunId] = useState<number | undefined>();
  const [selectedJob, setSelectedJob] = useState<IEtlJobControl | undefined>(undefined);
  const [selectedJobTypeKey, setSelectedJobTypeKey] = useState<string | undefined>(undefined);
  const [globalFilterValue, setGlobalFilterValue] = useState<string | undefined>('');

  const jobRunLogs = useMemo(() => {
    if (!jobsLogsData?.jobRunLogs) return [];
    return jobsLogsData.jobRunLogs.filter((runLog) => runLog.run === selectedJobRunId);
  }, [selectedJobRunId]);

  const { mutateAsync: getDownloadURL } = useGetETLFileDownloadUrl();
  const handleDownload = async (etlFileId: number, custFeedId: number, filename: string) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });

    try {
      const res = await getDownloadURL({ etlFileId, custFeedId });
      fetch(res.url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, filename);
          AppToaster.clear();
        })
        .catch((e) => {
          console.log(e);
          AppToaster.clear();
        });
    } catch (e) {
      AppToaster.clear();
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Something went wrong. Please try again.',
        icon: 'warning-sign',
      });
    }
  };

  const columns: Column<IEtlJobRun>[] = useMemo(
    () => [
      {
        Header: 'Job Run ID',
        accessor: 'id',
      },
      {
        Header: 'Run Date',
        accessor: 'runDt',
      },
      {
        Header: 'Run Status',
        accessor: 'runStatusCode',
        Cell: ({ value }) => jobRunStatusDisplayTextMap[value] || value,
      },
      {
        Header: 'Run Description',
        accessor: 'runDesc',
      },
      {
        Header: 'Job',
        id: 'jobId',
        accessor: (row) => row.job.id,
        Cell: ({ row }: { row: Row<IEtlJobRun> }) => <>{row.original.job.jobUiDisplayName}</>,
      },
      {
        Header: 'Job Type',
        id: 'jobType',
        accessor: (row) => row.job.jobTypeCode,
        Cell: ({ row }: { row: Row<IEtlJobRun> }) => (
          <>{jobTypeDisplayTexMap[row.original.job.jobTypeCode] || row.original.job.jobTypeCode}</>
        ),
      },
      {
        Header: 'Linked File',
        accessor: 'file',
        Cell: ({ value, row }) => (
          <Button
            minimal
            rightIcon={value?.fileName ? 'download' : null}
            onClick={() => {
              value && handleDownload(value.id, row.original.job.custodialFeed, value.fileName);
            }}
            disabled={!value}
          >
            {value?.fileName || 'NA'}
          </Button>
        ),
      },
    ],
    [],
  );

  const handleSelectJob = (job: IEtlJobControl | undefined) => {
    setSelectedJobTypeKey(undefined);
    setSelectedJob(job);
  };
  const handleSelectJobType = (jobType: string | undefined) => {
    setSelectedJob(undefined);
    setSelectedJobTypeKey(jobType);
  };

  const Filters = (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Box>
          <CustomDateRangePicker
            label={'Job Run Date Range'}
            isLoading={isJobsLogsDataLoading}
            startDate={startDate}
            endDate={endDate}
            setStartDate={(date) => setStarteDate(date)}
            setEndDate={(date) => setEndDate(date)}
          ></CustomDateRangePicker>
        </Box>
        <Box ml={2}>
          <CustomDropdown
            disabled={isJobsMetadataLoading}
            label={'Job'}
            text={!selectedJob ? 'All Jobs' : selectedJob.jobUiDisplayName}
            menuItems={[
              <MenuItem key={'_'} text={'All Jobs'} onClick={() => handleSelectJob(undefined)}></MenuItem>,
            ].concat(
              jobsMetada?.jobMetadata.map((job) => (
                <MenuItem key={job.jobName} text={job.jobUiDisplayName} onClick={() => handleSelectJob(job)}></MenuItem>
              )) || [],
            )}
          ></CustomDropdown>
        </Box>
        <Box ml={2}>
          <CustomDropdown
            disabled={isJobsMetadataLoading}
            label={'Job Type'}
            text={!selectedJobTypeKey ? 'All Job Types' : jobTypeDisplayTexMap[selectedJobTypeKey]}
            menuItems={[
              <MenuItem key={'_'} text={'All Job Types'} onClick={() => handleSelectJobType(undefined)}></MenuItem>,
            ].concat(
              Object.keys(jobTypeDisplayTexMap).map((jobTypeKey) => (
                <MenuItem
                  key={jobTypeKey}
                  text={jobTypeDisplayTexMap[jobTypeKey]}
                  onClick={() => handleSelectJobType(jobTypeKey)}
                ></MenuItem>
              )) || [],
            )}
          ></CustomDropdown>
        </Box>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <GlobalTableFilter globalFilter={globalFilterValue || ''} setGlobalFilter={setGlobalFilterValue} />
      </Navbar.Group>
    </Navbar>
  );

  return (
    <>
      {isJobsLogsDataFetching && <GlobalLoader></GlobalLoader>}
      <Box p={3}>
        {isJobsLogsDataLoading && <TableLoader></TableLoader>}
        {jobsLogsData?.jobRuns && (
          <CustomCard
            heading="Job Logs"
            body={
              <>
                {Filters}
                <Table
                  selectedJobTypeKey={selectedJobTypeKey}
                  selectedJob={selectedJob}
                  columns={columns}
                  data={jobsLogsData?.jobRuns}
                  setSelectedJobRunId={setSelectedJobRunId}
                  globalFilterValue={globalFilterValue || ''}
                />
              </>
            }
          ></CustomCard>
        )}
        <Drawer
          icon={'list-detail-view'}
          title={
            <h6 className="bp3-heading">
              {'Logs of job #'}
              {selectedJobRunId}
            </h6>
          }
          size={'50%'}
          isOpen={!!selectedJobRunId}
          onClose={() => setSelectedJobRunId(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            <JobRunLogs data={jobRunLogs}></JobRunLogs>
          </div>
        </Drawer>
      </Box>
    </>
  );
};

export default OpsJobsLogs;

import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import DevTools from './DevTools';
import AxiosProvider from './common/providers/AxiosProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AxiosExternalProvider from '@common/providers/AxiosExternalProvider';

//import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { FocusStyleManager } from '@blueprintjs/core';

FocusStyleManager.onlyShowFocusOnTabs();

const queryClient = new QueryClient();

const initSentry = () => {
  const env = process.env.REACT_APP_ENV?.toUpperCase() || '';
  if (['DEV', 'TEST', 'STAGE', 'PROD'].includes(env)) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV,
    });
  }
};

initSentry();

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <AxiosProvider>
        <AxiosExternalProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <DevTools />
          </QueryClientProvider>
        </AxiosExternalProvider>
      </AxiosProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

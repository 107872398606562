import { MenuItem } from '@blueprintjs/core';
import CustomDropdown from '@components/CustomDropdown/CustomDropdown';
import IFund from '@shared/interfaces/IFund';
import { FC, ReactElement, useCallback, useMemo } from 'react';

interface IFundSelectionDropdownProps {
  allFunds: IFund[];
  selectedFundId?: number;
  switchFund: (fundId: number) => void;
  disabled: boolean;
}
const FundSelectionDropdown: FC<IFundSelectionDropdownProps> = ({
  allFunds,
  selectedFundId,
  switchFund,
  disabled,
}): ReactElement => {
  console.log('selectedFundId in dropdown component', selectedFundId);
  const selectedFund = useMemo(() => {
    console.log('SELECTED');
    return allFunds.find((fund) => fund.id === selectedFundId);
  }, [selectedFundId, allFunds]);

  const handleFundChange = useCallback(
    (fundId) => {
      if (selectedFundId !== fundId) {
        console.log('Calling swithc funds');
        switchFund(fundId);
      }
    },
    [selectedFundId],
  );

  return (
    <CustomDropdown
      label="Fund"
      text={selectedFund?.fundName || ''}
      disabled={disabled}
      menuItems={allFunds.map((fund) => {
        return <MenuItem key={fund.id} onClick={() => handleFundChange(fund.id)} text={fund.fundName}></MenuItem>;
      })}
    ></CustomDropdown>
  );
};

export default FundSelectionDropdown;
/*}
    <div className="fund-drop">
      <p>Fund</p>
      <div className="dropdown">
        <button
          className="btn dropdown-toggle text-dark"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={disabled}
        >
          {selectedFund?.fundName}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {allFunds.map((fund) => {
            return (
              <li key={fund.id}>
                <a className="dropdown-item" href="#" onClick={() => handleFundChange(fund.id)}>
                  {fund.fundName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
        */

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import IUser from '@shared/interfaces/IUser';

export const ADMIN_SEARCH_USER_KEY = 'admin-search-users';
export default function useSearchUser(email: string): UseQueryResult<IUser, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IUser, IErrorResponse>(
    [ADMIN_SEARCH_USER_KEY, { email: email }],
    async () =>
      axios
        ?.get(`search-user?email=${encodeURIComponent(email)}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false, refetchOnWindowFocus: false },
  );
}

import { FC, ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import routes from './routes';
import { IRoute } from './common/interfaces';
import './assets/scss/main.scss';
import ConfirmCancelCashTransactionModal from './modals/ConfirmCancelCashTransactionModal';
import ProgressModal from './modals/ProgressModal';
import ConfirmDeactiveDocModel from './modals/ConfirmDeactiveDocModel';
import ConfirmRemoveUserFromOrganization from './modals/ConfirmRemoveUserFromOrganization';
import ConfirmUserRoleChange from './modals/ConfirmUserRoleChange';
import { Container } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { Colors } from '@blueprintjs/core';
import useUserPreferenceStore from '@stores/useUserPreferenceStore';
import ConfirmDeactivateExternalAccountModal from './modals/ConfirmDeactivateExternalAccountModal';

const withLayout =
  (Layout: FC<{ pageTitle?: string }>, Page: FC, pageTitle: string): FC =>
  (): ReactElement =>
    (
      <Layout pageTitle={pageTitle}>
        <Page></Page>
      </Layout>
    );

const App: FC = () => {
  setConfiguration({ gutterWidth: 10, breakpoints: [480, 768, 991, 1200, 1600] });
  const zoom = useUserPreferenceStore((state) => state.zoom);
  document.body.style.zoom = (1 + (zoom - 1) * 0.0625).toString();
  const darkTheme = false;

  return (
    <Container
      className={darkTheme ? 'bp3-dark' : ''}
      fluid={true}
      style={{ minHeight: '100vh', background: darkTheme ? 'rgb(72, 72,74)' : Colors.LIGHT_GRAY5, padding: 0 }}
    >
      <Switch>
        {routes.map(({ Layout, Page, path, isAuthenticationRequired, exact, pageTitle, acls }: IRoute) => {
          if (isAuthenticationRequired)
            return (
              <ProtectedRoute
                key={path}
                path={path}
                exact={exact}
                acls={acls}
                Layout={Layout}
                component={withLayout(Layout, Page, pageTitle)}
              />
            );
          else return <Route exact={exact} key={path} path={path} component={withLayout(Layout, Page, pageTitle)} />;
        })}
      </Switch>

      <ConfirmCancelCashTransactionModal></ConfirmCancelCashTransactionModal>
      <ProgressModal></ProgressModal>
      <ConfirmDeactiveDocModel></ConfirmDeactiveDocModel>
      <ConfirmDeactivateExternalAccountModal></ConfirmDeactivateExternalAccountModal>
      <ConfirmRemoveUserFromOrganization></ConfirmRemoveUserFromOrganization>
      <ConfirmUserRoleChange></ConfirmUserRoleChange>
    </Container>
  );
};

export default App;

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

interface IDateEvents {
  event: string;
  displayName: string;
  date: Date | undefined;
}

const getDateEvents = (): IDateEvents[] => {
  const dateEvents: { event: string; displayName: string; date: Date | undefined }[] = [];
  dateEvents.push({
    event: 'Latest',
    displayName: 'Latest',
    date: undefined,
  });

  dateEvents.push({
    event: 'LAST_MONTH',
    displayName: 'Last Month',
    date: dayjs().subtract(1, 'month').endOf('month').toDate(),
  });

  const currentQuarter = dayjs().quarter();
  if (currentQuarter > 1) {
    dateEvents.push({
      event: 'LAST_Q1',
      displayName: 'Last Q1 End',
      date: dayjs().set('month', 2).set('date', 31).toDate(),
    });
  }
  if (currentQuarter > 2) {
    dateEvents.push({
      event: 'LAST_Q2',
      displayName: 'Last Q2 End',
      date: dayjs().set('month', 5).set('date', 30).toDate(),
    });
  }

  if (currentQuarter > 3) {
    dateEvents.push({
      event: 'LAST_Q3',
      displayName: 'Last Q3 End',
      date: dayjs().set('month', 8).set('date', 30).toDate(),
    });
  }

  dateEvents.push({
    event: 'LAST_YEAR',
    displayName: 'Last Year',
    date: dayjs().subtract(1, 'year').endOf('year').toDate(),
  });

  return dateEvents;
};

export default getDateEvents;

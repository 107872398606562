import { ReactElement, FC, memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@blueprintjs/core';

const LogoutButton: FC = (): ReactElement => {
  const { logout } = useAuth0();
  const handleLogout = () => logout();
  return <Button intent="danger" icon="log-out" text="Sign Out" onClick={handleLogout} />;
};

export default memo(LogoutButton);

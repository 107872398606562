import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IUpdateTradeStatusResponse {
  id: number;
}

interface IUpdateTradeStatusRequest {
  shouldCancel?: boolean;
  shouldByPassLODCreation?: boolean;
  tradeId: number;
}
export default function useUpdateTradeStatus(): UseMutationResult<
  IUpdateTradeStatusResponse,
  IErrorResponse,
  IUpdateTradeStatusRequest,
  IUpdateTradeStatusResponse
> {
  const axios = useAxios();
  return useMutation<IUpdateTradeStatusResponse, IErrorResponse, IUpdateTradeStatusRequest, IUpdateTradeStatusResponse>(
    async ({ shouldCancel, shouldByPassLODCreation, tradeId }: IUpdateTradeStatusRequest) => {
      const routePath = 'update-trade';
      const searchParams = new URLSearchParams();
      if (shouldCancel) {
        searchParams.append('cancel', 'true');
      }
      if (shouldByPassLODCreation) {
        searchParams.append('shouldByPassLODCreation', 'true');
      }
      return axios
        ?.put(`${routePath}?${searchParams.toString()}`, { tradeId: tradeId })
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
  );
}

import IGetDocsResponse from '@shared/exchange/getDocs/IGetDocsRequest';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export const GET_DOCS_KEY = 'docs';
export default function useDocs(): UseQueryResult<IGetDocsResponse, Error> {
  const axios = useAxios();
  return useQuery<IGetDocsResponse, Error>(
    [GET_DOCS_KEY],
    async () => axios?.get('get-docs').then((res) => res.data.data),
    {
      staleTime: 60 * 1000,
    },
  );
}

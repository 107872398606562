import { IGetCurrentUserResponse } from '@shared/exchange/getCurrentUser';
import IFunctionACL from './IFuntionACL';

export type ACLKeys =
  | 'DASHBOARD'
  | 'EXPOSURE'
  | 'CASH_MANAGEMENT'
  | 'TRADE_MANAGEMENT_CREATE'
  | 'TRADE_MANAGEMENT_PROCESSING'
  | 'TRADE_MANAGEMENT_HISTORY'
  | 'ADMIN_USERS'
  | 'ADMIN_FUNDS'
  | 'ADMIN_ACCOUNTS'
  | 'ADMIN_DOCS'
  | 'ADMIN_FUND_ASSET_CLASS_POLICY'
  | 'ADMIN_EXTERNAL_TRANSFER_ACCOUNTS'
  | 'ADMIN_ORG_INVESTMENT_CONTACTS'
  | 'OPS_MANAGEMENT'
  | 'DOCUMENT_MANAGEMENT_FUND_CLIENT_SERVICE'
  | 'DOCUMENT_MANAGEMENT_FUND_INTERNAL'
  | 'WHAT_IF_REBALANCE'
  | 'DEFAULT';

export const canWriteAny = (acls: IFunctionACL[], currentUser: IGetCurrentUserResponse | undefined): boolean => {
  if (!currentUser || !currentUser.acls) return false;
  const ACL = currentUser.acls;
  return acls.some((aclRecord) => !!ACL[aclRecord.operationType]?.WRITE);
};
export const canReadAny = (acls: IFunctionACL[], currentUser: IGetCurrentUserResponse | undefined): boolean => {
  console.log('currentUsercurrentUser', currentUser);
  if (!currentUser || !currentUser.acls) return false;
  const ACL = currentUser.acls;
  return canWriteAny(acls, currentUser) || acls.some((aclRecord) => !!ACL[aclRecord.operationType]?.READ);
};

export const canWrite = (acl: IFunctionACL, currentUser: IGetCurrentUserResponse | undefined): boolean => {
  if (!currentUser || !currentUser.acls) return false;
  const ACL = currentUser.acls;
  return !!ACL[acl.operationType]?.WRITE;
};
export const canRead = (acl: IFunctionACL, currentUser: IGetCurrentUserResponse | undefined): boolean => {
  if (!currentUser || !currentUser.acls) return false;
  const ACL = currentUser.acls;
  return canWrite(acl, currentUser) || !!ACL[acl.operationType]?.READ;
};

import { IUpdateUserRequest } from '@shared/exchange/updateUser';
import IDoc from '@shared/interfaces/IDoc';
import IExternalTransferAccount from '@shared/interfaces/IExternalTransferAccount';
import create, { SetState } from 'zustand';
import { persist } from 'zustand/middleware';

interface IAdministrationStore {
  selectedFundId: number | undefined;
  setSelectedFundId: (fundId: number) => void;

  switchFund: (fundId: number) => void;
  resetAdministrationStore: () => void;

  errors: Array<string>;
  setErrors: (errors: Array<string>) => void;

  docToUpdate: IDoc | undefined;
  setDocToUpdate: (doc: IDoc | undefined) => void;

  userIdFromRemoveFromOrg: number | undefined;
  setUserIdFromRemoveFromOrg: (userId: number | undefined) => void;

  userDataToUpdate: IUpdateUserRequest | undefined;
  setUserDataToUpdate: (userDataToUpdate: IUpdateUserRequest | undefined) => void;

  externalAccountToDeactivate: IExternalTransferAccount | undefined;
  setExternalAccountToDeactivate: (externalAccount: IExternalTransferAccount | undefined) => void;
}

const useAdministrationStore = create<IAdministrationStore>(
  persist<IAdministrationStore>(
    (set: SetState<IAdministrationStore>) => ({
      selectedFundId: undefined,
      allFunds: undefined,
      setSelectedFundId: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },

      resetAdministrationStore: (): void => {
        set({ selectedFundId: undefined });
      },

      switchFund: (fundId: number): void => {
        set({ selectedFundId: fundId });
      },

      errors: [],
      setErrors: (errors: Array<string>): void => {
        set({ errors: errors });
      },
      docToUpdate: undefined,
      setDocToUpdate: (doc: IDoc | undefined) => {
        set({ docToUpdate: doc });
      },

      userIdFromRemoveFromOrg: undefined,
      setUserIdFromRemoveFromOrg: (userId: number | undefined) => {
        set({ userIdFromRemoveFromOrg: userId });
      },

      userDataToUpdate: undefined,
      setUserDataToUpdate: (userDataToUpdate: IUpdateUserRequest | undefined) => {
        set({ userDataToUpdate: userDataToUpdate });
      },

      externalAccountToDeactivate: undefined,
      setExternalAccountToDeactivate: (externalAccount: IExternalTransferAccount | undefined) => {
        set({ externalAccountToDeactivate: externalAccount });
      },
    }),
    {
      name: 'administration-cache',
      blacklist: [
        'selectedFundId',
        'userDataToUpdate',
        'userIdFromRemoveFromOrg',
        'docToUpdate',
        'externalAccountToDeactivate',
      ],
    },
  ),
);

export default useAdministrationStore;

import { Button, Card, Colors, InputGroup, Intent, Navbar } from '@blueprintjs/core';
import useGetWhatIfRebalSettings from '@common/hooks/useGetWhatIfRebalSettings';
import useUpdateWhatIfRebalSettings from '@common/hooks/useUpdateWhatIfRebalSettings';
import customRegisterFormField from '@common/utils/customRegisterFormField';
import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import formatAssetValue from '@common/utils/formatAssetValue';
import CustomCard from '@components/CustomCard';
import TableLoader from '@components/TableLoader';
import { AppToaster } from '@components/Toasters';
import IUpdateWhatIfRebalSettingsRequest from '@shared/exchange/updateWhatIfRebalSettings/IUpdateWhatIfRebalSettingsRequest';
import { FC, memo, ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex } from 'reflexbox';

interface ISettingsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountsDetails: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allAssetClassDetails: any;
  selectedFundId: number | undefined;
}

const Settings: FC<ISettingsProps> = ({ accountsDetails, allAssetClassDetails, selectedFundId }): ReactElement => {
  const {
    data: whatIfRebalSettings,
    isLoading: IsWhatIfRebalSettingsLoading,
    isSuccess: IsWhatIfRebalSettingsSuccess,
    refetch: refetchWhatIfRebalSettings,
    isFetching: IsWhatIfRebalSettingsFetching,
  } = useGetWhatIfRebalSettings(selectedFundId);

  const {
    mutateAsync: updateWhatIfRebalSettings,
    isLoading: isUpdateWhatIfRebalSettingsLoading,
    //isError: isUpdateWhatIfRebalSettingsError,
    error: updateWhatIfRebalSettingsError,
  } = useUpdateWhatIfRebalSettings();

  useEffect(() => {
    if (!updateWhatIfRebalSettingsError) return;
    AppToaster.show({
      icon: 'warning-sign',
      intent: Intent.DANGER,
      message: updateWhatIfRebalSettingsError?.errorMessage,
    });
  }, [updateWhatIfRebalSettingsError]);

  const { register, handleSubmit, setValue, formState, reset } = useForm({});
  const [isFormLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    if (!whatIfRebalSettings) return;
    const defaultFormValues: Record<string, string | number> = {};

    defaultFormValues['fundModelParams.phi'] = whatIfRebalSettings.fundModelParams?.phi;
    defaultFormValues['fundModelParams.gamma'] = whatIfRebalSettings.fundModelParams?.gamma;
    defaultFormValues['fundModelParams.asset_nd_sw'] = whatIfRebalSettings.fundModelParams?.asset_nd_sw;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let assetClass: any;
    console.log('allAssetClassDetails', allAssetClassDetails);
    for (assetClass of Object.values(allAssetClassDetails)) {
      const assetClassId = assetClass['fund_asset_class_target_id'];
      const assetClassParam = whatIfRebalSettings.assetClassModelParams.find(
        (param) => param.fundAssetClass == assetClassId,
      );
      console.log(assetClassParam);
      if (assetClassParam) {
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassParam.fundAssetClass}.tacticalUpperBound`] =
          assetClassParam.targetAllocationRangeUpperBound;
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassParam.fundAssetClass}.tacticalLowerBound`] =
          assetClassParam.targetAllocationRangeLowerBound;
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassParam.fundAssetClass}.tacticalTarget`] =
          assetClassParam.targetAllocation;
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassParam.fundAssetClass}.c_ac_d`] =
          assetClassParam.assetClassModelParams.c_ac_d;
      } else {
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassId}.tacticalUpperBound`] = '';
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassId}.tacticalLowerBound`] = '';
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassId}.tacticalTarget`] = '';
        defaultFormValues[`assetClassModelParams.assetClass_${assetClassId}.c_ac_d`] = '';
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let account: any;
    for (account of Object.values(accountsDetails)) {
      const accountId = account['accountId'];
      const accountParam = whatIfRebalSettings.accountModelParams.find((param) => param.account == accountId);
      if (accountParam) {
        defaultFormValues[`accountModelParams.account_${accountParam.account}.tacticalUpperBound`] =
          accountParam.targetAllocationRangeUpperBoundPct;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.tacticalLowerBound`] =
          accountParam.targetAllocationRangeLowerBoundPct;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.tacticalTarget`] =
          accountParam.targetAllocationPct;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.c_d`] =
          accountParam.accountModelParams.c_d;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.c_p`] =
          accountParam.accountModelParams.c_p;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.c_s`] =
          accountParam.accountModelParams.c_s;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.m_p`] =
          accountParam.accountModelParams.m_p;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.m_s`] =
          accountParam.accountModelParams.m_s;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.vc_p`] =
          accountParam.accountModelParams.vc_p;
        defaultFormValues[`accountModelParams.account_${accountParam.account}.vc_s`] =
          accountParam.accountModelParams.vc_s;
      } else {
        defaultFormValues[`accountModelParams.account_${accountId}.tacticalUpperBound`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.tacticalLowerBound`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.tacticalTarget`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.c_d`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.c_p`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.c_s`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.m_p`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.m_s`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.vc_p`] = '';
        defaultFormValues[`accountModelParams.account_${accountId}.vc_s`] = '';
      }
    }
    reset(defaultFormValues);
    setFormLoaded(true);
  }, [whatIfRebalSettings, accountsDetails, allAssetClassDetails]);

  const filteredParentAssetClassIdsOfAccounts = new Set<number>();
  for (const a of Object.values(accountsDetails)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const account: any = a;
    console.log('assetClasses', account['assetClasses']);
    if (account['assetClasses']) {
      for (const assetClass of account['assetClasses']) {
        filteredParentAssetClassIdsOfAccounts.add(assetClass['assetClassId']);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (formData: any) => {
    console.log(formData);
    if (selectedFundId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accountModelParams: Record<string, any> = {};
      for (const accountIdKey of Object.keys(formData.accountModelParams)) {
        const accountId = accountIdKey.replace('account_', '');
        accountModelParams[accountId] = formData.accountModelParams[accountIdKey];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const assetClassModelParams: Record<string, any> = {};
      for (const assetClassKey of Object.keys(formData.assetClassModelParams)) {
        const assetClassId = assetClassKey.replace('assetClass_', '');
        assetClassModelParams[assetClassId] = formData.assetClassModelParams[assetClassKey];
      }

      const data: IUpdateWhatIfRebalSettingsRequest = {
        ...formData,
        fundId: selectedFundId,
        accountModelParams,
        assetClassModelParams,
      };
      try {
        await updateWhatIfRebalSettings(data);
        await refetchWhatIfRebalSettings();
      } catch (e) {}
    }
  };

  const getStylesOfInputGroup = (typeKey: string, fieldKey: string, valueKey: string) => {
    return {
      backgroundColor:
        formState.dirtyFields[typeKey] &&
        formState.dirtyFields[typeKey][fieldKey] &&
        formState.dirtyFields[typeKey][fieldKey][valueKey]
          ? Colors.GOLD5
          : Colors.WHITE,
    };
  };
  return (
    <>
      {isFormLoaded && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box m={2}>
            {IsWhatIfRebalSettingsLoading && <TableLoader></TableLoader>}
            {IsWhatIfRebalSettingsSuccess && (
              <CustomCard
                heading="Settings"
                body={
                  <>
                    <Box p={2} width={['50%']}>
                      <Card style={{ padding: 0 }}>
                        <table className="bp3-html-table bp3-html-table-bordered" width="100%">
                          <thead>
                            <tr>
                              <td>Phi</td>
                              <td>Gamma</td>
                              <td>Asset Non-Decreasing Switch</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <InputGroup
                                  type="number"
                                  step="0.0001"
                                  {...customRegisterFormField(register, `fundModelParams.phi`, { valueAsNumber: true })}
                                  onBlur={(e) => {
                                    if (Number(e.target.value) > 1) setValue('fundModelParams.phi', 1);
                                  }}
                                ></InputGroup>
                              </td>
                              <td>
                                <InputGroup
                                  type="number"
                                  step="0.0001"
                                  {...customRegisterFormField(register, `fundModelParams.gamma`, {
                                    valueAsNumber: true,
                                  })}
                                  onBlur={(e) => {
                                    if (Number(e.target.value) > 1) setValue('fundModelParams.gamma', 1);
                                  }}
                                ></InputGroup>
                              </td>
                              <td>
                                <InputGroup
                                  type="number"
                                  step="0.0001"
                                  {...customRegisterFormField(register, `fundModelParams.asset_nd_sw`, {
                                    valueAsNumber: true,
                                  })}
                                  onBlur={(e) => {
                                    if (Number(e.target.value) > 0) setValue('fundModelParams.asset_nd_sw', 1);
                                  }}
                                ></InputGroup>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                    </Box>
                    <Box px={2}>
                      <CustomCard
                        heading={'Initial Allocations: Asset Classes'}
                        body={
                          <Box style={{ position: 'relative' }}>
                            <table className="bp3-html-table bp3-html-table-bordered table-head-bordered" width="100%">
                              <thead>
                                <tr>
                                  <th colSpan={3}></th>
                                  <th colSpan={3}>
                                    <Box textAlign="center">Fund policy</Box>
                                  </th>
                                  <th colSpan={10}></th>
                                </tr>
                                <tr>
                                  <th>Asset Class Name</th>
                                  <th>
                                    <Box textAlign="right">Initial Amount</Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Initial Allocation</Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Lower Bound</Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Target Allocation</Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Upper Bound</Box>
                                  </th>
                                  <th>Tactical Lower Bound</th>
                                  <th>Tactical Target</th>
                                  <th>Tactical Upper Bound</th>
                                  <th>
                                    <Box textAlign="right">Implied Lower Bound </Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Implied Target </Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Implied Upper Bound </Box>
                                  </th>
                                  <th>
                                    <Box textAlign="right">Coefficient of Deviation</Box>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  Array.from(filteredParentAssetClassIdsOfAccounts)?.map((assetClassId: number) => {
                                    const assetClass = allAssetClassDetails[assetClassId];
                                    return (
                                      <tr key={assetClass['asset_class_id']}>
                                        <td>{assetClass['asset_class_name']}</td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">
                                            {formatAssetValue({ value: assetClass['initial_allocation'] })}{' '}
                                          </Box>
                                        </td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">
                                            {formatAllocationPercentage({
                                              value: assetClass['initial_allocation_pct'],
                                            })}{' '}
                                          </Box>
                                        </td>

                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">
                                            {formatAllocationPercentage({ value: assetClass['lb_ac'] })}{' '}
                                          </Box>
                                        </td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">
                                            {formatAllocationPercentage({ value: assetClass['target_ac'] })}{' '}
                                          </Box>
                                        </td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">
                                            {formatAllocationPercentage({ value: assetClass['ub_ac'] })}{' '}
                                          </Box>
                                        </td>

                                        <td>
                                          <InputGroup
                                            {...customRegisterFormField(
                                              register,
                                              `assetClassModelParams.assetClass_${assetClass['fund_asset_class_target_id']}.tacticalLowerBound`,
                                            )}
                                            type="number"
                                            step="0.0001"
                                            style={getStylesOfInputGroup(
                                              'assetClassModelParams',
                                              `assetClass_${assetClass['fund_asset_class_target_id']}`,
                                              'tacticalLowerBound',
                                            )}
                                          ></InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup
                                            {...customRegisterFormField(
                                              register,
                                              `assetClassModelParams.assetClass_${assetClass['fund_asset_class_target_id']}.tacticalTarget`,
                                            )}
                                            type="number"
                                            step="0.0001"
                                            style={getStylesOfInputGroup(
                                              'assetClassModelParams',
                                              `assetClass_${assetClass['fund_asset_class_target_id']}`,
                                              'tacticalTarget',
                                            )}
                                          ></InputGroup>
                                        </td>
                                        <td>
                                          <InputGroup
                                            {...customRegisterFormField(
                                              register,
                                              `assetClassModelParams.assetClass_${assetClass['fund_asset_class_target_id']}.tacticalUpperBound`,
                                            )}
                                            type="number"
                                            step="0.0001"
                                            style={getStylesOfInputGroup(
                                              'assetClassModelParams',
                                              `assetClass_${assetClass['fund_asset_class_target_id']}`,
                                              'tacticalUpperBound',
                                            )}
                                          ></InputGroup>
                                        </td>

                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">{assetClass['implied_lb_ac']} </Box>
                                        </td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">{assetClass['implied_target_ac']} </Box>
                                        </td>
                                        <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                          <Box textAlign="right">{assetClass['implied_ub_ac']} </Box>
                                        </td>
                                        <td>
                                          <InputGroup
                                            {...customRegisterFormField(
                                              register,
                                              `assetClassModelParams.assetClass_${assetClass['fund_asset_class_target_id']}.c_ac_d`,
                                            )}
                                            type="number"
                                            step="0.0001"
                                            style={getStylesOfInputGroup(
                                              'assetClassModelParams',
                                              `assetClass_${assetClass['fund_asset_class_target_id']}`,
                                              'c_ac_d',
                                            )}
                                          ></InputGroup>
                                        </td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                            </table>
                          </Box>
                        }
                      ></CustomCard>
                    </Box>

                    <Box px={2} mt={2} mb={2}>
                      <CustomCard
                        heading={'Initial Allocations: Investments'}
                        body={
                          <table
                            className="bp3-html-table bp3-html-table-bordered sticky-thc table-head-bordered"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                <th colSpan={3}></th>
                                <th colSpan={3}>
                                  <Box textAlign="center">Asset Targets</Box>
                                </th>
                                <th colSpan={10}></th>
                              </tr>
                              <tr>
                                <th>Account Name</th>
                                <th>
                                  <Box textAlign="right">Allocation Amount </Box>
                                </th>
                                <th>
                                  <Box textAlign="right">Initial Allocation </Box>
                                </th>
                                <th>
                                  <Box textAlign="right">Lower Bound </Box>
                                </th>
                                <th>
                                  <Box textAlign="right">Target Allocation </Box>
                                </th>
                                <th>
                                  <Box textAlign="right">Upper Bound </Box>
                                </th>
                                <th>Tactical Lower Bound</th>
                                <th>Tactical Target</th>
                                <th>Tactical Upper Bound</th>
                                <th>coefficient - purchase</th>
                                <th>coefficient - sale</th>
                                <th>coefficient - deviation</th>
                                <th>minimum purchase</th>
                                <th>minimum sale</th>
                                <th>variable cost - purchase</th>
                                <th>variable cost – sale</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                Object.values(accountsDetails).map((account: any) => (
                                  <tr key={account['accountName']}>
                                    <td>{account['accountName']}</td>
                                    <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                      <Box textAlign="right">
                                        {formatAssetValue({ value: account['currentAllocation'] })}
                                      </Box>
                                    </td>
                                    <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                      <Box textAlign="right">
                                        {formatAllocationPercentage({ value: account['currentAllocationPct'] })}
                                      </Box>
                                    </td>

                                    <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                      <Box textAlign="right">
                                        {formatAllocationPercentage({
                                          value: Number(account['targeAllocationtLowerBound']),
                                        })}
                                      </Box>
                                    </td>
                                    <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                      <Box textAlign="right">
                                        {formatAllocationPercentage({
                                          value: Number(account['targetAllocation']),
                                        })}
                                      </Box>
                                    </td>
                                    <td style={{ backgroundColor: Colors.LIGHT_GRAY4 }}>
                                      <Box textAlign="right">
                                        {formatAllocationPercentage({
                                          value: Number(account['targeAllocationtUpperBound']),
                                        })}
                                      </Box>
                                    </td>

                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.tacticalLowerBound`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'tacticalLowerBound',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.tacticalTarget`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'tacticalTarget',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.tacticalUpperBound`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'tacticalUpperBound',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.c_p`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'c_p',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.c_s`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'c_s',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.c_d`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'c_d',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.m_p`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'm_p',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.m_s`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'm_s',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.vc_p`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'vc_p',
                                        )}
                                      ></InputGroup>
                                    </td>
                                    <td>
                                      <InputGroup
                                        {...customRegisterFormField(
                                          register,
                                          `accountModelParams.account_${account['accountId']}.vc_s`,
                                        )}
                                        type="number"
                                        step="0.0001"
                                        style={getStylesOfInputGroup(
                                          'accountModelParams',
                                          `account_${account['accountId']}`,
                                          'vc_s',
                                        )}
                                      ></InputGroup>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        }
                      ></CustomCard>
                    </Box>
                  </>
                }
              ></CustomCard>
            )}
          </Box>
          <Box>
            <Navbar
              style={{
                bottom: 0,
                position: 'fixed',
                right: '0rem',
                paddingLeft: '4.3rem',
                zIndex: 20,
                backgroundColor: Colors.LIGHT_GRAY5,
              }}
            >
              <Flex justifyContent="space-between">
                <Navbar.Group></Navbar.Group>
                <Navbar.Group>
                  <Box paddingLeft="4.7rem"></Box>
                </Navbar.Group>
                <Navbar.Group>
                  <Button
                    loading={isUpdateWhatIfRebalSettingsLoading || IsWhatIfRebalSettingsFetching}
                    intent={Intent.PRIMARY}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Navbar.Group>
              </Flex>
            </Navbar>
          </Box>
        </form>
      )}
    </>
  );
};

export default memo(Settings);

import ITradeItem from '@shared/interfaces/ITradeItem';
import { Column } from 'react-table';
//import Memo from '@assets/img/memo.svg';
import dayjs from 'dayjs';
import IDocFact from '@shared/interfaces/IDocFact';
import TradeStatusBadge from '@components/TradeStatusBadge/TradeStatusBadge';
import LODUpload from './LODUpload';
import getFileNameFromDocFact from '../commons/getFileNameFromDocFact';
import { Button, ButtonGroup, Intent, Tag } from '@blueprintjs/core';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';

interface IGetTradeItemsProcessingColumnsProps {
  setMemoText: (memo: string | undefined) => void;
  getDocFact: (tradeItemId: number) => IDocFact | null | undefined;
  handleLODDownload: (docFactId: number, name: string, isSigned: boolean) => void;
  isLODLoading: boolean;
}
const getTradeItemsProcessingColumns = ({
  setMemoText,
  handleLODDownload,
  getDocFact,
  isLODLoading,
}: IGetTradeItemsProcessingColumnsProps): Column<ITradeItem>[] => {
  return [
    {
      Header: 'Trade ID',
      accessor: 'id',
      Cell: ({ row }) => {
        return <>{row.original.id}</>;
      },
    },
    {
      Header: 'Account',
      accessor: (data) => {
        if (data.buyInd) {
          return data.cashTransaction.toAccount.accountName;
        } else {
          return data.cashTransaction.fromAccount.accountName;
        }
      },
    },
    {
      Header: () => <div className="text-end">Amount</div>,
      accessor: 'tradeAmt',
      Cell: ({ value }) => <div className="text-end">{formatAmountRoundTwo({ value: Number(value) })}</div>,
    },
    {
      Header: 'Buy/Sell',
      accessor: 'buyInd',
      Cell: ({ row }) => (
        <>
          {row.original.buyInd && <span>Buy</span>}
          {row.original.sellInd && <span>Sell</span>}
        </>
      ),
    },
    {
      Header: 'Cash Account',
      accessor: (data) => {
        if (data.buyInd) {
          return data.cashTransaction.fromAccount.accountName;
        } else {
          return data.cashTransaction.toAccount.accountName;
        }
      },
    },
    {
      Header: 'Created Dt',
      accessor: 'createdDt',
      Cell: ({ value }) => <>{dayjs(value).format('MM/DD/YYYY')}</>,
    },

    {
      Header: 'Trade Dt',
      accessor: 'tradeDt',
      Cell: ({ value }) => <>{dayjs(value).format('MM/DD/YYYY')}</>,
    },

    {
      Header: 'Settlement Dt',
      accessor: 'settlementDt',
      Cell: ({ value }) => <>{dayjs(value).format('MM/DD/YYYY')}</>,
    },

    {
      Header: 'Memo',
      accessor: 'tradeItemMemoText',
      Cell: ({ value }) => {
        return (
          <Button icon={'label'} small minimal intent={Intent.PRIMARY} onClick={() => setMemoText(value)}></Button>
        );
      },
    },
    {
      Header: 'LOD',
      accessor: 'lodGeneratedDt',
      id: 'lodGeneratedDt',
      Cell: ({ row }) => {
        if (isLODLoading)
          return (
            <div className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          );
        const docFact = getDocFact(row.original.id);
        const lodRequiredInd = row.original.accountDim.lodRequiredInd;
        if (!lodRequiredInd) return <></>;
        if (!docFact) return <LODUpload tradeItemId={row.original.id} isSigned={false}></LODUpload>;
        const downloadFileName = getFileNameFromDocFact(docFact, row);
        return (
          <Tag style={{ padding: 0 }}>
            <i className="bi bi-file-earmark-pdf" style={{ padding: '0.4rem', fontSize: '1.42rem' }}></i>
            <ButtonGroup>
              <Button
                rightIcon={'download'}
                onClick={() => handleLODDownload(docFact.id, downloadFileName, false)}
              ></Button>
              <LODUpload tradeItemId={row.original.id} isSigned={false}></LODUpload>
            </ButtonGroup>
          </Tag>
        );
      },
    },
    {
      Header: 'Signed LOD',
      accessor: 'signedLodUploadedDt',
      id: 'signedLodUploadedDt',
      Cell: ({ row }) => {
        if (isLODLoading)
          return (
            <div className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          );
        const docFact = getDocFact(row.original.id);
        const shouldUploadSignedLOD = row.original.docTemplate?.signature_required_ind;
        if (!shouldUploadSignedLOD) return <></>;
        if (!docFact || !docFact?.s3_lod_signed_file_url)
          return <LODUpload tradeItemId={row.original.id} isSigned={true}></LODUpload>;
        const downloadFileName = getFileNameFromDocFact(docFact, row);
        return (
          shouldUploadSignedLOD && (
            <Tag style={{ padding: 0 }}>
              <i style={{ padding: '0.4rem', fontSize: '1.42rem' }} className="bi bi-file-earmark-check"></i>
              <ButtonGroup>
                <Button
                  rightIcon={'download'}
                  onClick={() => handleLODDownload(docFact.id, downloadFileName, true)}
                ></Button>
                <LODUpload tradeItemId={row.original.id} isSigned={true}></LODUpload>
              </ButtonGroup>
            </Tag>
          )
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'tradeItemStatus',
      Cell: ({ value }) => (
        <>
          <TradeStatusBadge type={value.tradeItemStatusName} text={value.tradeItemStatusName}></TradeStatusBadge>
        </>
      ),
    },
  ];
};

export default getTradeItemsProcessingColumns;

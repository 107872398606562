import { Colors, HTMLTable, TextArea } from '@blueprintjs/core';
import UserAvatar from '@components/UserAvatar';
import dayjs from 'dayjs';
import { FC, ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import styled from '@emotion/styled';
import IOrgInvestmentContact from '@shared/interfaces/IOrgInvestmentContact';

interface IOrgInvestmentContactForAccountRowPanel {
  investmentContact: IOrgInvestmentContact;
}
const OrgInvestmentContactRowPanel: FC<IOrgInvestmentContactForAccountRowPanel> = ({
  investmentContact,
}): ReactElement => {
  let fullAddress: string;
  return (
    <Styles>
      <HTMLTable className="table" style={{ borderWidth: 1, borderStyle: 'solid', borderColor: Colors.LIGHT_GRAY1 }}>
        <tbody>
          <tr>
            <td className="keyName">Address Line One</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.street1Address}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">Address Line Two</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.street2Address}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">Fax</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.fax1No}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">City</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.city}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">State Code</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.stateCd}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">Postal Code</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.postalCode}
                </Box>
              </Flex>
            </td>
          </tr>
          <tr>
            <td className="keyName">Country</td>
            <td>
              <Flex>
                <Box display="inline-flex" alignItems="center">
                  {investmentContact.country}
                </Box>
              </Flex>
            </td>
          </tr>
          {investmentContact.deactUserId && (
            <>
              <tr>
                <td className="keyName">Deactivated By</td>
                <td>
                  <Flex alignItems={'center'}>
                    <Box>
                      <UserAvatar user={investmentContact.deactUserId}></UserAvatar>
                    </Box>
                    <Box>
                      <div>
                        {investmentContact.deactUserId.firstName + ' ' + investmentContact.deactUserId.lastName}
                      </div>
                    </Box>
                  </Flex>
                </td>
              </tr>
              <tr>
                <td className="keyName">Date Of Deactivation</td>
                <td>
                  <Flex alignItems={'center'}>
                    <Box>
                      <div>{dayjs(investmentContact.deactDt).format('MM/DD/YYYY')}</div>
                    </Box>
                  </Flex>
                </td>
              </tr>
            </>
          )}
          <tr>
            <td className="keyName">Full Address</td>
            <td>
              <>
                {(() => {
                  fullAddress = `${investmentContact.street1Address}\n${investmentContact.street2Address}\n${investmentContact.city}, ${investmentContact.stateCd} ${investmentContact.postalCode}\n${investmentContact.country}`;
                })()}
                <TextArea
                  style={{
                    whiteSpace: 'pre-line',
                    width: '300px',
                    height: '80px',
                  }}
                  value={fullAddress}
                  readOnly
                />
              </>
            </td>
          </tr>
        </tbody>
      </HTMLTable>
    </Styles>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY4} !important;
  }
`;
export default OrgInvestmentContactRowPanel;

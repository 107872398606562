import { Button, Colors, Intent, Navbar, ProgressBar } from '@blueprintjs/core';
import useFunds from '@common/hooks/useFunds';
import useWhatIfRebal from '@common/hooks/useWhatIfRebal';
import { AppToaster } from '@components/Toasters';
import { IGetExposureResponse } from '@shared/exchange/getExposure';
import { IGetWhatIfRebalSettingsResponse } from '@shared/exchange/getWhatIfRebalSettings';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { isNumber } from 'lodash';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Flex, Box } from 'reflexbox';
import Controls from './Controls';
import ExposureDataFormPanel from './ExposureDataFormPanel';
import getExposureDataFormConfig from './getExposureDataFormConfig';

interface IRebalancingProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountsDetails: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allAssetClassDetails: any;
  data: IGetExposureResponse;
  whatIfRebalSettings: IGetWhatIfRebalSettingsResponse;
}

const Rebalancing: FC<IRebalancingProps> = ({
  accountsDetails,
  allAssetClassDetails,
  data,
  whatIfRebalSettings,
}): ReactElement => {
  const {
    selectedFundId,
    setSelectedFundId,
    setAllFunds,
    lockedAccountsMap,
    userDefinedCashflowRedemption,
    userDefinedCashflowContribution,
    scheduledCashflowRedemption,
    scheduledCashflowContribution,
    transactionsDegree,
    targetConstraints,
  } = useWhatIfRebalStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: allFundsData, isError: isFundsDataError } = useFunds();
  const exposureDataFormColumns = useMemo(() => getExposureDataFormConfig(), []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rebalResults, setRebalResults] = useState<any>({});

  const {
    mutateAsync: runWhatIfRebal,
    isLoading: isWhatIfRebalRunning,
    //isError: isWhatIfRebalError,
    reset: resetWhatIfRebal,
  } = useWhatIfRebal();

  const {
    setActiveTab,
    setTradeOrdersFromRebalResults,
    setSelectedFundId: setSelectedFundInTradeOrderManagement,
  } = useTradeOrderManagementStore(({ setActiveTab, setTradeOrdersFromRebalResults, setSelectedFundId }) => {
    return {
      setActiveTab,
      setTradeOrdersFromRebalResults,
      setSelectedFundId,
    };
  });

  useEffect(() => {
    if (!allFundsData || !allFundsData.funds) return;
    setAllFunds(allFundsData?.funds);
    if (!selectedFundId) setSelectedFundId(allFundsData.funds[0]?.id);
  }, [allFundsData]);

  const getSettingsOfAccount = (accountId: number) => {
    return whatIfRebalSettings.accountModelParams.find((_) => _.account === accountId)?.accountModelParams;
  };

  const handleRunRebalance = async () => {
    if (
      !isNumber(whatIfRebalSettings?.fundModelParams?.phi) ||
      !isNumber(whatIfRebalSettings?.fundModelParams?.gamma) ||
      !isNumber(whatIfRebalSettings?.fundModelParams?.asset_nd_sw)
    ) {
      console.log('GHYGHG HGHG G ghg');
      AppToaster.show({
        icon: 'warning-sign',
        intent: Intent.DANGER,
        message: 'Settings are empty or incomplete',
      });
      return;
    }

    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            {"Running Rebalancing Scenario's"}
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'refresh',
      timeout: 0,
    });

    const portfolios = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const assetClassesMap: Record<number, any> = {};

    for (const lockedAccountMapKey in lockedAccountsMap) {
      //Populate only the asset classes that are direct parents of accounts
      for (const assetClassesOfAccount of accountsDetails[Number(lockedAccountMapKey)]['assetClasses']) {
        assetClassesMap[assetClassesOfAccount.assetClassId] = allAssetClassDetails[assetClassesOfAccount.assetClassId];
      }
    }

    for (const lockedAccountMapKey in lockedAccountsMap) {
      // Calculate asset class spit allocation of account
      //set the correct allocation% of the asset classes that the accounts belongs to.

      //set default 0 values for assetClasses
      const portfolioAssetClassAllocationSplit: Record<string, number> = {};
      for (const assetClass of Object.values(assetClassesMap)) {
        portfolioAssetClassAllocationSplit[assetClass['asset_class_name']] = 0;
      }

      for (const assetClassesOfAccount of accountsDetails[Number(lockedAccountMapKey)]['assetClasses']) {
        portfolioAssetClassAllocationSplit[assetClassesOfAccount['assetClassName']] =
          assetClassesOfAccount['allocationPct'];
      }

      const accountSettings = getSettingsOfAccount(Number(lockedAccountMapKey));
      const portfolioData = {
        portfolio_id: selectedFundId,
        asset_id: Number(lockedAccountMapKey),
        asset_name: accountsDetails[Number(lockedAccountMapKey)]['accountName'],
        w_init: accountsDetails[Number(lockedAccountMapKey)]['currentAllocationPct'],
        target: accountsDetails[Number(lockedAccountMapKey)]['targetAllocationPct'],
        lb: accountsDetails[Number(lockedAccountMapKey)]['targeAllocationtLowerBound'],
        ub: accountsDetails[Number(lockedAccountMapKey)]['targeAllocationtUpperBound'],
        lock: lockedAccountsMap[lockedAccountMapKey] === true ? 1 : 0,
        c_p: Number(accountSettings?.c_p),
        c_s: Number(accountSettings?.c_s),
        c_d: Number(accountSettings?.c_d),
        m_p: Number(accountSettings?.m_p),
        m_s: Number(accountSettings?.m_s),
        vc_p: Number(accountSettings?.vc_p),
        vc_s: Number(accountSettings?.vc_s),
        ...portfolioAssetClassAllocationSplit,
      };

      portfolios.push(portfolioData);
    }
    const cumulativeContributionAmount =
      (userDefinedCashflowContribution || 0) +
      (scheduledCashflowContribution || 0) -
      (userDefinedCashflowRedemption || 0) -
      (scheduledCashflowRedemption || 0);
    const portfolioValue = data?.exposureData.totalAssetClassNav ? Number(data?.exposureData.totalAssetClassNav) : 0;
    const contributionRate = portfolioValue == 0 ? 0 : cumulativeContributionAmount / portfolioValue;
    const payload = {
      port_id: selectedFundId,
      port_settings: [
        {
          portfolio_id: selectedFundId,
          portfolio_name: '',
          port_value: portfolioValue,
          contrib_rate: contributionRate,
          asset_nd_sw: whatIfRebalSettings?.fundModelParams.asset_nd_sw,
          phi: whatIfRebalSettings?.fundModelParams.phi,
          gamma: whatIfRebalSettings?.fundModelParams.gamma,
        },
      ],
      port: portfolios,
      ac_bonds: Object.values(assetClassesMap),
      slider1_value: transactionsDegree,
      slider2_value: targetConstraints,
    };

    let wSum = 0;
    for (const portfolio of portfolios) {
      console.log(portfolio['asset_name'], portfolio['w_init']);
      wSum += portfolio['w_init'];
    }
    console.log(wSum);
    console.log('****');
    let tSum = 0;
    for (const portfolio of portfolios) {
      console.log(portfolio['asset_name'], portfolio['target']);
      tSum += portfolio['target'];
    }
    console.log(tSum);

    try {
      const rebalResults = await runWhatIfRebal(payload);
      setRebalResults(rebalResults);
    } catch (e) {
      console.log(e);
    }
    AppToaster.clear();
  };

  const resetRebalResults = () => {
    setRebalResults(undefined);
    resetWhatIfRebal();
  };

  useEffect(() => {
    resetRebalResults();
  }, [selectedFundId]);

  const history = useHistory();
  const acceptTrades = () => {
    const tradeOrderItemsFromRebalRecord = [];
    for (const rebalRecord of rebalResults['rebal_df']) {
      if (!rebalRecord['buy'] && !rebalRecord['sell']) continue;
      tradeOrderItemsFromRebalRecord.push({
        accountId: rebalRecord['asset_id'],
        buy: rebalRecord['buy'],
        sell: rebalRecord['sell'],
      });
    }
    if (selectedFundId) {
      setSelectedFundInTradeOrderManagement(selectedFundId);
      setTradeOrdersFromRebalResults(tradeOrderItemsFromRebalRecord);
      setActiveTab('TradeOrderEntry');
      history.push('/trade-management');
    }
  };

  return (
    <>
      <Flex flexDirection="column">
        <Box
          style={{
            top: '6.25rem',
            position: 'fixed',
            left: 0,
            right: 0,
            paddingLeft: '3.5rem',
            zIndex: 18,
          }}
        >
          {selectedFundId && (
            <Controls resetRebalResults={resetRebalResults} selectedFundId={selectedFundId}></Controls>
          )}
        </Box>
        <Box m={2} style={{ paddingTop: '10.2rem' }}>
          <ExposureDataFormPanel
            columns={exposureDataFormColumns}
            data={data}
            rebalResults={rebalResults}
            resetRebalResults={resetRebalResults}
          ></ExposureDataFormPanel>
        </Box>
      </Flex>
      <Box>
        <Navbar
          style={{
            bottom: 0,
            position: 'fixed',
            right: '0rem',
            paddingLeft: '4.3rem',
            zIndex: 20,
            backgroundColor: Colors.LIGHT_GRAY5,
          }}
        >
          <Flex justifyContent="space-between">
            <Navbar.Group></Navbar.Group>
            <Navbar.Group>
              <Box paddingLeft="4.7rem"></Box>
            </Navbar.Group>
            <Navbar.Group>
              <Button
                intent={Intent.PRIMARY}
                icon="refresh"
                onClick={handleRunRebalance}
                loading={isWhatIfRebalRunning}
              >
                Run Rebalance
              </Button>
              <Box ml={2}>
                <Button disabled={!rebalResults?.rebal_df} intent={Intent.PRIMARY} icon="tick" onClick={acceptTrades}>
                  Accept Trades
                </Button>
              </Box>
            </Navbar.Group>
          </Flex>
        </Navbar>
      </Box>
    </>
  );
};
export default Rebalancing;

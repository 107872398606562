import formatAllocationPercentage from '@common/utils/formatAllocationPercentage';
import formatAssetValue from '@common/utils/formatAssetValue';
import { Box, Flex } from '@rebass/grid/emotion';
import IFundSummary from '@shared/interfaces/IFundSummary';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { Row } from 'react-table';

export default {
  formatAssetValue,
  formatFundName: ({ value, row }: { value: string; row: Row<IFundSummary> }): ReactElement => (
    <>
      {!row.original.isLatest && <span className="table-error" title="Data Not Up to Date"></span>}
      <div className="bp3-text-large">{value}</div>
      <div className="bp3-text-small">
        {row.original.fund_type_name}&nbsp; | &nbsp;Last Refreshed on:{' '}
        {dayjs(row.original.time_dim_key).format('MM/DD/YYYY')}
      </div>
    </>
  ),
  formatDailyChange: ({ value }: { value: { dailyChangeValue: number; dailyChangePct: number } }): ReactElement => {
    const formattedDailyChancePct = formatAllocationPercentage({ value: value.dailyChangePct });
    return (
      <Flex flexDirection="row-reverse">
        <Box pl={2}>
          <span className={`bp3-tag ${value.dailyChangePct < 0 ? 'bp3-intent-danger' : 'bp3-intent-success'}`}>
            {value.dailyChangePct < 0 ? ` ↓ ${formattedDailyChancePct}` : ` ↑ ${formattedDailyChancePct}`}
          </span>
        </Box>
        {formatAssetValue({ value: value.dailyChangeValue })}
      </Flex>
    );
  },
} as const;

import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import { Cell, Column, useTable, useGlobalFilter, useFilters } from 'react-table';
import useFunds from '@common/hooks/useFunds';
import TableLoader from '@components/TableLoader';
import useAccounts from '@common/hooks/useAccounts';
import IAccount from '@shared/interfaces/IAccount';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import useAdministrationStore from '@stores/useAdministrationStore';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';
import useAuthStore from '@stores/useAuthStore';
import { Box, Flex } from 'reflexbox';
import CustomCard from '@components/CustomCard';
import {
  Button,
  Callout,
  Classes,
  Colors,
  Divider,
  Drawer,
  Icon,
  IconSize,
  Intent,
  NonIdealState,
  Switch,
  Tag,
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import UserAvatar from '@components/UserAvatar';
import dayjs from 'dayjs';
import formatTransactionAmount from '@common/utils/formatTransactionAmount';
import getCountryDataFromCallingCode from '@common/utils/getCountryDataFromCallingCode';
import GlobalLoader from '@components/GlobalLoader';
import getInvestmentNotificationTimeValue from '@common/utils/getInvestmentNotificationTimeValue';
import OrgInvestmentContactsForAccountPage from './OrgInvestmentContactsForAccount';
// import AssetClassMappingForAccount from './AssetClassMappingForAccount';
// import getAssetClassTableBaseConfig from './AssetClassMappingForAccount/getAssetClassMappingConfig';
// import useExposure from '@common/hooks/useExposure';
// import useExposureStore from '@stores/useExposureStore';

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: { width?: string; wordWrap: 'break-word' } = {
    wordWrap: 'break-word',
  };
  switch (columnId) {
    case 'id':
      style.width = '5%';
      break;
    case 'accountName':
      style.width = '30%';
      break;
    case 'accountType':
      style.width = '20%';
      break;
    case 'accountBenchmark':
      style.width = '20%';
      break;
    case 'accountAssetClasses':
      style.width = '20%';
      break;
    case 'activeInd':
      style.width = '10%';
      break;
  }
  return { style };
};

const getCellProps = (cell: Cell<IAccount>) => {
  const styles = getStyleOfColumns(cell.column.id);
  return { ...styles };
};

const getHeaderProps = (column: Column<IAccount>) => {
  const styles = getStyleOfColumns(column.id);
  return { ...styles };
};

export function AccountsRecord({ data }: { data: IAccount | undefined }): ReactElement {
  // const { selectedFundId, filterDate } = useExposureStore();
  // const { data: exposureData, isSuccess: isExposureSuccess } = useExposure(selectedFundId, filterDate);
  // const exposureTableColumns = useMemo(() => getAssetClassTableBaseConfig(), []);

  // useEffect(() => {
  //   exposureData;
  // }, [exposureData]);
  return (
    <>
      {data && (
        <Styles>
          <Flex>
            <Box m={2} width={[8 / 8]}>
              <CustomCard
                heading={'Basic Information'}
                body={
                  <Flex p={2}>
                    <Box style={{ background: Colors.WHITE, width: '100%' }}>
                      <table
                        width="100%"
                        className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered"
                        style={{ tableLayout: 'fixed' }}
                      >
                        <tbody>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account ID</Box>
                            </td>
                            <td className="valueName">
                              <Box p={2}>{data.id}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Fund</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.fund.fundName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Name</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span style={{ fontWeight: 'bold' }}>{data.accountName}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Legal Name</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountLegalName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Type</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountType.accountTypeName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Code</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountCode}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Fund Code</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountFundCode}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Identifier Key</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountIdentifierKey}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Identifier Key Type</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountIdentifierKeyTypeCode}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Benchmark</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.accountBenchmark?.benchmarkName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Cusip</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.cusip}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Isin</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.isin}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Sedol</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.sedol}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Letter Of Directive (LOD Required to trade)</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.lodRequiredInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>

                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Status</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.activeInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <>
                              <td className="keyName">
                                <Box p={2}>Deactivated By</Box>
                              </td>
                              <td>
                                {data.deactBy ? (
                                  <Flex alignItems={'center'}>
                                    <Box>
                                      <UserAvatar user={data.deactBy}></UserAvatar>
                                    </Box>
                                    <Box ml={2}>
                                      <div>{data.deactBy.firstName + ' ' + data.deactBy.lastName}</div>
                                    </Box>
                                  </Flex>
                                ) : (
                                  <Flex alignItems={'center'}>
                                    <Box ml={2}>
                                      <div> </div>
                                    </Box>
                                  </Flex>
                                )}
                              </td>
                            </>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Date Of Deactivation</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.deactDt ? dayjs(data.deactDt).format('MM/DD/YYYY hh:mm:ss A') : ''}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Reason For Deactivation</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.deactReasonText}</span>
                              </Box>
                            </td>
                          </tr>

                          <tr>
                            <td className="keyName">
                              <Box p={2}>Cash Account</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.cashAccountInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Primary Cash Account </Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.primaryCashAccountInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Source Account No</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.srcAccountNo}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Provider Name</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>{data.providerName}</span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <>
                              <td className="keyName">
                                <Box p={2}>Created By</Box>
                              </td>
                              <td>
                                {data.createdBy ? (
                                  <Flex alignItems={'center'}>
                                    <Box>
                                      <UserAvatar user={data.createdBy}></UserAvatar>
                                    </Box>
                                    <Box ml={2}>
                                      <div>{data.createdBy.firstName + ' ' + data.createdBy.lastName}</div>
                                    </Box>
                                  </Flex>
                                ) : (
                                  <Flex alignItems={'center'}>
                                    <Box ml={2}>
                                      <div> </div>
                                    </Box>
                                  </Flex>
                                )}
                              </td>
                            </>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Created Date</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>
                                  {data.createdDt ? dayjs(data.createdDt).format('MM/DD/YYYY hh:mm:ss A') : ''}
                                </span>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <>
                              <td className="keyName">
                                <Box p={2}>Updated By</Box>
                              </td>
                              <td>
                                {data.updatedBy ? (
                                  <Flex alignItems={'center'}>
                                    <Box>
                                      <UserAvatar user={data.updatedBy}></UserAvatar>
                                    </Box>
                                    <Box ml={2}>
                                      <div>{data.updatedBy.firstName + ' ' + data.updatedBy.lastName}</div>
                                    </Box>
                                  </Flex>
                                ) : (
                                  <Flex alignItems={'center'}>
                                    <Box ml={2}>
                                      <div> </div>
                                    </Box>
                                  </Flex>
                                )}
                              </td>
                            </>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Updated Date</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <span>
                                  {data.updatedDt ? dayjs(data.updatedDt).format('MM/DD/YYYY hh:mm:ss A') : ''}
                                </span>
                              </Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Flex>
                }
              ></CustomCard>
            </Box>
          </Flex>
          <Divider></Divider>
          <Flex>
            <Box m={2} width={[8 / 8]}>
              <CustomCard
                heading={'Liquidity Information'}
                body={
                  <Flex>
                    <Box style={{ background: Colors.WHITE, width: '100%' }}>
                      <table
                        width="100%"
                        className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered"
                        style={{ tableLayout: 'fixed' }}
                      >
                        <tbody>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Invest At Will</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.investAtWillInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Investment Frequency</Box>
                            </td>
                            <td>
                              <Box pl={2}>{data.investmentFrequencyCode}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Settlement Period (in days)</Box>
                            </td>
                            <td>
                              <Box pl={2}>{data.settlementInDays > 0 ? `T+${data.settlementInDays}` : `T`}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Last Business Day Trade</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.lastBusinessDayTradeInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Days from Last Business Day Window</Box>
                            </td>
                            <td>
                              <Box pl={2}>{data.daysFromLastBusinessDayWindowNo}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Minimum Investment Amount</Box>
                            </td>
                            <td>
                              <Box pl={2}>{formatTransactionAmount({ value: Number(data.minimumInvestmentAmt) })}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Investment Notification</Box>
                            </td>
                            <td>
                              <Box pl={2}>
                                {console.log('Minutes: ' + data.investNotificationMins)}
                                {console.log('Period: ' + data.investNotificationPeriod)}
                                {data.investNotificationPeriod === 0
                                  ? `T ${getInvestmentNotificationTimeValue({ value: data.investNotificationMins })}`
                                  : data.investNotificationPeriod > 0
                                  ? `T-${data.investNotificationPeriod}  ${getInvestmentNotificationTimeValue({
                                      value: data.investNotificationMins,
                                    })}`
                                  : 'N/A'}
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Account Domicile Country </Box>
                            </td>
                            <td>
                              <Box pl={2}>
                                {getCountryDataFromCallingCode({ value: data.accountDomicileCountryId.toString() })}
                              </Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Flex>
                }
              ></CustomCard>
            </Box>
          </Flex>
          <Divider></Divider>
          <Flex>
            <Box m={2} width={[8 / 8]}>
              <CustomCard
                heading={'Wire Information'}
                body={
                  <Flex>
                    <Box style={{ background: Colors.WHITE, width: '100%' }}>
                      <table
                        width="100%"
                        className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered"
                        style={{ tableLayout: 'fixed' }}
                      >
                        <tbody>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Wired</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                <Switch
                                  readOnly={true}
                                  checked={data.wireInd}
                                  disabled={true}
                                  style={{ marginBottom: 0 }}
                                ></Switch>
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Bank ABA Code</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireBankAbaCode}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Base Currency</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireBaseCurrency}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Beneficiary Account Name</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireBeneficiaryAccountName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Beneficiary Account Number</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireBeneficiaryAccountNumber}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Beneficiary Bank Name</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireBeneficiaryBankName}</Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Notification Period in (days)</Box>
                            </td>
                            <td>
                              <Box p={2}>
                                {data.wireNotificationPeriodDays && data.wireNotificationPeriodDays > 0
                                  ? `T+${data.wireNotificationPeriodDays}`
                                  : data.wireNotificationPeriodDays === 0
                                  ? 'T'
                                  : 'N/A'}
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Reference</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.wireReferenceText}</Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Flex>
                }
              ></CustomCard>
            </Box>
          </Flex>
          <Divider></Divider>
          <Flex>
            <Box m={2} width={[8 / 8]}>
              <CustomCard
                heading={'Account Investment Policy'}
                rightToolbar={<Box></Box>}
                body={
                  <Flex>
                    <Box style={{ background: Colors.WHITE, width: '100%' }}>
                      <table
                        width="100%"
                        className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered"
                      >
                        <tbody>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>Target Allocation Lower Limit %:</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.targetAllocationRangeLowerBoundPct}</Box>
                            </td>
                            <td className="keyName">
                              <Box p={2}>Target Allocation %:</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.targetAllocationPct}</Box>
                            </td>
                            <td className="keyName">
                              <Box p={2}>Target Allocation Upper Limit %:</Box>
                            </td>
                            <td>
                              <Box p={2}>{data.targetAllocationRangeUpperBoundPct}</Box>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </Box>
                  </Flex>
                }
              ></CustomCard>
            </Box>
          </Flex>
          <Divider></Divider>
          {/* <Flex>
            <Box m={2} width={[8 / 8]}> */}
          {/* <CustomCard
                heading={'Asset Class Mapping'}
                rightToolbar={<Box></Box>}
                body={ */}
          {/* <Flex> */}
          {/* <Box style={{ background: Colors.WHITE, width: '100%' }}>
                      {data.accountAssetClasses?.length ? (
                        <Flex>
                          <AssetClassMappingForAccount assetClasses={data.accountAssetClasses} columns={[]} />
                        </Flex>
                      ) : (
                        <Flex>
                          <AssetClassMappingForAccount assetClasses={[]} columns={[]} />
                        </Flex>
                      )}
                    </Box> */}
          {/* {console.log(`Exposure Data length: ${exposureData?.exposureData.allocations.length}`)}
                {isExposureSuccess && exposureData && (
                  <AssetClassMappingForAccount columns={exposureTableColumns} data={exposureData} />
                )}
              </Flex> */}
          {/* }
              ></CustomCard> */}
          {/* </Box> */}
          {/* </Flex> */}
          {/* {console.log(`Length of asset classes: ${data.accountAssetClasses?.length}`)}
          {data.accountAssetClasses?.length ? (
            <Flex>
              <AssetClassMappingForAccount assetClasses={data.accountAssetClasses} columns={[]} />
            </Flex>
          ) : (
            <Flex>
              <AssetClassMappingForAccount assetClasses={[]} columns={[]} />
            </Flex>
          )} */}
          {/* <Divider></Divider> */}
          {data.investmentContactId?.investmentContacts.length ? (
            <Flex>
              <OrgInvestmentContactsForAccountPage contacts={data.investmentContactId?.investmentContacts} />
            </Flex>
          ) : (
            <Flex>
              <OrgInvestmentContactsForAccountPage contacts={[]} />
            </Flex>
          )}

          {!data && (
            <Box mt={2}>
              <NonIdealState
                icon={'issue'}
                title={<h5 className="bp3-heading"> No Account record found for the selected account</h5>}
              />
            </Box>
          )}
          <Divider style={{ margin: 0 }}></Divider>
        </Styles>
      )}
    </>
  );
}

function AccountsTable({
  columns,
  data,
  fundSelectionDropdown,
  isWriteEnabled,
  isReadEnabled,
  setSelectedAccountRecord,
}: {
  columns: Column<IAccount>[];
  data: IAccount[];
  fundSelectionDropdown: ReactElement;
  isWriteEnabled: boolean;
  isReadEnabled: boolean;
  setSelectedAccountRecord: (record: IAccount) => void;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );

  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div>{fundSelectionDropdown}</div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>

      <table
        className="bp3-html-table  bp3-html-table-bordered"
        {...getTableProps()}
        style={{ tableLayout: 'fixed', width: '100%' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
              {isReadEnabled && <th style={{ width: '8%', textAlign: 'right' }}>View</th>}
              {isWriteEnabled && <th style={{ width: '8%', textAlign: 'right' }}>Edit</th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps(getCellProps(cell))} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
                {isReadEnabled && (
                  <td>
                    <Flex flexDirection="row-reverse">
                      <Button onClick={() => setSelectedAccountRecord(row.original)} minimal intent={Intent.PRIMARY}>
                        <Icon icon={'eye-open'} size={IconSize.LARGE} />
                      </Button>
                    </Flex>
                  </td>
                )}
                {isWriteEnabled && (
                  <td>
                    <Flex flexDirection={'row-reverse'}>
                      <Link
                        className="bp3-button bp3-minimal bp3-outlined bp3-intent-primary bp3-icon-edit"
                        to="/administration/accounts"
                      ></Link>
                    </Flex>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && (
        <NonIdealState
          icon={'issue'}
          title={<h5 className="bp3-heading"> No accounts found for the selected fund.</h5>}
        />
      )}
    </>
  );
}

const AccountsPage: FC = (): ReactElement => {
  const { data: fundsData, isLoading: isGetFundsLoading, isError, error } = useFunds(true);
  const { data: accountsData, isLoading: isGetAccountsLoading, isFetching } = useAccounts();
  const { selectedFundId, setSelectedFundId, switchFund } = useAdministrationStore();
  const [selectedAccountRecord, setSelectedAccountRecord] = useState<IAccount | undefined>();
  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const canCurrentUserReadACL = useAuthStore((state) => state.canCurrentUserReadACL);
  const isWriteEnabled = canCurrentUserWriteACL('ADMIN_ACCOUNTS');
  const isReadEnabled = canCurrentUserReadACL('ADMIN_ACCOUNTS');

  useEffect(() => {
    if (!selectedFundId && fundsData && fundsData?.funds) {
      setSelectedFundId(fundsData?.funds[0].id);
    }
  }, [fundsData]);

  const getAccountsOfSelectedFunds = () => {
    if (!accountsData) return [];
    return accountsData.accounts.filter((account: IAccount) => account.fund.id === selectedFundId);
  };

  const columns = useMemo(
    (): Column<IAccount>[] => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: 'Name',
        accessor: 'accountName',
        Cell: ({ value, row }) => {
          let cashAccountBadge = <></>;
          if (row.original.cashAccountInd) cashAccountBadge = <Tag intent={Intent.NONE}>C</Tag>;
          if (row.original.primaryCashAccountInd) cashAccountBadge = <Tag intent={Intent.PRIMARY}>C</Tag>;

          return (
            <>
              {value} {cashAccountBadge}
              <Box mt={1}>
                <small>
                  {row.original?.cusip && <Tag>cusip {row.original?.cusip}</Tag>}
                  {row.original?.sedol && <Tag>sedol {row.original?.sedol}</Tag>}
                  {row.original?.isin && <Tag>isin {row.original?.isin}</Tag>}
                </small>
              </Box>
            </>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'accountType',
        Cell: ({ value }) => value.accountTypeName,
      },
      {
        Header: 'Asset Class',
        id: 'accountAssetClasses',
        accessor: 'accountAssetClasses',
        Cell: ({ value }) =>
          value?.map((assetClass, i) => (
            <div key={assetClass.masterAssetClass.id}>
              {assetClass.masterAssetClass.className} {i !== value.length - 1 && <>,</>}
            </div>
          )),
      },

      {
        Header: 'Asset Class Benchmark',
        id: 'accountBenchmark',
        accessor: 'accountBenchmark',
        Cell: ({ value }) => value?.benchmarkName,
      },
      {
        Header: 'Status',
        accessor: 'activeInd',
        Cell: ({ value }) => (
          <Switch
            defaultChecked={value}
            readOnly={true}
            checked={value}
            disabled={!isWriteEnabled}
            style={{ marginBottom: 0 }}
          ></Switch>
        ),
      },
    ],

    [],
  );

  const fundSelectionDropdown = (
    <>
      {selectedFundId && fundsData?.funds && (
        <FundSelectionDropdown
          switchFund={switchFund}
          selectedFundId={selectedFundId}
          allFunds={fundsData?.funds}
          disabled={false}
        ></FundSelectionDropdown>
      )}
    </>
  );

  return (
    <Flex m={2}>
      <Box width={[10 / 12]}>
        <CustomCard
          heading="Investment Accounts"
          body={
            <Box>
              {isError ? (
                <Callout intent={Intent.DANGER}>{error?.errorMessage}</Callout>
              ) : (
                <>
                  {isFetching && <GlobalLoader></GlobalLoader>}
                  {isGetAccountsLoading || isGetFundsLoading ? (
                    <TableLoader></TableLoader>
                  ) : (
                    <AccountsTable
                      columns={columns}
                      data={getAccountsOfSelectedFunds()}
                      fundSelectionDropdown={fundSelectionDropdown}
                      isWriteEnabled={isWriteEnabled}
                      isReadEnabled={isReadEnabled}
                      setSelectedAccountRecord={setSelectedAccountRecord}
                    />
                  )}
                </>
              )}
            </Box>
          }
        ></CustomCard>
        <Drawer
          icon={'list-detail-view'}
          title={
            <h6 className="bp3-heading">
              {'Account Record #'}
              {selectedAccountRecord?.id}
            </h6>
          }
          size={'75%'}
          isOpen={!!selectedAccountRecord?.id}
          onClose={() => setSelectedAccountRecord(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            <AccountsRecord data={selectedAccountRecord}></AccountsRecord>
          </div>
        </Drawer>
      </Box>
      <Box mx={2} width={[2 / 12]}>
        <Callout>
          <small>
            <Box mb={2}>
              <Tag intent={Intent.NONE}>C</Tag> Cash Account{' '}
            </Box>
            <Box>
              <Tag intent={Intent.PRIMARY}>C</Tag> Primary Cash Account
            </Box>
          </small>
        </Callout>
      </Box>
    </Flex>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY5} !important;
  }
  .table tbody tr td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    // white-space: nowrap;
    // word-wrap: break-word;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // overflow-y: hidden;
  }
`;

export default memo(AccountsPage);

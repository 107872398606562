import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetFileCreationMetadataResponse } from '@shared/exchange/getFileCreationMetadata';

export default function useGetFileCreationMetadata(): UseQueryResult<IGetFileCreationMetadataResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetFileCreationMetadataResponse, IErrorResponse>(
    ['get-file-creation-metadata'],
    async () =>
      axios
        ?.get('get-file-creation-metadata')
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 0, retry: false },
  );
}

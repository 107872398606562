import { FC, forwardRef, HTMLProps } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { FormGroup } from '@blueprintjs/core';

type InputProps = HTMLProps<HTMLInputElement>;

interface ICashManagementEntryTransactionDatePickerProps {
  isLoading: boolean;
  selectedTransactionDate?: string | undefined;
  setTransactionDate: (date: Date) => void;
}

const CashManagementEntryTransactionDatePicker: FC<ICashManagementEntryTransactionDatePickerProps> = ({
  isLoading,
  setTransactionDate,
  selectedTransactionDate,
}) => {
  const DateFilterComponent = forwardRef<HTMLInputElement, InputProps>(({ value, onClick, onChange }, ref) => (
    <FormGroup label="Transaction Date" labelFor="cash-entry-date-picker">
      <input
        className="bp3-input bp3-fill bp3-large"
        ref={ref}
        onClick={onClick}
        onChange={onChange}
        id="cash-entry-date-picker"
        value={value}
      ></input>
    </FormGroup>
  ));

  return (
    <>
      <div style={{ zIndex: 999 }}>
        <DatePicker
          disabled={isLoading}
          selected={dayjs(selectedTransactionDate).toDate()}
          customInput={<DateFilterComponent />}
          onChange={(date: Date) => {
            setTransactionDate(date);
          }}
          className="p-0 m-0"
          wrapperClassName="p-0 m-0 w-100"
        />
      </div>
    </>
  );
};

export default CashManagementEntryTransactionDatePicker;

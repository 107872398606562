import { ReactElement, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useGetETLMarketData from '@common/hooks/useGetETLMarketData';

import React from 'react';
import { useTable, useGroupBy, useExpanded, Column, useFilters, useGlobalFilter } from 'react-table';
import IProxyDailyValue from '@shared/interfaces/IProxyDailyValue';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import {
  Alignment,
  Button,
  Classes,
  Divider,
  Drawer,
  Intent,
  Navbar,
  NonIdealState,
  Position,
} from '@blueprintjs/core';
import CustomDateRangePicker from '@components/CustomDateRangePicker';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import { Tooltip2 } from '@blueprintjs/popover2';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import GlobalTableFilter from '@components/GlobalTableFilter';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';

const RefreshCycleDescriptionMap: Record<string, string> = {
  D: 'Daily',
  M: 'Monthly',
  W: 'Weekly',
  H: 'Hourly',
  Y: 'Yearly',
  Q: 'Quarterly',
};

/*
function MarketDataDetailsDrawer({
  isOpen,
  data,
  close,
}: {
  isOpen: boolean;
  data: IProxyDailyValue[];
  close: () => void;
}) {
  return (
    <Drawer onClose={close} title="">
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>r of data integration with Palantir Foundry</div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>Footer</div>
    </Drawer>
  );
}
*/

function Table({
  columns,
  data,
  globalFilterValue,
}: {
  columns: Column<IProxyDailyValue>[];
  data: IProxyDailyValue[];
  globalFilterValue: string;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        groupBy: ['proxy_ticker'],
      },
      expandSubRows: false,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useExpanded, // useGroupBy would be pretty useless without useExpanded ;)
  );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  // We don't want to render all of the rows for this example, so cap
  // it at 100 for this use case

  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              <th>#</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {/*column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>{column.isGrouped ? 'GG ' : ' '}</span>
                  ) : null*/}

                  {column.render('Header')}
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                <td>{i + 1}</td>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // For educational purposes, let's color the
                      // cell depending on what type it is given
                      // from the useGroupBy hook
                      {...cell.getCellProps()}
                      key={cell.column.id + '_' + cell.row.id}
                    >
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>{cell.render('Cell')}</>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
                <td>
                  <Flex flexDirection="row-reverse">
                    <Button
                      outlined
                      minimal
                      intent={Intent.PRIMARY}
                      rightIcon={'chevron-right'}
                      {...row.getToggleRowExpandedProps()}
                    >
                      View Details
                    </Button>
                  </Flex>
                </td>
                <Drawer
                  icon={'list-detail-view'}
                  title={<h6 className="bp3-heading">{row.values.proxy_name}</h6>}
                  size={'33%'}
                  isOpen={row.isExpanded}
                  onClose={() => row.toggleRowExpanded()}
                >
                  <div className={Classes.DRAWER_BODY}>
                    <div>
                      <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-condensed" width="100%">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Market Date</th>
                            <th>
                              {' '}
                              <Box textAlign="right">Market Value</Box>
                            </th>
                            <th>Refresh Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.subRows.map((r, i) => (
                            <tr key={r.id}>
                              <td>{i + 1}</td>
                              <td>{dayjs(r.original.as_of_date).format('MM/DD/YYYY')}</td>
                              <td>
                                <Box textAlign="right">
                                  <Tooltip2 position={Position.BOTTOM} content={<>{+Number(r.original.proxy_value)}</>}>
                                    {formatAmountRoundTwo({ value: Number(r.original.proxy_value) })}
                                  </Tooltip2>
                                </Box>
                              </td>

                              <td>{dayjs(r.original.run_date).format('MM/DD/YYYY')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {row.subRows.length === 0 && (
                        <Box mt={2}>
                          <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No Data Found.</h5>} />
                        </Box>
                      )}
                      <Divider style={{ margin: 0 }}></Divider>
                    </div>
                  </div>
                </Drawer>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <Box mt={2}>
          <NonIdealState
            icon={'issue'}
            title={<h5 className="bp3-heading"> No Market Date Found for selected date range.</h5>}
          />
        </Box>
      )}
    </>
  );
}

const OpsMarketData = (): ReactElement => {
  const [startDate, setStarteDate] = useState<Date>(dayjs().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());
  const {
    data: marketData,
    isLoading: isMarketDataLoading,
    isFetching: isMarketDataFetching,
  } = useGetETLMarketData(startDate, endDate);
  //const [selectedDetailsData, setSelectedDetailsData] = useState<IProxyDailyValue>();
  const [globalFilterValue, setGlobalFilterValue] = useState<string | undefined>('');

  const columns: Column<IProxyDailyValue>[] = React.useMemo(
    () => [
      {
        Header: 'Ticker',
        accessor: 'proxy_ticker',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value}`,
      },
      {
        Header: 'Name',
        accessor: 'proxy_name',
        aggregate: (leaves) => leaves[0],
        Aggregated: ({ value }) => `${value}`,
      },
      {
        Header: 'Refresh Cycle',
        accessor: 'refresh_cycle',
        aggregate: (leaves) => RefreshCycleDescriptionMap[leaves[0]] || leaves[0],
        Aggregated: ({ value }) => `${value}`,
      },
    ],
    [],
  );

  const Filters = (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <CustomDateRangePicker
          label={'Market Date Range'}
          isLoading={isMarketDataLoading}
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => setStarteDate(date)}
          setEndDate={(date) => setEndDate(date)}
        ></CustomDateRangePicker>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <GlobalTableFilter globalFilter={globalFilterValue || ''} setGlobalFilter={setGlobalFilterValue} />
      </Navbar.Group>
    </Navbar>
  );
  return (
    <>
      {isMarketDataFetching && <GlobalLoader></GlobalLoader>}
      <Box p={3}>
        {isMarketDataLoading && <TableLoader></TableLoader>}
        {marketData?.proxyData && (
          <CustomCard
            heading="Market Data"
            body={
              <>
                {Filters}
                <Table globalFilterValue={globalFilterValue || ''} columns={columns} data={marketData?.proxyData} />
              </>
            }
          ></CustomCard>
        )}
      </Box>
    </>
  );
};

export default OpsMarketData;

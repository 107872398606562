export const ROLES = [
  'OrgAdmin',
  'OrgInvestmentAdmin',
  'OrgInvestmentAnalyst',
  'OrgOpsAdmin',
  'OrgReadOnly',
  'OrgClientReadOnly',
] as const;

export const ROLES_CONFIG = [
  {
    role_name: 'OrgAdmin',
    display_name: 'Admin',
  },
  {
    role_name: 'OrgInvestmentAdmin',
    display_name: 'Portfolio Manager',
  },
  {
    role_name: 'OrgInvestmentAnalyst',
    display_name: 'Investment Analyst',
  },
  {
    role_name: 'OrgOpsAdmin',
    display_name: 'Ops Admin',
  },
  {
    role_name: 'OrgReadOnly',
    display_name: 'Read Only',
  },
  {
    role_name: 'SaaSAdmin',
    display_name: 'SaaS Admin',
  },
  {
    role_name: 'OrgClientReadOnly',
    display_name: 'Client Read Only',
  },
] as const;

export enum RoleEnum {
  OrgAdmin = 'OrgAdmin',
  OrgInvestmentAdmin = 'OrgInvestmentAdmin',
  OrgInvestmentAnalyst = 'OrgInvestmentAnalyst',
  OrgOpsAdmin = 'OrgOpsAdmin',
  OrgReadOnly = 'OrgReadOnly',
  OrgClientReadOnly = 'OrgClientReadOnly',
}

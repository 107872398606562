import { IGetCurrentUserResponse } from '@shared/exchange/getCurrentUser';
import IUserOrgRole from '@shared/interfaces/IUserOrgRole';
import useAuthStore from '@stores/useAuthStore';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

export default function useCurrentUser(): UseQueryResult<IGetCurrentUserResponse, Error> {
  const axios = useAxios();
  const { setCurrentUserOrgRole, setCurrentUser } = useAuthStore(({ setCurrentUserOrgRole, setCurrentUser }) => {
    return {
      setCurrentUserOrgRole,
      setCurrentUser,
    };
  });

  return useQuery<IGetCurrentUserResponse, Error>(
    ['currentUser'],
    async () => axios?.get('get-current-user').then((res) => res.data.data),
    {
      staleTime: 30 * 60 * 1000,
      onSuccess: (data: IGetCurrentUserResponse) => {
        setCurrentUser(data);
        const currentOrgRole = data?.userOrgRoles.find((orgRole: IUserOrgRole) => orgRole.org.id == data?.currentOrgId);
        if (currentOrgRole) setCurrentUserOrgRole(currentOrgRole);
      },
    },
  );
}

import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetCashTransactionsAuditResponse } from '@shared/exchange/getCashTransactionsAudit';

export const GET_CASH_TRANSACTIONS_KEY = 'get-cash-transactions';
export default function useGetCashTransactionsAudit(
  cashTransactionId: number,
): UseQueryResult<IGetCashTransactionsAuditResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetCashTransactionsAuditResponse, IErrorResponse>(
    [GET_CASH_TRANSACTIONS_KEY, { cashTransactionId }],
    async () => {
      if (!cashTransactionId) return [];
      const searchParams = new URLSearchParams();
      searchParams.append('cashTransactionId', String(cashTransactionId));
      return axios
        ?.get(`get-cash-transactions-audit?${searchParams.toString()}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}

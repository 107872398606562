import { Card, Divider } from '@blueprintjs/core';
import formatAssetValue from '@common/utils/formatAssetValue';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import ScheduledCashflowInputPanel from './ScheduledCashflowInputPanel';
import Sliders from './Sliders';
import UserDefindCashflowInputPanel from './UserDefindCashflowInputPanel';

const Controls = ({
  resetRebalResults,
  selectedFundId,
}: {
  selectedFundId: number;
  resetRebalResults: () => void;
}): ReactElement => {
  const {
    userDefinedCashflowRedemption,
    userDefinedCashflowContribution,
    scheduledCashflowRedemption,
    scheduledCashflowContribution,
  } = useWhatIfRebalStore();
  return (
    <Card style={{ padding: 0, borderRadius: 0 }} elevation={2}>
      <Flex>
        <Box width={[6 / 12]}>
          <Flex flexDirection="column">
            <Flex>
              <Box width={[1 / 2]}>
                <ScheduledCashflowInputPanel
                  selectedFundId={selectedFundId}
                  onChange={resetRebalResults}
                ></ScheduledCashflowInputPanel>
              </Box>
              <Divider style={{ margin: 0 }} />
              <Box width={[1 / 2]}>
                <UserDefindCashflowInputPanel onChange={resetRebalResults}></UserDefindCashflowInputPanel>
              </Box>
            </Flex>
            <Divider style={{ margin: 0 }} />
            <Box textAlign="center" py={2}>
              Net Contribution Amount:
              <Box pl={3} display={'inline'}>
                <strong>
                  {formatAssetValue({
                    value:
                      (userDefinedCashflowContribution || 0) +
                      (scheduledCashflowContribution || 0) -
                      (userDefinedCashflowRedemption || 0) -
                      (scheduledCashflowRedemption || 0),
                  })}
                </strong>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Divider style={{ margin: 0 }} />
        <Box width={[6 / 12]}>
          <Sliders resetRebalResults={resetRebalResults}></Sliders>
        </Box>
      </Flex>
    </Card>
  );
};

export default Controls;

import { Card } from '@blueprintjs/core';
import { ReactElement } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const PieChartLoader = (props: IContentLoaderProps): ReactElement => (
  <Card style={{ padding: 0, paddingBottom: 2, overflow: 'hidden' }}>
    <ContentLoader
      viewBox="0 0 100% 300"
      height={350}
      width={'100%'}
      {...props}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="48" />
      <circle cx="140" cy="185" r="120" />
      <rect x="330" y="100" rx="0" ry="0" width="4%" height="30" />
      <rect x="370" y="100" rx="0" ry="0" width="40%" height="30" />
      <rect x="330" y="140" rx="0" ry="0" width="4%" height="30" />
      <rect x="370" y="140" rx="0" ry="0" width="40%" height="30" />
      <rect x="330" y="180" rx="0" ry="0" width="4%" height="30" />
      <rect x="370" y="180" rx="0" ry="0" width="40%" height="30" />
      <rect x="330" y="220" rx="0" ry="0" width="4%" height="30" />
      <rect x="370" y="220" rx="0" ry="0" width="40%" height="30" />
    </ContentLoader>
  </Card>
);
export default PieChartLoader;

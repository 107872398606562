import { Alignment, Navbar } from '@blueprintjs/core';
import { Box } from '@rebass/grid/emotion';
import { FC, ReactNode } from 'react';

interface IPageToolbarProps {
  toolbarRow?: ReactNode;
  leftSegment?: ReactNode;
  rightSegment?: ReactNode;
  toolbarSubRow?: ReactNode | null;
}

const PageToolbar: FC<IPageToolbarProps> = ({ leftSegment, rightSegment }) => {
  return (
    <Box pb={4} mb={3}>
      <Navbar style={{ top: '3.125rem', position: 'fixed', right: '0rem', paddingLeft: '4.3rem', zIndex: 20 }}>
        {leftSegment && <Navbar.Group align={Alignment.LEFT}>{leftSegment}</Navbar.Group>}
        {rightSegment && <Navbar.Group align={Alignment.RIGHT}>{rightSegment}</Navbar.Group>}
      </Navbar>
    </Box>
  );
};

export default PageToolbar;

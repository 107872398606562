interface IFormatTransactionAmount {
  value: number;
}
const formatTransactionAmount = ({ value }: IFormatTransactionAmount): string => {
  if (!value || Number.isNaN(value)) return '0';
  const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
  return nf.format(Math.trunc(value));
};

export default formatTransactionAmount;

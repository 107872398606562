import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { ItemListRenderer, Select } from '@blueprintjs/select';
import SelectWrapper from '@components/SelectWrapper/SelectWrapper';
import IAccount from '@shared/interfaces/IAccount';
import { FC, ReactElement, useCallback } from 'react';
import { Flex } from 'reflexbox';

interface IUserSelectionDropdownProps {
  externalAccounts: IAccount[];
  cashAccounts: IAccount[];
  investmentAccounts: IAccount[];
  selectedAccountName: string | undefined;
  switchAccount: (accountName: string) => void;
  disabled: boolean;
}
const AccountSelectionDropdown: FC<IUserSelectionDropdownProps> = ({
  externalAccounts,
  cashAccounts,
  investmentAccounts,
  selectedAccountName,
  switchAccount,
  disabled,
}): ReactElement => {
  const handleChange = useCallback(
    (accountName) => {
      switchAccount(accountName);
    },
    [switchAccount],
  );

  const AccountSelect = Select.ofType<IAccount | undefined>();

  const renderMenu: ItemListRenderer<IAccount | undefined> = ({ itemsParentRef, renderItem }) => {
    const cashAccountsItems = cashAccounts.map(renderItem);
    const privateEquityAcccountsItems = investmentAccounts.map(renderItem);
    const externalAccountsItems = externalAccounts.map(renderItem);

    return (
      <Menu ulRef={itemsParentRef}>
        {renderItem(undefined, -1)}
        {cashAccounts?.length > 0 && <MenuDivider title={'Cash Accounts'} />}
        {cashAccountsItems}
        {investmentAccounts?.length > 0 && <MenuDivider title={'Investment Accounts'} />}
        {privateEquityAcccountsItems}
        {externalAccounts?.length > 0 && <MenuDivider title={'External'} />}
        {externalAccountsItems}
      </Menu>
    );
  };

  return (
    <>
      <Flex>
        <SelectWrapper label="Accounts">
          <AccountSelect
            popoverProps={{ minimal: true }}
            filterable={false}
            items={[]}
            itemListRenderer={renderMenu}
            itemRenderer={(account: IAccount | undefined, { handleClick }) => (
              <MenuItem onClick={handleClick} text={account ? account.accountName : 'All Accounts'} />
            )}
            onItemSelect={(account: IAccount | undefined) => handleChange(account?.accountName)}
          >
            <Button
              minimal={true}
              outlined={true}
              disabled={disabled}
              text={selectedAccountName ? selectedAccountName : 'All Accounts'}
              rightIcon="chevron-down"
            />
          </AccountSelect>
        </SelectWrapper>
      </Flex>

      {/*

      <div className="fund-drop">
        <p>Accounts</p>
        <div className="dropdown">
          <button
            className="btn dropdown-toggle text-dark"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={disabled}
          >
            {selectedAccountName ? selectedAccountName : 'All Accounts'}
          </button>
          <ul className="dropdown-menu" style={{ width: 'auto' }} aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#" onClick={() => handleChange(undefined)}>
                All Accounts
              </a>
            </li>
            {cashAccounts?.length > 0 && (
              <li>
                <a className="dropdown-item disabled dropdown-item-heading">Cash Accounts</a>
              </li>
            )}

            {cashAccounts?.map((account) => {
              return (
                <li key={account.accountName}>
                  <a className="dropdown-item" href="#" onClick={() => handleChange(account.accountName)}>
                    {account.accountName}
                  </a>
                </li>
              );
            })}
            {privateEquityAcccounts?.length > 0 && (
              <li>
                <a className="dropdown-item disabled dropdown-item-heading">Private Equity</a>
              </li>
            )}
            {privateEquityAcccounts?.map((account) => {
              return (
                <li key={account.accountName}>
                  <a className="dropdown-item" href="#" onClick={() => handleChange(account.accountName)}>
                    {account.accountName}
                  </a>
                </li>
              );
            })}

            {externalAccounts?.length > 0 && (
              <li>
                <a className="dropdown-item disabled dropdown-item-heading">External</a>
              </li>
            )}
            {externalAccounts?.map((account) => {
              return (
                <li key={account.accountName}>
                  <a className="dropdown-item" href="#" onClick={() => handleChange(account.accountName)}>
                    {account.accountName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
          */}
    </>
  );
};

export default AccountSelectionDropdown;

import { GET_TRADE_ITEM_KEY } from '@common/hooks/useGetTradeItems';
import { GET_TRADES_KEY } from '@common/hooks/useGetTrades';
import useUpdateTradeStatus from '@common/hooks/useUpdateTradeStatus';
import ITrade from '@shared/interfaces/ITrade';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Column, useExpanded, useFilters, useTable } from 'react-table';
import TradeItemsProcessingSubPane from './TradeItemsProcessingSubPane';
import { GET_DOC_FACTS_KEY } from '@common/hooks/useDocFacts';
import useAuthStore from '@stores/useAuthStore';
import useCashManagementStore from '@stores/useCashManagementStore';
import { useHistory } from 'react-router';
import { Button, Intent, ButtonGroup, Collapse, NonIdealState, Alert, Position } from '@blueprintjs/core';
import { Box } from 'reflexbox';
import SimpleBar from 'simplebar-react';
import { AppToaster } from '@components/Toasters/AppToaster';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import TradeAudit from '../TradeAudit';

interface ITradeProcessingTableProps {
  columns: Column<ITrade>[];
  data: ITrade[];
  selectedTradeStatusName: string | undefined;
}

const TradeProcessingTable = ({ columns, data, selectedTradeStatusName }: ITradeProcessingTableProps): ReactElement => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow, toggleRowExpanded, setFilter } =
    useTable<ITrade>(
      {
        columns,
        data,
      },
      useFilters,
      useExpanded,
    );

  useEffect(() => {
    setFilter('tradeStatus', selectedTradeStatusName);
  }, [selectedTradeStatusName]);

  const [statusUpdating, setStatusUpdating] = useState<boolean>(false);
  const [selectedTradeRecord, setSelectedTradeRecord] = useState<ITrade | undefined>();

  const { mutateAsync: updateTradeStatus, error: updateTradeStatusError } = useUpdateTradeStatus();
  const queryClient = useQueryClient();
  const [eventAction, setEventAction] = useState<{ event: 'NEXT' | 'WF_TERMINATE'; tradeId: number } | undefined>();

  const callEvent = useCallback(async () => {
    if (!eventAction) return;
    setStatusUpdating(true);
    try {
      const { event, tradeId } = eventAction;
      if (event === 'NEXT') await updateTradeStatus({ tradeId });
      else if (event === 'WF_TERMINATE') await updateTradeStatus({ shouldCancel: true, tradeId });
      setEventAction(undefined);
      setStatusUpdating(false);
      queryClient.resetQueries(GET_DOC_FACTS_KEY);
      queryClient.resetQueries(GET_TRADES_KEY);
      queryClient.resetQueries(GET_TRADE_ITEM_KEY);
    } catch (e) {
      setEventAction(undefined);
      setStatusUpdating(false);
    }
  }, [eventAction]);

  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const { setSuperSearchText, superSearch, setSupeSearchType } = useCashManagementStore(
    ({ setSuperSearchText, superSearch, setSupeSearchType }) => {
      return { setSuperSearchText, superSearch, setSupeSearchType };
    },
  );

  const history = useHistory();
  const handleViewCashTransactionsOfTrade = (tradeId: number) => {
    setSuperSearchText(tradeId.toString());
    setSupeSearchType('TRADE');
    superSearch(tradeId.toString(), undefined);
    history.push('/cash-management');
  };

  useEffect(() => {
    if (updateTradeStatusError) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: updateTradeStatusError?.errorMessage,
        icon: 'warning-sign',
      });
    }
  }, [updateTradeStatusError]);

  // Render the UI for your table
  return (
    <SimpleBar>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          <tr></tr>
          <tr className={`theadTr${1}`}>
            {headers.map((column, i) => (
              <th {...column.getHeaderProps()} key={i} id={`${1}itheadTh${i}`}>
                {column.render('Header')}
              </th>
            ))}
            <th>Action</th>
            <th>Cash Entries</th>
            <th>Audit</th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} id={`tbl-row${row.id}`} key={i}>
                  {row.cells.map((cell, i) => {
                    i++;
                    return (
                      <td
                        {...cell.getCellProps()}
                        id={`${row.id}tbodyTD${i - 1}`}
                        onClick={() => {
                          return;
                        }}
                        key={i}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  {canCurrentUserWriteACL('TRADE_MANAGEMENT_PROCESSING') && (
                    <td>
                      <ButtonGroup>
                        {row.original.events?.NEXT && (
                          <Button
                            icon={'tick-circle'}
                            intent={Intent.PRIMARY}
                            onClick={() => setEventAction({ event: 'NEXT', tradeId: row.original.id })}
                          >
                            {row.original.events?.NEXT.name}
                          </Button>
                        )}
                        {row.original.events?.WF_TERMINATE && (
                          <Button
                            icon={'trash'}
                            intent={Intent.DANGER}
                            onClick={() => setEventAction({ event: 'WF_TERMINATE', tradeId: row.original.id })}
                          >
                            {row.original.events?.WF_TERMINATE.name}
                          </Button>
                        )}
                      </ButtonGroup>
                    </td>
                  )}
                  <td>
                    <Popover2 content={<h1>Popover!</h1>} position={Position.BOTTOM}>
                      <Tooltip2
                        interactionKind="hover"
                        content="See related Cash Entries"
                        position={Position.BOTTOM}
                        openOnTargetFocus={true}
                      >
                        <Button
                          small
                          minimal
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            handleViewCashTransactionsOfTrade(row.original.id);
                          }}
                          className="btn btn-link"
                        >
                          <i className="bi bi-cash-coin"></i>
                        </Button>
                      </Tooltip2>
                    </Popover2>
                  </td>
                  <td>
                    <Tooltip2
                      interactionKind="hover"
                      content="See Trade Batch Audit"
                      position={Position.LEFT}
                      openOnTargetFocus={true}
                    >
                      <Button
                        small
                        minimal
                        intent={Intent.PRIMARY}
                        onClick={() => {
                          console.log(`You clicked Trade Batch Id: ${row.original.id} `);
                          setSelectedTradeRecord(row.original);
                        }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </Button>
                    </Tooltip2>
                  </td>
                </tr>

                <tr>
                  <td colSpan={100} style={{ padding: 0 }}>
                    {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                    <Collapse isOpen={row.isExpanded}>
                      <Box p={3}>
                        <TradeItemsProcessingSubPane
                          tradeId={row.original.id}
                          tradeStatusName={row.original.tradeStatus.tradeStatusName}
                          fundId={row.original.fund.id}
                          toggleClose={toggleRowExpanded}
                          id={row.id}
                        ></TradeItemsProcessingSubPane>
                      </Box>
                    </Collapse>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {rows?.length == 0 && (
        <Box m={2} p={4}>
          <NonIdealState icon={'info-sign'} title={<h5 className="bp3-heading">No trade batches found.</h5>} />
        </Box>
      )}

      {selectedTradeRecord && (
        <TradeAudit
          tradeRecord={selectedTradeRecord}
          isOpen={!!selectedTradeRecord}
          close={() => setSelectedTradeRecord(undefined)}
        />
      )}

      <Alert
        icon={eventAction?.event === 'NEXT' ? 'tick-circle' : 'trash'}
        intent={eventAction?.event === 'NEXT' ? Intent.PRIMARY : Intent.DANGER}
        isOpen={!!eventAction}
        //onAfterOpen={afterOpenModal}
        confirmButtonText="Yes"
        onConfirm={() => eventAction && callEvent()}
        onCancel={() => setEventAction(undefined)}
        cancelButtonText={'Cancel'}
        loading={statusUpdating}
      >
        <div>
          {!statusUpdating ? <p>Are you sure you want to update the status?</p> : <p>Updating the status...</p>}
        </div>
      </Alert>
    </SimpleBar>
  );
};

export default TradeProcessingTable;

import { Button, Callout, Intent, Position } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import ITradeItem from '@shared/interfaces/ITradeItem';
import useCashManagementStore from '@stores/useCashManagementStore';
import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, useExpanded, useTable } from 'react-table';
import TradeItemAudit from '../TradeItemAudit';

interface ITradeItemsProcessingTableProps {
  columns: Column<ITradeItem>[];
  data: ITradeItem[];
  tradeStatusName: string;
}

const TradeItemsProcessingTable = ({
  columns,
  data,
  tradeStatusName,
}: ITradeItemsProcessingTableProps): ReactElement => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable<ITradeItem>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: tradeStatusName === 'Pending Approval' ? ['lodGeneratedDt', 'signedLodUploadedDt'] : [],
      },
    },
    useExpanded,
  );

  const { setSuperSearchText, superSearch, setSupeSearchType } = useCashManagementStore(
    ({ setSuperSearchText, superSearch, setSupeSearchType }) => {
      return { setSuperSearchText, superSearch, setSupeSearchType };
    },
  );

  const [selectedTradeItemRecord, setSelectedTradeItemRecord] = useState<ITradeItem | undefined>();

  const history = useHistory();
  const handleViewCashTransactionsOfTradeItem = (tradeItemId: number) => {
    setSuperSearchText(tradeItemId.toString());
    setSupeSearchType('TRADE_ITEM');
    superSearch(undefined, tradeItemId.toString());
    history.push('/cash-management');
  };

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          <tr></tr>
          <tr className={`theadTr${1}`}>
            {headers.map((column, i) => (
              <th {...column.getHeaderProps()} key={i} id={`${1}itheadTh${i}`}>
                {column.render('Header')}
              </th>
            ))}
            <th>Cash Entries</th>
            <th>Audit</th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          <tr></tr>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} id={`tbl-row${row.id}`} key={i}>
                  {row.cells.map((cell, i) => {
                    i++;
                    return (
                      <td
                        {...cell.getCellProps()}
                        id={`${row.id}tbodyTD${i - 1}`}
                        onClick={() => {
                          return;
                        }}
                        key={i}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  <td>
                    <Popover2 content={<h1>Popover!</h1>} position={Position.LEFT}>
                      <Tooltip2
                        interactionKind="hover"
                        content="See related Cash Entries"
                        position={Position.LEFT}
                        openOnTargetFocus={true}
                      >
                        <Button
                          small
                          minimal
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            handleViewCashTransactionsOfTradeItem(row.original.id);
                          }}
                          className="btn btn-link"
                        >
                          <i className="bi bi-cash-coin"></i>
                        </Button>
                      </Tooltip2>
                    </Popover2>
                  </td>
                  <td>
                    <Tooltip2
                      interactionKind="hover"
                      content="See Trade Audit"
                      position={Position.LEFT}
                      openOnTargetFocus={true}
                    >
                      <Button
                        small
                        minimal
                        intent={Intent.PRIMARY}
                        onClick={() => {
                          console.log(`You clicked Trade Id: ${row.original.id} `);
                          setSelectedTradeItemRecord(row.original);
                        }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </Button>
                    </Tooltip2>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {data?.length == 0 && <Callout intent={Intent.PRIMARY}>No trades found for this trade batch.</Callout>}
      {selectedTradeItemRecord && (
        <TradeItemAudit
          isOpen={!!selectedTradeItemRecord}
          close={() => setSelectedTradeItemRecord(undefined)}
          tradeItemRecord={selectedTradeItemRecord}
        />
      )}
    </>
  );
};

export default TradeItemsProcessingTable;

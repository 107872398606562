import useGetCashTransactions from '@common/hooks/useGetCashTransactions';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import GlobalLoader from '@components/GlobalLoader';
import PageToolbar from '@components/PageToolbar';
import UserSelectionDropdown from '@components/UsrSelectionDropdown';
import useCashManagementStore from '@stores/useCashManagementStore';
import { FC, ReactElement, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CashTransactionsTable from './components/CashTransactionsTable/CashTransactionsTable';
import 'bootstrap-daterangepicker/daterangepicker.css';
import dayjs from 'dayjs';
import AccountSelectionDropdown from './components/CashTransactionsTable/AccountSelectionDropdown';
import TransactionTypeSelectionDropdown from './components/CashTransactionsTable/TransactionTypeSelectionDropdown';
import TransactionStatusSelectionDropdown from './components/CashTransactionsTable/TransactionStatusSelectionDropdown';
import TableLoader from '@components/TableLoader';
import useAuthStore from '@stores/useAuthStore';
import SimpleBar from 'simplebar-react';
import CustomDatePicker from '@components/CustomDateRangePicker';
import { Box } from 'reflexbox';
import { Col, Container, Row } from 'react-grid-system';
import CustomCard from '@components/CustomCard';
import { Icon, InputGroup, ControlGroup, Button, Intent, Callout } from '@blueprintjs/core';

const CashManagementPage: FC = (): ReactElement => {
  const {
    selectedFundId,
    setSelectedFundId,
    setAllFunds,
    allFunds,
    switchFund,
    allUsers,
    setAllUsers,
    switchUser,
    selectedUserId,
    startDate,
    endDate,
    globalFilterValue,
    setGlobalFilterValue,
    selectedAccountName,
    setSelectedAccountName,
    setSelectedCashtransactionTypeName,
    selectedCashtransactionTypeName,
    selectedTransactionStatusName,
    setSelectedTransactionStatusName,
    setStartDate,
    setEndDate,
    superSearchType,
    setSupeSearchType,
    superSearchText,
    setSuperSearchText,
    superSearch,
    searchTradeId,
    searchTradeItemId,
    disableAllFilters,
    clearSearch,
    setDisableAllFilters,
  } = useCashManagementStore();

  const { data, isFetching, isLoading, isError, error, isSuccess } = useGetCashTransactions(
    selectedFundId,
    startDate,
    endDate,
    searchTradeId,
    searchTradeItemId,
  );

  const canCurrentUserWriteACL = useAuthStore(useCallback((state) => state.canCurrentUserWriteACL, []));
  useEffect(() => {
    if (!data || !data.allFundsOfCurrentOrg) return;
    setAllFunds(data?.allFundsOfCurrentOrg);
    if (!selectedFundId || !data?.allFundsOfCurrentOrg.map((fund) => fund.id).includes(selectedFundId))
      setSelectedFundId(data?.allFundsOfCurrentOrg[0].id);
    if (!data.allUsersOfOrg) return;
    setAllUsers(data?.allUsersOfOrg);
    //setSelectedUserId(data?.selectedUserId);
  }, [data]);

  const FiltersToolbar = (
    <>
      {allUsers && (
        <Box marginRight={2}>
          <UserSelectionDropdown
            allUsers={allUsers}
            selectedUserId={selectedUserId}
            switchUser={switchUser}
            disabled={false}
          ></UserSelectionDropdown>
        </Box>
      )}
      {data && (
        <Box marginRight={2}>
          <AccountSelectionDropdown
            externalAccounts={data.externalAccounts}
            cashAccounts={data.cashAccounts}
            investmentAccounts={data.accounts}
            disabled={false}
            selectedAccountName={selectedAccountName}
            switchAccount={(accountName) => {
              setSelectedAccountName(accountName);
              setGlobalFilterValue(accountName);
            }}
          ></AccountSelectionDropdown>
        </Box>
      )}
      {data && (
        <Box marginRight={2}>
          <TransactionTypeSelectionDropdown
            transactionTypes={data?.cashTransactionTypes}
            selectedTransactionTypeName={selectedCashtransactionTypeName}
            disabled={false}
            switchTransactionType={setSelectedCashtransactionTypeName}
          ></TransactionTypeSelectionDropdown>
        </Box>
      )}
      {data && (
        <Box marginRight={2}>
          <TransactionStatusSelectionDropdown
            transactionStatuses={data?.transactionStatuses}
            selectedTransactionStatusName={selectedTransactionStatusName}
            disabled={false}
            switchTransactionStatus={setSelectedTransactionStatusName}
          ></TransactionStatusSelectionDropdown>
        </Box>
      )}
    </>
  );
  const handleSuperSearchBlur = () => {
    if (!superSearchText || superSearchText.length === 0) {
      setDisableAllFilters(false);
    }
  };

  const clearAndHideSuperSearch = () => {
    clearSearch();
  };

  const handleSuperSearch = () => {
    let searchTradeId: string | undefined = undefined;
    let searchTradeItemId: string | undefined = undefined;
    if (!!superSearchText && !!superSearchType) {
      if (superSearchType === 'TRADE') searchTradeId = superSearchText;
      else if (superSearchType === 'TRADE_ITEM') searchTradeItemId = superSearchText;
    }
    superSearch(searchTradeId, searchTradeItemId);
  };

  return (
    <>
      {isError && <Callout intent={Intent.DANGER}> {error?.errorMessage}</Callout>}
      {(isSuccess || isLoading) && (
        <>
          <PageToolbar
            leftSegment={
              <>
                {!disableAllFilters && (
                  <>
                    <Box mr={2}>
                      {selectedFundId && allFunds && (
                        <FundSelectionDropdown
                          switchFund={switchFund}
                          selectedFundId={selectedFundId}
                          allFunds={allFunds}
                          disabled={false}
                        ></FundSelectionDropdown>
                      )}
                    </Box>

                    <Box mr={2}>
                      {data && (
                        <CustomDatePicker
                          isLoading={!data}
                          setStartDate={(date) => setStartDate(dayjs(date).format('YYYY-MM-DD'))}
                          setEndDate={(date) => setEndDate(dayjs(date).format('YYYY-MM-DD'))}
                          startDate={data?.startDate}
                          endDate={data?.endDate}
                        ></CustomDatePicker>
                      )}
                    </Box>
                  </>
                )}

                <Box mr={2}>
                  <ControlGroup vertical={false}>
                    <InputGroup
                      leftIcon="search"
                      placeholder="Search"
                      value={superSearchText}
                      onChange={(e) => setSuperSearchText(e.target.value)}
                      onFocus={() => setDisableAllFilters(true)}
                      onBlur={handleSuperSearchBlur}
                      type="text"
                      rightElement={
                        <>
                          {disableAllFilters && (
                            <select
                              className="bp3-button bp3-minimal bp3-outlined"
                              onChange={(e) => setSupeSearchType(e.target.value as 'TRADE' | 'TRADE_ITEM')}
                              value={superSearchType}
                              disabled={!superSearchText}
                            >
                              <option value="TRADE">Trade Batch ID</option>
                              <option value="TRADE_ITEM">Trade ID</option>
                            </select>
                          )}
                        </>
                      }
                    />

                    {disableAllFilters && (
                      <Button
                        intent={Intent.PRIMARY}
                        minimal
                        outlined
                        disabled={!superSearchText}
                        onClick={clearAndHideSuperSearch}
                      >
                        Reset screen
                      </Button>
                    )}
                    {disableAllFilters && (
                      <Button intent="primary" disabled={!superSearchText} onClick={() => handleSuperSearch()}>
                        Search
                      </Button>
                    )}
                  </ControlGroup>
                </Box>
              </>
            }
            rightSegment={
              <Box>
                {canCurrentUserWriteACL('CASH_MANAGEMENT') && (
                  <Link to="/cash-management/create" className="bp3-button bp3-intent-primary">
                    <Icon icon="add"></Icon>
                    <Box>Record Transaction</Box>
                  </Link>
                )}
              </Box>
            }
          ></PageToolbar>

          <Container fluid={true}>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Box mt={3}>
                  <CustomCard
                    heading={'Cash Transactions'}
                    innerToolbar={!disableAllFilters ? FiltersToolbar : null}
                    body={
                      <>
                        {isFetching && <GlobalLoader></GlobalLoader>}

                        {isLoading && <TableLoader></TableLoader>}
                        {data && (
                          <SimpleBar forceVisible="y" autoHide={false}>
                            <CashTransactionsTable
                              data={data}
                              selectedUserId={selectedUserId}
                              globalFilterValue={globalFilterValue}
                              selectedTransactionTypeName={selectedCashtransactionTypeName}
                              selectedTransactionStatusName={selectedTransactionStatusName}
                            ></CashTransactionsTable>
                          </SimpleBar>
                        )}
                      </>
                    }
                  ></CustomCard>
                </Box>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CashManagementPage;

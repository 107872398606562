import useCashEntryCreationFormMetaData from '@common/hooks/useCashEntryCreationFormMetaData';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import PageToolbar from '@components/PageToolbar';
import useCashManagementStore from '@stores/useCashManagementStore';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import CashManagementEntryTransactionDatePicker from './components/CashManagementEntryTransactionDatePicker';
import { Controller, useForm } from 'react-hook-form';
import ICashTransactionTypeAccountOption from '@shared/interfaces/ICashTransactionTypeAccountOption';
import useCreateCashTransactionEntry from '@common/hooks/useCreateCashTransactionEntry';
import { ICreateCashTransactionEntryRequest } from '@shared/exchange/createCashTransactionEntry';
import PerformanceChart from '../ExposurePage/components/PerformanceChart';
import useFundSummaryHistory from '@common/hooks/useFundSummaryHistory';
import useFundsSummary from '@common/hooks/useFundsSummary';
import { useHistory } from 'react-router-dom';
import { GET_CASH_TRANSACTIONS_KEY } from '@common/hooks/useGetCashTransactions';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import FormLoader from '@components/FormLoader';
import { Box, Flex } from 'reflexbox';
import CustomCard from '@components/CustomCard';
import { Button, Callout, FormGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import customRegisterFormField from '@common/utils/customRegisterFormField';
import CurrencyInput from 'react-currency-input-field';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';

const CashManagementEntryCreationPage: FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const {
    selectedFundId,
    setSelectedFundId,
    setAllFunds,
    allFunds,
    switchFund,
    //reset: resetCashManagementStore,
  } = useCashManagementStore(({ selectedFundId, setSelectedFundId, setAllFunds, allFunds, switchFund }) => {
    return { selectedFundId, setSelectedFundId, setAllFunds, allFunds, switchFund };
  });
  const { data: cashEntryMetadata, isLoading: isMetaDataLoading } = useCashEntryCreationFormMetaData(selectedFundId);
  const {
    mutateAsync: postCreateCashTransactionEntry,
    isError: isErrorCreateCashTransaction,
    isLoading: isPostCreateCashTransactionEntryLoading,
    error,
    //reset,
  } = useCreateCashTransactionEntry();

  const history = useHistory();

  const { data: fundSummaryHistoryData, isLoading: isFundSummaryHistoryLoading } =
    useFundSummaryHistory(selectedFundId);
  const { data: fundsSummaryData } = useFundsSummary();

  const [selectedTransactionOption, setSelectedTransactionOption] =
    useState<ICashTransactionTypeAccountOption | undefined>(undefined);

  const summaryOfSelectedFund = useMemo(() => {
    if (!fundsSummaryData) return null;
    return fundsSummaryData?.fundsSummary.find((_) => _.fund_id === selectedFundId);
  }, [fundsSummaryData, selectedFundId]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
    control,
  } = useForm();

  const selectedCashTransactionTypeId = watch('cashTransactionType');
  const fromAccount = watch('fromAccount');
  const toAccount = watch('toAccount');

  const getOptionsOfSelectedCashTransaction = () => {
    return cashEntryMetadata?.cashTransactionTypesAccountsOptions.find(
      (_) => _.transactionType.id == selectedCashTransactionTypeId,
    );
  };

  useEffect(() => {
    if (selectedFundId) return;
    if (!cashEntryMetadata || !cashEntryMetadata?.allFundsOfCurrentOrg) return;
    setAllFunds(cashEntryMetadata?.allFundsOfCurrentOrg);
    setSelectedFundId(cashEntryMetadata?.allFundsOfCurrentOrg[0].id);
    setValue('cashTransactionType', cashEntryMetadata.cashTransactionTypes[0]?.id);
  }, [cashEntryMetadata, selectedFundId]);

  useEffect(() => {
    clearErrors();
    if (!selectedCashTransactionTypeId) return;
    const options: ICashTransactionTypeAccountOption | undefined = getOptionsOfSelectedCashTransaction();
    if (options) {
      setSelectedTransactionOption(options);
      setValue('fromAccount', options?.fromAccounts[0]?.id);
      setValue('toAccount', options?.toAccounts[0]?.id);

      if (options?.fromAccounts && options?.fromAccounts.length === 0) trigger('fromAccount');
      if (options?.toAccounts && options?.toAccounts.length === 0) trigger('toAccount');
    }
  }, [selectedCashTransactionTypeId, selectedFundId, cashEntryMetadata]);

  const fromAccountDetails = useMemo(() => {
    if (!selectedCashTransactionTypeId) return;
    if (!fromAccount) return;
    const options: ICashTransactionTypeAccountOption | undefined = getOptionsOfSelectedCashTransaction();
    const fromAccountDetails = options?.fromAccounts.find((fromAccountDetail) => fromAccountDetail.id == fromAccount);
    return fromAccountDetails;
  }, [fromAccount, selectedCashTransactionTypeId, selectedFundId]);

  const toAccountDetails = useMemo(() => {
    if (!selectedCashTransactionTypeId) return;
    if (!toAccount) return;
    const options: ICashTransactionTypeAccountOption | undefined = getOptionsOfSelectedCashTransaction();
    const toAccountDetails = options?.toAccounts.find((fromAccountDetail) => fromAccountDetail.id == toAccount);
    return toAccountDetails;
  }, [toAccount, selectedCashTransactionTypeId, selectedFundId]);

  const onSubmit = async (data: ICreateCashTransactionEntryRequest) => {
    clearErrors();
    if (!selectedFundId) return;
    const options: ICashTransactionTypeAccountOption | undefined = getOptionsOfSelectedCashTransaction();

    const requestObj: ICreateCashTransactionEntryRequest = {
      ...data,
      fundId: selectedFundId,
      fromAccountIsExternalInd: !!options?.fromAccountIsExternalInd,
      toAccountIsExternalInd: !!options?.toAccountIsExternalInd,
    };
    try {
      await postCreateCashTransactionEntry(requestObj);
      //resetCashManagementStore();
      queryClient.invalidateQueries(GET_CASH_TRANSACTIONS_KEY);
      history.push('/cash-management/');
    } catch (e) {}
  };

  const setTransactionDate = (_date: Date | undefined) => {
    if (_date === undefined) return;
    const date = new Date(_date);
    const dateString = dayjs(date).format('YYYY-MM-DD');
    setValue('transactionDate', dateString);
  };

  useEffect(() => {
    setTransactionDate(new Date());
  }, []);

  return (
    <div>
      <PageToolbar
        leftSegment={
          <>
            {selectedFundId && allFunds && (
              <FundSelectionDropdown
                switchFund={switchFund}
                selectedFundId={selectedFundId}
                allFunds={allFunds}
                disabled={false}
              ></FundSelectionDropdown>
            )}
          </>
        }
      ></PageToolbar>
      <Flex mt={4}>
        <Box width={[1, 1 / 2]} mx={2}>
          <CustomCard
            heading={'Record Transaction'}
            body={
              <Box p={3}>
                {isMetaDataLoading && <FormLoader></FormLoader>}
                {isErrorCreateCashTransaction && (
                  <div className="alert alert-danger  text-center mb-4" role="alert">
                    {error?.errorMessage}
                  </div>
                )}
                {cashEntryMetadata && (
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Flex>
                      <Box width={[1 / 2]} p={1}>
                        <FormGroup label="Transaction Type" labelFor="text-input">
                          <HTMLSelect
                            large={true}
                            fill={true}
                            id="cash-from"
                            {...customRegisterFormField(register, 'cashTransactionType', {
                              required: true,
                              valueAsNumber: true,
                            })}
                          >
                            {cashEntryMetadata?.cashTransactionTypes.map((transactionType) => (
                              <option key={transactionType.id} value={transactionType.id} className="my-2">
                                {transactionType.transactionTypeName}
                              </option>
                            ))}
                          </HTMLSelect>
                        </FormGroup>
                      </Box>

                      {/*
                      {cashEntryMetadata?.cashTransactionTypes && (
                        <div className="col-lg-6">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              id="cash-transaction-entry-transaction-type"
                              {...register('cashTransactionType', { required: true, valueAsNumber: true })}
                            >
                              {cashEntryMetadata?.cashTransactionTypes.map((transactionType) => (
                                <option key={transactionType.id} value={transactionType.id} className="my-2">
                                  {transactionType.transactionTypeName}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="cash-transaction-entry-transaction-type">Transaction Type</label>
                          </div>
                        </div>
                              )}*/}
                      <Box width={[1 / 2]} p={1}>
                        <Controller
                          name="transactionDate"
                          control={control}
                          //defaultValue={false}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <CashManagementEntryTransactionDatePicker
                                isLoading={false}
                                setTransactionDate={setTransactionDate}
                                selectedTransactionDate={field.value}
                              ></CashManagementEntryTransactionDatePicker>
                            );
                          }}
                        />
                      </Box>
                    </Flex>
                    <Flex>
                      <Box width={[1 / 2]} p={1}>
                        <FormGroup
                          label={'From Account'}
                          intent={errors?.fromAccount ? Intent.DANGER : Intent.NONE}
                          helperText={errors?.fromAccount?.message}
                        >
                          <HTMLSelect
                            large={true}
                            fill={true}
                            id="cash-from"
                            {...customRegisterFormField(register, 'fromAccount', {
                              required: { value: true, message: 'No Suitable Accounts Found.' },
                              valueAsNumber: true,
                            })}
                          >
                            {selectedTransactionOption?.fromAccounts?.map((account) => (
                              <option key={account.id} value={account.id}>
                                {account.accountName}
                              </option>
                            ))}
                          </HTMLSelect>

                          {fromAccountDetails && fromAccountDetails.cashAccountInd && (
                            <Callout icon="dollar" intent="primary">
                              <div className="">
                                <p>
                                  {fromAccountDetails.primaryCashAccountInd && <> Primary </>} Account Selected:{' '}
                                  {fromAccountDetails.accountName} <br /> Account Cash Balance:{' '}
                                  <strong className="fw-bold">
                                    ${formatAmountRoundTwo({ value: Number(fromAccountDetails.netAssetValue) })}
                                  </strong>
                                </p>
                              </div>
                            </Callout>
                          )}
                        </FormGroup>
                      </Box>
                      <Box width={[1 / 2]} p={1}>
                        <FormGroup
                          label={'To Account'}
                          intent={errors?.toAccount ? Intent.DANGER : Intent.NONE}
                          helperText={errors?.toAccount?.message}
                        >
                          <HTMLSelect
                            large={true}
                            fill={true}
                            id="cash-to"
                            {...customRegisterFormField(register, 'toAccount', {
                              required: { value: true, message: 'No Suitable Accounts Found.' },
                              valueAsNumber: true,
                            })}
                          >
                            {selectedTransactionOption?.toAccounts?.map((account) => (
                              <option key={account.id} value={account.id}>
                                {account.accountName}
                              </option>
                            ))}
                          </HTMLSelect>

                          {toAccountDetails && toAccountDetails.cashAccountInd && (
                            <Callout icon="dollar" intent="primary">
                              <div className="">
                                <p>
                                  {toAccountDetails.primaryCashAccountInd && <> Primary </>} Account Selected:{' '}
                                  {toAccountDetails.accountName} <br /> Account Cash Balance:{' '}
                                  <strong>
                                    ${formatAmountRoundTwo({ value: Number(toAccountDetails.netAssetValue) })}
                                  </strong>{' '}
                                  as on <strong>{dayjs(toAccountDetails.timeDimKey).format('MM/DD/YYYY')}</strong>
                                </p>
                              </div>
                            </Callout>
                          )}
                        </FormGroup>
                      </Box>
                    </Flex>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-floating">
                          <FormGroup
                            helperText={errors?.amount?.message}
                            intent={errors?.amount ? 'danger' : 'none'}
                            label="Amount"
                            labelFor="text-input"
                          >
                            <CurrencyInput
                              min={0}
                              defaultValue={0}
                              className={'bp3-input bp3-large bp3-fill'}
                              onValueChange={(value) => setValue('amount', value)}
                              value={watch('amount') || ''}
                              {...customRegisterFormField(register, 'amount', {
                                required: { value: true, message: 'Please provide a valid Amount.' },
                                // valueAsNumber: true,
                              })}
                            />
                            {/*
                            <InputGroup
                              large={true}
                              intent={errors?.amount ? 'danger' : 'none'}
                              {...customRegisterFormField(register, 'amount', {
                                valueAsNumber: true,
                                required: { value: true, message: 'Please provide a valid Amount.' },
                              })}
                              type="number"
                              id="text-input"
                              placehol
                              der="Enter the value for Amount"
                            />
                            */}
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <FormGroup
                        helperText={errors?.memo?.message}
                        intent={errors?.amount ? 'danger' : 'none'}
                        label="Memo"
                        labelFor="text-input"
                      >
                        <input
                          id="memoText"
                          placeholder="Write something here ..."
                          {...register('memo', {
                            maxLength: {
                              value: 80,
                              message: 'Memo should be maximun of 80 characters in length.',
                            },
                          })}
                          className="bp3-input bp3-fill bp3-large"
                        ></input>
                      </FormGroup>
                    </div>

                    <div>
                      <Button intent={Intent.PRIMARY} type="submit" loading={isPostCreateCashTransactionEntryLoading}>
                        {isPostCreateCashTransactionEntryLoading && (
                          <div className="spinner-border text-light spinner-border-sm me-2"></div>
                        )}
                        Record Transaction
                      </Button>
                      <Button
                        minimal={true}
                        disabled={isPostCreateCashTransactionEntryLoading}
                        onClick={() => history.push('/cash-management')}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                )}
              </Box>
            }
          ></CustomCard>
        </Box>
        <Box width={[1, 1 / 2]} mx={2}>
          {summaryOfSelectedFund && !isFundSummaryHistoryLoading && (
            <PerformanceChart
              fundSummaryHistory={fundSummaryHistoryData?.fundSummaryHistory}
              fundSummary={summaryOfSelectedFund}
            ></PerformanceChart>
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default CashManagementEntryCreationPage;

import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import SelectWrapper from '@components/SelectWrapper/SelectWrapper';
import IUser from '@shared/interfaces/IUser';
import { FC, ReactElement, useCallback, useMemo } from 'react';
import { Flex } from 'reflexbox';

interface IUserSelectionDropdownProps {
  allUsers: IUser[];
  selectedUserId?: number;
  switchUser: (userId: number) => void;
  disabled: boolean;
}
const UserSelectionDropdown: FC<IUserSelectionDropdownProps> = ({
  allUsers,
  selectedUserId,
  switchUser,
  disabled,
}): ReactElement => {
  const selectedUser = useMemo(() => {
    return allUsers.find((user) => user.id === selectedUserId);
  }, [selectedUserId, allUsers]);

  const handleUserChange = useCallback(
    (userId) => {
      console.log(userId);
      if (selectedUserId !== userId) {
        switchUser(userId);
      }
    },
    [selectedUserId],
  );

  const UserSelect = Select.ofType<IUser | undefined>();

  return (
    <>
      {/*
      <CustomDropdown
        label={'funds'}
        menuItems={allUsers.map((user) => {
          return (
            <MenuItem key={user.id} onClick={() => handleUserChange(user.id)}>
              {user?.firstName + ' ' + user?.lastName}
            </MenuItem>
          );
        })}
        disabled={false}
        text={!selectedUser ? 'All  Users' : selectedUser?.firstName + ' ' + selectedUser?.lastName}
      ></CustomDropdown>
      */}
      <Flex>
        <SelectWrapper label="Users">
          <UserSelect
            popoverProps={{ minimal: true }}
            filterable={false}
            items={[undefined, ...allUsers]}
            itemRenderer={(user: IUser | undefined, { handleClick }) => (
              <MenuItem onClick={handleClick} text={!user ? 'All Users' : user?.firstName + ' ' + user?.lastName} />
            )}
            onItemSelect={(user: IUser | undefined) => handleUserChange(user?.id)}
          >
            <Button
              minimal={true}
              outlined={true}
              disabled={disabled}
              text={!selectedUser ? 'All  Users' : selectedUser?.firstName + ' ' + selectedUser?.lastName}
              rightIcon="chevron-down"
            />
          </UserSelect>

          {/*}
        <div className="dropdown">
          <button
            className="btn dropdown-toggle text-dark"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={disabled}
          >
            {!selectedUser ? 'All  Users' : selectedUser?.firstName + ' ' + selectedUser?.lastName}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#" onClick={() => handleUserChange(undefined)}>
                All Users
              </a>
            </li>
            {allUsers.map((user) => {
              return (
                <li key={user.id}>
                  <a className="dropdown-item" href="#" onClick={() => handleUserChange(user.id)}>
                    {user?.firstName + ' ' + user?.lastName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
          */}
        </SelectWrapper>
      </Flex>
    </>
  );
};

export default UserSelectionDropdown;

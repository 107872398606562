import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import { IGetFundSummaryHistoryResponse } from '@shared/exchange/getFundSummaryHistory';

export default function useFundSummaryHistory(
  selectedFundId: number | undefined,
): UseQueryResult<IGetFundSummaryHistoryResponse, Error> {
  const axios = useAxios();
  return useQuery<IGetFundSummaryHistoryResponse, Error>(
    ['fundsSummary', { selectedFundId }],
    async () => {
      if (!selectedFundId) return null;
      return axios?.get(`get-fund-summary-history?fundId=${selectedFundId}`).then((res) => res.data.data);
    },
    { staleTime: 60 * 1000 },
  );
}

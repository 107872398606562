import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetUsersResponse } from '@shared/exchange/getUsers';

export const ADMIN_GET_USERS_KEY = 'admin-users';
export default function useUsers(): UseQueryResult<IGetUsersResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetUsersResponse, IErrorResponse>(
    [ADMIN_GET_USERS_KEY],
    async () =>
      axios
        ?.get('get-users')
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        }),
    { staleTime: 60 * 1000, retry: false, refetchOnWindowFocus: false },
  );
}

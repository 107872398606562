import create, { SetState } from 'zustand';

interface IGlobalStore {
  isGlobalProgress: boolean;
  globalProgressMessage: string;
  isGlobalProgressCancelable: boolean;
  isProgressEnd: boolean;
  startGlobalProgress: (msg: string, isGlobalProgressCancelable?: boolean, isProgressEnd?: boolean) => void;
  stopGlobalProgress: () => void;
}
const useGlobalStore = create<IGlobalStore>((set: SetState<IGlobalStore>) => ({
  isGlobalProgress: false,
  globalProgressMessage: '',
  isGlobalProgressCancelable: false,
  isProgressEnd: false,
  startGlobalProgress: (msg: string, isGlobalProgressCancelable = false, isProgressEnd = false) =>
    set({ isGlobalProgress: true, globalProgressMessage: msg, isGlobalProgressCancelable, isProgressEnd }),
  stopGlobalProgress: () => set({ isGlobalProgress: false }),
}));

export default useGlobalStore;

import { useAxios } from '@common/providers/AxiosProvider';
import {
  ICreateDocMgmtFactEntryRequest,
  ICreateDocMgmtFactEntryResponse,
} from '@shared/exchange/createDocMgmtFactEntry';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useCreateDocMgmtFactEntry(): UseMutationResult<
  ICreateDocMgmtFactEntryResponse,
  IErrorResponse,
  ICreateDocMgmtFactEntryRequest,
  ICreateDocMgmtFactEntryResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateDocMgmtFactEntryResponse,
    IErrorResponse,
    ICreateDocMgmtFactEntryRequest,
    ICreateDocMgmtFactEntryResponse
  >(async (data: ICreateDocMgmtFactEntryRequest) =>
    axios
      ?.post('create-doc-mgmt-fact-entry', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import useSendResetPasswordLink from '@common/hooks/useSendResetPasswordLink';
import getInitialsFromName from '@common/utils/getInitialsFromName';
import IUser from '@shared/interfaces/IUser';
import useGlobalStore from '@stores/useGlobalStore';
import { ReactElement, FC, memo } from 'react';
import { Box } from 'reflexbox';

interface HeaderUserSettingsProps {
  user?: IUser;
}

const HeaderUserSettings: FC<HeaderUserSettingsProps> = ({ user }): ReactElement => {
  const name = user?.firstName + ' ' + user?.lastName;
  const { mutateAsync: sendResetPasswordLink } = useSendResetPasswordLink();
  const startGlobalProgress = useGlobalStore((state) => state.startGlobalProgress);

  const handleChangePassword = async () => {
    if (!user) return;
    const reserPasswordData = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
      email: user.userEmailId,
      connection: process.env.REACT_APP_AUTH0_CONNECTION || '',
    };
    startGlobalProgress('Processing your request...');
    await sendResetPasswordLink(reserPasswordData);
    startGlobalProgress('An email with the instructions to reset your password is sent to you.', true, true);
  };
  return (
    <>
      {user && (
        <>
          <Popover2
            position={'bottom'}
            usePortal={true}
            content={
              <Box>
                <ul className="pro-menu px-0" aria-labelledby="dropdownMenuButton2">
                  <div className="view-pro px-2">
                    <div className="pro-icon"> {getInitialsFromName(name)}</div>
                    <div className="pro-details">
                      <h3>{name}</h3>
                      <span>{user?.userEmailId}</span>
                      {/*<button>View Profile</button>*/}
                    </div>
                  </div>
                  <hr />
                  <button className="btn btn-link" onClick={() => handleChangePassword()}>
                    Change Password
                  </button>
                  {/*<Link to="#">Settings</Link>*/}
                </ul>
              </Box>
            }
          >
            <Button icon="user" text={getInitialsFromName(name)} />
          </Popover2>
        </>
      )}
    </>
  );
};

export default memo(HeaderUserSettings, (prevProps, nextProps) => {
  if (prevProps.user && !nextProps.user) return true;
  return false;
});

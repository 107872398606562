import { Button, Collapse, Intent, NonIdealState, Position } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import ITrade from '@shared/interfaces/ITrade';
import useCashManagementStore from '@stores/useCashManagementStore';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//import Info from '@assets/img/info(i).svg';
import { Column, useExpanded, useFilters, useTable } from 'react-table';
import { Box } from 'reflexbox';
import TradeAudit from '../TradeAudit';
import TradeItemsHistorySubPane from './TradeItemsHistorySubPane';

interface ITradeHistoryTableProps {
  columns: Column<ITrade>[];
  data: ITrade[];
  selectedTradeStatusName: string | undefined;
}

const TradeHistoryTable = ({ columns, data, selectedTradeStatusName }: ITradeHistoryTableProps): ReactElement => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow, toggleRowExpanded, setFilter } =
    useTable<ITrade>(
      {
        columns,
        data,
      },
      useFilters,
      useExpanded,
    );

  const { setSuperSearchText, superSearch, setSupeSearchType } = useCashManagementStore(
    ({ setSuperSearchText, superSearch, setSupeSearchType }) => {
      return { setSuperSearchText, superSearch, setSupeSearchType };
    },
  );

  const history = useHistory();
  const handleViewCashTransactionsOfTrade = (tradeId: number) => {
    setSuperSearchText(tradeId.toString());
    setSupeSearchType('TRADE');
    superSearch(tradeId.toString(), undefined);
    history.push('/cash-management');
  };

  useEffect(() => {
    setFilter('tradeStatus', selectedTradeStatusName);
  }, [selectedTradeStatusName]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [selectedTradeRecord, setSelectedTradeRecord] = useState<ITrade | undefined>();

  /*
  const openTradeAudit = (tradeId: number) => {
    setSelectedTradeIdForAudit(tradeId);
  };*/

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          <tr></tr>
          <tr className={`theadTr${1}`}>
            {headers.map((column, i) => (
              <th {...column.getHeaderProps()} key={i} id={`${1}itheadTh${i}`}>
                {column.render('Header')}
              </th>
            ))}
            <th>Cash Entries</th>
            <th>Audit</th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} id={`tbl-row${row.id}`} key={i}>
                  {row.cells.map((cell, i) => {
                    i++;
                    return (
                      <>
                        <td
                          {...cell.getCellProps()}
                          id={`${row.id}tbodyTD${i - 1}`}
                          onClick={() => {
                            return;
                          }}
                          key={i}
                        >
                          {cell.render('Cell')}
                        </td>
                      </>
                    );
                  })}
                  {/* <td>
                    <button className="btn btn-link" onClick={() => setSelectedTradeIdForAudit(row.original.id)}>
                      <img src={Info} alt="edit" />
                    </button>
                  </td>
                 */}
                  <td>
                    <Popover2 content={<h1>Popover!</h1>} position={Position.BOTTOM}>
                      <Tooltip2
                        interactionKind="hover"
                        content="See related Cash Entries"
                        position={Position.BOTTOM}
                        openOnTargetFocus={true}
                      >
                        <Button
                          small
                          minimal
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            handleViewCashTransactionsOfTrade(row.original.id);
                          }}
                          className="btn btn-link"
                        >
                          <i className="bi bi-cash-coin"></i>
                        </Button>
                      </Tooltip2>
                    </Popover2>
                  </td>
                  <td>
                    <Tooltip2
                      interactionKind="hover"
                      content="See Trade Batch Audit"
                      position={Position.LEFT}
                      openOnTargetFocus={true}
                    >
                      <Button
                        small
                        minimal
                        intent={Intent.PRIMARY}
                        onClick={() => {
                          console.log(`You clicked Trade Batch Id: ${row.original.id} `);
                          setSelectedTradeRecord(row.original);
                        }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </Button>
                    </Tooltip2>
                  </td>
                </tr>

                <tr>
                  <td colSpan={100} style={{ padding: 0 }}>
                    {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                    <Collapse isOpen={row.isExpanded}>
                      <Box p={3}>
                        <TradeItemsHistorySubPane
                          tradeId={row.original.id}
                          toggleClose={toggleRowExpanded}
                          id={row.id}
                        ></TradeItemsHistorySubPane>
                      </Box>
                    </Collapse>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {/* {selectedTradeIdForAuditedTradeId && <TradeAudit tradeId={selectedTradeIdForAuditedTradeId}></TradeAudit>} */}
      {rows?.length == 0 && (
        <Box m={2} p={4}>
          <NonIdealState icon={'info-sign'} title={<h5 className="bp3-heading">No trade batches found.</h5>} />
        </Box>
      )}
      {selectedTradeRecord && (
        <TradeAudit
          tradeRecord={selectedTradeRecord}
          isOpen={!!selectedTradeRecord}
          close={() => setSelectedTradeRecord(undefined)}
        />
      )}
    </>
  );
};

export default TradeHistoryTable;

import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetSettlementDateOfTradeResponse } from '@shared/exchange/getSettlementDateOfTrade';
import { useMutation, UseMutationResult } from 'react-query';

interface IGetSettlementDateOfTradeRequest {
  accountId: number;
  tradeDate: string;
}

export default function ueGetSettlementDate(): UseMutationResult<
  IGetSettlementDateOfTradeResponse,
  IErrorResponse,
  IGetSettlementDateOfTradeRequest[],
  IGetSettlementDateOfTradeResponse
> {
  const axios = useAxios();
  return useMutation<
    IGetSettlementDateOfTradeResponse,
    IErrorResponse,
    IGetSettlementDateOfTradeRequest[],
    IGetSettlementDateOfTradeResponse
  >(async (data: IGetSettlementDateOfTradeRequest[]) => {
    const searchParams = new URLSearchParams();
    searchParams.append('data', JSON.stringify(data));
    return axios
      ?.get(`get-settlement-date?${searchParams.toString()}`)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      });
  });
}

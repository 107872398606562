interface IFormatAssetValueArg {
  value: number;
}
const formatAssetValue = ({ value }: IFormatAssetValueArg): string => {
  if (!value || Number.isNaN(value)) return '0';
  const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
  return nf.format(Math.trunc(value));
};

export default formatAssetValue;

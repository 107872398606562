import { Button, Intent } from '@blueprintjs/core';
import useExposure from '@common/hooks/useExposure';
import useGetWhatIfRebalSettings from '@common/hooks/useGetWhatIfRebalSettings';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import PageToolbar from '@components/PageToolbar';
import TableLoader from '@components/TableLoader';
import IExposure from '@shared/interfaces/IExposure';
import useWhatIfRebalStore from '@stores/useWhatIfRebalStore';
import { ReactElement, useEffect, useState } from 'react';
import { Flex, Box } from 'reflexbox';

import Rebalancing from './components/Rebalancing';
import Settings from './components/Settings';
import './WhatIfRebal.scss';

const WhatIfRebalPage = (): ReactElement => {
  const {
    selectedFundId,
    switchFund,
    setAllFunds,
    setSelectedFundId,
    allFunds,
    setAccountLocked,
    resetAllAccountLocked,
  } = useWhatIfRebalStore(
    ({
      selectedFundId,
      switchFund,
      setAllFunds,
      allFunds,
      setAccountLocked,
      resetAllAccountLocked,
      setSelectedFundId,
    }) => {
      return {
        selectedFundId,
        setAllFunds,
        switchFund,
        allFunds,
        setAccountLocked,
        resetAllAccountLocked,
        setSelectedFundId,
      };
    },
  );
  const [view, setView] = useState<'REBALANCING' | 'SETTINGS'>('REBALANCING');
  const { data, isLoading: isExpsoureLoading, isSuccess: isExposureSuccess } = useExposure(selectedFundId, undefined);

  const {
    data: whatIfRebalSettings,
    isLoading: isWhatIfRebalSettingLoading,
    isSuccess: isWhatIfRebalSuccess,
  } = useGetWhatIfRebalSettings(selectedFundId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accountsDetails, setAccountsDetails] = useState<Record<number, any>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allAssetClassDetails, setAllAssetClassDetails] = useState<Record<number, any>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    if (!data || !data.allFundsofCurrentOrg) return;
    setAllFunds(data?.allFundsofCurrentOrg);
    setSelectedFundId(data?.fundId);
  }, [data]);

  const recursivelyPopulateLockedAccountsMap = (exposureRecords: IExposure[]) => {
    exposureRecords.forEach((record) => {
      if (record.account_id) {
        if (record.invest_at_will_ind === 0) setAccountLocked(record.account_id, true);
        else setAccountLocked(record.account_id, false);
      }
      if (record.subRows) recursivelyPopulateLockedAccountsMap(record.subRows);
    });
  };

  const recursivelyPopulateAccountDetailsMap = (exposureRecords: IExposure[]) => {
    exposureRecords.forEach((record) => {
      if (record.account_id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let tempAccountDetails: Record<number, any> = {};
        if (accountsDetails[record.account_id]) tempAccountDetails = accountsDetails[record.account_id];
        if (!tempAccountDetails[record.account_id]) tempAccountDetails[record.account_id] = { assetClasses: [] };
        tempAccountDetails[record.account_id]['assetClasses']?.push({
          assetClassId: record.account_asset_class_id,
          assetClassName: record.account_asset_class_name,
          allocationPct: +(Number(record.allocation_pct) / 100),
        });
        tempAccountDetails[record.account_id]['accountId'] = record.account_id;
        tempAccountDetails[record.account_id]['accountName'] = record.name;
        tempAccountDetails[record.account_id]['currentAllocation'] = Number(record.asset_class_nav);

        tempAccountDetails[record.account_id]['currentAllocationPct'] = +Number(record.current_allocation_pct);

        tempAccountDetails[record.account_id]['targetAllocation'] = +Number(
          record.target_allocation ? record.target_allocation : 0,
        );
        if (
          record.target_allocation === null &&
          record.target_allocation_range_lower_bound === null &&
          record.target_allocation_range_upper_bound === null
        ) {
          tempAccountDetails[record.account_id]['targetAllocationPct'] = +Number(record.current_allocation_pct);
          tempAccountDetails[record.account_id]['targeAllocationtLowerBound'] = 0;
          tempAccountDetails[record.account_id]['targeAllocationtUpperBound'] = 100;
        } else {
          tempAccountDetails[record.account_id]['targetAllocationPct'] = +Number(record.target_allocation);
          tempAccountDetails[record.account_id]['targeAllocationtLowerBound'] = +Number(
            record.target_allocation_range_lower_bound,
          );
          tempAccountDetails[record.account_id]['targeAllocationtUpperBound'] = +Number(
            record.target_allocation_range_upper_bound,
          );
        }

        setAccountsDetails((prev) => {
          return { ...prev, ...tempAccountDetails };
        });
      } else {
        //Its not account so either parent asset class or sub asset class
        const assetClassParam = whatIfRebalSettings?.assetClassModelParams.find(
          (param) => param.fundAssetClass == record.fund_asset_class_target_id,
        );
        const allAssetClassDetails = {
          initial_allocation: record.asset_class_nav,
          initial_allocation_pct: record.current_allocation_pct,
          asset_class_id: record.fund_asset_class_id,
          fund_asset_class_target_id: record.fund_asset_class_target_id,
          asset_class_name: record.fund_asset_class_name,
          lb_ac: +Number(record.target_allocation_range_lower_bound),
          target_ac: +Number(record.target_allocation),
          ub_ac: +Number(record.target_allocation_range_upper_bound),
          implied_lb_ac: 0,
          implied_target_ac: 0,
          implied_ub_ac: 0,
          c_ac_d: Number(assetClassParam?.assetClassModelParams.c_ac_d),
        };
        setAllAssetClassDetails((prev) => {
          return { ...prev, [Number(record.fund_asset_class_id)]: allAssetClassDetails };
        });
      }
      if (record.subRows) recursivelyPopulateAccountDetailsMap(record.subRows);
    });
  };
  useEffect(() => {
    if (data) {
      resetAllAccountLocked();
      setAllAssetClassDetails({});
      setAccountsDetails({});
      recursivelyPopulateLockedAccountsMap(data.exposureData.allocations);
      recursivelyPopulateAccountDetailsMap(data.exposureData.allocations);
    }
  }, [data, whatIfRebalSettings]);
  return (
    <>
      <Flex flexDirection="column">
        <PageToolbar
          leftSegment={
            <>
              <Box mr={2}>
                {selectedFundId && allFunds && (
                  <FundSelectionDropdown
                    switchFund={switchFund}
                    selectedFundId={selectedFundId}
                    allFunds={allFunds}
                    disabled={false}
                  ></FundSelectionDropdown>
                )}
              </Box>
            </>
          }
          rightSegment={
            <>
              {view == 'REBALANCING' && (
                <Button intent={Intent.PRIMARY} icon="cog" onClick={() => setView('SETTINGS')}>
                  Settings
                </Button>
              )}
              {view == 'SETTINGS' && (
                <Button intent={Intent.PRIMARY} icon="arrow-left" onClick={() => setView('REBALANCING')}>
                  Back to Rebalancing
                </Button>
              )}
            </>
          }
        ></PageToolbar>
      </Flex>
      <Box pb={4} mb={3}>
        {view === 'REBALANCING' && (
          <>
            {(isExpsoureLoading || isWhatIfRebalSettingLoading) && <TableLoader></TableLoader>}
            {isExposureSuccess && isWhatIfRebalSuccess && data && selectedFundId && whatIfRebalSettings && (
              <Rebalancing
                accountsDetails={accountsDetails}
                allAssetClassDetails={allAssetClassDetails}
                data={data}
                whatIfRebalSettings={whatIfRebalSettings}
              ></Rebalancing>
            )}
          </>
        )}
        {view === 'SETTINGS' && (
          <>
            {(isExpsoureLoading || isWhatIfRebalSettingLoading) && <TableLoader></TableLoader>}
            {isExposureSuccess &&
              isWhatIfRebalSuccess &&
              data &&
              selectedFundId &&
              accountsDetails &&
              allAssetClassDetails && (
                <Settings
                  selectedFundId={selectedFundId}
                  accountsDetails={accountsDetails}
                  allAssetClassDetails={allAssetClassDetails}
                ></Settings>
              )}
          </>
        )}
      </Box>
    </>
  );
};

export default WhatIfRebalPage;

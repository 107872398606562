import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateExternalTransferAccountRequest } from '@shared/exchange/createExternalTransferAccount';
import ICreateExternalTransferAccountResponse from '@shared/exchange/createExternalTransferAccount/ICreateExternalTransferAccountResponse';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

export default function useCreateExternalTransferAccount(): UseMutationResult<
  ICreateExternalTransferAccountResponse,
  IErrorResponse,
  ICreateExternalTransferAccountRequest,
  ICreateExternalTransferAccountResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateExternalTransferAccountResponse,
    IErrorResponse,
    ICreateExternalTransferAccountRequest,
    ICreateExternalTransferAccountResponse
  >(async (data: ICreateExternalTransferAccountRequest) =>
    axios
      ?.post('create-external-transfer-account', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

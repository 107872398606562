import { Colors, Dialog, Icon, NonIdealState, Tag } from '@blueprintjs/core';
import useDocFacts from '@common/hooks/useDocFacts';
import useFunds from '@common/hooks/useFunds';

import useGetTradeItemsAudit from '@common/hooks/useGetTradeItemAudit';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import UserAvatar from '@components/UserAvatar';
import styled from '@emotion/styled';
import ITradeItem from '@shared/interfaces/ITradeItem';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { FC, ReactElement } from 'react-bootstrap/node_modules/@types/react';
import { Box, Flex } from 'reflexbox';

interface ITradeItemAuditProps {
  isOpen: boolean;
  close: () => void;
  tradeItemRecord: ITradeItem;
}

const TradeItemAudit: FC<ITradeItemAuditProps> = (props): ReactElement => {
  const {
    data: tradeItemAuditData,
    isLoading: isViewTradeOrderAuditLoading,
    isFetching: isViewTradeOrderAuditFetching,
  } = useGetTradeItemsAudit(props.tradeItemRecord.id);
  console.log(tradeItemAuditData);
  console.log(
    `Length of Trade Audit Data for trade batch id ${props.tradeItemRecord.id} is ${tradeItemAuditData?.tradeItemsAudit.length}`,
  );

  const { isOpen, close, tradeItemRecord } = props;

  const { id, cashTransaction, tradeAmt, buyInd, sellInd } = tradeItemRecord;

  const { selectedFundId, setSelectedFundId } = useTradeOrderManagementStore();
  const { data: fundData } = useFunds();

  const selectedFundData = fundData?.funds.filter((_) => _.id === selectedFundId);
  const fundName = selectedFundData && selectedFundData[0].fundName;

  const { data: docFactsData } = useDocFacts(tradeItemRecord.trade.id);

  const getDocFact = (tradeItemId: number) => {
    if (!docFactsData?.doc_facts) return null;
    return docFactsData?.doc_facts.find((aDocFact) => aDocFact.trade_item_id === tradeItemId);
  };

  // Get Doc Fact Data and LOD Related Data to validate before displaying LOD Generated Dt, Signed LOD Uploaded By and Signed LOD Updated Date
  const docFact = getDocFact(id);
  const lodRequiredInd = tradeItemRecord.accountDim.lodRequiredInd;
  const signedLOD = tradeItemRecord.docTemplate?.signature_required_ind;

  // Get Badge Color For Trade Item Status
  const getBadgeColor = (type: string) => {
    let color = '#6c757d';
    switch (type.toLowerCase()) {
      case 'pending approval':
        color = '#f5b540';
        break;
      case 'approved':
        color = '#03d618';
        break;
      case 'rejected':
        color = '#f23737';
        break;
      case 'pending trade':
        color = '#ff6b00';
        break;
      case 'traded':
        color = '#4b85cd';
        break;
      default:
        color = '#6c757d';
    }
    return color;
  };

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (!tradeItemAuditData || !tradeItemAuditData?.tradeItemsAudit || tradeItemAuditData?.tradeItemsAudit.length === 0)
      return;
    if (!docFactsData || !docFactsData?.doc_facts || docFactsData?.doc_facts.length === 0) return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
  }, [fundData, tradeItemAuditData, docFactsData]);

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Dialog
        canOutsideClickClose={false}
        title={`Trade Audit`}
        icon="history"
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
      >
        <Box>
          {isViewTradeOrderAuditFetching && <GlobalLoader></GlobalLoader>}
          {isViewTradeOrderAuditLoading && <TableLoader></TableLoader>}

          <div>
            {!!tradeItemAuditData && (
              <Styles>
                <Box style={{ background: Colors.WHITE }}>
                  <table width="100%" className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered">
                    <tbody>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Fund</Box>
                        </td>
                        <td>
                          <Box pl={2}>{fundName}</Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Trade ID</Box>
                        </td>
                        <td>
                          <Box p={2}>{id}</Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Account</Box>
                        </td>
                        <td>
                          <Box p={2}>
                            {buyInd ? cashTransaction.toAccount.accountName : cashTransaction.fromAccount.accountName}
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Amount</Box>
                        </td>
                        <td>
                          <Box p={2}>{formatAmountRoundTwo({ value: Number(tradeAmt) })}</Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Buy/Sell</Box>
                        </td>
                        <td>
                          <Box p={2}>
                            {buyInd && <span>Buy</span>}
                            {sellInd && <span>Sell</span>}
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="keyName">
                          <Box p={2}>Cash Account</Box>
                        </td>
                        <td>
                          <Box p={2}>
                            {buyInd ? cashTransaction.fromAccount.accountName : cashTransaction.toAccount.accountName}
                          </Box>
                        </td>
                      </tr>

                      {lodRequiredInd && docFact && tradeItemRecord.lodGeneratedDt && (
                        <>
                          <tr>
                            <td className="keyName">
                              <Box p={2}>LOD Generated Date</Box>
                            </td>
                            <td>
                              <Box p={2}>{dayjs(tradeItemRecord.lodGeneratedDt).format('MM/DD/YYYY hh:mm:ss A')}</Box>
                            </td>
                          </tr>

                          {signedLOD && docFact && docFact.s3_lod_signed_file_url && (
                            <>
                              {tradeItemRecord.signedLodUploadedBy && (
                                <tr>
                                  <td className="keyName">
                                    <Box p={2}>Signed LOD Uploaded By</Box>
                                  </td>
                                  <td>
                                    <Flex alignItems={'center'}>
                                      <Box>
                                        {tradeItemRecord.signedLodUploadedBy && (
                                          <UserAvatar user={tradeItemRecord.signedLodUploadedBy}></UserAvatar>
                                        )}
                                      </Box>
                                      <Box ml={2}>
                                        {tradeItemRecord.signedLodUploadedBy && (
                                          <div>
                                            {tradeItemRecord.signedLodUploadedBy.firstName +
                                              ' ' +
                                              tradeItemRecord.signedLodUploadedBy.lastName}
                                          </div>
                                        )}
                                      </Box>
                                    </Flex>
                                  </td>
                                </tr>
                              )}
                              {tradeItemRecord.signedLodUploadedDt && (
                                <tr>
                                  <td className="keyName">
                                    <Box p={2}>Signed LOD Uploaded Date</Box>
                                  </td>
                                  <td>
                                    <Box p={2}>
                                      {tradeItemRecord.signedLodUploadedDt && (
                                        <span>
                                          {dayjs(tradeItemRecord.signedLodUploadedDt).format('MM/DD/YYYY hh:mm:ss A')}
                                        </span>
                                      )}
                                    </Box>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </Box>
                <div className="timeline">
                  {tradeItemAuditData?.tradeItemsAudit?.map((anAudit, i) => (
                    <div key={anAudit.id} className={`container right ${i == 0 ? 'active' : ''}`}>
                      <div className="content">
                        <table className="table" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>Status</td>
                              <td>
                                <Tag
                                  minimal={true}
                                  round={true}
                                  icon={
                                    <Icon
                                      icon="full-circle"
                                      color={getBadgeColor(anAudit?.tradeItemStatus?.tradeItemStatusName)}
                                      size={8}
                                    ></Icon>
                                  }
                                  style={{
                                    borderWidth: 2,
                                    borderStyle: 'solid',
                                    borderColor: getBadgeColor(anAudit?.tradeItemStatus?.tradeItemStatusName),
                                    background: 'transparent',
                                    fontSize: 14,
                                  }}
                                >
                                  <span>{anAudit.tradeItemStatus.tradeItemStatusName}</span>
                                </Tag>
                              </td>
                            </tr>
                            <tr>
                              <td>Updated By</td>
                              <td>
                                <Flex alignItems={'center'}>
                                  <Box>
                                    <UserAvatar user={anAudit.actionUserId}></UserAvatar>
                                  </Box>
                                  <Box ml={2}>
                                    <div>{anAudit.actionUserId.firstName + ' ' + anAudit.actionUserId.lastName}</div>
                                  </Box>
                                </Flex>
                              </td>
                            </tr>
                            <tr>
                              <td> Updated Date</td>
                              <td>
                                <span>{dayjs(anAudit.createdDt).format('MM/DD/YYYY hh:mm:ss A')}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </Styles>
            )}
          </div>
          {(!tradeItemAuditData || tradeItemAuditData?.tradeItemsAudit.length === 0) &&
            tradeItemAuditData?.tradeItemsAudit.length === 0 && (
              <NonIdealState
                icon={'issue'}
                title={<h5 className="bp3-heading">No Audit present for the given trade order</h5>}
              />
            )}
        </Box>
      </Dialog>
    </>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY5} !important;
  }
  .table tbody tr td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

export default TradeItemAudit;

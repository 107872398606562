import { Button, Callout, Radio, RadioGroup } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import useUserPreferenceStore from '@stores/useUserPreferenceStore';
import { ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';

const AppSettings = (): ReactElement => {
  const { zoom, setZoom } = useUserPreferenceStore(({ zoom, setZoom }) => {
    return { zoom, setZoom };
  });
  return (
    <>
      <Popover2
        placement={'bottom'}
        usePortal={true}
        content={
          <Flex p={3}>
            <Box>
              <Callout>
                <Box paddingX={1}>
                  <RadioGroup
                    inline={true}
                    label="Zoom Level"
                    onChange={(e) => setZoom(Number(e.currentTarget.value))}
                    selectedValue={zoom}
                  >
                    <Radio label="1" value={1} />
                    <Radio label="2" value={2} />
                    <Radio label="3" value={3} />
                  </RadioGroup>
                </Box>
              </Callout>
            </Box>
          </Flex>
        }
      >
        <Button icon="wrench" />
      </Popover2>
    </>
  );
};

export default AppSettings;

import { Colors, Dialog, Icon, NonIdealState, Tag } from '@blueprintjs/core';
import useFunds from '@common/hooks/useFunds';

import useGetTradesAudit from '@common/hooks/useGetTradeAudit';
import formatAmountRoundTwo from '@common/utils/formatAmountRoundTwo';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import UserAvatar from '@components/UserAvatar';
import styled from '@emotion/styled';
import ITrade from '@shared/interfaces/ITrade';
import useTradeOrderManagementStore from '@stores/useTradeOrderManagementStore';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { FC, ReactElement } from 'react-bootstrap/node_modules/@types/react';
import { Box, Flex } from 'reflexbox';
import '../../../assets/scss/timeline.scss';

interface ITradeAuditProps {
  tradeRecord: ITrade;
  isOpen: boolean;
  close: () => void;
}
const TradeAudit: FC<ITradeAuditProps> = (props): ReactElement => {
  const {
    data: tradeAuditData,
    isLoading: isViewTradeAuditLoading,
    isFetching: isViewTradeAuditFetching,
  } = useGetTradesAudit(props.tradeRecord.id);
  console.log(tradeAuditData);
  console.log(
    `Length of Trade Batch Audit Data for trade batch id ${props.tradeRecord.id} is ${tradeAuditData?.tradesAudit.length}`,
  );

  const { isOpen, close, tradeRecord } = props;

  const { id, buyOrSell, totalBuyAmt, totalSellAmt } = tradeRecord;

  const { selectedFundId, setSelectedFundId } = useTradeOrderManagementStore();
  const { data: fundData } = useFunds();

  const selectedFundData = fundData?.funds.filter((_) => _.id === selectedFundId);
  const fundName = selectedFundData && selectedFundData[0].fundName;

  // Get Badge Color For Trade Status
  const getBadgeColor = (type: string) => {
    let color = '#6c757d';
    switch (type.toLowerCase()) {
      case 'pending approval':
        color = '#f5b540';
        break;
      case 'approved':
        color = '#03d618';
        break;
      case 'rejected':
        color = '#f23737';
        break;
      case 'pending trade':
        color = '#ff6b00';
        break;
      case 'traded':
        color = '#4b85cd';
        break;
      default:
        color = '#6c757d';
    }
    return color;
  };

  useEffect(() => {
    if (!fundData || !fundData?.funds || fundData?.funds.length === 0) return;
    if (!tradeAuditData || !tradeAuditData?.tradesAudit || tradeAuditData?.tradesAudit.length === 0) return;
    if (!selectedFundId) setSelectedFundId(fundData?.funds[0].id);
  }, [fundData, tradeAuditData]);

  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      canOutsideClickClose={false}
      title={`Trade Batch Audit`}
      icon="history"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <Box>
        {isViewTradeAuditFetching && <GlobalLoader></GlobalLoader>}
        {isViewTradeAuditLoading && <TableLoader></TableLoader>}

        <div>
          {!!tradeAuditData && (
            <Styles>
              <Box style={{ background: Colors.WHITE }}>
                <table width="100%" className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered">
                  <tbody>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Fund</Box>
                      </td>
                      <td>
                        <Box pl={2}>{fundName}</Box>
                      </td>
                    </tr>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Trade Batch ID</Box>
                      </td>
                      <td>
                        <Box p={2}>{id}</Box>
                      </td>
                    </tr>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Buy/Sell</Box>
                      </td>
                      <td>
                        <Box p={2}>{buyOrSell}</Box>
                      </td>
                    </tr>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Total Buy</Box>
                      </td>
                      <td>
                        <Box p={2}>{formatAmountRoundTwo({ value: Number(totalBuyAmt) })}</Box>
                      </td>
                    </tr>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Total Sell</Box>
                      </td>
                      <td>
                        <Box p={2}>{formatAmountRoundTwo({ value: Number(totalSellAmt) })}</Box>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
              <div className="timeline">
                {tradeAuditData?.tradesAudit?.map((anAudit, i) => (
                  <div key={anAudit.id} className={`container right ${i == 0 ? 'active' : ''}`}>
                    <div className="content">
                      <table className="table" style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td>Status</td>
                            <td>
                              <Tag
                                minimal={true}
                                round={true}
                                icon={
                                  <Icon
                                    icon="full-circle"
                                    color={getBadgeColor(anAudit?.tradeStatus?.tradeStatusName)}
                                    size={8}
                                  ></Icon>
                                }
                                style={{
                                  borderWidth: 2,
                                  borderStyle: 'solid',
                                  borderColor: getBadgeColor(anAudit?.tradeStatus?.tradeStatusName),
                                  background: 'transparent',
                                  fontSize: 14,
                                }}
                              >
                                <span>{anAudit.tradeStatus.tradeStatusName}</span>
                              </Tag>
                            </td>
                          </tr>
                          <tr>
                            <td>Updated By</td>
                            <td>
                              <Flex alignItems={'center'}>
                                <Box>
                                  <UserAvatar user={anAudit.actionUserId}></UserAvatar>
                                </Box>
                                <Box ml={2}>
                                  <div>{anAudit.actionUserId.firstName + ' ' + anAudit.actionUserId.lastName}</div>
                                </Box>
                              </Flex>
                            </td>
                          </tr>
                          <tr>
                            <td> Updated Date</td>
                            <td>
                              <span>{dayjs(anAudit.createdDt).format('MM/DD/YYYY hh:mm:ss A')}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            </Styles>
          )}
        </div>
        {(!tradeAuditData || tradeAuditData?.tradesAudit.length === 0) && tradeAuditData?.tradesAudit.length === 0 && (
          <NonIdealState icon={'issue'} title={<h5 className="bp3-heading">No Audit present for the given trade</h5>} />
        )}
      </Box>
    </Dialog>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY5} !important;
  }
  .table tbody tr td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

export default TradeAudit;

import {
  Icon,
  Navbar,
  InputGroup,
  Button,
  Intent,
  Colors,
  Dialog,
  Callout,
  FormGroup,
  ProgressBar,
  Tag,
  Alert,
  NonIdealState,
} from '@blueprintjs/core';
import useCreateDocMgmtFactEntry from '@common/hooks/useCreateDocMgmtFactEntry';
import useCreateDocMgmtFolderEntry from '@common/hooks/useCreateDocMgmtFolderEntry';
import useGetDocMgmtDirectories from '@common/hooks/useGetDocMgmDirectories';
import useGetDocMgmtFileDownloadUrl from '@common/hooks/useGetDocMgmtFileDownloadUrl';
import useGetDocMgmtFileUploadUrl from '@common/hooks/useGetDocMgmtFileUploadUrl';
import usePublishDocMgmtFactEntry from '@common/hooks/usePublishDocMgmtFactEntry';
import usePublishDocMgmtFolderEntry from '@common/hooks/usePublishDocMgmtFolderEntry';
import useUpload from '@common/hooks/useUpload';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import { AppToaster } from '@components/Toasters';
import { yupResolver } from '@hookform/resolvers/yup';
import { IGetDocMgmtFileUploadUrlResponse } from '@shared/exchange/getDocMgmtFileUploadUrl';
import IDocMgmtFact from '@shared/interfaces/IDocMgmtFact';
import IDocMgmtFolder from '@shared/interfaces/IDocMgmtFolder';
import useDocumentManagementStore from '@stores/useDocumentManagementStore';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Box, Flex } from 'reflexbox';
import * as yup from 'yup';

const formSchema = yup.object().shape({
  file: yup
    .mixed()
    .required('File is required.')
    .test('type', 'File is required', (value: FileList) => {
      return value.length > 0;
    })
    .test('fileSize', 'The file size should not be more than 5 MB', (value: FileList) => {
      return value.length > 0 && value[0].size <= 5000000;
    }),
});

function DocumentsMainPanel({
  type,
  hasWriteAccess,
}: {
  type: 'INTERNAL' | 'EXTERNAL';
  hasWriteAccess: boolean;
}): ReactElement {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  let levelCode = 'F';
  if (pathParts[1] === 'funds') levelCode = 'F';
  else if (pathParts[1] === 'org') levelCode = 'O';
  else if (pathParts[1] === 'user') levelCode = 'U';

  const { selectedFundId, selectedFolder, setSelectedFolder } = useDocumentManagementStore(
    ({ selectedFundId, selectedFolder, setSelectedFolder }) => {
      return { selectedFundId, selectedFolder, setSelectedFolder };
    },
  );
  const [folderPath, setFolderPath] = useState('');
  const {
    data: directoryData,
    isLoading: isDirectoryDataLoading,
    isFetching: isDirectoryDataFetching,
    refetch: refetchDirectoryData,
    //error,
  } = useGetDocMgmtDirectories(type, levelCode, selectedFolder?.id, selectedFundId, true);

  useEffect(() => {
    if (!directoryData) return;
    if (!selectedFolder) {
      setFolderPath('/');
      return;
    }
    const folderPath = selectedFolder.folderUrl.replace(directoryData?.basePath || '', '');
    setFolderPath(folderPath);
  }, [directoryData, selectedFolder, type]);

  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string | undefined>();
  const [publishOrUnpublishAction, setPublishOrUnPublishAction] =
    useState<'Publish' | 'Unpublish' | undefined>(undefined);
  const [folderToPublishOrUnpublish, setFolderToPublishOrUnpublish] = useState<IDocMgmtFolder>();
  const [fileToPublishOrUnpublish, setFileToPublishOrUnpublish] = useState<IDocMgmtFact>();

  const {
    mutateAsync: createFolder,
    isLoading: isCreateFolderLoading,
    error: errorCreateFolder,
  } = useCreateDocMgmtFolderEntry();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setUploadFormFieldValue,
  } = useForm({ resolver: yupResolver(formSchema) });

  const {
    mutateAsync: getSignedUploadUrl,
    isLoading: isGetUploadUrlLoading,
    error: getSignedUploadUrlError,
  } = useGetDocMgmtFileUploadUrl();

  const { mutateAsync: upload, isLoading: isUploadLoading, error: errorUpload } = useUpload();
  const {
    mutateAsync: createFile,
    isLoading: isCreateFileLoading,
    error: errorCreateFile,
  } = useCreateDocMgmtFactEntry();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (data: { file: FileList }) => {
    if (!selectedFolder) return;
    try {
      const response: IGetDocMgmtFileUploadUrlResponse = await getSignedUploadUrl({
        fileName: data.file[0].name,
        folderId: selectedFolder?.id,
        externalInd: true,
        contentType: data.file[0].type,
      });
      const postData = new FormData();
      postData.append('file', data.file[0]);
      await upload({
        uploadUrl: response.url,
        doc: postData,
        method: 'PUT',
      });

      await createFile({
        docMgmtDocTypeId: 1,
        docMgmtFolderId: selectedFolder.id,
        fundId: selectedFundId,
        internalInd: type === 'INTERNAL',
        externalInd: type === 'EXTERNAL',
        fileName: `${data.file[0].name}`,
        fileS3FullUrl: `${folderPath}/${data.file[0].name}`.replace('/', ''),
        fileDesc: '',
      });
      setUploadModalOpen(false);
      refetchDirectoryData();
      setUploadFormFieldValue('file', undefined);
    } catch (error) {
      console.log(error);
    }
  };

  const { mutateAsync: getDownloadURL } = useGetDocMgmtFileDownloadUrl();
  const handleDownload = async (file: IDocMgmtFact) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });

    try {
      const res = await getDownloadURL({ docFileId: file.id });
      fetch(res.url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, file.fileName);
          AppToaster.clear();
        })
        .catch((e) => {
          console.log(e);
          AppToaster.clear();
        });
    } catch (e) {
      AppToaster.clear();
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Something went wrong. Please try again.',
        icon: 'warning-sign',
      });
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) return;
    try {
      await createFolder({
        levelCd: 'F',
        internalInd: type === 'INTERNAL',
        externalInd: type === 'EXTERNAL',
        folderName: newFolderName,
        folderUrl: `${folderPath}`.replace('/', ''),
        fundId: selectedFundId,
      });
      setCreateFolderModalOpen(false);
      setNewFolderName(undefined);
      refetchDirectoryData();
    } catch (e) {}
  };

  const {
    mutateAsync: updatePublishFolder,
    isLoading: isLoadingUpdatePublishFolder,
    error: errorUpdatePublishFolder,
  } = usePublishDocMgmtFolderEntry();

  const {
    mutateAsync: updatePublishFile,
    isLoading: isLoadingUpdatePublishFile,
    error: errorUpdatePublishFile,
  } = usePublishDocMgmtFactEntry();

  const handleUpdatePublishFolder = (folder: IDocMgmtFolder, publishOrUnpublish: 'Publish' | 'Unpublish') => {
    setPublishOrUnPublishAction(publishOrUnpublish);
    setFolderToPublishOrUnpublish(folder);
  };

  const handleUpdatePublishFile = (file: IDocMgmtFact, publishOrUnpublish: 'Publish' | 'Unpublish') => {
    setPublishOrUnPublishAction(publishOrUnpublish);
    setFileToPublishOrUnpublish(file);
  };

  const confirmUpdatePublishFodler = async () => {
    if (!folderToPublishOrUnpublish) return;
    try {
      await updatePublishFolder({
        isPublish: publishOrUnpublishAction === 'Publish' ? true : false,
        id: folderToPublishOrUnpublish.id,
      });
      refetchDirectoryData();
      setPublishOrUnPublishAction(undefined);
      setFolderToPublishOrUnpublish(undefined);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmUpdatePublishFile = async () => {
    if (!fileToPublishOrUnpublish) return;
    try {
      await updatePublishFile({
        isPublish: publishOrUnpublishAction === 'Publish' ? true : false,
        ids: [fileToPublishOrUnpublish.id],
      });
      refetchDirectoryData();
      setPublishOrUnPublishAction(undefined);
      setFileToPublishOrUnpublish(undefined);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!errorUpload) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: errorUpload?.errorMessage,
      icon: 'warning-sign',
    });
  }, [errorUpload]);

  useEffect(() => {
    if (!errorUpdatePublishFolder) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: errorUpdatePublishFolder?.errorMessage,
      icon: 'warning-sign',
    });
  }, [errorUpdatePublishFolder]);

  useEffect(() => {
    if (!errorUpdatePublishFile) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: errorUpdatePublishFile?.errorMessage,
      icon: 'warning-sign',
    });
  }, [errorUpdatePublishFile]);

  useEffect(() => {
    if (!errorCreateFolder) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: errorCreateFolder?.errorMessage,
      icon: 'warning-sign',
    });
  }, [errorCreateFolder]);

  useEffect(() => {
    if (!getSignedUploadUrlError) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: getSignedUploadUrlError?.errorMessage,
      icon: 'warning-sign',
    });
  }, [getSignedUploadUrlError]);

  useEffect(() => {
    if (!errorCreateFile) return;
    AppToaster.show({
      intent: Intent.DANGER,
      message: errorCreateFile?.errorMessage,
      icon: 'warning-sign',
    });
  }, [errorCreateFile]);

  //const { data: docTypeData } = useGetFileCreationMetadata();
  const isSubmitting = isUploadLoading || isGetUploadUrlLoading || isCreateFileLoading;
  return (
    <>
      <div>
        <Navbar>
          <Flex
            flexDirection="row-reverse"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            height="100%"
          >
            {hasWriteAccess && (
              <>
                <Box>
                  <Button
                    fill
                    disabled={isDirectoryDataLoading || !selectedFolder}
                    icon={'upload'}
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      setUploadFormFieldValue('file', undefined);
                      setUploadModalOpen(true);
                    }}
                  >
                    Upload File
                  </Button>
                </Box>
                <Box mx={1}>
                  <Button
                    fill
                    disabled={isDirectoryDataLoading}
                    icon={'folder-new'}
                    intent={Intent.PRIMARY}
                    onClick={() => setCreateFolderModalOpen(true)}
                  >
                    Create Folder
                  </Button>
                </Box>
              </>
            )}
            <Box flex={1}>
              <InputGroup
                large
                disabled={isDirectoryDataLoading}
                fill
                value={folderPath ? folderPath : '/'}
                readOnly
                style={{ paddingTop: 3 }}
                leftIcon="home"
              ></InputGroup>
            </Box>
          </Flex>
        </Navbar>

        {isDirectoryDataFetching && <GlobalLoader></GlobalLoader>}
        {isDirectoryDataLoading && <TableLoader></TableLoader>}
        {directoryData && (
          <>
            <table className="bp3-html-table bp3-html-table-bordered" width="100%">
              <thead>
                <tr style={{ backgroundColor: Colors.WHITE }}>
                  <th>Name</th>
                  <th>Created Date</th>

                  {hasWriteAccess && (
                    <>
                      <th>Status</th>
                      <th></th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {directoryData?.directories.map((directory) => (
                  <tr key={directory.id}>
                    <td>
                      <Button onClick={() => setSelectedFolder(directory)} intent={Intent.PRIMARY} minimal>
                        <Icon icon={'folder-close'} size={22}></Icon>
                        <Box pl={2} display="inline">
                          {directory.folderName}
                        </Box>
                      </Button>
                    </td>
                    <td>{dayjs(directory.createdDt).format('MM/DD/YYYY hh:mm A')}</td>

                    {hasWriteAccess && (
                      <>
                        <td>
                          {directory.visibleInd && (
                            <Tag
                              round
                              intent={Intent.SUCCESS}
                              icon={'eye-on'}
                              style={{
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: Colors.GREEN3,
                                color: Colors.GREEN3,
                                background: 'transparent',
                                fontSize: 14,
                              }}
                            >
                              Published
                            </Tag>
                          )}
                          {!directory.visibleInd && (
                            <Tag
                              round
                              intent={Intent.DANGER}
                              icon={'eye-off'}
                              style={{
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: Colors.RED3,
                                color: Colors.RED3,
                                background: 'transparent',
                                fontSize: 14,
                              }}
                            >
                              Unpublished
                            </Tag>
                          )}
                        </td>
                        <td>
                          {directory.visibleInd && (
                            <Button
                              onClick={() => handleUpdatePublishFolder(directory, 'Unpublish')}
                              icon={'eye-off'}
                              intent={Intent.DANGER}
                            >
                              Unpublish
                            </Button>
                          )}
                          {!directory.visibleInd && (
                            <Button
                              onClick={() => handleUpdatePublishFolder(directory, 'Publish')}
                              icon={'eye-on'}
                              intent={Intent.SUCCESS}
                            >
                              Publish
                            </Button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}

                {directoryData?.files.map((file) => (
                  <tr key={file.id}>
                    <td>
                      <Button minimal rightIcon={'download'} onClick={() => handleDownload(file)}>
                        <Icon icon={'document'} size={22}></Icon>
                        <Box pl={2} display="inline">
                          {file.fileName}
                        </Box>
                      </Button>
                    </td>
                    <td>{dayjs(file.createdDt).format('MM/DD/YYYY hh:mm A')}</td>

                    {hasWriteAccess && (
                      <>
                        <td>
                          {file.visibleInd && (
                            <Tag
                              intent={Intent.SUCCESS}
                              icon={'eye-on'}
                              round
                              style={{
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: Colors.GREEN3,
                                color: Colors.GREEN3,
                                background: 'transparent',
                                fontSize: 14,
                              }}
                            >
                              Published
                            </Tag>
                          )}
                          {!file.visibleInd && (
                            <Tag
                              intent={Intent.DANGER}
                              icon={'eye-off'}
                              round
                              style={{
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: Colors.RED3,
                                color: Colors.RED3,
                                background: 'transparent',
                                fontSize: 14,
                              }}
                            >
                              Unpublished
                            </Tag>
                          )}
                        </td>
                        <td>
                          {file.visibleInd && (
                            <Button
                              onClick={() => handleUpdatePublishFile(file, 'Unpublish')}
                              icon={'eye-off'}
                              intent={Intent.DANGER}
                            >
                              Unpublish
                            </Button>
                          )}
                          {!file.visibleInd && (
                            <Button
                              onClick={() => handleUpdatePublishFile(file, 'Publish')}
                              icon={'eye-on'}
                              intent={Intent.SUCCESS}
                            >
                              Publish
                            </Button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                <tr>
                  <td colSpan={100}>
                    {directoryData?.directories.length === 0 && directoryData?.files.length === 0 && (
                      <NonIdealState
                        icon={'issue'}
                        title={<h5 className="bp3-heading"> No folders or files present.</h5>}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>

      <Dialog
        isOpen={uploadModalOpen}
        //onAfterOpen={afterOpenModal}
        title={'Upload File'}
        icon={'upload'}
        onClose={() => setUploadModalOpen(false)}
        isCloseButtonShown={!isSubmitting}
        canOutsideClickClose={false}
      >
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb={2}>
              <Callout intent={Intent.PRIMARY} icon={null}>
                Choose a file to upload to <strong>{folderPath}</strong>
              </Callout>
            </Box>
            <FormGroup label="">
              <input type="file" className="bp3-input bp3-fill" {...register('file')}></input>
            </FormGroup>
            {errors?.file?.message && <Callout intent={Intent.DANGER}>{errors?.file?.message}</Callout>}

            <Box mt={2} justifyContent="flex-end" display="flex">
              <Button
                type="submit"
                icon={'upload'}
                intent={Intent.PRIMARY}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Upload
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Dialog
        isOpen={createFolderModalOpen}
        //onAfterOpen={afterOpenModal}
        title={'Create New Folder'}
        icon={'folder-new'}
        onClose={() => setCreateFolderModalOpen(false)}
        isCloseButtonShown={!isCreateFolderLoading}
        canOutsideClickClose={false}
      >
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb={2}>
              <Callout intent={Intent.PRIMARY} icon={null}>
                Create a new folder in <strong>{folderPath}</strong>
              </Callout>
            </Box>
            <FormGroup label="Folder Name">
              <InputGroup onChange={(e) => setNewFolderName(e.target.value)}></InputGroup>
            </FormGroup>

            <Box mt={2} justifyContent="flex-end" display="flex">
              <Button
                type="submit"
                icon={'folder-new'}
                intent={Intent.PRIMARY}
                disabled={!newFolderName || newFolderName.trim().length === 0}
                loading={isCreateFolderLoading}
                onClick={() => handleCreateFolder()}
              >
                Create
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>

      <Alert
        isOpen={!!folderToPublishOrUnpublish}
        onConfirm={confirmUpdatePublishFodler}
        confirmButtonText={'Yes'}
        loading={isLoadingUpdatePublishFolder}
        onCancel={() => setFolderToPublishOrUnpublish(undefined)}
        cancelButtonText={'Cancel'}
        intent={publishOrUnpublishAction === 'Publish' ? Intent.SUCCESS : Intent.DANGER}
        icon={publishOrUnpublishAction === 'Publish' ? 'eye-on' : 'eye-off'}
      >
        <div>
          Are you sure you want to {publishOrUnpublishAction} folder {folderToPublishOrUnpublish?.folderName}?
        </div>
      </Alert>

      <Alert
        isOpen={!!fileToPublishOrUnpublish}
        onConfirm={confirmUpdatePublishFile}
        confirmButtonText={'Yes'}
        loading={isLoadingUpdatePublishFile}
        onCancel={() => setFileToPublishOrUnpublish(undefined)}
        cancelButtonText={'Cancel'}
        intent={publishOrUnpublishAction === 'Publish' ? Intent.SUCCESS : Intent.DANGER}
        icon={publishOrUnpublishAction === 'Publish' ? 'eye-on' : 'eye-off'}
      >
        <div>
          Are you sure you want to {publishOrUnpublishAction} file {fileToPublishOrUnpublish?.fileName}?
        </div>
      </Alert>
    </>
  );
}

export default DocumentsMainPanel;

import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import SelectWrapper from '@components/SelectWrapper/SelectWrapper';
import ITradeStatusRef from '@shared/interfaces/ITradeStatusRef';
import { FC, ReactElement, useCallback, useMemo } from 'react';
import { Flex } from 'reflexbox';

interface ITransactionTypeSelectionDropdownProps {
  tradeStatuses: ITradeStatusRef[];
  selectedTradeStatusName: string | undefined;
  switchTradeStatus: (name: string | undefined) => void;
  disabled: boolean;
}
const TradeStatusSelectionDropdown: FC<ITransactionTypeSelectionDropdownProps> = ({
  tradeStatuses,
  selectedTradeStatusName,
  switchTradeStatus,
  disabled,
}): ReactElement => {
  const handleChange = useCallback(
    (selectedTradeStatusName) => {
      switchTradeStatus(selectedTradeStatusName);
    },
    [selectedTradeStatusName],
  );

  const TradeStatusSelect = Select.ofType<ITradeStatusRef | undefined>();

  const seletedTradeStatus = useMemo(() => {
    return tradeStatuses.find((status) => status.tradeStatusName === selectedTradeStatusName);
  }, [selectedTradeStatusName, tradeStatuses]);
  return (
    <>
      <Flex>
        <SelectWrapper label="Status">
          <TradeStatusSelect
            popoverProps={{ minimal: true }}
            filterable={false}
            items={[undefined, ...tradeStatuses]}
            itemRenderer={(tradeStatus: ITradeStatusRef | undefined, { handleClick }) => (
              <MenuItem onClick={handleClick} text={tradeStatus ? tradeStatus.tradeStatusName : 'All Status'} />
            )}
            onItemSelect={(tradeStatus: ITradeStatusRef | undefined) => handleChange(tradeStatus?.tradeStatusName)}
          >
            <Button
              minimal={true}
              outlined={true}
              disabled={disabled}
              text={seletedTradeStatus ? seletedTradeStatus.tradeStatusName : 'All Status'}
              rightIcon="chevron-down"
            />
          </TradeStatusSelect>
        </SelectWrapper>
      </Flex>
    </>
  );
};

export default TradeStatusSelectionDropdown;

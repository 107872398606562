import CustomCard from '@components/CustomCard';
import { ReactElement, useEffect, useRef } from 'react';

const MarketsMostActiveGainersLosers = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js';
    script.async = true;
    script.innerHTML = data; /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
    ref?.current?.appendChild(script);
  }, [ref]);

  return (
    <CustomCard
      simple
      heading={'US Exchanges Most Active, Top Gainers/Losers'}
      body={
        <div className="tradingview-widget-container" ref={ref}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/markets/" rel="noopener noreferrer" target="_blank">
              <span className="blue-text">Stock Market Today</span>
            </a>{' '}
            by TradingView
          </div>
        </div>
      }
    ></CustomCard>
  );
};

export default MarketsMostActiveGainersLosers;

const data = ` {
    "colorTheme": "light",
    "dateRange": "12M",
    "exchange": "US",
    "showChart": true,
    "locale": "en",
    "largeChartUrl": "",
    "isTransparent": false,
    "showSymbolLogo": false,
    "showFloatingTooltip": false,
    "width": "100%",
    "height": "600",
    "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
    "plotLineColorFalling": "rgba(41, 98, 255, 1)",
    "gridLineColor": "rgba(240, 243, 250, 0)",
    "scaleFontColor": "rgba(120, 123, 134, 1)",
    "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
    "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
    "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
    "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
    "symbolActiveColor": "rgba(41, 98, 255, 0.12)"
  }
  `;

import { Alert } from '@blueprintjs/core';
import useGlobalStore from '@stores/useGlobalStore';
import { ReactElement } from 'react';

const ProgressModal = (): ReactElement => {
  const { isGlobalProgress, globalProgressMessage, isGlobalProgressCancelable, isProgressEnd, stopGlobalProgress } =
    useGlobalStore(
      ({ isGlobalProgress, globalProgressMessage, isGlobalProgressCancelable, isProgressEnd, stopGlobalProgress }) => {
        return {
          isGlobalProgress,
          globalProgressMessage,
          isGlobalProgressCancelable,
          isProgressEnd,
          stopGlobalProgress,
        };
      },
    );
  return (
    <>
      <Alert
        isOpen={isGlobalProgress}
        onConfirm={stopGlobalProgress}
        confirmButtonText={isGlobalProgressCancelable ? 'OK' : undefined}
      >
        <div className="pb-3">{globalProgressMessage}</div>
        {!isProgressEnd && (
          <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </Alert>
      {/*
      {isGlobalProgress && (
        <SweetAlert
          title={undefined}
          showConfirm={isGlobalProgressCancelable}
          showCancel={false}
          onConfirm={stopGlobalProgress}
          btnSize="sm"
          customClass="modal-box  px-0 pt-0"
        >
          <div className="modal-header text-center">{isProgressEnd ? 'Done' : 'In Progress...'} </div>
          <div className="modal-details">
            <div className="pb-3">{globalProgressMessage}</div>
            {!isProgressEnd && (
              <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
        </SweetAlert>
      )} 
            */}
    </>
  );
};

export default ProgressModal;

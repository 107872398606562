import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import { Cell, Column, useTable, useGlobalFilter, useFilters } from 'react-table';
import useFunds from '@common/hooks/useFunds';
import TableLoader from '@components/TableLoader';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import useAdministrationStore from '@stores/useAdministrationStore';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';
import useAuthStore from '@stores/useAuthStore';
import { Box, Flex } from 'reflexbox';
import CustomCard from '@components/CustomCard';
import {
  Button,
  Callout,
  Classes,
  Colors,
  Divider,
  Drawer,
  Icon,
  IconSize,
  Intent,
  NonIdealState,
  Switch,
} from '@blueprintjs/core';
import IExternalTransferAccount from '@shared/interfaces/IExternalTransferAccount';
import useExternalTransferAccounts from '@common/hooks/useExternalTransferAccounts';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import UserAvatar from '@components/UserAvatar';
import GlobalLoader from '@components/GlobalLoader';

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: { width?: string; wordWrap: 'break-word' } = {
    wordWrap: 'break-word',
  };
  switch (columnId) {
    case 'id':
      style.width = '5%';
      break;
    case 'accountName':
      style.width = '30%';
      break;
    case 'accountType':
      style.width = '20%';
      break;
    case 'accountHoldingInstitutionName':
      style.width = '20%';
      break;
    case 'accountContactEmailId':
      style.width = '20%';
      break;
    case 'activeInd':
      style.width = '10%';
      break;
  }
  return { style };
};

const getCellProps = (cell: Cell<IExternalTransferAccount>) => {
  const styles = getStyleOfColumns(cell.column.id);
  return { ...styles };
};

const getHeaderProps = (column: Column<IExternalTransferAccount>) => {
  const styles = getStyleOfColumns(column.id);
  return { ...styles };
};

export function ExternalTransferAccount({ data }: { data: IExternalTransferAccount | undefined }): ReactElement {
  return (
    <>
      {data && (
        <Styles>
          <Box style={{ background: Colors.WHITE }}>
            <table width="100%" className="table bp3-html-table bp3-html-table-condensed bp3-html-table-bordered">
              <tbody>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Fund</Box>
                  </td>
                  <td>
                    <Box pl={2}>{data.fund.fundName}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Name</Box>
                  </td>
                  <td>
                    <Box pl={2}>{data.accountName}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Type</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountType.accountTypeName}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Holding Institution</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountHoldingInstitutionName}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Contact Email</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountContactEmailId}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Address</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountAddress}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account No</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountNo}</Box>
                  </td>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Account Swift Code</Box>
                  </td>
                  <td>
                    <Box p={2}>{data.accountSwiftCode}</Box>
                  </td>
                </tr>
                <tr>
                  <>
                    <td className="keyName">
                      <Box p={2}>Created By</Box>
                    </td>
                    <td>
                      <Flex alignItems={'center'}>
                        <Box>
                          <UserAvatar user={data.createdBy}></UserAvatar>
                        </Box>
                        <Box ml={2}>
                          <div>{data.createdBy.firstName + ' ' + data.createdBy.lastName}</div>
                        </Box>
                      </Flex>
                    </td>
                  </>
                </tr>
                <tr>
                  <td className="keyName">
                    <Box p={2}>Created Date</Box>
                  </td>
                  <td>
                    <Box p={2}>
                      <span>{dayjs(data.createdDt).format('MM/DD/YYYY hh:mm:ss A')}</span>
                    </Box>
                  </td>
                </tr>
                {data.activeInd ? (
                  <tr>
                    <td className="keyName">
                      <Box p={2}>Status</Box>
                    </td>
                    <td>
                      <Box p={2}>
                        <Switch
                          readOnly={true}
                          checked={data.activeInd}
                          disabled={true}
                          style={{ marginBottom: 0 }}
                        ></Switch>
                      </Box>
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Status</Box>
                      </td>
                      <td>
                        <Box p={2}>
                          <Switch
                            readOnly={true}
                            checked={data.activeInd}
                            disabled={true}
                            style={{ marginBottom: 0 }}
                          ></Switch>
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      {data.accountDeactivatedByUserId && (
                        <>
                          <td className="keyName">
                            <Box p={2}>Deactivated By</Box>
                          </td>
                          <td>
                            <Flex alignItems={'center'}>
                              <Box>
                                <UserAvatar user={data.accountDeactivatedByUserId}></UserAvatar>
                              </Box>
                              <Box ml={2}>
                                <div>
                                  {data.accountDeactivatedByUserId.firstName +
                                    ' ' +
                                    data.accountDeactivatedByUserId.lastName}
                                </div>
                              </Box>
                            </Flex>
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <td className="keyName">
                        <Box p={2}>Deactivated Date</Box>
                      </td>
                      <td>
                        <Box p={2}>
                          <span>{dayjs(data.accountDeactivatedDt).format('MM/DD/YYYY hh:mm:ss A')}</span>
                        </Box>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </Box>
          {!data && (
            <Box mt={2}>
              <NonIdealState
                icon={'issue'}
                title={<h5 className="bp3-heading"> No External Account record found for the selected account</h5>}
              />
            </Box>
          )}
          <Divider style={{ margin: 0 }}></Divider>
        </Styles>
      )}
    </>
  );
}

function ExternalTransferAccountsTable({
  columns,
  data,
  fundSelectionDropdown,
  isWriteEnabled,
  isReadEnabled,
  setSelectedExternalAccountRecord,
}: {
  columns: Column<IExternalTransferAccount>[];
  data: IExternalTransferAccount[];
  fundSelectionDropdown: ReactElement;
  isWriteEnabled: boolean;
  isReadEnabled: boolean;
  setSelectedExternalAccountRecord: (record: IExternalTransferAccount) => void;
}): JSX.Element {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );

  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div>{fundSelectionDropdown}</div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>

      <table
        className="bp3-html-table  bp3-html-table-bordered"
        {...getTableProps()}
        style={{ tableLayout: 'fixed', width: '100%' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
              {isReadEnabled && <th style={{ width: '8%', textAlign: 'right' }}>View</th>}
              {isWriteEnabled && <th style={{ width: '8%', textAlign: 'right' }}>Edit</th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps(getCellProps(cell))} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
                {isReadEnabled ? (
                  <td>
                    <Flex flexDirection="row-reverse">
                      <Button
                        onClick={() => setSelectedExternalAccountRecord(row.original)}
                        minimal
                        intent={Intent.PRIMARY}
                      >
                        <Icon icon={'eye-open'} size={IconSize.LARGE} />
                      </Button>
                    </Flex>
                  </td>
                ) : (
                  <td>
                    <Flex flexDirection="row-reverse">
                      <Button
                        onClick={() => setSelectedExternalAccountRecord(row.original)}
                        minimal
                        intent={Intent.PRIMARY}
                        disabled={true}
                      >
                        <Icon icon={'eye-open'} size={IconSize.LARGE} />
                      </Button>
                    </Flex>
                  </td>
                )}
                {isWriteEnabled ? (
                  <td>
                    <Flex flexDirection={'row-reverse'}>
                      <Link
                        className="bp3-button bp3-minimal bp3-outlined bp3-intent-primary bp3-icon-edit"
                        to={{
                          pathname: '/administration/external-accounts/edit',
                          state: { from: { externalAccount: row.original } },
                        }}
                      ></Link>
                    </Flex>
                  </td>
                ) : (
                  <td>
                    <Flex flexDirection={'row-reverse'}>
                      <Link
                        className="bp3-button bp3-minimal bp3-outlined bp3-intent-primary bp3-icon-edit bp3-disabled"
                        to={{
                          pathname: '/administration/external-accounts/edit',
                          state: { from: { externalAccount: row.original } },
                        }}
                        style={{ pointerEvents: 'none' }}
                      ></Link>
                    </Flex>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && (
        <NonIdealState
          icon={'issue'}
          title={<h5 className="bp3-heading"> No External Transfer/Suspense Accounts found for the selected fund.</h5>}
        />
      )}
    </>
  );
}

const ExternalTransferAccountsPage: FC = (): ReactElement => {
  const { data: fundsData, isLoading: isGetFundsLoading, isError, error } = useFunds();
  const { data: externalAccountsData, isLoading: isGetAccountsLoading, isFetching } = useExternalTransferAccounts();
  const { selectedFundId, setSelectedFundId, switchFund } = useAdministrationStore();
  const [selectedExternalAccountRecord, setSelectedExternalAccountRecord] =
    useState<IExternalTransferAccount | undefined>();
  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const canCurrentUserReadACL = useAuthStore((state) => state.canCurrentUserReadACL);
  const isWriteEnabled = canCurrentUserWriteACL('ADMIN_EXTERNAL_TRANSFER_ACCOUNTS');
  const isReadEnabled = canCurrentUserReadACL('ADMIN_EXTERNAL_TRANSFER_ACCOUNTS');

  useEffect(() => {
    if (!selectedFundId && fundsData && fundsData?.funds) {
      setSelectedFundId(fundsData?.funds[0].id);
    }
  }, [fundsData]);

  const { setExternalAccountToDeactivate } = useAdministrationStore(({ setExternalAccountToDeactivate }) => {
    return {
      setExternalAccountToDeactivate,
    };
  });

  const getExternalAccountsOfSelectedFunds = () => {
    if (!externalAccountsData) return [];
    return externalAccountsData.externalTransferAccounts.filter(
      (externalAccount: IExternalTransferAccount) => externalAccount.fund.id === selectedFundId,
    );
  };

  const columns = useMemo(
    (): Column<IExternalTransferAccount>[] => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: 'Name',
        accessor: 'accountName',
        Cell: ({ value }) => {
          return <>{value}</>;
        },
      },
      {
        Header: 'Type',
        accessor: 'accountType',
        Cell: ({ value }) => value.accountTypeName,
      },
      {
        Header: 'Institution Name',
        accessor: 'accountHoldingInstitutionName',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Contact Email',
        accessor: 'accountContactEmailId',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Active',
        accessor: 'activeInd',
        Cell: ({ value, row }) => (
          <Switch
            defaultChecked={value}
            // readOnly={true}
            checked={value}
            disabled={!isWriteEnabled}
            style={{ marginBottom: 0 }}
            onChange={() => setExternalAccountToDeactivate(row.original)}
          ></Switch>
        ),
      },
    ],

    [],
  );

  const fundSelectionDropdown = (
    <>
      {selectedFundId && fundsData?.funds && (
        <FundSelectionDropdown
          switchFund={switchFund}
          selectedFundId={selectedFundId}
          allFunds={fundsData?.funds}
          disabled={false}
        ></FundSelectionDropdown>
      )}
    </>
  );

  return (
    <Flex m={2}>
      <Box width={[10 / 12]}>
        <CustomCard
          heading="External Transfer/Suspense Accounts"
          body={
            <Box>
              {isError ? (
                <Callout intent={Intent.DANGER}>{error?.errorMessage}</Callout>
              ) : (
                <>
                  {isFetching && <GlobalLoader></GlobalLoader>}
                  {isGetAccountsLoading || isGetFundsLoading ? (
                    <TableLoader></TableLoader>
                  ) : (
                    <ExternalTransferAccountsTable
                      columns={columns}
                      data={getExternalAccountsOfSelectedFunds()}
                      fundSelectionDropdown={fundSelectionDropdown}
                      isWriteEnabled={isWriteEnabled}
                      isReadEnabled={isReadEnabled}
                      setSelectedExternalAccountRecord={setSelectedExternalAccountRecord}
                    />
                  )}
                </>
              )}
            </Box>
          }
          rightToolbar={
            <>
              {isWriteEnabled ? (
                <NavLink
                  to={{
                    pathname: '/administration/external-accounts/create',
                    state: { undefined },
                  }}
                  className="bp3-button bp3-intent-primary bp3-icon-add"
                >
                  Add External Transfer/Suspense Account
                </NavLink>
              ) : (
                <NavLink
                  to={{
                    pathname: '/administration/external-accounts/create',
                    state: { undefined },
                  }}
                  className="bp3-button bp3-intent-primary bp3-icon-add bp3-disabled"
                  style={{ pointerEvents: 'none' }}
                >
                  Add External Transfer/Suspense Account
                </NavLink>
              )}
            </>
          }
        ></CustomCard>
        <Drawer
          icon={'list-detail-view'}
          title={
            <h6 className="bp3-heading">
              {'External Transfer/Suspense Account Record #'}
              {selectedExternalAccountRecord?.id}
            </h6>
          }
          size={'50%'}
          isOpen={!!selectedExternalAccountRecord?.id}
          onClose={() => setSelectedExternalAccountRecord(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            <ExternalTransferAccount data={selectedExternalAccountRecord}></ExternalTransferAccount>
          </div>
        </Drawer>
      </Box>
    </Flex>
  );
};

const Styles = styled.div`
  .table tbody tr .keyName {
    background-color: ${Colors.LIGHT_GRAY5} !important;
  }
  .table tbody tr td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

export default memo(ExternalTransferAccountsPage);

import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import SelectWrapper from '@components/SelectWrapper/SelectWrapper';
import ICashTransactionType from '@shared/interfaces/ICashTransactionType';
import { FC, ReactElement, useCallback } from 'react';
import { Flex } from 'reflexbox';

interface ITransactionTypeSelectionDropdownProps {
  transactionTypes: ICashTransactionType[];
  selectedTransactionTypeName: string | undefined;
  switchTransactionType: (name: string) => void;
  disabled: boolean;
}
const TransactionTypeSelectionDropdown: FC<ITransactionTypeSelectionDropdownProps> = ({
  transactionTypes,
  selectedTransactionTypeName,
  switchTransactionType,
  disabled,
}): ReactElement => {
  const handleChange = useCallback(
    (transactionTypeId) => {
      switchTransactionType(transactionTypeId);
    },
    [switchTransactionType],
  );

  const TransactionTypeSelect = Select.ofType<ICashTransactionType | undefined>();

  return (
    <>
      <Flex>
        <SelectWrapper label="Types">
          <TransactionTypeSelect
            popoverProps={{ minimal: true }}
            filterable={false}
            items={[undefined, ...transactionTypes]}
            itemRenderer={(transactionType: ICashTransactionType | undefined, { handleClick }) => (
              <MenuItem
                onClick={handleClick}
                text={transactionType ? transactionType.transactionTypeName : 'All Types'}
              />
            )}
            onItemSelect={(transactionType: ICashTransactionType | undefined) =>
              handleChange(transactionType?.transactionTypeName)
            }
          >
            <Button
              minimal={true}
              outlined={true}
              disabled={disabled}
              text={selectedTransactionTypeName ? selectedTransactionTypeName : 'All Types'}
              rightIcon="chevron-down"
            />
          </TransactionTypeSelect>
        </SelectWrapper>
      </Flex>
      {/*}
    <div className="fund-drop">
      <p>Type</p>
      <div className="dropdown">
        <button
          className="btn dropdown-toggle text-dark"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={disabled}
        >
          {selectedTransactionTypeName ? selectedTransactionTypeName : 'All Types'}
        </button>
        <ul className="dropdown-menu" style={{ width: 'auto' }} aria-labelledby="dropdownMenuButton1">
          <li>
            <a className="dropdown-item" href="#" onClick={() => handleChange(undefined)}>
              All Types
            </a>
          </li>

          {transactionTypes?.map((transactionType) => {
            return (
              <li key={transactionType.transactionTypeName}>
                <a className="dropdown-item" href="#" onClick={() => handleChange(transactionType.transactionTypeName)}>
                  {transactionType.transactionTypeName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
        */}
    </>
  );
};

export default TransactionTypeSelectionDropdown;

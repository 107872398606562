import IUpdateDocRequest from '@shared/exchange/updateDoc/IUpdateDocRequest';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';

interface IUpdateDocResponse {
  id: number;
}

export default function useUpdateDoc(): UseMutationResult<
  IUpdateDocResponse,
  AxiosError,
  IUpdateDocRequest,
  IUpdateDocResponse
> {
  const axios = useAxios();
  return useMutation<IUpdateDocResponse, AxiosError, IUpdateDocRequest, IUpdateDocResponse>(
    async (docUpdateEntry: IUpdateDocRequest) => {
      const routePath = 'update-doc';
      return axios?.post(routePath, docUpdateEntry).then((res) => res.data.data);
    },
  );
}

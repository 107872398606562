import useDocs from '@common/hooks/useDocs';
import TableLoader from '@components/TableLoader';
import IDoc from '@shared/interfaces/IDoc';
import { memo, ReactElement, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Cell, Column, useFilters, useGlobalFilter, useTable } from 'react-table';
import useGetDocTemplateDownloadUrl from '@common/hooks/useGetDocTemplateDownloadUrl';
import { useCallback } from 'react';
import FileSaver from 'file-saver';
//import useGlobalStore from '@stores/useGlobalStore';
import useAdministrationStore from '@stores/useAdministrationStore';
import useFunds from '@common/hooks/useFunds';
import FundSelectionDropdown from '@components/FundSelectionDropdown';
import GlobalLoader from '@components/GlobalLoader';
import useAuthStore from '@stores/useAuthStore';
import CustomCard from '@components/CustomCard';
import { Box, Flex } from 'reflexbox';
import { Button, Icon, Intent, NonIdealState, ProgressBar, Switch } from '@blueprintjs/core';
import pdfIcon from '@assets/img/pdf-icon.svg';
import { AppToaster } from '@components/Toasters';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';
import GlobalTableFilter from '@components/GlobalTableFilter';

const getStyleOfColumns = (columnId: string | undefined) => {
  const style: { width?: string; wordWrap: 'break-word' } = {
    wordWrap: 'break-word',
  };
  switch (columnId) {
    case 'id':
      style.width = '5%';
      break;
    case 'account':
      style.width = '30%';
      break;
  }
  return { style };
};

const getCellProps = (cell: Cell<IDoc>) => {
  const styles = getStyleOfColumns(cell.column.id);
  return { ...styles };
};
const getHeaderProps = (column: Column<IDoc>) => {
  const styles = getStyleOfColumns(column.id);
  return { ...styles };
};

const DocsListTable = ({
  columns,
  data,
  fundSelectionDropdown,
}: {
  columns: Column<IDoc>[];
  data: IDoc[];
  fundSelectionDropdown: ReactElement;
}): ReactElement => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
  );

  return (
    <>
      <Flex justifyContent={'space-between'} m={2}>
        <div>{fundSelectionDropdown}</div>
        <GlobalTableFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </Flex>

      <table
        className="bp3-html-table bp3-html-table-bordered"
        {...getTableProps()}
        style={{ tableLayout: 'fixed', width: '100%' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(getHeaderProps(column))} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps(getCellProps(cell))} key={row.id + '_' + j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length == 0 && (
        <NonIdealState
          icon={'issue'}
          title={<h5 className="bp3-heading"> No documents found for the selected fund.</h5>}
        />
      )}
    </>
  );
};

const DocsPage = (): ReactElement => {
  const { data: docsData, isLoading: isDocsLoading, isFetching } = useDocs();
  const { mutateAsync: getDownloadUrl } = useGetDocTemplateDownloadUrl();
  /*const { startGlobalProgress, stopGlobalProgress } = useGlobalStore(({ startGlobalProgress, stopGlobalProgress }) => {
    return { startGlobalProgress, stopGlobalProgress };
  });*/
  const { selectedFundId, setSelectedFundId, switchFund } = useAdministrationStore();

  const { data: fundsData, isLoading: isGetFundsLoading } = useFunds();
  useEffect(() => {
    if (!selectedFundId && fundsData) {
      setSelectedFundId(fundsData?.funds[0].id);
    }
  }, [fundsData]);

  const { setDocToUpdate } = useAdministrationStore(({ setDocToUpdate }) => {
    return {
      setDocToUpdate,
    };
  });

  const canCurrentUserWriteACL = useAuthStore((state) => state.canCurrentUserWriteACL);
  const isWriteEnabled = canCurrentUserWriteACL('ADMIN_DOCS');

  const handleDownloadUrl = useCallback(async (id: number, name: string) => {
    AppToaster.show({
      message: (
        <Box>
          <Box mb={2} textAlign="center">
            Preparing your download
          </Box>
          <ProgressBar stripes value={100} intent={Intent.PRIMARY}></ProgressBar>
        </Box>
      ),
      icon: 'download',
      timeout: 0,
    });
    const res = await getDownloadUrl({ docTemplateId: id });

    fetch(res.url)
      .then((res) => res.blob())
      .then((blob) => {
        FileSaver.saveAs(blob, `${name}.pdf`);
        AppToaster.clear();
      })
      .catch((e) => {
        console.log(e);
        AppToaster.clear();
      });
  }, []);

  const columns = useMemo(
    (): Column<IDoc>[] => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: 'Account',
        accessor: (row) => row.account?.account_name,
        Cell: ({ value }: { value: string }) => {
          return <>{!value ? 'Default' : value}</>;
        },
      },

      {
        Header: 'Signature Reqd.',
        accessor: 'signature_required_ind',
        Cell: ({ value }) => {
          return value === 1 ? <Icon icon="tick-circle"></Icon> : <Icon icon="ban-circle"></Icon>;
        },
      },

      {
        Header: 'Created Date',
        accessor: 'created_dt',
        Cell: ({ value }) => {
          return <>{new Date(value).toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Active',
        accessor: 'active_ind',
        Cell: ({ value, row }) => {
          return (
            <Switch
              style={{ margin: 0 }}
              onChange={() => setDocToUpdate(row.original)}
              disabled={!isWriteEnabled}
              checked={value === 1}
            ></Switch>
          );
        },
      },
      {
        Header: 'Document',
        accessor: 's3_template_url',
        Cell: ({ value, row }) => {
          const valueParts = value.split('/');
          const name = valueParts[valueParts.length - 1];
          return (
            <Button
              minimal
              outlined
              icon={<img src={pdfIcon} width={15} />}
              rightIcon="download"
              onClick={() => handleDownloadUrl(row.original.id, name)}
            >
              <>{name}</>
            </Button>
          );
        },
      },
    ],
    [],
  );

  const fundSelectionDropdown = (
    <>
      {selectedFundId && fundsData?.funds && (
        <FundSelectionDropdown
          switchFund={switchFund}
          selectedFundId={selectedFundId}
          allFunds={fundsData?.funds}
          disabled={false}
        ></FundSelectionDropdown>
      )}
    </>
  );

  const getDocsOfSelectedFunds = useCallback(() => {
    if (!docsData) return [];
    return docsData.docs.filter((doc: IDoc) => doc.fund.id === selectedFundId);
  }, [docsData, selectedFundId]);

  const isLoading = isGetFundsLoading || isDocsLoading;
  return (
    <Flex m={2}>
      <Box width={[12 / 12, 12 / 12, 10 / 12]}>
        <CustomCard
          heading="Letter of Directives"
          rightToolbar={
            <>
              {isWriteEnabled && (
                <NavLink
                  to="/administration/doc-templates/create"
                  className="bp3-button bp3-intent-primary bp3-icon-document"
                >
                  Add Template
                </NavLink>
              )}
            </>
          }
          body={
            <Box>
              {isFetching && <GlobalLoader></GlobalLoader>}
              <div>
                {isLoading ? (
                  <TableLoader></TableLoader>
                ) : (
                  <DocsListTable
                    columns={columns}
                    data={getDocsOfSelectedFunds()}
                    fundSelectionDropdown={fundSelectionDropdown}
                  />
                )}
              </div>
            </Box>
          }
        ></CustomCard>
      </Box>
    </Flex>
  );
};

export default memo(DocsPage);

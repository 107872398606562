import CustomCard from '@components/CustomCard';
import { ReactElement, useEffect, useRef } from 'react';

const MartketOverviewWidget = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.async = true;
    script.innerHTML = data; /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
    ref?.current?.appendChild(script);
  }, [ref]);

  return (
    <CustomCard
      simple
      heading={'Market Overview'}
      body={
        <div className="tradingview-widget-container" ref={ref}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/markets/" rel="noopener noreferrer" target="_blank">
              <span className="blue-text">Financial Markets</span>
            </a>{' '}
            by TradingView
          </div>
        </div>
      }
    ></CustomCard>
  );
};

export default MartketOverviewWidget;

const data = ` {
  "colorTheme": "light",
  "dateRange": "12M",
  "showChart": true,
  "locale": "en",
  "largeChartUrl": "",
  "isTransparent": false,
  "showSymbolLogo": true,
  "showFloatingTooltip": false,
  "width": "100%",
  "height": "660",
  "plotLineColorGrowing": "#4b85cd",
  "plotLineColorFalling": "#4b85cd",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(120, 123, 134, 1)",
  "belowLineFillColorGrowing": "rgba(184, 223, 255, 0.2)",
  "belowLineFillColorFalling": "rgba(184, 223, 255, 0.2) ",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(184, 223, 255, 0.2) !important",
  "tabs": [
	{
      "title": "Eq Indices (CFDs)",
      "symbols": [
    	{
          "s": "FOREXCOM:SPXUSD",
          "d": "S&P 500 Index"
    	},
    	{
          "s": "FOREXCOM:NSXUSD",
          "d": "Nasdaq 100 Index"
    	},
    	{
          "s": "FOREXCOM:DJI",
          "d": "Dow Jones 30 (DJIA)"
    	},
    	{
          "s": "FOREXCOM:US2000",
          "d": "R2000 Small Cap Index"
    	},
    	{
          "s": "FOREXCOM:UKXGBP",
          "d": "FTSE 100 Index"
    	},
    	{
          "s": "FOREXCOM:GRXEUR",
          "d": "DAX 40 Index"
    	},
    	{
          "s": "FOREXCOM:FRXEUR",
          "d": "CAC 40 Index"
    	},
    	{
          "s": "FOREXCOM:EU50",
          "d": "Eurostoxx 50 Index"
    	},
    	{
          "s": "FOREXCOM:AUXAUD",
          "d": "S&P/ASX 200 Index"
    	},
    	{
          "s": "FOREXCOM:JPXJPY",
          "d": "Nikkei 225 Index"
    	},
    	{
          "s": "FOREXCOM:HKXHKD",
          "d": "Hang Seng Index"
    	},
    	{
          "s": "FOREXCOM:SIMSCI",
          "d": "MSCI Singapore Index"
    	},
    	{
          "s": "FOREXCOM:CHN50",
          "d": "China A50 Index"
    	}
  	],
      "originalTitle": "Indices"
	},
	{
      "title": "Bonds",
      "symbols": [
    	{
          "s": "CME:GE1!",
          "d": "Eurodollar"
    	},
    	{
          "s": "CBOT:ZB1!",
          "d": "T-Bond"
    	},
    	{
          "s": "CBOT:UB1!",
          "d": "Ultra T-Bond"
    	},
    	{
          "s": "EUREX:FGBL1!",
          "d": "Euro Bund"
    	},
    	{
          "s": "EUREX:FBTP1!",
          "d": "Euro BTP"
    	},
    	{
          "s": "EUREX:FGBM1!",
          "d": "Euro BOBL"
    	}
  	],
      "originalTitle": "Bonds"
	},
	{
      "title": "Forex",
      "symbols": [
    	{
          "s": "FX:EURUSD"
    	},
    	{
          "s": "FX:GBPUSD"
    	},
    	{
          "s": "FX:USDJPY"
    	},
    	{
          "s": "FX:USDCHF"
    	},
    	{
          "s": "FX:AUDUSD"
    	},
    	{
          "s": "FX:USDCAD"
    	}
  	],
      "originalTitle": "Forex"
	},
	{
      "title": "Comdty",
      "symbols": [
    	{
          "s": "FOREXCOM:XAUUSD",
     	 "d": "Gold"
    	},
    	{
          "s": "FOREXCOM:XAGUSD",
          "d": "Silver"
    	},
    	{
          "s": "FOREXCOM:WTIUSD",
          "d": "WTI Crude Oil"
    	},
    	{
          "s": "FOREXCOM:SOYUSD",
          "d": "Soy Bean Oil"
    	},
    	{
          "s": "FOREXCOM:COTUSD",
          "d": "Cotton"
    	},
    	{
          "s": "FOREXCOM:SUGUSD",
          "d": "Sugar"
    	}
  	]
	},
	{
      "title": "Crypto",
  	"symbols": [
    	{
          "s": "COINBASE:BTCUSD",
          "d": "BITCOIN"
    	},
    	{
          "s": "COINBASE:ETHUSD",
          "d": "ETHEREUM"
    	},
    	{
          "s": "COINBASE:SOLUSD",
          "d": "SOLANO"
    	},
    	{
          "s": "BITSTAMP:XRPUSD",
          "d": "XRP"
    	},
    	{
          "s": "KRAKEN:ADAUSD",
          "d": "CARDANO"
    	},
    	{
          "s": "COINBASE:LINKUSD",
          "d": "CHAINLINK"
    	}
  	]
	}
  ]
}
`;

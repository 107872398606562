import { IGetExposureResponse } from '@shared/exchange/getExposure';
import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import { useQueryClient } from 'react-query';

export default function useExposure(fundId?: number, dateString?: string): UseQueryResult<IGetExposureResponse, Error> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useQuery<IGetExposureResponse, Error>(
    ['exposure', { fundId, dateString }],
    async () => {
      console.log('Here', fundId);
      const defaultExposureData: IGetExposureResponse | undefined = queryClient.getQueryData([
        'exposure',
        { fundId: undefined, dateString: undefined },
      ]);
      if (fundId && fundId === defaultExposureData?.fundId && !dateString) return defaultExposureData;
      const searchParams = new URLSearchParams();
      const routePath = 'get-exposure';
      if (fundId) {
        searchParams.append('fundId', String(fundId));
      }
      if (dateString) {
        searchParams.append('date', dateString);
      }
      return axios?.get(`${routePath}?${searchParams.toString()}`).then((res) => res.data.data);
    },
    { staleTime: 60 * 1000 },
  );
}

import { useAxios } from '@common/providers/AxiosProvider';
import { ICreateOrUpdateDocFactRequest } from '@shared/exchange/createOrUpdateDocFact';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { useMutation, UseMutationResult } from 'react-query';

interface ICreateOrUpdateDocFactResponse {
  id: number;
}

export default function useCreateOrUpdateDocFact(): UseMutationResult<
  ICreateOrUpdateDocFactResponse,
  IErrorResponse,
  ICreateOrUpdateDocFactRequest,
  ICreateOrUpdateDocFactResponse
> {
  const axios = useAxios();
  return useMutation<
    ICreateOrUpdateDocFactResponse,
    IErrorResponse,
    ICreateOrUpdateDocFactRequest,
    ICreateOrUpdateDocFactResponse
  >(async (data: ICreateOrUpdateDocFactRequest) =>
    axios
      ?.post('create-or-update-doc-fact', data)
      .then((res) => res.data.data)
      .catch((e) => {
        throw e.response.data;
      }),
  );
}

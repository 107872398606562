import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Column, useExpanded, useFilters, useGlobalFilter, useTable } from 'react-table';
import { Button, Classes, Drawer, Callout, NonIdealState, Card, Navbar, Alignment } from '@blueprintjs/core';
import { Box } from 'reflexbox';
import GlobalLoader from '@components/GlobalLoader';
import TableLoader from '@components/TableLoader';
import CustomCard from '@components/CustomCard';
import useGetOpsFeedsAndIntegrations from '@common/hooks/useGetOpsFeedsAndIntegrations';
import { ICustodialAccountWithMetrics } from '@shared/exchange/getOpsFeedsAndIntegrations/IGetOpsFeedsAndIntegrationsResponse';
import { Popover2, Classes as Popover2Classes } from '@blueprintjs/popover2';
import FeedsPanel from './FeedsPanel';
import ICustodialFeed from '@shared/interfaces/ICustodialFeed';
import FilesPanel from './FilesPanel';
import useGetOpsJobLogs from '@common/hooks/useGetJobLogs';
import IEtlFileControl from '@shared/interfaces/IEtlFileControl';
import { JobRunLogs } from '../OpsJobLogs/OpsJobLogs';
import GlobalTableFilter from '@components/GlobalTableFilter';
import fuzzyTextFilterTableFn from '@common/utils/fuzzyTextFilterFn';

function Table({
  columns,
  data,
  custodialFeeds,
  setSelectedCustodialFeed,
  globalFilterValue,
}: {
  columns: Column<ICustodialAccountWithMetrics>[];
  data: ICustodialAccountWithMetrics[];
  custodialFeeds: ICustodialFeed[];
  setSelectedCustodialFeed: (custodialFeed: ICustodialFeed) => void;
  globalFilterValue: string;
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterTableFn,
    }),
    [],
  );

  const initialExpanded = useMemo(() => {
    const expanded: Record<string, boolean> = {};
    data.forEach((_, i) => {
      expanded['' + i] = true;
    });
    return { expanded };
  }, [data, columns]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, setGlobalFilter } =
    useTable(
      {
        columns,
        data,
        filterTypes,
        initialState: initialExpanded,
      },
      useFilters,
      useGlobalFilter,
      useExpanded,
    );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  const getCustodialFeedsOfAccount = useCallback(
    (selectedCustodialAccountId) => {
      if (!custodialFeeds) return [];
      return custodialFeeds.filter((feed) => feed.custodialAccount.custodialAccountId == selectedCustodialAccountId);
    },
    [custodialFeeds],
  );

  return (
    <>
      <table {...getTableProps()} className="bp3-html-table bp3-html-table-bordered" width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              <th>#</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {/*column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>{column.isGrouped ? 'GG ' : ' '}</span>
                    ) : null*/}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={row.id} className={'src_' + row.id}>
                  <td>{i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        // For educational purposes, let's color the
                        // cell depending on what type it is given
                        // from the useGroupBy hook
                        {...cell.getCellProps()}
                        key={cell.column.id + '_' + cell.row.id}
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>{cell.render('Cell')}</>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr className={'dest_' + row.id}>
                    <td></td>

                    <td colSpan={visibleColumns.length + 1} style={{ padding: 0 }}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      <Box p={4}>
                        <Card style={{ padding: 0 }}>
                          <FeedsPanel
                            data={getCustodialFeedsOfAccount(row.original.custodialAccountId)}
                            setSelectedCustodialFeed={setSelectedCustodialFeed}
                          ></FeedsPanel>
                        </Card>
                      </Box>
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <Box mt={2}>
          <NonIdealState icon={'issue'} title={<h5 className="bp3-heading"> No Custodial Accounts Found.</h5>} />
        </Box>
      )}
    </>
  );
}

const OpsFeedsAndIntegrations = (): ReactElement => {
  const [startDate, setStarteDate] = useState<Date>(dayjs().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());
  const {
    data: opsFeedsAndIntegrationData,
    isLoading: isOpsFeedsAndIntegrationDataLoading,
    isFetching: isOpsFeedsAndIntegrationDataFetching,
  } = useGetOpsFeedsAndIntegrations(startDate, endDate);
  const {
    data: jobsLogsData,
    isLoading: isJobsLogsDataLoading,
    //isFetching: isJobsLogsDataFetching,
  } = useGetOpsJobLogs(startDate, endDate);

  const [selectedCustodialFeed, setSelectedCustodialFeed] = useState<ICustodialFeed | undefined>();

  const etlFiles = useMemo(() => {
    if (!selectedCustodialFeed || !opsFeedsAndIntegrationData?.etlFileControl) return [];
    return opsFeedsAndIntegrationData.etlFileControl.filter((file) => file.custodialFeed === selectedCustodialFeed?.id);
  }, [opsFeedsAndIntegrationData, selectedCustodialFeed, startDate, endDate]);

  const [globalFilterValue, setGlobalFilterValue] = useState<string | undefined>('');

  const custodialAccountsColumn: Column<ICustodialAccountWithMetrics>[] = useMemo(
    () => [
      {
        Header: 'Custodial Account Name',
        accessor: 'custodialAccountName',
      },
      {
        Header: 'Contact Info',
        accessor: 'custodialAccountContactInfo',
      },
      {
        Header: 'Feeds Count',
        accessor: 'totalFeedCount',
      },
      {
        Header: 'Funds List',
        accessor: 'supportedFunds',
        Cell: ({ value }) => (
          <>
            <Popover2
              interactionKind="click"
              popoverClassName={Popover2Classes.POPOVER2_CONTENT_SIZING}
              placement="right"
              enforceFocus={false}
              isOpen={undefined}
              content={
                <>
                  {value?.funds.map((fund) => (
                    <Box key={fund.id} pb={2}>
                      <Callout>
                        <h5 className="bp3-heading">{fund.fundName}</h5>
                      </Callout>
                    </Box>
                  ))}
                </>
              }
            >
              {value?.funds && value?.funds.length > 0 ? (
                <Button minimal outlined intent="primary" text={`View Funds (${value?.funds?.length})`} />
              ) : (
                'No funds'
              )}
            </Popover2>
          </>
        ),
      },
    ],
    [],
  );

  const [selectedFile, setSelectedFile] = useState<IEtlFileControl>();

  const jobRunLogs = useMemo(() => {
    if (!jobsLogsData?.jobRunLogs || !jobsLogsData?.jobRuns || !selectedFile) return [];
    const jobRunIds = jobsLogsData.jobRuns
      .filter((jobRun) => {
        if (!jobRun?.file) return false;
        return jobRun.file?.id == selectedFile.id;
      })
      .map((jobRun) => jobRun.id);
    return jobsLogsData.jobRunLogs.filter((runLog) => jobRunIds.includes(runLog.run));
  }, [selectedFile]);

  const Filters = (
    <Navbar>
      <Navbar.Group align={Alignment.RIGHT}>
        <GlobalTableFilter globalFilter={globalFilterValue || ''} setGlobalFilter={setGlobalFilterValue} />
      </Navbar.Group>
    </Navbar>
  );
  return (
    <>
      {isOpsFeedsAndIntegrationDataFetching && <GlobalLoader></GlobalLoader>}
      <Box p={3}>
        {isOpsFeedsAndIntegrationDataLoading && <TableLoader></TableLoader>}
        {opsFeedsAndIntegrationData?.custodialAccounts && (
          <CustomCard
            heading="Custodial Accounts"
            body={
              <>
                {Filters}
                <Table
                  columns={custodialAccountsColumn}
                  data={opsFeedsAndIntegrationData?.custodialAccounts}
                  custodialFeeds={opsFeedsAndIntegrationData.custodialFeeds}
                  setSelectedCustodialFeed={setSelectedCustodialFeed}
                  globalFilterValue={globalFilterValue || ''}
                />
              </>
            }
          ></CustomCard>
        )}
        {/*
        <Drawer
          icon={'list-detail-view'}
          title={<h6 className="bp3-heading">{'Feeds of ' + selectedCustodialAccount?.custodialAccountName}</h6>}
          size={'70%'}
          isOpen={!!selectedCustodialAccount?.id}
          onClose={() => setSelectedCustodialAccount(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            <FeedsPanel data={custodialFeeds} setSelectedCustodialFeed={setSelectedCustodialFeed}></FeedsPanel>
          </div>
        </Drawer>
        */}
        <Drawer
          icon={'document'}
          title={<h6 className="bp3-heading">{'Files of Feed ' + selectedCustodialFeed?.feedName}</h6>}
          size={'75%'}
          isOpen={!!selectedCustodialFeed?.id}
          onClose={() => setSelectedCustodialFeed(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            <FilesPanel
              isLoading={isOpsFeedsAndIntegrationDataLoading}
              isFetching={isOpsFeedsAndIntegrationDataFetching}
              data={etlFiles}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStarteDate}
              setEndDate={setEndDate}
              setSelectedFile={setSelectedFile}
              selectedCustodialFeedId={selectedCustodialFeed?.id}
            ></FilesPanel>
          </div>
        </Drawer>
        <Drawer
          icon={'list-detail-view'}
          title={
            <h6 className="bp3-heading">
              {'Logs of file '}
              {selectedFile?.fileName}
            </h6>
          }
          size={'60%'}
          isOpen={!!selectedFile}
          onClose={() => setSelectedFile(undefined)}
        >
          <div className={Classes.DRAWER_BODY}>
            {!isJobsLogsDataLoading && <JobRunLogs data={jobRunLogs}></JobRunLogs>}
          </div>
        </Drawer>
      </Box>
    </>
  );
};

export default OpsFeedsAndIntegrations;

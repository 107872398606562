import { useQuery, UseQueryResult } from 'react-query';
import { useAxios } from '../providers/AxiosProvider';
import IErrorResponse from '@shared/interfaces/IErrorReponse';
import { IGetTradeItemsResponse } from '@shared/exchange/getTradeItems';

export const GET_TRADE_ITEM_KEY = 'get-trades-items';
export default function useGetTradeItems(
  tradeId: number | undefined,
): UseQueryResult<IGetTradeItemsResponse, IErrorResponse> {
  const axios = useAxios();
  return useQuery<IGetTradeItemsResponse, IErrorResponse>(
    [GET_TRADE_ITEM_KEY, { tradeId }],
    async () => {
      if (!tradeId) return [];
      return axios
        ?.get(`get-trade-items?tradeId=${tradeId}`)
        .then((res) => res.data.data)
        .catch((e) => {
          throw e.response.data;
        });
    },
    { staleTime: 60 * 1000, retry: false },
  );
}
